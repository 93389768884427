#product.top-category-4{
  #iqitproductsnav a{
    color: $blue_color
  }
  #wrapper{
    #gim-product-tags{

      ul{

        li{

          border: 1px solid $blue_color;

          &:first-child{


          }
          span{

          }
        }
      }
    }
    .breadcrumb{
      .col.col-auto{

      }
    }
  }
  #inner-wrapper{

    .product-info-row{

    }
    .col-product-image{
      #product-images-thumbs{
        .slick-track{

          .slick-slide{

          }
          .thumb-container{
            img{

            }
          }
          button.btn-secondary{

            i{
              &:before{

                background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png');
              }
            }
          }
        }
      }
      .product-cover{
        .expander{

        }
      }
    }
    .col-product-info{
      .product_header_container{

      }
      .product-reference{

        .label{

        }
        span{

        }
      }
      h1.page-title{

        span{

        }
      }
      h4{

      }
      #lhcom_product_page_average{

        .average_note{

        }
        .average_star_position{

          .lhcom_list_reviews .star_display_off,
          .star_display_off{
            background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png');
          }
          .lhcom_list_reviews .star_display_on,
          .star_display_on{
            background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png');
          }
          .lhcom_list_reviews .star_display_half,
          .star_display_half{
            background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png');
          }
        }
      }

      .product-information{

        .rte-content{

          #see-more-description-product{
            color: $blue_color;

          }
        }
        .product-prices{

          .tax-shipping-delivery-label{

          }
          .has-discount{
            .current-price{

              .product-price{
                color: $blue_color;

              }
              .offer-label{

              }
              .eshop-label{

              }
            }
          }
          .current-price{

            .product-price{

            }
            .offer-label{

            }
            .eshop-label{

            }
          }
          .product-discount{

            .regular-price{

            }
            .eshop-label{

            }
          }
          .physical-shop-price{

          }
          .badge-discount{

          }
        }
        .product-actions{

          form{

            .product-variants{

              .form-control-label{

              }
              .custom-select2{
                select{

                  &:focus{

                  }
                }
                &:after{
                  color: $blue_color;

                }
              }
            }
            .product-quantity{

              .qty-label{

              }
              .qty{

                #quantity_wanted{

                }
                .input-group-btn-vertical{
                  button{

                    &:hover{

                    }
                    .touchspin-up{

                      &:before{
                        background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png');

                      }
                    }
                    .touchspin-down{

                    }
                  }
                }
              }
            }
            .product-add-to-cart{

              .btn-iqitcompare-add{

              }
              .btn-iqitwishlist-add{

              }
              .add-to-cart{

                background-color: $blue_color;

                .fa-shopping-bag{

                  &:before{

                  }
                  &:after{

                  }
                }
                &:hover{

                  color: $blue_color;
                  .fa-shopping-bag{
                    background-color: $blue_color;
                    &:before{


                    }
                    &:after{

                    }
                  }
                }
              }
            }
            .product-additional-info{
              .see_price{
                background-color: $blue_color;
                &:hover{
                  background-color: #fff;
                  color: $blue_color;
                }
              }
            }
          }
          .after-product-actions{

            .wrapper-field-eof{
              .eof-banner{

              }
            }
          }
        }
      }
    }
    .tabs.product-tabs{
      #product-infos-tabs{

        .nav-item{

          .nav-link{

            &.active{
              border-top-color: $blue_color;

            }
          }
        }
      }
      #product-infos-tabs-content{

        ul{
          li{

            &:before{

              background-color: $blue_color;

            }
          }
        }
        .product-features{
          table{
            tbody{
              tr{
                td{

                  ul{
                    li{

                      &:before{

                        background-color: $blue_color;
                      }
                      &:last-child{
                        &:before{

                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        #lhcom_product_page_reviews{
          .review{
            .rate_star{

              .lhcom_list_reviews .star_display_off,
              .star_display_off{
                background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png');
              }
              .lhcom_list_reviews .star_display_on,
              .star_display_on{
                background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png');
              }
              .lhcom_list_reviews .star_display_half,
              .star_display_half{
                background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png');
              }
            }
            .review_column{
              .content{
                border-left: 1px solid $blue_color!important;
              }
              .review_column{
                .review_text{

                }
                .lhcom_usefulness_like_dislike{
                  .vote-like{
                    a{
                      i{

                      }
                    }
                  }
                  .vote-dislike{
                    a{
                      i{

                      }
                    }
                  }
                }
              }
            }
          }
          .lhcom_buttons{
            .write{
              .btn-primary{
                background-color: $blue_color;
                border:1px solid $blue_color;
                &:hover{
                  color: $blue_color;
                }
              }
            }
            .view{
              .all-product-link{
                span{
                  i{

                  }
                }
              }
            }
          }
        }
      }
    }
    #product-infos-accordion-mobile{
      .nav-tabs{
        .nav-link{
          border-color: $blue_color;
          .fa-angle-up{
            &:before{
              background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png');
            }
          }
        }
      }
      .tab-content{
        *{

        }
        ul{
          li{

            &:before{

              background-color: $blue_color;

            }
          }
        }
      }
    }
    .wrapper-field-line{
      background-color: $blue_color;

      @media(max-width: 1245px){

      }
      &:before{

        background-color: $blue_color;

      }
      &:after{

        background-color: $blue_color;

      }
      .field-line-inner{
        .line-title{

        }
        .box-line{

          .box-line-title{

          }
          .box-line-text{

          }
          &:last-child{

          }
          &.box-line-1{
            .box-line-title{
              &:before{

              }
            }
          }
          &.box-line-2{
            .box-line-title{
              &:before{

              }
            }
          }
          &.box-line-3{
            .box-line-title{
              &:before{

              }
            }
          }
        }
      }
    }
    .crossselling-products{
      .block-content{
        .slick-arrow{

          &.slick-prev{

          }
          &.slick-next{

          }
        }
      }
    }
    .product-accessories{

      .section-title{

      }
      .block-content{
        .slick-arrow{

          &.slick-prev{

          }
          &.slick-next{

          }
        }
      }
    }
  }
  #footer{

  }
  #form_show_price{

    input,
    textarea{

    }
    label{

    }
    .wrapp_action_quantity{

      a{

        &.up_quantity{
          &:before{
            background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png');
          }
        }
        &.down_quantity{
          &:before{
            background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png');
          }
        }
      }
    }
    #sendShowPrice{
      background-color: $blue_color;
      font-size: 15px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      transition: 0.3s ease;
      &:hover{
        background-color: #fff;
        color: $blue_color;
      }
    }
  }
}




@media(max-width: $tablet){
  #product.top-category-4{
    #inner-wrapper{

      .col-product-image{
        #product-images-thumbs{
          .slick-track{

            .slick-slide{

            }
            .thumb-container{
              img{

              }
            }
          }
        }
        .product-cover{
          .expander{

          }
        }
      }
      .col-product-info{
        .product_header_container{

        }
        .product-reference{

          .label{

          }
          span{

          }
        }
        h1.page-title{

          span{

          }
        }
        h4{

        }
        #lhcom_product_page_average{

          .average_note{

          }
          .average_star_position{

            .lhcom_list_reviews .star_display_off,
            .star_display_off{

            }
            .lhcom_list_reviews .star_display_on,
            .star_display_on{

            }
            .lhcom_list_reviews .star_display_half,
            .star_display_half{

            }
          }
        }

        .product-information{

          .rte-content{

          }
          .product-prices{

            .tax-shipping-delivery-label{

            }
            .current-price{

              .product-price{

              }
              .offer-label{

              }
              .eshop-label{

              }
            }
            .product-discount{

              .regular-price{

              }
              .eshop-label{

              }
            }
            .physical-shop-price{

            }
            .badge-discount{

            }
          }
          .product-actions{

            form{

              .product-variants{

                .form-control-label{

                }
                .custom-select2{
                  select{

                    &:focus{

                    }
                  }
                  &:after{

                  }
                }
              }
              .product-quantity{

                .qty-label{

                }
                .qty{

                  #quantity_wanted{

                  }
                  .input-group-btn-vertical{
                    button{

                      &:hover{

                      }
                      .touchspin-up{

                        &:before{

                        }
                      }
                      .touchspin-down{

                      }
                    }
                  }
                }
              }
              .product-add-to-cart{

                .btn-iqitcompare-add{

                }
                .btn-iqitwishlist-add{

                }
                .add-to-cart{

                  .fa-shopping-bag{

                    &:before{

                    }
                    &:after{

                    }
                  }
                  &:hover{

                    .fa-shopping-bag{

                      &:before{


                      }
                      &:after{

                      }
                    }
                  }
                }
              }
              .product-additional-info{

              }
            }
            .after-product-actions{

              .wrapper-field-eof{
                .eof-banner{

                }
              }
            }
          }
        }
      }
      .tabs.product-tabs{
        #product-infos-tabs{

          .nav-item{

            .nav-link{


              &.active{

              }
            }
          }
        }
        #product-infos-tabs-content{

          .product-features{
            table{
              tbody{
                tr{
                  td{

                    ul{
                      li{

                        &:before{

                        }
                        &:last-child{
                          &:before{

                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          #lhcom_product_page_reviews{
            .review{
              .rate_star{

                .lhcom_list_reviews .star_display_off,
                .star_display_off{

                }
                .lhcom_list_reviews .star_display_on,
                .star_display_on{

                }
                .lhcom_list_reviews .star_display_half,
                .star_display_half{

                }
              }
              .review_column{
                .content{

                }
                .review_column{
                  .review_text{

                  }
                  .lhcom_usefulness_like_dislike{
                    .vote-like{
                      a{
                        i{

                        }
                      }
                    }
                    .vote-dislike{
                      a{
                        i{

                        }
                      }
                    }
                  }
                }
              }
            }
            .lhcom_buttons{
              .write{
                .btn-primary{
                  background-color: $blue_color;
                  &:hover{

                  }
                }
              }
              .view{
                .all-product-link{
                  span{
                    i{

                    }
                  }
                }
              }
            }
          }
        }
      }
      #product-infos-accordion-mobile{
        .nav-tabs{
          .nav-link{

          }
        }
        .tab-content{
          *{

          }
        }
      }
      .wrapper-field-line{

        &:before{

        }
        &:after{

        }
        .field-line-inner{
          .line-title{

          }
          .box-line{

            .box-line-title{

            }
            .box-line-text{

            }
            &:last-child{

            }
            &.box-line-1{

              .box-line-title{
                &:before{

                }
              }
            }
            &.box-line-2{

              .box-line-title{
                &:before{

                }
              }
            }
            &.box-line-3{

              .box-line-title{
                &:before{

                }
              }
            }
          }
        }
      }
    }
  }
}
@media(max-width: $mobile){
  #product.top-category-4{
    #inner-wrapper{

      .product-info-row{

      }
      .col-product-image{
        #product-images-thumbs{
          .slick-track{

            .slick-slide{

            }
            .thumb-container{
              img{

              }
            }
            button.btn-secondary{

              i{
                &:before{

                }
              }
            }
          }
        }
        .product-cover{
          .expander{

          }
        }
      }
      .col-product-info{

        .product_header_container{

        }
        .product-reference{

          .label{

          }
          span{

          }
        }
        h1.page-title{

          span{

          }
        }
        h4{

        }
        #lhcom_product_page_average{

          .average_note{

          }
          .average_star_position{

            .lhcom_list_reviews .star_display_off,
            .star_display_off{

            }
            .lhcom_list_reviews .star_display_on,
            .star_display_on{

            }
            .lhcom_list_reviews .star_display_half,
            .star_display_half{

            }
          }
        }

        .product-information{
          .rte-content{

          }
          .product-prices{

            .tax-shipping-delivery-label{

            }
            .current-price{

              .product-price{

              }
              .offer-label{

              }
              .eshop-label{

              }
            }
            .product-discount{

              .regular-price{

              }
              .eshop-label{

              }
            }
            .physical-shop-price{

            }
            .badge-discount{

            }
          }
          .product-actions{
            form{

              .product-variants{
                > .product-variants-item{

                }

                .form-control-label{

                }
                .custom-select2{
                  select{

                    &:focus{

                    }
                  }
                  &:after{

                  }
                }
              }
              .product-quantity{

                .qty-label{

                }
                .qty{
                  .input-group{

                  }
                  #quantity_wanted{

                  }
                  .input-group-btn-vertical{
                    button{

                      .touchspin-up{

                        &:before{

                        }
                      }
                      .touchspin-down{

                      }
                    }
                  }
                }
              }
              .product-add-to-cart{

                .btn-iqitcompare-add{

                }
                .btn-iqitwishlist-add{

                }
                .add-to-cart{

                  .fa-shopping-bag{

                    &:before{

                    }
                    &:after{

                    }
                  }
                  &:hover{

                    .fa-shopping-bag{
                      &:before{


                      }
                      &:after{

                      }
                    }
                  }
                }
              }
              .product-additional-info{

              }
            }

            .after-product-actions{

              .wrapper-field-eof{
                .eof-banner{


                }
              }
            }
          }
        }
      }
      .tabs.product-tabs{

        #product-infos-tabs{

          .nav-item{

            .nav-link{

              &.active{

              }
            }
          }
        }

        #product-infos-tabs-content{

          .product-features{
            table{
              tbody{
                tr{
                  td{

                    ul{
                      li{

                        &:before{

                        }
                        &:last-child{
                          &:before{

                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          #lhcom_product_page_reviews{
            .review{

              .rate_star{

                .lhcom_list_reviews .star_display_off,
                .star_display_off{

                }
                .lhcom_list_reviews .star_display_on,
                .star_display_on{

                }
                .lhcom_list_reviews .star_display_half,
                .star_display_half{

                }
              }
              .review_column{
                .content{


                }
                .review_column{
                  .review_text{

                  }
                  .lhcom_usefulness_like_dislike{
                    .vote-like{
                      a{
                        i{

                        }
                      }
                    }
                    .vote-dislike{
                      a{
                        i{

                        }
                      }
                    }
                  }
                }
              }
            }
            .lhcom_buttons{
              .write{
                .btn-primary{

                  &:hover{

                  }
                }
              }
              .view{
                .all-product-link{
                  span{
                    i{


                    }
                  }
                }
              }
            }
          }
        }
      }
      #product-infos-accordion-mobile{

        .nav-tabs{

          .nav-link{

            .fa-angle-up{

              &:before{
                background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png');

              }
            }
          }
        }
        .tab-content{

          &#product-infos-accordion-mobile-0{

          }
          .rte-content{

          }
        }
        .product-features{
          table{
            tbody{
              tr{
                td{

                  @media(max-width: 480px){

                  }
                  ul{
                    li{

                      &:before{

                        background-color: $blue_color;

                      }
                      &:last-child{
                        &:before{

                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        #lhcom_product_page_reviews{
          .review{
            .rate_star{

              .lhcom_list_reviews .star_display_off,
              .star_display_off{

              }
              .lhcom_list_reviews .star_display_on,
              .star_display_on{

              }
              .lhcom_list_reviews .star_display_half,
              .star_display_half{

              }
            }
            .review_column{
              .content{
                border-left: 1px solid $blue_color!important;
                @media(max-width: 767px){

                }

              }
              .review_column{
                .review_text{

                }
                .lhcom_usefulness_like_dislike{
                  .vote-like{
                    a{
                      i{

                      }
                    }
                  }
                  .vote-dislike{
                    a{
                      i{

                      }
                    }
                  }
                }
              }
            }
          }
          .lhcom_buttons{
            .write{
              .btn-primary{
                background-color: $blue_color;

                border:1px solid $blue_color;

                &:hover{

                  color: $blue_color;
                }
              }
            }
            .view{
              .all-product-link{
                span{
                  i{

                  }
                }
              }
            }
          }
        }
        .lhcom_list_reviews .review{

        }
      }
      .wrapper-field-line{

        &:before{

        }
        &:after{

        }
        .field-line-inner{
          .line-title{

          }
          .box-line{

            .box-line-title{

            }
            .box-line-text{

            }
            &:last-child{

            }
            &.box-line-1{

              .box-line-title{
                &:before{

                }
              }
            }
            &.box-line-2{

              .box-line-title{
                &:before{

                }
              }
            }
            &.box-line-3{

              .box-line-title{
                &:before{

                }
              }
            }
          }
        }
      }
    }
  }

  #gim-product-tags{

    ul{

      li{

        &:first-child{

        }
        &:last-child{

          span{
            border-bottom: 1px solid $blue_color;
          }
        }
        span{
          border: 1px solid $blue_color;

        }
      }
    }
  }
}
@media(max-width: 420px){
  #product.top-category-4{
    #inner-wrapper{

      .col-product-image{
        #product-images-thumbs{
          .slick-track{

            .slick-slide{

            }
            .thumb-container{
              img{

              }
            }
          }
        }
        .product-cover{
          .expander{

          }
        }
      }
      .col-product-info{
        .product_header_container{

        }
        .product-reference{

          .label{

          }
          span{

          }
        }
        h1.page-title{

          span{

          }
        }
        h4{

        }
        #lhcom_product_page_average{

          .average_note{

          }
          .average_star_position{

            .lhcom_list_reviews .star_display_off,
            .star_display_off{

            }
            .lhcom_list_reviews .star_display_on,
            .star_display_on{

            }
            .lhcom_list_reviews .star_display_half,
            .star_display_half{

            }
          }
        }

        .product-information{
          .rte-content{

          }
          .product-prices{

            .tax-shipping-delivery-label{

            }
            .current-price{

              .product-price{

              }
              .offer-label{

              }
              .eshop-label{

              }
            }
            .product-discount{

              .regular-price{

              }
              .eshop-label{

              }
            }
            .physical-shop-price{

            }
            .badge-discount{

            }
          }
          .product-actions{
            .product-variants{

              .form-control-label{

              }
              .custom-select2{
                select{

                  &:focus{

                  }
                }
                &:after{

                }
              }
            }
            .product-quantity{

              .qty-label{

              }
              .qty{

                #quantity_wanted{

                }
                .input-group-btn-vertical{
                  button{

                    .touchspin-up{

                      &:before{

                      }
                    }
                    .touchspin-down{

                    }
                  }
                }
              }
            }
            .product-add-to-cart{

              .btn-iqitcompare-add{

              }
              .btn-iqitwishlist-add{

              }
              .add-to-cart{

                .fa-shopping-bag{

                  &:before{

                  }
                  &:after{

                  }
                }
                &:hover{

                  .fa-shopping-bag{

                    &:before{


                    }
                    &:after{

                    }
                  }
                }
              }
            }
            .product-additional-info{

            }
          }
        }
      }
      .tabs.product-tabs{
        #product-infos-tabs{

          .nav-item{

            .nav-link{

              &.active{

              }
            }
          }
        }
        #product-infos-tabs-content{

          .product-features{
            table{
              tbody{
                tr{
                  td{

                    ul{
                      li{

                        &:before{

                        }
                        &:last-child{
                          &:before{

                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          #lhcom_product_page_reviews{
            .review{
              .rate_star{

                .lhcom_list_reviews .star_display_off,
                .star_display_off{

                }
                .lhcom_list_reviews .star_display_on,
                .star_display_on{

                }
                .lhcom_list_reviews .star_display_half,
                .star_display_half{

                }
              }
              .review_column{
                .content{

                }
                .review_column{
                  .review_text{

                  }
                  .lhcom_usefulness_like_dislike{
                    .vote-like{
                      a{
                        i{

                        }
                      }
                    }
                    .vote-dislike{
                      a{
                        i{

                        }
                      }
                    }
                  }
                }
              }
            }
            .lhcom_buttons{
              .write{
                .btn-primary{

                  &:hover{

                  }
                }
              }
              .view{
                .all-product-link{
                  span{
                    i{


                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
