body.page-cart{
  #main-page-content{
    .fa-angle-down{
      &:before{
        content: "\f107";
        background-image: none;
        background-position: unset;
        width: 100%;
        height: auto;
        color: $main_color;
        font-weight: 700;
      }
    }
    .fa-angle-up{
      &:before{
        font-weight: 700;
        color: $main_color;
      }
    }
    h1.page-title{
      border-bottom: none;
      padding-bottom: 0;
      color: #000;
    }
    .cart-grid{
      .cart-grid-body{
        .cart-item-header{
          padding-top: 10px;
          padding-bottom: 10px;
        }
        .product-price{
          color: #000;
        }
      }
      .cart-detailed-totals{
        .value{
          font-weight: 600;
        }
      }
      .cart-detailed-actions{
        .btn-primary{
          transition: 0.3s ease;
          &:hover{
            opacity: 0.9;
          }
          &:active{
            background-color: $main_color;
          }
        }
      }
      .btn-secondary{
        margin-top: 5px!important;
        background-color: #000;
        color: #fff;
      }
    }
  }
}