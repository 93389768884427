#header{
  background-color: $main_color;
  .container{
    max-width: 1800px;
    margin-right: auto;
    margin-left: auto;
    padding: 0;
  }
  #header-search-btn{
    .dropdown-search{
      background-color: $main_color;
      border-color: $main_color;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.7215686274509804);
      .form-search-control{
        background-color: #e4e4e4;
        border: solid 1px #e4e4e4;
        color: #000;
        &:focus{
          box-shadow: none;
        }
      }
    }
  }

  .header-top{
    padding: 0;
  }

  .center-nav{
    font-size: 1.5em;
    @extend .PFBulletinSansPro-Regular;
  }

  .header-nav{
    .right-nav{
      > div{
        &:after{
          content: none;
        }
      }
      .fa-credit-card{
        vertical-align: middle;
        margin-right: 3px;
      }
    }
  }
  .block-links-inline{
    ul{
      > li{
        &:after{
          background-color: $main_color;
          opacity: 1;
          margin: 0 .3rem 0.1rem;
          height: 1.1em;
        }
      }
    }
  }

  .ps-shoppingcart{
    .cart-products-count-btn{
      @extend .PFBulletinSansPro-Regular;
      top: -6px;
      right: -6px;
    }
    .fa-shopping-bag:before{
      @include sprite($cart-white);
    }
  }
}
#mobile-header{
  background-color: $main_color;
  #search-widget-mobile{
    background-color: $main_color;
    border-color: $main_color;
    .form-search-control{
      background-color: #f1f1f1;
      border: solid 1px #f1f1f1;
      color: #000;
      &:focus{
        box-shadow: none;
      }
    }
    .search-btn{
      i{
        position: relative;
        top: 2px;
      }
    }
  }
}

#iqithtmlandbanners-block-4{
  margin-right: 1rem;
  a > i {
    margin-right: 1.5rem;
    font-size: 1.142857rem;
    line-height: 12px;
    &.fa-facebook {
      margin-right: .5rem;
    }
  }
}

#language_selector{
  margin-left: 1rem;
  text-transform: uppercase;
}
@media(max-width: 1815px) and (min-width: $tablet + 1){
  #header{
    .container{

    }

    .header-top{

    }

    .center-nav{

    }
    #desktop-header{
      padding-left: 5px;
      padding-right: 5px;
    }
    .header-nav{
      padding-left: 5px;
      padding-right: 5px;

      .right-nav{
        padding-left: 0;
        padding-right: 20px;
        > div{
          &:after{

          }
        }
      }
    }
    .block-links-inline{
      ul{
        > li{
          &:after{

          }
        }
      }
    }

    .ps-shoppingcart{
      .cart-products-count-btn{

      }
    }
  }

}
@media(max-width: 1440px) and (min-width: $lgs +1){
  #header{

    .container{

    }

    .header-top{

    }

    .center-nav{
      position: relative;
      left: 28px;
    }

    .header-nav{
      .right-nav{

        > div{
          p{

          }
          &:after{

          }
        }
        .block-iqitlinksmanager-2{
          ul{
            li{
              a{

              }
            }
          }
        }
      }
      .left-nav{

        p{

        }
      }
    }
    .block-links-inline{
      ul{
        > li{
          &:after{

          }
        }
      }
    }

    .ps-shoppingcart{
      .cart-products-count-btn{


      }
    }
  }
  #iqithtmlandbanners-block-4{

  }
}
@media(max-width: $lgs){
  #header{

    .container{

    }

    .header-top{

    }

    .center-nav{
      font-size: 1.3em;
      width: 100%;
      flex: 0 0 auto;
      max-width: 129px;
      padding-left: 5px;
      padding-right: 5px;
    }

    .header-nav{
      .right-nav{
        width: 100%;
        flex: 0 0 auto;
        max-width: 417px;
        > div{
          p{
            font-size: 11px;
          }
          &:after{

          }
        }
        .block-iqitlinksmanager-2{
          ul{
            li{
              a{
                font-size: 11px;
              }
            }
          }
        }
      }
      .left-nav{
        width: 100%;
        flex: 0 0 auto;
        max-width: 465px;
        padding-right: 10px;
        p{
          font-size: 11px;
        }
      }
    }
    .block-links-inline{
      ul{
        > li{
          &:after{

          }
        }
      }
    }

    .ps-shoppingcart{
      .cart-products-count-btn{


      }
    }
  }
  #iqithtmlandbanners-block-4{
    margin-right: 5px;
  }
}
#_mobile_iqitmegamenu-mobile{

  #iqitmegamenu-mobile{

    > li{
      > a{

        @extend .PFBulletinSansPro-Regular;
      }
    }
  }
}
@media(max-width: 1250px){
  #header{
    #iqithtmlandbanners-block-2{
      font-size: 11px;
    }
    .header-nav{
      .right-nav{

        > div{
          p{
            font-size: 0px;
            i{
              display: none;
            }
          }
          &:after{

          }
        }
        .block-iqitlinksmanager-2{
          ul{
            li{
              a{

              }
            }
          }
        }
      }
      .left-nav{

        p{

        }
      }
    }
  }
}
@media(max-width: $tablet){
  #header{
    .container{

    }
    .header-top{

    }
    .center-nav{
      padding-right: 65px;
      max-width: 192px;
    }
    .header-nav{
      padding-bottom: 6px;
      .block-iqitlinksmanager-2{
        display: none !important;
      }
      .right-nav{
        position: absolute;
        right: 0;
        bottom: 0;
        //display: none;
        > div{
          p{

          }
          &:after{

          }
        }
        .block-iqitlinksmanager-2{
          ul{
            li{
              a{

              }
            }
          }
        }
      }
      .left-nav{
        padding-left: 43px;
        max-width: 495px;
        p{

        }
      }
    }
    .block-links-inline{
      ul{
        > li{
          &:after{

          }
        }
      }
    }
    .ps-shoppingcart{
      .cart-products-count-btn{


      }
    }

  }
}
#mobile-header{
  .mobile-main-bar{
    padding-left: 32px;
    padding-right: 32px;
    .col-auto{
      margin-left: 7px;
      #mobile-cart-toogle{
        .fa-shopping-bag{
          .mobile-cart-products-count{

          }
        }
      }
    }
    .col-mobile-menu-dropdown{
      &.show{
        background: none;
        #_mobile_iqitmegamenu-mobile{
          height: 100vh;
          position: fixed;
          z-index: 5005;
          top: 0;
          left: 0;
          overflow-x: hidden;
          width: 100%;
         > ul{
           padding-top: 100px;
           > li{
             > a{
               border-bottom: 2px solid #e38d8c;
             }
              .mm-expand{
                .fa-angle-down{
                  position: relative;
                  bottom: 10px;
                  &:before{
                    content: "\f107";
                    font-size: 25px;
                  }
                }
                .fa-angle-up{
                  bottom: 9px;
                  position: relative;
                  &:before{
                    font-size: 25px;
                  }
                }
              }
              ul{
                padding-left: 0;
                li{
                  a{
                    font-size: 13px;
                    border-bottom: 1px solid #a11b14;
                    line-height: 32px;
                    padding-bottom: 0;
                    padding-top: 0;
                    min-height: unset;
                  }
                }
              }
            }
           #language_selector{
             position: absolute;
             //top: 42px;
             //left: -8px;
             top: 58px;
             left: 9px;
             .language-selector-wrapper{
               .language-selector{
                 .expand-more{
                   font-size: 15px;
                   padding: 0;
                   min-height: 23px;
                   .fa-angle-down{
                     width: 30px;
                     height: 10px;
                     &:after{
                       display: none;
                     }
                     &:before{
                       content: '';
                       position: absolute;
                       top: 3px;
                       width: 8px;
                       height: 8px;
                       background-color: transparent;
                       border-left: 2px solid #fff;
                       border-top: 2px solid #fff;
                       right: 18px;
                       left: inherit;
                       box-shadow: none;
                       transform: rotate(225deg);
                     }
                   }
                 }
               }
             }
           }
           #iqithtmlandbanners-block-5{
             .block-title{
               display: none;
             }
             position: absolute;
             top:5px;
             left: 12px;
             .menu-phone{
               a{
                 width: 100%;
                 max-width: 143px;
                 position: relative;
                 font-size: 18px;
                 font-weight: 400;
                 margin: 0 auto;
                 padding: 0;
                 line-height: 27px;
                 min-height: unset;
                 opacity: 1;
                 padding-left: 20px;
                 text-align: left;
                 font-family: 'PFBulletinSansPro-Regular';
                 &:before{
                   content: "";
                   position: absolute;
                   display: inline-block;
                   @include sprite($phone);
                   @extend .custom-icon;
                   vertical-align: bottom;
                   left: 0;
                   top:50%;
                   transform: translateY(-50%);
                 }
               }
             }
           }
          }
          .menu-close{
            position: absolute;
            right: 16px;
            top: 47px;
            width: 40px;
            height: 40px;
            font-size: 0;
            &:before,
            &:after{
              content: "";
              position: absolute;
              display: inline-block;
              width: 23px;
              height:2px;
              background-color: #fff;
              left: 50%;
            }
            &:before{
              transform: rotate(45deg);
              top: 17px;
              left: 9px;

            }
            &:after{
              transform: rotate(-45deg);
              top: 17px;
              left: 9px;
            }
          }
          #iqitmegamenu-mobile{
            min-height: 100%;
            padding-left: 20px;
            padding-right: 20px;
            > li{
              > a{
                min-height: unset;
                font-size: 20px;
                padding: 4px 0px;
                padding-bottom: 15px;
              }
              &:nth-child(5){
                margin-top: 31px;
                border: none;
                //> a{
                //  font-size: 15px;
                //  border: none;
                //  line-height: 26px;
                //}
              }
              &:nth-child(n + 5){
                border: none;
                > a{
                  font-size: 15px;
                  border: none;
                  padding: 0;
                  line-height: 26px;
                  font-family:'Open Sans';
                }
              }
              &:nth-child(8){
                padding-left: 34px;
                margin-top: 5px;
                &:before{
                  content: "";
                  position: absolute;
                  width: 25px;
                  height: 20px;
                  left: 0;
                  top:50%;
                  transform: translateY(-37%);
                  background-image: url(../img/mobile-menu-bbclub.png);
                  background-repeat: no-repeat;
                }
              }
            }
          }
        }
      }
      margin-left: 20px;
      a{
        i{
          background-color: $main_color;
          padding: 7px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          position: relative;
          &:before{
            content: "";
            width: 26px;
            height: 2px;
            display: inline-block;
            box-shadow: 0 6px 0 0 #fff;
            background-color: #fff;
            top: 13px;
            position: absolute;
            left: 7px;
          }
          &:after{
            content: "";
            position: absolute;
            display: inline-block;
            //box-shadow: 0 8px 0 0 #fff;
            background-color: #fff;
            bottom: 13px;
            left: 7px;
            width: 26px;
            height: 2px;
          }
        }
      }
    }
  }
}
@media(max-width: $mobile){
  #header{
    .container{
      display: inline-block;
      width: 100%;
      .justify-content-between{
        flex-wrap: unset;
        margin-right: 0;
        margin-left: 0;
        width: 100%;
        flex-flow: column;
      }
    }
    .header-top{

    }
    .center-nav{
      margin: auto;
      margin-top: 8px;
      font-size: 16px;
      margin-bottom: 4px;
    }
    .header-nav{

      .right-nav{
        bottom: 5px;
        font-size: 15px;
        > div{
          p{

          }
          &:after{

          }
        }
        .block-iqitlinksmanager-2{
          ul{
            li{
              a{

              }
            }
          }
        }
      }
      .left-nav{
        max-width: unset;
        text-align: center;
        padding-left: 5px;
        padding-right: 5px;
        flex: unset;
        display: inline-block;
        width: 100%;
        p{

        }
      }
    }
    .block-links-inline{
      ul{
        > li{
          &:after{

          }
        }
      }
    }
    .ps-shoppingcart{
      .cart-products-count-btn{


      }
    }
    #mobile-header{
      .mobile-main-bar{
        padding-top: 0;
        padding-bottom: 38px;
        padding-left: 17px;
        padding-right: 17px;
        .col-auto{

          #mobile-cart-toogle{
            .fa-shopping-bag{
              .mobile-cart-products-count{

              }
            }
          }
        }
        .col-mobile-menu-dropdown{
          &.show{

            #_mobile_iqitmegamenu-mobile{

              #iqitmegamenu-mobile{

                > li{
                  > a{

                  }
                }
              }
            }
          }

          a{
            i{

              &:before{

              }
              &:after{

              }
            }
          }
        }
      }
    }
  }
}
@media(max-width: 470px){
  #header{
    .container{

      .justify-content-between{

      }
    }
    .header-top{

    }
    .center-nav{

    }
    .header-nav{

      .right-nav{

        > div{
          p{

          }
          &:after{

          }
        }
        .block-iqitlinksmanager-2{
          ul{
            li{
              a{

              }
            }
          }
        }
      }
      .left-nav{

        p{

        }
      }
    }
    .block-links-inline{
      ul{
        > li{
          &:after{

          }
        }
      }
    }
    .ps-shoppingcart{
      .cart-products-count-btn{


      }
    }
    #mobile-header{
      .mobile-main-bar{
        position: relative;
        text-align: center;
        padding-top: 8px;
        padding-bottom: 2px;
        .row-mobile-header{
          width: 100%;
          display: inline-block;
          .col-mobile-logo{
            text-align: left;
            width: 100%;
            float: left;
            margin-bottom: 15px;
            padding-left: 0;
            padding-right: 50px;
          }
        }
        .col-auto{
          display: inline-block;
          #mobile-cart-toogle{
            .fa-shopping-bag{
              position: relative;
              top: 2px;
              .mobile-cart-products-count{

              }
            }
          }
        }
        .col-mobile-menu-dropdown{
          margin-left: 0;
          position: absolute;
          top: 4px;
          right: 8px;
          &.show{

            #_mobile_iqitmegamenu-mobile{

              #iqitmegamenu-mobile{

                > li{
                  > a{

                  }
                }
              }
            }
          }

          a{
            i{

              &:before{

              }
              &:after{

              }
            }
          }
        }
      }
    }
  }
}
