#content{
  .rte-content{
    .doc-title{
      background-color: $main_color;
      padding: 3px;
      margin: 3px 0px;
      color: #fff;
      cursor: pointer;
    }
    .doc-answer{
      p{
        &:first-child{
          border:1px solid red;
          line-height: 1.5em;
          padding: 10px;
          margin-bottom: 10px;
        }
        &:first-of-type:first-letter{
          display: block;
          width: 30px;
          padding-top: 5px;
          float: left;
          font-size: 20px;
          color: #b11c11;
          font-weight: bold;
          padding-right: 10px;
        }
      }
      .doc-content-answer{
        padding: 10px;
        border: 1px solid #369bcf;
        line-height: 1.5em;
        margin-bottom: 10px;
        p{
          padding: 0;
          border:none;
          &:first-of-type:first-letter{
            display: block;
            width: 30px;
            padding-top: 5px;
            float: left;
            font-size: 20px;
            color: #369bcf;
            font-weight: bold;
            padding-right: 10px;
          }
        }
      }
    }
  }
}