#iqitmegamenu-wrapper{
  position: relative;
  .nav-link{
    @extend .PFBulletinSansPro-Regular;
  }
  .cbp-hrmenu-tab{
    .cbp-hrsub.cbp-notfit{
      top: calc(100% - 15px);
      left: 0;
      border-bottom: 5px solid $main_color;
      box-shadow: 0 2px 64px rgba(0, 0, 0, 0.1);
    }
  }
  .cbp-categories-row{
    >div{
      padding: 0 5px;
    }
    .cbp-category-link-w{
      a{
        color: $main_text;
      }
      .cbp-category-title{
        display: none;
      }
    }
    .cbp-category-thumb{
      width: 22.55%;
      margin: 0 1.45% 0 0;
      float: left;
      position: relative;
    }
    .cbp-category-tree{
      width: 74.55%;
      margin-left: 1.45%;
      columns: 3;
      margin-top: -10px;
      column-gap: 20px;
      li{
        padding: 0;
        margin: 0;
        break-inside: avoid;
        &:nth-child(3n + 1){
          clear: left;
        }
        &:nth-child(3n + 3){
          clear: right;
        }
        a{
          padding: 9px 0;
          border-bottom: 1px solid #c0c0c0;
          display: block;
          line-height: 1;
          transition: border-color $presta_transition, border-width-bottom $presta_transition;
          white-space: nowrap;
          width: 100%;
          max-width: 279px;
          overflow: hidden;
          text-overflow: ellipsis;
          &:hover{
            border-color: $main_color;
            border-bottom-width: 2px;
            padding-bottom: 8px;
          }
          &:before{
            content: none;
          }
        }
      }
    }
  }
}