.elementor-widget-icon-box{
  .fa-heart-o:before{
    @include sprite($heart);
  }
}

.elementor-element-y8xgt18{
  position: relative;
  z-index: 2;
}

#left-column .slick-products-carousel{
  height: auto;
  visibility: visible;
}

//#onepagecheckoutps_step_review_container {
//
//  > h5 {
//    position: relative;
//    &:after {
//      position: absolute;
//      right: 0;
//      top: 50%;
//      transform: translateY(-50%);
//      font-family: FontAwesome;
//      content: "\f067";
//      display: block;
//      font-size: 16px;
//      font-weight: 400;
//    }
//
//    &:hover {
//      cursor: pointer;
//    }
//  }
//
//  #header-order-detail-content,
//  #order-detail-content {
//    overflow: hidden;
//    height: 0;
//    visibility: hidden;
//    transition: height 0.3s;
//  }
//
//  &.contColappsed {
//
//    > h5 {
//      &:after {
//        content: "\f068";
//      }
//    }
//
//    #header-order-detail-content,
//    #order-detail-content {
//      height: auto;
//      visibility: visible;
//    }
//  }
//}

.fa.fa-clock-o.fa-spin{
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 2px solid $background;
  position: relative;
  animation: none;
  &:before,
  &:after{
    content: '|';
    background: none;
    position: absolute;
    bottom: 50%;
    left: 50%;
    background-color: $background;
    animation-name: clockHands;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    transform-origin: bottom center;
  }
  &:before{
    z-index: 3;
    font-size: 0.25em;
    font-weight: 700;
    animation-duration: 24s;
  }
  &:after{
    z-index: 4;
    font-size: 0.3em;
    animation-duration: 2s;
  }
}

@keyframes clockHands {
  from {transform: translateX(-50%) rotate(0)}
  to {transform: translateX(-50%) rotate(360deg)}
}

.category-description img{
  height: auto !important;
  max-width: 100%;
}

#mobile-cart-products-count:empty:before{
  content: '0';
}
#iqithtmlandbanners-block-7{
  margin-top: 20px;
  @media (max-width: $mobile){
    text-align: right;
  }
}
#iqithtmlandbanners-block-6,
#iqithtmlandbanners-block-7{
  @media (max-width: $mobile){
    margin: 20px 0 0;
    align-items: end;
  }
  .block-title{
    display: none;
  }
}

#search_widget,
#search-widget-mobile{
  .fa-search{
    &:before{
      background-position: $search-color-offset-x+3px $search-color-offset-y+3px;
    }
  }
}
