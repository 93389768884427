@media(max-width: $tablet){
  #index{
    #footer-container-first{
      padding-left: 32px;
      padding-right: 32px;
    }
  }
}
@media(max-width: 576px){
  #footer-container-first{
    .block-newsletter{
      p{
        //max-width: 267px;
        line-height: 23px;
      }
    }
    .ps-emailsubscription-block{
      .newsletter-input-group{
        margin-top: 0;
      }
    }
  }
}