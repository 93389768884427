body.page-customer-account{
  #content-wrapper{
    margin-top: 30px;
    margin-bottom: 30px;
    .page-header{
      .page-title{
        font-size: 27px;
        border: none;
        padding-bottom: 0;
      }
    }
    #content{
      hr{
        display: none;
      }
      #submit-login{
        background: $main_color;
        padding-left: 45px;
        padding-right: 45px;
        font-size: 15px;
        transition: 0.3s ease;
        &:hover{
          opacity: 0.9;
        }
        &:focus{
          box-shadow: none;
        }
        &:active{
          background: $main_color;
        }
      }
      .no-account{
        margin-top: 15px;
      }
    }
  }
}
body.page-customer-account{
  .page-header{
    .page-title{
      font-size: 27px;
      border: none;
      padding-bottom: 0;
    }
  }
  #content{
    .register-form{
      p{
        &:first-child{
          margin-bottom: 20px;
        }
      }
      .custom-checkbox{
        span{
          border: 2px solid #c2c2c2;
          width: 18px;
          height: 18px;
          .fa-check{
            top: 1px;
            &:before{
              color: $main_color;
              font-size: 13px;
            }
          }
        }
      }
      .btn-primary{
        background: $main_color;
        padding-left: 45px;
        padding-right: 45px;
        font-size: 15px;
        transition: 0.3s ease;
        &:hover{
          opacity: 0.9;
        }
        &:focus{
          box-shadow: none;
        }
        &:active{
          background: $main_color;
        }
      }
    }
  }
}