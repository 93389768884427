
#products{
  width: 100%;
  display: inline-block;
  .alert.alert-warning{
    margin-top: 60px;
  }
}
#js-product-list-top{
  border: none;
  padding-bottom: 2px;
  padding-top: 2px;
  margin-top: 41px;
  .view-switcher{
    display: none;
  }
  .col.col-auto{
    width: 100%;
    .showing.hidden-sm-down{
      display: none;
    }
    .products-sort-order,
    .products-nb-per-page{
      label{
        @extend .PFBulletinSansPro-Regular;
        float: left;
        font-size: 16px;
        font-weight: 400;
        margin-right: 8px;
        line-height: 27px;
        margin-bottom: 0;
      }
      .select-title{
        float: right;
        border: 2px solid #c2c2c2;
        width: 139px;
        position: relative;
        span{
          color: #9b9b9b;
          font-size: 13px;
        }
        .fa-angle-down{
          &:before{
            position: absolute;
            right: 7px;
            top:50%;
            transform: translateY(-50%);
          }
        }
      }
      .current{
        color: $main_color!important;
      }
      &.show{
        .select-title{

          .fa-angle-down{
            &:before{
              transform: translateY(-50%)rotate(180deg);
            }
          }
        }
      }
    }
    .products-nb-per-page{
      float: right;
      .select-title{
        width: 66px;
        color: #9b9b9b;
        font-size: 13px;
      }
      .dropdown-menu{
        border: 1px solid #c2c2c2;
        border-bottom: 1px solid $main_color;
        a{
          border-bottom: 1px solid #c2c2c2;
          &:last-child{
            text-align: center;
            background-color: $main_color;
            color: #fff!important;
            border: 1px solid $main_color;
            border-top:none;
            transition: 0.3s ease;
          }
        }
      }
    }
  }
}
#js-product-list{
  .products-grid{
    background-color: #fff;
    box-shadow: 0 2px 64px rgba(0, 0, 0, 0.1);
  }
  .pagination{
    ul{
      li{
        border:none;
        a{
          color: $main_color;
          font-size: 15px;
          padding: .4rem 2px;;
        }
        .next,
        .previous{
          position: relative;
          top:3px;
          i{
            &:before{
              font-size: 27px;
            }
          }
        }
        .next{
          padding-left: 12px;
        }
        .previous{
          padding-right: 12px;
          color: #c2c2c2;
        }
        &.current{
          a{
            color: #000;
          }
        }
      }
    }
  }
  .product-miniature-grid{
    height: auto;
    .product-description{
      position: relative;
      .price-countdown-wrapper{
        //position: absolute;
        //top: -30px;
      }
    }
  }
}
.page-category{
  //font-family: 'PFBulletinSansPro-Regular', sans-serif, Sans-serif;
  #content-wrapper{
    flex: 0 0 76%;
    max-width: 76%;
    padding-left: 9px;
    padding-right: 0;
  }
  #left-column{
    flex: 0 0 24%;
    max-width: 24%;
  }
  #inner-wrapper{
    #notifications +.row{
      position: relative;
      padding-top: 40px;
    }
    .row{
      &:nth-child(2){
        margin-bottom: 30px;
      }
      #js-product-list-header{
        width: 100%;
        display: inline-block;
        .page-title{

        }
      }
      h1.page-title{
        width: 100%;
        display: inline-block;
        //position: absolute;
        font-size: 50px;
        margin-bottom: 23px;
      }
      &.image-description-row{
        display: block;
        @extend .clearfix;
        + .row{
          @media(max-width: 767px){
            padding-top: 40px;
          }
          @media(max-width: 480px){
            padding-top: 0;
          }
          padding-top: 83px;
        }
      }
      .category-description{
        &+ .category-description-more, a{
          color: $main_color;
          transition: color $presta_transition;
          cursor: pointer;
          display: inline-block;
          &:hover{
            color: $highlighted_main;
          }
        }
      }
      .category-description-more,
      .category-description{
        float: left;
        margin-bottom: 0;
        transition: max-height $presta_transition;
        overflow: hidden;
        clear: left;
        h3{
          font-size: 50px;
          font-weight: 400;
          line-height: 54px;
          margin-bottom: 25px;
        }
        p{
          font-size: 14px;
          line-height: 20px;
        }
      }
      .category-image{
        float: right;
        width: 30%;
        margin-bottom: 0;
        position: relative;
        + .category-description
        , + .page-title{
          width: 70%;
          padding-right: 63px;
        }
        ~ .category-description-more{
          width: 70%;
          padding-right: 63px;
        }
      }
    }
  }
}

@media(max-width: 1199px) and (min-width: 768px){
  body.page-category{
    #wrapper{
      #content-wrapper{
        flex: 0 0 72%;
        max-width: 72%;
      }
    }
  }
}
@media(max-width: 991px) and (min-width: 768px){
  body.page-category{
    #wrapper{
      #content-wrapper{
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}

@media(max-width: 767px){
  body.page-category{
    #inner-wrapper{
      .row{
        h1.page-title{
          line-height: 45px;
          padding-top: 5px;
        }
        .category-description{
          width: 100%;
          padding: 0;
        }
        .category-image{
          width: 100%;
          float: unset;
          text-align: center;
          margin-top: 10px;
          + .category-description
          , + .page-title{
            width: 100%;
          }
        }
      }
    }
    #wrapper{
      #content-wrapper{
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}
