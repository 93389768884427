.scroll-to-content{
  .fa-long-arrow-down{
    cursor: pointer;
    &:before{
      transition: height $presta_transition,
                  background-position $presta_transition,
                  margin-top $presta_transition;
    }
    &:hover{
      &:before{
        margin-top: 3px;
        height: $red-arrow-down-height - 3px;
        background-position-y: $red-arrow-down-offset-y - 3px;
      }
    }
  }
}