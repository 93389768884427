// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$bicep-name: 'bicep';
$bicep-x: 181px;
$bicep-y: 336px;
$bicep-offset-x: -181px;
$bicep-offset-y: -336px;
$bicep-width: 20px;
$bicep-height: 18px;
$bicep-total-width: 493px;
$bicep-total-height: 466px;
$bicep-image: '../img/sprite.png';
$bicep: (181px, 336px, -181px, -336px, 20px, 18px, 493px, 466px, '../img/sprite.png', 'bicep', );
$calendar-name: 'calendar';
$calendar-x: 176px;
$calendar-y: 86px;
$calendar-offset-x: -176px;
$calendar-offset-y: -86px;
$calendar-width: 46px;
$calendar-height: 46px;
$calendar-total-width: 493px;
$calendar-total-height: 466px;
$calendar-image: '../img/sprite.png';
$calendar: (176px, 86px, -176px, -86px, 46px, 46px, 493px, 466px, '../img/sprite.png', 'calendar', );
$card-name: 'card';
$card-x: 0px;
$card-y: 454px;
$card-offset-x: 0px;
$card-offset-y: -454px;
$card-width: 16px;
$card-height: 12px;
$card-total-width: 493px;
$card-total-height: 466px;
$card-image: '../img/sprite.png';
$card: (0px, 454px, 0px, -454px, 16px, 12px, 493px, 466px, '../img/sprite.png', 'card', );
$cardiogram-name: 'cardiogram';
$cardiogram-x: 174px;
$cardiogram-y: 176px;
$cardiogram-offset-x: -174px;
$cardiogram-offset-y: -176px;
$cardiogram-width: 46px;
$cardiogram-height: 36px;
$cardiogram-total-width: 493px;
$cardiogram-total-height: 466px;
$cardiogram-image: '../img/sprite.png';
$cardiogram: (174px, 176px, -174px, -176px, 46px, 36px, 493px, 466px, '../img/sprite.png', 'cardiogram', );
$cart-name: 'cart';
$cart-x: 150px;
$cart-y: 260px;
$cart-offset-x: -150px;
$cart-offset-y: -260px;
$cart-width: 24px;
$cart-height: 24px;
$cart-total-width: 493px;
$cart-total-height: 466px;
$cart-image: '../img/sprite.png';
$cart: (150px, 260px, -150px, -260px, 24px, 24px, 493px, 466px, '../img/sprite.png', 'cart', );
$cart-white-name: 'cart_white';
$cart-white-x: 214px;
$cart-white-y: 260px;
$cart-white-offset-x: -214px;
$cart-white-offset-y: -260px;
$cart-white-width: 24px;
$cart-white-height: 24px;
$cart-white-total-width: 493px;
$cart-white-total-height: 466px;
$cart-white-image: '../img/sprite.png';
$cart-white: (214px, 260px, -214px, -260px, 24px, 24px, 493px, 466px, '../img/sprite.png', 'cart_white', );
$checkmark-name: 'checkmark';
$checkmark-x: 88px;
$checkmark-y: 0px;
$checkmark-offset-x: -88px;
$checkmark-offset-y: 0px;
$checkmark-width: 48px;
$checkmark-height: 48px;
$checkmark-total-width: 493px;
$checkmark-total-height: 466px;
$checkmark-image: '../img/sprite.png';
$checkmark: (88px, 0px, -88px, 0px, 48px, 48px, 493px, 466px, '../img/sprite.png', 'checkmark', );
$compare-name: 'compare';
$compare-x: 61px;
$compare-y: 397px;
$compare-offset-x: -61px;
$compare-offset-y: -397px;
$compare-width: 21px;
$compare-height: 17px;
$compare-total-width: 493px;
$compare-total-height: 466px;
$compare-image: '../img/sprite.png';
$compare: (61px, 397px, -61px, -397px, 21px, 17px, 493px, 466px, '../img/sprite.png', 'compare', );
$disc-name: 'disc';
$disc-x: 343px;
$disc-y: 256px;
$disc-offset-x: -343px;
$disc-offset-y: -256px;
$disc-width: 24px;
$disc-height: 25px;
$disc-total-width: 493px;
$disc-total-height: 466px;
$disc-image: '../img/sprite.png';
$disc: (343px, 256px, -343px, -256px, 24px, 25px, 493px, 466px, '../img/sprite.png', 'disc', );
$dosage-name: 'dosage';
$dosage-x: 262px;
$dosage-y: 86px;
$dosage-offset-x: -262px;
$dosage-offset-y: -86px;
$dosage-width: 41px;
$dosage-height: 46px;
$dosage-total-width: 493px;
$dosage-total-height: 466px;
$dosage-image: '../img/sprite.png';
$dosage: (262px, 86px, -262px, -86px, 41px, 46px, 493px, 466px, '../img/sprite.png', 'dosage', );
$dumbbell-name: 'dumbbell';
$dumbbell-x: 121px;
$dumbbell-y: 336px;
$dumbbell-offset-x: -121px;
$dumbbell-offset-y: -336px;
$dumbbell-width: 20px;
$dumbbell-height: 20px;
$dumbbell-total-width: 493px;
$dumbbell-total-height: 466px;
$dumbbell-image: '../img/sprite.png';
$dumbbell: (121px, 336px, -121px, -336px, 20px, 20px, 493px, 466px, '../img/sprite.png', 'dumbbell', );
$empty-star-name: 'empty_star';
$empty-star-x: 355px;
$empty-star-y: 397px;
$empty-star-offset-x: -355px;
$empty-star-offset-y: -397px;
$empty-star-width: 16px;
$empty-star-height: 14px;
$empty-star-total-width: 493px;
$empty-star-total-height: 466px;
$empty-star-image: '../img/sprite.png';
$empty-star: (355px, 397px, -355px, -397px, 16px, 14px, 493px, 466px, '../img/sprite.png', 'empty_star', );
$empty-star-large-name: 'empty_star_large';
$empty-star-large-x: 420px;
$empty-star-large-y: 118px;
$empty-star-large-offset-x: -420px;
$empty-star-large-offset-y: -118px;
$empty-star-large-width: 18px;
$empty-star-large-height: 18px;
$empty-star-large-total-width: 493px;
$empty-star-large-total-height: 466px;
$empty-star-large-image: '../img/sprite.png';
$empty-star-large: (420px, 118px, -420px, -118px, 18px, 18px, 493px, 466px, '../img/sprite.png', 'empty_star_large', );
$full-star-name: 'full_star';
$full-star-x: 299px;
$full-star-y: 397px;
$full-star-offset-x: -299px;
$full-star-offset-y: -397px;
$full-star-width: 16px;
$full-star-height: 14px;
$full-star-total-width: 493px;
$full-star-total-height: 466px;
$full-star-image: '../img/sprite.png';
$full-star: (299px, 397px, -299px, -397px, 16px, 14px, 493px, 466px, '../img/sprite.png', 'full_star', );
$full-star-large-name: 'full_star_large';
$full-star-large-x: 420px;
$full-star-large-y: 60px;
$full-star-large-offset-x: -420px;
$full-star-large-offset-y: -60px;
$full-star-large-width: 18px;
$full-star-large-height: 18px;
$full-star-large-total-width: 493px;
$full-star-large-total-height: 466px;
$full-star-large-image: '../img/sprite.png';
$full-star-large: (420px, 60px, -420px, -60px, 18px, 18px, 493px, 466px, '../img/sprite.png', 'full_star_large', );
$half-star-name: 'half_star';
$half-star-x: 411px;
$half-star-y: 397px;
$half-star-offset-x: -411px;
$half-star-offset-y: -397px;
$half-star-width: 16px;
$half-star-height: 14px;
$half-star-total-width: 493px;
$half-star-total-height: 466px;
$half-star-image: '../img/sprite.png';
$half-star: (411px, 397px, -411px, -397px, 16px, 14px, 493px, 466px, '../img/sprite.png', 'half_star', );
$half-star-large-name: 'half_star_large';
$half-star-large-x: 420px;
$half-star-large-y: 176px;
$half-star-large-offset-x: -420px;
$half-star-large-offset-y: -176px;
$half-star-large-width: 18px;
$half-star-large-height: 18px;
$half-star-large-total-width: 493px;
$half-star-large-total-height: 466px;
$half-star-large-image: '../img/sprite.png';
$half-star-large: (420px, 176px, -420px, -176px, 18px, 18px, 493px, 466px, '../img/sprite.png', 'half_star_large', );
$heart-name: 'heart';
$heart-x: 88px;
$heart-y: 176px;
$heart-offset-x: -88px;
$heart-offset-y: -176px;
$heart-width: 46px;
$heart-height: 41px;
$heart-total-width: 493px;
$heart-total-height: 466px;
$heart-image: '../img/sprite.png';
$heart: (88px, 176px, -88px, -176px, 46px, 41px, 493px, 466px, '../img/sprite.png', 'heart', );
$heart-small-name: 'heart_small';
$heart-small-x: 301px;
$heart-small-y: 336px;
$heart-small-offset-x: -301px;
$heart-small-offset-y: -336px;
$heart-small-width: 20px;
$heart-small-height: 18px;
$heart-small-total-width: 493px;
$heart-small-total-height: 466px;
$heart-small-image: '../img/sprite.png';
$heart-small: (301px, 336px, -301px, -336px, 20px, 18px, 493px, 466px, '../img/sprite.png', 'heart_small', );
$instagram-name: 'instagram';
$instagram-x: 0px;
$instagram-y: 336px;
$instagram-offset-x: 0px;
$instagram-offset-y: -336px;
$instagram-width: 21px;
$instagram-height: 21px;
$instagram-total-width: 493px;
$instagram-total-height: 466px;
$instagram-image: '../img/sprite.png';
$instagram: (0px, 336px, 0px, -336px, 21px, 21px, 493px, 466px, '../img/sprite.png', 'instagram', );
$minus-name: 'minus';
$minus-x: 420px;
$minus-y: 351px;
$minus-offset-x: -420px;
$minus-offset-y: -351px;
$minus-width: 18px;
$minus-height: 2px;
$minus-total-width: 493px;
$minus-total-height: 466px;
$minus-image: '../img/sprite.png';
$minus: (420px, 351px, -420px, -351px, 18px, 2px, 493px, 466px, '../img/sprite.png', 'minus', );
$nutrients-name: 'nutrients';
$nutrients-x: 262px;
$nutrients-y: 172px;
$nutrients-offset-x: -262px;
$nutrients-offset-y: -172px;
$nutrients-width: 37px;
$nutrients-height: 44px;
$nutrients-total-width: 493px;
$nutrients-total-height: 466px;
$nutrients-image: '../img/sprite.png';
$nutrients: (262px, 172px, -262px, -172px, 37px, 44px, 493px, 466px, '../img/sprite.png', 'nutrients', );
$nutrients-white-name: 'nutrients_white';
$nutrients-white-x: 343px;
$nutrients-white-y: 0px;
$nutrients-white-offset-x: -343px;
$nutrients-white-offset-y: 0px;
$nutrients-white-width: 37px;
$nutrients-white-height: 44px;
$nutrients-white-total-width: 493px;
$nutrients-white-total-height: 466px;
$nutrients-white-image: '../img/sprite.png';
$nutrients-white: (343px, 0px, -343px, 0px, 37px, 44px, 493px, 466px, '../img/sprite.png', 'nutrients_white', );
$phone-name: 'phone';
$phone-x: 478px;
$phone-y: 0px;
$phone-offset-x: -478px;
$phone-offset-y: 0px;
$phone-width: 15px;
$phone-height: 16px;
$phone-total-width: 493px;
$phone-total-height: 466px;
$phone-image: '../img/sprite.png';
$phone: (478px, 0px, -478px, 0px, 15px, 16px, 493px, 466px, '../img/sprite.png', 'phone', );
$plus-name: 'plus';
$plus-x: 420px;
$plus-y: 234px;
$plus-offset-x: -420px;
$plus-offset-y: -234px;
$plus-width: 18px;
$plus-height: 17px;
$plus-total-width: 493px;
$plus-total-height: 466px;
$plus-image: '../img/sprite.png';
$plus: (420px, 234px, -420px, -234px, 18px, 17px, 493px, 466px, '../img/sprite.png', 'plus', );
$product-bottle-name: 'product-bottle';
$product-bottle-x: 262px;
$product-bottle-y: 0px;
$product-bottle-offset-x: -262px;
$product-bottle-offset-y: 0px;
$product-bottle-width: 41px;
$product-bottle-height: 46px;
$product-bottle-total-width: 493px;
$product-bottle-total-height: 466px;
$product-bottle-image: '../img/sprite.png';
$product-bottle: (262px, 0px, -262px, 0px, 41px, 46px, 493px, 466px, '../img/sprite.png', 'product-bottle', );
$product-calendar-name: 'product-calendar';
$product-calendar-x: 176px;
$product-calendar-y: 0px;
$product-calendar-offset-x: -176px;
$product-calendar-offset-y: 0px;
$product-calendar-width: 46px;
$product-calendar-height: 46px;
$product-calendar-total-width: 493px;
$product-calendar-total-height: 466px;
$product-calendar-image: '../img/sprite.png';
$product-calendar: (176px, 0px, -176px, 0px, 46px, 46px, 493px, 466px, '../img/sprite.png', 'product-calendar', );
$product-heart-name: 'product-heart';
$product-heart-x: 0px;
$product-heart-y: 260px;
$product-heart-offset-x: 0px;
$product-heart-offset-y: -260px;
$product-heart-width: 46px;
$product-heart-height: 36px;
$product-heart-total-width: 493px;
$product-heart-total-height: 466px;
$product-heart-image: '../img/sprite.png';
$product-heart: (0px, 260px, 0px, -260px, 46px, 36px, 493px, 466px, '../img/sprite.png', 'product-heart', );
$red-angle-down-name: 'red_angle_down';
$red-angle-down-x: 478px;
$red-angle-down-y: 208px;
$red-angle-down-offset-x: -478px;
$red-angle-down-offset-y: -208px;
$red-angle-down-width: 12px;
$red-angle-down-height: 8px;
$red-angle-down-total-width: 493px;
$red-angle-down-total-height: 466px;
$red-angle-down-image: '../img/sprite.png';
$red-angle-down: (478px, 208px, -478px, -208px, 12px, 8px, 493px, 466px, '../img/sprite.png', 'red_angle_down', );
$red-angle-right-name: 'red_angle_right';
$red-angle-right-x: 478px;
$red-angle-right-y: 56px;
$red-angle-right-offset-x: -478px;
$red-angle-right-offset-y: -56px;
$red-angle-right-width: 8px;
$red-angle-right-height: 14px;
$red-angle-right-total-width: 493px;
$red-angle-right-total-height: 466px;
$red-angle-right-image: '../img/sprite.png';
$red-angle-right: (478px, 56px, -478px, -56px, 8px, 14px, 493px, 466px, '../img/sprite.png', 'red_angle_right', );
$red-angle-up-name: 'red_angle_up';
$red-angle-up-x: 478px;
$red-angle-up-y: 160px;
$red-angle-up-offset-x: -478px;
$red-angle-up-offset-y: -160px;
$red-angle-up-width: 12px;
$red-angle-up-height: 8px;
$red-angle-up-total-width: 493px;
$red-angle-up-total-height: 466px;
$red-angle-up-image: '../img/sprite.png';
$red-angle-up: (478px, 160px, -478px, -160px, 12px, 8px, 493px, 466px, '../img/sprite.png', 'red_angle_up', );
$red-arrow-down-name: 'red_arrow_down';
$red-arrow-down-x: 420px;
$red-arrow-down-y: 291px;
$red-arrow-down-offset-x: -420px;
$red-arrow-down-offset-y: -291px;
$red-arrow-down-width: 15px;
$red-arrow-down-height: 20px;
$red-arrow-down-total-width: 493px;
$red-arrow-down-total-height: 466px;
$red-arrow-down-image: '../img/sprite.png';
$red-arrow-down: (420px, 291px, -420px, -291px, 15px, 20px, 493px, 466px, '../img/sprite.png', 'red_arrow_down', );
$red-arrow-left-name: 'red_arrow_left';
$red-arrow-left-x: 361px;
$red-arrow-left-y: 336px;
$red-arrow-left-offset-x: -361px;
$red-arrow-left-offset-y: -336px;
$red-arrow-left-width: 19px;
$red-arrow-left-height: 14px;
$red-arrow-left-total-width: 493px;
$red-arrow-left-total-height: 466px;
$red-arrow-left-image: '../img/sprite.png';
$red-arrow-left: (361px, 336px, -361px, -336px, 19px, 14px, 493px, 466px, '../img/sprite.png', 'red_arrow_left', );
$red-arrow-right-name: 'red_arrow_right';
$red-arrow-right-x: 122px;
$red-arrow-right-y: 397px;
$red-arrow-right-offset-x: -122px;
$red-arrow-right-offset-y: -397px;
$red-arrow-right-width: 19px;
$red-arrow-right-height: 14px;
$red-arrow-right-total-width: 493px;
$red-arrow-right-total-height: 466px;
$red-arrow-right-image: '../img/sprite.png';
$red-arrow-right: (122px, 397px, -122px, -397px, 19px, 14px, 493px, 466px, '../img/sprite.png', 'red_arrow_right', );
$search-name: 'search';
$search-x: 278px;
$search-y: 260px;
$search-offset-x: -278px;
$search-offset-y: -260px;
$search-width: 24px;
$search-height: 24px;
$search-total-width: 493px;
$search-total-height: 466px;
$search-image: '../img/sprite.png';
$search: (278px, 260px, -278px, -260px, 24px, 24px, 493px, 466px, '../img/sprite.png', 'search', );
$search-color-name: 'search_color';
$search-color-x: 61px;
$search-color-y: 336px;
$search-color-offset-x: -61px;
$search-color-offset-y: -336px;
$search-color-width: 20px;
$search-color-height: 20px;
$search-color-total-width: 493px;
$search-color-total-height: 466px;
$search-color-image: '../img/sprite.png';
$search-color: (61px, 336px, -61px, -336px, 20px, 20px, 493px, 466px, '../img/sprite.png', 'search_color', );
$shaker-name: 'shaker';
$shaker-x: 343px;
$shaker-y: 84px;
$shaker-offset-x: -343px;
$shaker-offset-y: -84px;
$shaker-width: 29px;
$shaker-height: 46px;
$shaker-total-width: 493px;
$shaker-total-height: 466px;
$shaker-image: '../img/sprite.png';
$shaker: (343px, 84px, -343px, -84px, 29px, 46px, 493px, 466px, '../img/sprite.png', 'shaker', );
$shaker-white-name: 'shaker_white';
$shaker-white-x: 343px;
$shaker-white-y: 170px;
$shaker-white-offset-x: -343px;
$shaker-white-offset-y: -170px;
$shaker-white-width: 29px;
$shaker-white-height: 46px;
$shaker-white-total-width: 493px;
$shaker-white-total-height: 466px;
$shaker-white-image: '../img/sprite.png';
$shaker-white: (343px, 170px, -343px, -170px, 29px, 46px, 493px, 466px, '../img/sprite.png', 'shaker_white', );
$shop-name: 'shop';
$shop-x: 0px;
$shop-y: 0px;
$shop-offset-x: 0px;
$shop-offset-y: 0px;
$shop-width: 48px;
$shop-height: 48px;
$shop-total-width: 493px;
$shop-total-height: 466px;
$shop-image: '../img/sprite.png';
$shop: (0px, 0px, 0px, 0px, 48px, 48px, 493px, 466px, '../img/sprite.png', 'shop', );
$tick-name: 'tick';
$tick-x: 478px;
$tick-y: 110px;
$tick-offset-x: -478px;
$tick-offset-y: -110px;
$tick-width: 10px;
$tick-height: 10px;
$tick-total-width: 493px;
$tick-total-height: 466px;
$tick-image: '../img/sprite.png';
$tick: (478px, 110px, -478px, -110px, 10px, 10px, 493px, 466px, '../img/sprite.png', 'tick', );
$user-name: 'user';
$user-x: 86px;
$user-y: 260px;
$user-offset-x: -86px;
$user-offset-y: -260px;
$user-width: 24px;
$user-height: 24px;
$user-total-width: 493px;
$user-total-height: 466px;
$user-image: '../img/sprite.png';
$user: (86px, 260px, -86px, -260px, 24px, 24px, 493px, 466px, '../img/sprite.png', 'user', );
$user-color-name: 'user_color';
$user-color-x: 420px;
$user-color-y: 0px;
$user-color-offset-x: -420px;
$user-color-offset-y: 0px;
$user-color-width: 18px;
$user-color-height: 20px;
$user-color-total-width: 493px;
$user-color-total-height: 466px;
$user-color-image: '../img/sprite.png';
$user-color: (420px, 0px, -420px, 0px, 18px, 20px, 493px, 466px, '../img/sprite.png', 'user_color', );
$users-name: 'users';
$users-x: 0px;
$users-y: 176px;
$users-offset-x: 0px;
$users-offset-y: -176px;
$users-width: 48px;
$users-height: 44px;
$users-total-width: 493px;
$users-total-height: 466px;
$users-image: '../img/sprite.png';
$users: (0px, 176px, 0px, -176px, 48px, 44px, 493px, 466px, '../img/sprite.png', 'users', );
$video-button-name: 'video-button';
$video-button-x: 0px;
$video-button-y: 88px;
$video-button-offset-x: 0px;
$video-button-offset-y: -88px;
$video-button-width: 48px;
$video-button-height: 48px;
$video-button-total-width: 493px;
$video-button-total-height: 466px;
$video-button-image: '../img/sprite.png';
$video-button: (0px, 88px, 0px, -88px, 48px, 48px, 493px, 466px, '../img/sprite.png', 'video-button', );
$video-play-name: 'video_play';
$video-play-x: 88px;
$video-play-y: 88px;
$video-play-offset-x: -88px;
$video-play-offset-y: -88px;
$video-play-width: 48px;
$video-play-height: 48px;
$video-play-total-width: 493px;
$video-play-total-height: 466px;
$video-play-image: '../img/sprite.png';
$video-play: (88px, 88px, -88px, -88px, 48px, 48px, 493px, 466px, '../img/sprite.png', 'video_play', );
$white-arrow-left-name: 'white_arrow_left';
$white-arrow-left-x: 240px;
$white-arrow-left-y: 397px;
$white-arrow-left-offset-x: -240px;
$white-arrow-left-offset-y: -397px;
$white-arrow-left-width: 19px;
$white-arrow-left-height: 14px;
$white-arrow-left-total-width: 493px;
$white-arrow-left-total-height: 466px;
$white-arrow-left-image: '../img/sprite.png';
$white-arrow-left: (240px, 397px, -240px, -397px, 19px, 14px, 493px, 466px, '../img/sprite.png', 'white_arrow_left', );
$white-arrow-right-name: 'white_arrow_right';
$white-arrow-right-x: 181px;
$white-arrow-right-y: 397px;
$white-arrow-right-offset-x: -181px;
$white-arrow-right-offset-y: -397px;
$white-arrow-right-width: 19px;
$white-arrow-right-height: 14px;
$white-arrow-right-total-width: 493px;
$white-arrow-right-total-height: 466px;
$white-arrow-right-image: '../img/sprite.png';
$white-arrow-right: (181px, 397px, -181px, -397px, 19px, 14px, 493px, 466px, '../img/sprite.png', 'white_arrow_right', );
$white-compare-name: 'white_compare';
$white-compare-x: 0px;
$white-compare-y: 397px;
$white-compare-offset-x: 0px;
$white-compare-offset-y: -397px;
$white-compare-width: 21px;
$white-compare-height: 17px;
$white-compare-total-width: 493px;
$white-compare-total-height: 466px;
$white-compare-image: '../img/sprite.png';
$white-compare: (0px, 397px, 0px, -397px, 21px, 17px, 493px, 466px, '../img/sprite.png', 'white_compare', );
$white-heart-small-name: 'white_heart_small';
$white-heart-small-x: 241px;
$white-heart-small-y: 336px;
$white-heart-small-offset-x: -241px;
$white-heart-small-offset-y: -336px;
$white-heart-small-width: 20px;
$white-heart-small-height: 18px;
$white-heart-small-total-width: 493px;
$white-heart-small-total-height: 466px;
$white-heart-small-image: '../img/sprite.png';
$white-heart-small: (241px, 336px, -241px, -336px, 20px, 18px, 493px, 466px, '../img/sprite.png', 'white_heart_small', );
$spritesheet-width: 493px;
$spritesheet-height: 466px;
$spritesheet-image: '../img/sprite.png';
$spritesheet-sprites: ($bicep, $calendar, $card, $cardiogram, $cart, $cart-white, $checkmark, $compare, $disc, $dosage, $dumbbell, $empty-star, $empty-star-large, $full-star, $full-star-large, $half-star, $half-star-large, $heart, $heart-small, $instagram, $minus, $nutrients, $nutrients-white, $phone, $plus, $product-bottle, $product-calendar, $product-heart, $red-angle-down, $red-angle-right, $red-angle-up, $red-arrow-down, $red-arrow-left, $red-arrow-right, $search, $search-color, $shaker, $shaker-white, $shop, $tick, $user, $user-color, $users, $video-button, $video-play, $white-arrow-left, $white-arrow-right, $white-compare, $white-heart-small, );
$spritesheet: (493px, 466px, '../img/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
