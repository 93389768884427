#product{
  #wrapper{
    .breadcrumb{
      .col.col-auto{
        display: none;
      }
    }
  }
  #inner-wrapper{
    margin-top: 65px;
    .product-info-row{
      margin-bottom: 20px;
    }
    .col-product-image{
      #product-images-thumbs{
        //.slick-track{
        //  margin: auto;
        //  .slick-slide{
        //    max-width: 75px;
        //  }
        //  .thumb-container{
        //    img{
        //      margin: auto;
        //    }
        //  }
          button.btn-secondary{
            font-size: 0;
            background: none;
            border:none;
            padding: 5px 7px;

            i{
              &:before{
                content:"";
                display: inline-block;
                @include sprite($video-button);
              }
            }
          }
        //}
      }
      .product-cover{
        .expander{
          display: none;
        }
      }
    }
    .col-product-info{
      .product_header_container{
        border-bottom: none;
        margin-bottom: 2px;
      }
      .product-reference{
        font-size: 0.857142rem;
        line-height: 10px;
        text-transform: uppercase;
        color: $secondary_color;
        font-weight: bold;
        label{
          margin: 0;
        }
        span{
          font-size: 1.071428rem;
        }
      }
      h1.page-title{
        margin-bottom: 6px;
        margin-top: 16px;
        span{
          color: $secondary_color;
          font-size: 3rem;
          font-weight: bold;
          line-height: 1;
        }
      }
      h4{
        color: $secondary_color;
        @extend .PFBulletinSansPro-Regular;
        font-size: 15px;
        font-weight: 400;
        line-height: 15px;
        text-transform: uppercase;
      }
      #lhcom_product_page_average{
        width: 100%;
        display: inline-block;
        margin-top: 11px;
        .average_note{
          display: none;
        }
        .average_star_position{
          margin-left: 0;
          .lhcom_list_reviews .star_display_off,
          .star_display_off{
            @include sprite($empty-star-large);
          }
          .lhcom_list_reviews .star_display_on,
          .star_display_on{
            @include sprite($full-star-large);
          }
          .lhcom_list_reviews .star_display_half,
          .star_display_half{
            @include sprite($half-star-large);
          }
        }
      }

      .product-information{
        max-width: 460px;
        .rte-content{
          color: $secondary_color;
          font-family: "Open Sans";
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 20px;
          #see-more-description-product{
            color: $main_color;
            text-transform: uppercase;
            font-size: 15px;
            font-weight: 400;
            display: inline;
            @extend .PFBulletinSansPro-Regular;
            &:before{
              content: ' ';
            }
            &.margin-top{
              margin: 0;
            }
          }
        }
        .product-prices{
          border-bottom: 2px solid $secondary_background;
          padding-bottom: 26px;
          .tax-shipping-delivery-label{
            display: none;
          }
          .has-discount{
            .current-price{
              display: inline-block;
              margin-right: 13px;
              .product-price{
                color: #d23024;
                @extend .PFBulletinSansPro-Regular;
                font-size: 40px;
                font-weight: 400;
                line-height: 40px;
              }
              .offer-label{
                color: $secondary_color;
                @extend .PFBulletinSansPro-Regular;
                font-size: 15px;
                font-weight: 400;
                line-height: 15px;
                text-transform: uppercase;
                display: block;
                margin-top: 7px;
              }
              .eshop-label{
                color: $secondary_color;
                @extend .PFBulletinSansPro-Regular;
                font-size: 15px;
                font-weight: 400;
                line-height: 15px;
                text-transform: uppercase;
                display: block;
                margin-top: 7px;
              }
            }
          }
          .current-price{
            display: inline-block;
            margin-right: 13px;
            .product-price{
              color: #000;
              @extend .PFBulletinSansPro-Regular;
              font-size: 40px;
              font-weight: 400;
              line-height: 40px;
            }
            .offer-label{
              color: $secondary_color;
              @extend .PFBulletinSansPro-Regular;
              font-size: 15px;
              font-weight: 400;
              line-height: 15px;
              text-transform: uppercase;
              display: block;
              margin-top: 7px;
            }
            .eshop-label{
              color: $secondary_color;
              @extend .PFBulletinSansPro-Regular;
              font-size: 15px;
              font-weight: 400;
              line-height: 15px;
              text-transform: uppercase;
              display: block;
              margin-top: 7px;
            }
          }
          .product-discount{
            display: inline-block;
            margin-right: 13px;
            .regular-price{
              color: $secondary_color;
              @extend .PFBulletinSansPro-Regular;
              font-size: 40px;
              font-weight: 400;
              line-height: 40px;
              text-decoration: none;
            }
            .eshop-label{
              color: $secondary_color;
              @extend .PFBulletinSansPro-Regular;
              font-size: 15px;
              font-weight: 400;
              line-height: 15px;
              text-transform: uppercase;
              display: block;
              margin-top: 7px;
            }
          }
          .physical-shop-price-container{
            display: inline-block;
            .physical-shop-price{
              color: $secondary_color;
              font-size: 21px;
              font-weight: 400;
              line-height: 18px;
              @extend .PFBulletinSansPro-Regular;
              text-decoration: line-through;
            }
            .eshop-label{
              color: $secondary_color;
              @extend .PFBulletinSansPro-Regular;
              font-size: 15px;
              font-weight: 400;
              line-height: 15px;
              text-transform: uppercase;
              display: block;
              margin-top: 7px;
            }
          }
          .badge-discount{
            display: none;
          }
        }
        .product-actions{
          width: 100%;
          display: inline-block;
          form{
            float: left;
            margin-right: 5px;
            .product-variants{
              float: left;
              margin-right: 23px;
              .form-control-label{
                color: $secondary_color;
                @extend .PFBulletinSansPro-Regular;
                font-size: 16px;
                font-weight: 400;
                line-height: 16px;
                margin-top: 12px;
                padding-bottom: 6px;
              }
              .custom-select2{
                select{
                  border: 2px solid #c2c2c2;
                  padding: 4px 1rem;
                  line-height: 20px;
                  padding-right: 41px;
                  color: #9b9b9b;
                  font-family: "Open Sans";
                  font-size: 13px;
                  line-height: 20px;
                  height: auto;
                  &:focus{
                    box-shadow: none;
                  }
                }
                &:after{
                  color: $main_color;
                  font-size: 25px;
                  height: auto;
                  top: 50%;
                  transform: translateY(-50%);
                  bottom: auto;
                }
              }
            }
            .product-quantity{
              float: left;
              margin-top: .5rem;
              .qty-label{
                padding-bottom: 6px;
                padding-top: .8rem;
                color: $secondary_color;
                @extend .PFBulletinSansPro-Regular;
                font-size: 16px;
                font-weight: 400;
                line-height: 16px;
                display: block;
                margin-top: 4px;
              }
              .qty{
                width: 63px;
                float: left;
                border: 2px solid #c2c2c2;
                top: 1px;
                position: relative;
                #quantity_wanted{
                  padding-top: 5px;
                  padding-bottom: 4px;
                  padding-left: 6px;
                  padding-right: 6px;
                  text-align: center;
                  border: none;
                }
                .input-group-btn-vertical{
                  button{
                    padding: 5px 12px;
                    border: none;
                    &:hover{
                      background-color: #fff;
                    }
                    .touchspin-up{
                      top: 0px;
                      &:before{
                        @include sprite($red-angle-up);
                        content: "";
                        display: inline-block;
                      }
                    }
                    .touchspin-down{
                      top: -1px;
                    }
                  }
                }
              }
            }
            .product-add-to-cart{
              width: 100%;
              display: inline-block;
              padding-top: 7px!important;
              .btn-iqitcompare-add{
                display: none;
              }
              .btn-iqitwishlist-add{
                display: none;
              }
              .add-to-cart{
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
                background-color: $main_color;
                min-width: 260px;
                padding: 0 1.5rem;
                font-size: 15px;
                line-height: 36px;
                padding-right: 66px;
                text-transform: uppercase;
                padding-top: 4px;
                @extend .PFBulletinSansPro-Regular;
                text-transform: uppercase;
                .fa-shopping-bag{
                  position: absolute;
                  right: 3px;
                  top: 0;
                  background-color: #fff;
                  width: 40px;
                  height: 40px;
                  margin-right: 0;
                  z-index: 99;
                  transition: background-color $presta_transition;
                  &:before{
                    top: 50%;
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    opacity: 1;
                    transition: opacity $presta_transition;
                  }
                  &:after{
                    content: "";
                    top: 50%;
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    opacity: 0;
                    @include sprite($cart-white);
                    transition: opacity $presta_transition;
                  }
                }
                &:hover{
                  background-color: #fff;
                  color: $main_color;
                  .fa-shopping-bag{
                    background-color: $main_color;
                    &:before{
                      opacity: 0;

                    }
                    &:after{
                      opacity: 1;
                    }
                  }
                }
              }
            }
            .product-additional-info{
              border:none;
              .see_price{
                width: auto;
                font-size: 15px;
                background-color: $main_color;
                margin-top: 0;
                margin-bottom: 10px;
                min-width: 262px;
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
                transition: 0.3s ease;
                &:hover{
                  background-color: #fff;
                  color: $main_color;
                }
              }
            }
          }
          .after-product-actions{
            width: calc(22% - 5px);
            float: right;
            margin-left: 5px;
            margin-top: 19px;
            .wrapper-field-eof{
              .eof-banner{
                width: 76px;
                height: 76px;
                background-image: url(../img/Bitmap.png);
                background-repeat: no-repeat;
                float: right;
              }
            }
          }
        }
      }
      .scroll-icons{
        > a{
          display: inline-block;
          text-transform: uppercase;
          @extend .PFBulletinSansPro-Regular;
          padding: 5px;
          font-size: 1.2rem;
          &:hover{
            color: $main_color;
          }
          &:last-child{
            margin-right: 0;
          }
          &#nutrition-button{
            line-height: $nutrients-height;
          }
          &#useful-button{
            line-height: $shaker-height;
          }
        }
      }
    }
    .tabs.product-tabs{
      #product-infos-tabs{
        margin-bottom: 0;
        border: none;
        .nav-item{
          margin-right: 0;
          .nav-link{
            border-bottom: none;
            padding: 11px 20px;
            color: $secondary_color;
            font-size: 20px;
            @extend .PFBulletinSansPro-Regular;
            opacity: 1;
            transition: border-top-width .1s $presta_transition_function, background-color .1s $presta_transition_function;
            &.active{
              border-top: 3px solid $main_color;
              background-color: $background;
              font-weight: bold;
            }
          }
        }
      }
      #product-infos-tabs-content{
        box-shadow: 0 2px 64px rgba(0, 0, 0, 0.15);
        padding: 19px;
        padding-top: 44px;
        padding-bottom: 38px;
        img{
          max-width: 100%;
        }
        //ul{
        //  li{
        //    list-style: none;
        //    position: relative;
        //    &:before{
        //      content: "";
        //      position: absolute;
        //      display: inline-block;
        //      width: 15px;
        //      height: 1px;
        //      background-color: $main_color;
        //      left: -23px;
        //      top: 10px;
        //    }
        //  }
        //}
        .product-features{
          table{
            tbody{
              tr{
                td{
                  border: none;
                  padding: 0;
                  ul{
                    li{
                      display: inline-block;
                      position: relative;
                      margin-right: 20px;
                      &:before{
                        content: "";
                        position: absolute;
                        display: inline-block;
                        width: 2px;
                        height: 15px;
                        background-color: #c71b1b;
                        right: -11px;
                        top: 50%;
                        left: auto;
                        transform: translateY(-39%) rotate(30deg);
                      }
                      &:last-child{
                        &:before{
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        #lhcom_product_page_reviews{
          .review{
            .rate_star{
              margin-bottom: 8px;
              .lhcom_list_reviews .star_display_off,
              .star_display_off{
                @include sprite($empty-star-large);
              }
              .lhcom_list_reviews .star_display_on,
              .star_display_on{
                @include sprite($full-star-large);
              }
              .lhcom_list_reviews .star_display_half,
              .star_display_half{
                @include sprite($half-star-large);
              }
            }
            .review_column{
              .content{
                border-left: 1px solid #c71b1b!important;
              }
              .review_column{
                .review_text{
                  margin-top: 0;
                }
                .lhcom_usefulness_like_dislike{
                  .vote-like{
                    a{
                      i{
                        color: #149a14;
                      }
                    }
                  }
                  .vote-dislike{
                    a{
                      i{
                        color: $main_color;
                      }
                    }
                  }
                }
              }
            }
          }
          .lhcom_buttons{
            .write{
              .btn-primary{
                background-color: $main_color;
                color: #fff;
                border:1px solid $main_color;
                transition: 0.3s ease;
                &:hover{
                  background-color: #fff;
                  color: $main_color;
                }
              }
            }
            .view{
              .all-product-link{
                span{
                  i{
                    top: 7px;
                    position: relative;
                  }
                }
              }
            }
          }
        }
      }
    }
    #product-infos-accordion-mobile{
      img{
        max-width: 100%;
      }
      .nav-tabs{
        .nav-link{
          @extend .PFBulletinSansPro-Regular;
          font-size: 20px;
          font-weight: 400;
          line-height: 24px;
          padding: 8px 10px;
        }
      }
      .tab-content{
        *{
          @extend .PFBulletinSansPro-Regular;
        }
        ul{
          li{
            list-style: none;
            position: relative;
            &:before{
              content: "";
              position: absolute;
              display: inline-block;
              width: 15px;
              height: 1px;
              background-color: $main_color;
              left: -23px;
              top: 10px;
            }
          }
        }
      }
    }
    .wrapper-field-line{
      background-color: $main_color;
      width: 100%;
      display: none;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      padding-bottom: 58px;
      padding-top: 60px;
      margin-bottom: 24px;
      @media(max-width: 1245px){
        padding-left: 20px;
        padding-right: 20px;
      }
      &.show{
        display: inline-block;
      }
      &:before{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $main_color;
        left: -100%;
        top:0;
      }
      &:after{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $main_color;
        right: -100%;
        top:0;
      }
      .field-line-inner{
        .line-title{
          @extend .PFBulletinSansPro-Regular;
          color: #fff;
          font-size: 50px;
          text-align: center;
          font-weight: 400;
          line-height: 40px;
          padding-bottom: 24px;
        }
        .box-line{
          padding-top: 75px;
          &.text-md-center .box-line-title:before{
            @media(min-width: 768px){
              left: 50%;
              transform: translateX(-50%);
            }
          }
          .box-line-title{
            font-size: 28px;
            @extend .PFBulletinSansPro-Regular;
            font-weight: 400;
            line-height: 32px;
            color: #fff;
            position: relative;
            margin-bottom: 14px;
            &:before{
              content: "";
              position: absolute;
              left: 0;
            }
          }
          .box-line-text{
            color: #fff;
            font-family: "Open Sans";
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
          }
          &.box-line-1{
            .box-line-title{
              &:before{
                top: -56px;
                @include sprite($product-bottle);
              }
            }
          }
          &.box-line-2{
            .box-line-title{
              &:before{
                top: -54px;
                @include sprite($product-calendar);
              }
            }
          }
          &.box-line-3{
            .box-line-title{
              &:before{
                top: -49px;
                @include sprite($product-heart);
              }
            }
          }
        }
      }
    }
    .crossselling-products{
      .block-content{
        .slick-arrow{
          top: auto;
          bottom: 15px;
          right: auto;
          transform: translate(-50%, 100%);
          width: 40px;
          height: 40px;
          display: inline-block;
          cursor: pointer;
          &.slick-prev{
            padding: 0;
            left: calc(50% - 21px);
          }
          &.slick-next{
            padding: 0;
            left: calc(50% + 21px);
          }
        }
      }
    }
    .product-accessories{
      margin-top: 100px;
      .section-title{
        @extend .PFBulletinSansPro-Regular;
        color: $secondary_color;
        font-size: 50px;
        font-weight: 400;
        line-height: 40px;
        margin-bottom: 4px;
        border: none;
      }
      .block-content{
        .slick-arrow{
          top: auto;
          bottom: 15px;
          right: auto;
          transform: translate(-50%, 100%);
          width: 40px;
          height: 40px;
          display: inline-block;
          opacity:1;
          cursor: pointer;
          &.slick-prev{
            padding: 0;
            left: calc(50% - 21px);
          }
          &.slick-next{
            padding: 0;
            left: calc(50% + 21px);
          }
        }
      }
    }
  }
  #footer{
    margin-top: 68px;
  }
  #form_show_price{
    background: #fff;
    border-radius: 0;
    input,
    textarea{
      border:2px solid  #c2c2c2;
    }
    input[name="quantity"]{
      padding: 0 6px;
    }
    label{
      color: $secondary_color;
      font-weight: 700;
    }
    .wrapp_action_quantity{
      border:2px solid  #c2c2c2;
      border-left:none;
      a{
        background-color: #fff;
        padding: 5px;
        &.up_quantity{
          &:before{
            @include sprite($red-angle-up);
          }
        }
        &.down_quantity{
          &:before{
            @include sprite($red-angle-down);
          }
        }
      }
    }
    #sendShowPrice{
      background-color: $main_color;
      font-size: 15px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      transition: 0.3s ease;
      &:hover{
        background-color: #fff;
        color: $main_color;
      }
    }
    .success_stage_sp{

    }
  }
}

#iqitproductsnav {
  margin-top: 10px;
  a{
    color: $main_color;
    font-size: 24px;
    font-weight: 400;
    margin-left: 10px;
  }
}

#gim-product-tags{
  margin-bottom: 62px;
  ul{
    text-align: center;
    width: fit-content;
    overflow: hidden;
    margin: auto;
    display: block;
    padding-left: 1px;
    padding-right: 1px;
    li{
      //display: inline;
      border: 1px solid $main_color;
      //padding: 6px 10px;
      padding: 0px 10px;
      float: left;
      margin-left: -1px;
      margin-bottom: 1px;
      &:first-child{
        margin-left: 0;

      }
      span{
        color: $secondary_color;
        font-size: 13px;
        line-height: 20px;
        padding: 5.5px 10px;
        //width: 100%;
        display: inline-block;
        cursor: default;
        text-transform: uppercase;
      }
    }
  }
}

@media(max-width: $tablet){
  #product{
    #inner-wrapper{

      .col-product-image{
        #product-images-thumbs{
          .slick-track{

            .slick-slide{

            }
            .thumb-container{
              img{

              }
            }
          }
        }
        .product-cover{
          .expander{

          }
        }
      }
      .col-product-info{
        .product_header_container{

        }
        .product-reference{

          .label{

          }
          span{

          }
        }
        h1.page-title{

          span{

          }
        }
        h4{

        }
        #lhcom_product_page_average{

          .average_note{

          }
          .average_star_position{

            .lhcom_list_reviews .star_display_off,
            .star_display_off{

            }
            .lhcom_list_reviews .star_display_on,
            .star_display_on{

            }
            .lhcom_list_reviews .star_display_half,
            .star_display_half{

            }
          }
        }

        .product-information{

          .rte-content{

          }
          .product-prices{

            .tax-shipping-delivery-label{

            }
            .current-price{

              .product-price{

              }
              .offer-label{

              }
              .eshop-label{

              }
            }
            .product-discount{

              .regular-price{

              }
              .eshop-label{

              }
            }
            .physical-shop-price{

            }
            .badge-discount{

            }
          }
          .product-actions{

            form{

              .product-variants{

                .form-control-label{

                }
                .custom-select2{
                  select{

                    &:focus{

                    }
                  }
                  &:after{

                  }
                }
              }
              .product-quantity{

                .qty-label{

                }
                .qty{

                  #quantity_wanted{

                  }
                  .input-group-btn-vertical{
                    button{

                      &:hover{

                      }
                      .touchspin-up{

                        &:before{

                        }
                      }
                      .touchspin-down{

                      }
                    }
                  }
                }
              }
              .product-add-to-cart{

                .btn-iqitcompare-add{

                }
                .btn-iqitwishlist-add{

                }
                .add-to-cart{

                  .fa-shopping-bag{

                    &:before{

                    }
                    &:after{

                    }
                  }
                  &:hover{

                    .fa-shopping-bag{

                      &:before{


                      }
                      &:after{

                      }
                    }
                  }
                }
              }
              .product-additional-info{

              }
            }
            .after-product-actions{

              .wrapper-field-eof{
                .eof-banner{

                }
              }
            }
          }
        }
      }
      .tabs.product-tabs{
        #product-infos-tabs{

          .nav-item{

            .nav-link{


              &.active{

              }
            }
          }
        }
        #product-infos-tabs-content{

          .product-features{
            table{
              tbody{
                tr{
                  td{

                    ul{
                      li{

                        &:before{

                        }
                        &:last-child{
                          &:before{

                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          #lhcom_product_page_reviews{
            .review{
              .rate_star{

                .lhcom_list_reviews .star_display_off,
                .star_display_off{

                }
                .lhcom_list_reviews .star_display_on,
                .star_display_on{

                }
                .lhcom_list_reviews .star_display_half,
                .star_display_half{

                }
              }
              .review_column{
                .content{

                }
                .review_column{
                  .review_text{

                  }
                  .lhcom_usefulness_like_dislike{
                    .vote-like{
                      a{
                        i{

                        }
                      }
                    }
                    .vote-dislike{
                      a{
                        i{

                        }
                      }
                    }
                  }
                }
              }
            }
            .lhcom_buttons{
              .write{
                .btn-primary{

                  &:hover{

                  }
                }
              }
              .view{
                .all-product-link{
                  span{
                    i{

                    }
                  }
                }
              }
            }
          }
        }
      }
      #product-infos-accordion-mobile{
        .nav-tabs{
          .nav-link{

          }
        }
        .tab-content{
          *{

          }
        }
      }
      .wrapper-field-line{
        padding-left: 20px;
        padding-right: 13px;
        &:before{

        }
        &:after{

        }
        .field-line-inner{
          .line-title{

          }
          .box-line{

            .box-line-title{

            }
            .box-line-text{

            }
            &:last-child{

            }
          }
        }
      }
    }
  }
}
@media(max-width: $mobile){
  #product{
    #inner-wrapper{
      margin-top: 47px;
      .product-info-row{
        margin-bottom: 2px;
      }
      .col-product-image{
        #product-images-thumbs{
          .slick-track{

            .slick-slide{

            }
            .thumb-container{
              img{

              }
            }
            button.btn-secondary{
              padding-bottom: 0px;
              padding-top: 0px;
              i{
                &:before{
                  content:"";
                  display: inline-block;
                  @include sprite($video-button);
                }
              }
            }
          }
        }
        .product-cover{
          .expander{

          }
        }
      }
      .col-product-info{
        margin-top: 10px;
        .product_header_container{

        }
        .product-reference{

          .label{

          }
          span{

          }
        }
        h1.page-title{

          span{

          }
        }
        h4{

        }
        #lhcom_product_page_average{

          .average_note{

          }
          .average_star_position{

            .lhcom_list_reviews .star_display_off,
            .star_display_off{

            }
            .lhcom_list_reviews .star_display_on,
            .star_display_on{

            }
            .lhcom_list_reviews .star_display_half,
            .star_display_half{

            }
          }
        }

        .product-information{
          .rte-content{
            margin-bottom: 20px;
          }
          .product-prices{
            padding-bottom: 30px;
            .tax-shipping-delivery-label{

            }
            .current-price{

              .product-price{

              }
              .offer-label{

              }
              .eshop-label{

              }
            }
            .product-discount{

              .regular-price{

              }
              .eshop-label{

              }
            }
            .physical-shop-price{

            }
            .badge-discount{

            }
          }
          .product-actions{
            form{
              width: 100%;
              margin-right: 0;
              .product-variants{
                > .product-variants-item{

                }

                .form-control-label{
                  margin-top: 8px;
                }
                .custom-select2{
                  select{
                    max-width: 139px;
                    &:focus{

                    }
                  }
                  &:after{

                  }
                }
              }
              .product-quantity{
                margin-top: 3px;
                .qty-label{
                  margin-top: 4px;
                }
                .qty{
                  .input-group{
                    height: 28px;
                  }
                  #quantity_wanted{

                  }
                  .input-group-btn-vertical{
                    button{

                      .touchspin-up{
                        top: 2px;
                        &:before{

                        }
                      }
                      .touchspin-down{
                        top: 0px;
                      }
                    }
                  }
                }
              }
              .product-add-to-cart{

                .btn-iqitcompare-add{

                }
                .btn-iqitwishlist-add{

                }
                .add-to-cart{
                  width: 260px;
                  position: relative;
                  .fa-shopping-bag{
                    right: 0px;
                    &:before{

                    }
                    &:after{

                    }
                  }
                  &:hover{

                    .fa-shopping-bag{
                      &:before{


                      }
                      &:after{

                      }
                    }
                  }
                }
              }
              .product-additional-info{

              }
            }

            .after-product-actions{
              margin-top: 24px;
              margin-right: 0;
              margin-left: 19px;
              float: left;
              .wrapper-field-eof{
                .eof-banner{
                  width: 53px;
                  height: 53px;
                  background-size: 100%;

                }
              }
            }
          }
        }
      }
      .tabs.product-tabs{
        margin-top: 0;
        #product-infos-tabs{

          .nav-item{

            .nav-link{

              &.active{

              }
            }
          }
        }

        #product-infos-tabs-content{

          .product-features{
            table{
              tbody{
                tr{
                  td{

                    ul{
                      li{

                        &:before{

                        }
                        &:last-child{
                          &:before{

                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          #lhcom_product_page_reviews{
            .review{

              .rate_star{

                .lhcom_list_reviews .star_display_off,
                .star_display_off{

                }
                .lhcom_list_reviews .star_display_on,
                .star_display_on{

                }
                .lhcom_list_reviews .star_display_half,
                .star_display_half{

                }
              }
              .review_column{
                .content{
                  border-bottom: none;

                }
                .review_column{
                  .review_text{

                  }
                  .lhcom_usefulness_like_dislike{
                    .vote-like{
                      a{
                        i{

                        }
                      }
                    }
                    .vote-dislike{
                      a{
                        i{

                        }
                      }
                    }
                  }
                }
              }
            }
            .lhcom_buttons{
              .write{
                .btn-primary{

                  &:hover{

                  }
                }
              }
              .view{
                .all-product-link{
                  span{
                    i{


                    }
                  }
                }
              }
            }
          }
        }
      }
      #product-infos-accordion-mobile{
        box-shadow: 0 2px 64px rgba(0, 0, 0, 0.1);
        .nav-tabs{
          border-bottom: none;
          .nav-link{
            background-color: #fff;
            .fa-angle-up{
              top: 0px;
              &:before{
                @include sprite($red-angle-up);
                content: "";
                display: inline-block;
              }
            }
          }
        }
        .tab-content{
          padding: 10px 20px;
          font-size: 14px;
          padding-bottom: 15px;
          &#product-infos-accordion-mobile-0{
            padding-top: 38px;
          }
          .rte-content{
            font-size: 14px;
            line-height: 20px;
          }
        }
        .product-features{
          table{
            tbody{
              tr{
                td{
                  border: none;
                  padding: 0;
                  @media(max-width: 480px){
                    width: 100%;
                    display: inline-block;
                    margin-bottom: 5px;
                  }
                  ul{
                    li{
                      display: inline-block;
                      position: relative;
                      margin-right: 20px;
                      &:before{
                        content: "";
                        position: absolute;
                        display: inline-block;
                        width: 2px;
                        height: 15px;
                        background-color: #c71b1b;
                        right: -11px;
                        top: 50%;
                        transform: translateY(-39%) rotate(30deg);
                      }
                      &:last-child{
                        &:before{
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        #lhcom_product_page_reviews{
          .review{
            .rate_star{
              margin-bottom: 8px;
              .lhcom_list_reviews .star_display_off,
              .star_display_off{
                @include sprite($empty-star-large);
              }
              .lhcom_list_reviews .star_display_on,
              .star_display_on{
                @include sprite($full-star-large);
              }
              .lhcom_list_reviews .star_display_half,
              .star_display_half{
                @include sprite($half-star-large);
              }
            }
            .review_column{
              .content{
                border-left: 1px solid #c71b1b!important;
                @media(max-width: 767px){
                  border-bottom: none;
                }

              }
              .review_column{
                .review_text{
                  margin-top: 0;
                }
                .lhcom_usefulness_like_dislike{
                  .vote-like{
                    a{
                      i{
                        color: #149a14;
                        font-family: 'Material Icons';
                      }
                    }
                  }
                  .vote-dislike{
                    a{
                      i{
                        color: $main_color;
                        font-family: 'Material Icons';
                      }
                    }
                  }
                }
              }
            }
          }
          .lhcom_buttons{
            .write{
              .btn-primary{
                background-color: $main_color;
                color: #fff;
                border:1px solid $main_color;
                transition: 0.3s ease;
                &:hover{
                  background-color: #fff;
                  color: $main_color;
                }
              }
            }
            .view{
              .all-product-link{
                span{
                  i{
                    top: 7px;
                    font-family: 'Material Icons';
                    position: relative;
                  }
                }
              }
            }
          }
        }
        .lhcom_list_reviews .review{
          border-bottom: none;
        }
      }
      .wrapper-field-line{
        padding-top: 55px;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 48px;
        margin-bottom: 5px;
        &:before{

        }
        &:after{

        }
        .field-line-inner{
          .line-title{
            line-height: 48px;
          }
        }
      }
    }
  }

  #gim-product-tags{
    margin-bottom: 37px;
    width: 100%;
    display: inline-block;
    ul{
      text-align: left;
      float: left;
      li{
        display: block;
        border:none;
        padding: 0;
        margin-top: -2px;
        float: unset;
        &:first-child{
          margin-top: 0;
        }
        &:last-child{

          span{
            border-bottom: 1px solid $main_color;
          }
        }
        span{
          border: 1px solid #c71b1b;
          padding: 6px 10px;
        }
      }
    }
  }
}
@media(max-width: 420px){
  #product{
    #inner-wrapper{

      .col-product-image{
        #product-images-thumbs{
          .slick-track{

            .slick-slide{

            }
            .thumb-container{
              img{

              }
            }
          }
        }
        .product-cover{
          .expander{

          }
        }
      }
      .col-product-info{
        .product_header_container{

        }
        .product-reference{

          .label{

          }
          span{

          }
        }
        h1.page-title{

          span{

          }
        }
        h4{

        }
        #lhcom_product_page_average{

          .average_note{

          }
          .average_star_position{

            .lhcom_list_reviews .star_display_off,
            .star_display_off{

            }
            .lhcom_list_reviews .star_display_on,
            .star_display_on{

            }
            .lhcom_list_reviews .star_display_half,
            .star_display_half{

            }
          }
        }

        .product-information{
          .rte-content{

          }
          .product-prices{

            .tax-shipping-delivery-label{

            }
            .current-price{

              .product-price{

              }
              .offer-label{

              }
              .eshop-label{

              }
            }
            .product-discount{

              .regular-price{

              }
              .eshop-label{

              }
            }
            .physical-shop-price{
              width: 100%;
              display: block;
              bottom: auto;
              margin-top: 10px;
            }
            .badge-discount{

            }
          }
          .product-actions{
            .product-variants{

              .form-control-label{

              }
              .custom-select2{
                select{

                  &:focus{

                  }
                }
                &:after{

                }
              }
            }
            .product-quantity{

              .qty-label{

              }
              .qty{

                #quantity_wanted{

                }
                .input-group-btn-vertical{
                  button{

                    .touchspin-up{

                      &:before{

                      }
                    }
                    .touchspin-down{

                    }
                  }
                }
              }
            }
            .product-add-to-cart{

              .btn-iqitcompare-add{

              }
              .btn-iqitwishlist-add{

              }
              .add-to-cart{

                .fa-shopping-bag{

                  &:before{

                  }
                  &:after{

                  }
                }
                &:hover{

                  .fa-shopping-bag{

                    &:before{


                    }
                    &:after{

                    }
                  }
                }
              }
            }
            .product-additional-info{

            }
          }
        }
      }
      .tabs.product-tabs{
        #product-infos-tabs{

          .nav-item{

            .nav-link{

              &.active{

              }
            }
          }
        }
        #product-infos-tabs-content{

          .product-features{
            table{
              tbody{
                tr{
                  td{

                    ul{
                      li{

                        &:before{

                        }
                        &:last-child{
                          &:before{

                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          #lhcom_product_page_reviews{
            .review{
              .rate_star{

                .lhcom_list_reviews .star_display_off,
                .star_display_off{

                }
                .lhcom_list_reviews .star_display_on,
                .star_display_on{

                }
                .lhcom_list_reviews .star_display_half,
                .star_display_half{

                }
              }
              .review_column{
                .content{

                }
                .review_column{
                  .review_text{

                  }
                  .lhcom_usefulness_like_dislike{
                    .vote-like{
                      a{
                        i{

                        }
                      }
                    }
                    .vote-dislike{
                      a{
                        i{

                        }
                      }
                    }
                  }
                }
              }
            }
            .lhcom_buttons{
              .write{
                .btn-primary{

                  &:hover{

                  }
                }
              }
              .view{
                .all-product-link{
                  span{
                    i{


                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media(min-width: 768px) {

}
