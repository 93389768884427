.darique-addon-presents {
  .section-title {
    margin-bottom: 20px;
  }

  .you-have-gift {
    margin-top: 24px;

    &:before {
      font-family: FontAwesome;
      content: "\f06b";
      display: inline-block;
      color: $main_color;
      margin-right: 8px;
      font-size: 1.5rem;
    }

    font-family: 'PFBulletinSansPro-Regular', sans-serif;
    border-bottom: 2px solid $main_color;
    color: $main_color;
    padding-bottom: 8px;
    margin-bottom: 15px;
    font-size: 1.5rem;
  }

  .darique-addon-grid {
    display: flex;
    flex-wrap: wrap;

    .gift-product {
      &-inner {
        border-bottom: 2px solid $secondary_background;
        margin: 0 15px;

        @media (max-width: $smaller) {
          margin: 0 5px;
        }
      }

      .gift-product-title {
        margin-top: 10px;
        display: block;
        vertical-align: bottom;
        min-height: 2.4em;
        line-height: 1;
        margin-bottom: 0.4rem;
        font-size: 14px;
        padding-bottom: 0.4rem;
        text-align: left;
        font-family: 'PFBulletinSansPro-Regular';
      }

      .gift-product-inner {
        text-align: center;

        .gift-img-product {
          img {
          //  max-width: 170px;
            max-width: 100%;
           // width: 100%;
          }
        }

        h5 {
          text-align: center;
        }
      }
    }
  }
}

.cart-darique {
  padding: 0;
  border: none;
  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .arrow_left {
  //  border-right-color: #55c65e;
  //  border-left: 0;
    left: 4px;
    opacity: 0.3;
    border: solid black;
    border-width: 0 5px 5px 0;
    display: inline-block;
    padding: 9px;
    top: 50%;
    transform: translateY(-50%) rotate(135deg);
  //  transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }
  }

  .arrow_right {
    opacity: 0.3;
    border: solid black;
    border-width: 0 5px 5px 0;
    display: inline-block;
    padding: 9px;
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
    right: 4px;
  //  transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }
  }

  .dariqueItem {
    .gift .gift-img img {
      max-width: 100%;
      height: auto;
      width: 100%;
    }
  }

  div#dariqueWrapper {
   // display: flex;
  //  flex-wrap: wrap;

   > .dariqueRuleContainer {
    //  flex: 1 1 50%;
      float: left;
      width: 48.5%;
   //   padding: 0 0 1.2rem 0;
    //  border-bottom: 1px solid hsla(0,0%,63%,.25);
      margin-bottom: 1.2rem;
      margin-right: 3%;
    //  align-self: flex-start;

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-child(2n+1) {
        clear: both;
      }
    }
  }

  ul.dariqueWrapper {
    margin-bottom: 0;
    display: block;
    margin: 0 30px;

    > div > div {
      &:first-child {
        .gift {
          margin-left: 0;
        }
      }

      &:last-child {
        .gift {
          margin-right: 0;
        }
      }
    }

    li {
      padding: 0;

      .gift {
        margin: 0 15px;
        float: left;
   //     min-height: 260px;
        border: none;
        text-align: center;
        cursor: pointer;
        //  padding: 15px;
        padding: 0 0 0.6em;
      //  border-bottom: 2px solid hsla(0, 0%, 63%, .25);

        &:hover,
        &:focus {
      //    border-bottom: 2px solid $main_color;
          background: transparent;
        }

        &.active {
   //       border-bottom: 2px solid $main_color;
          background: transparent;
        }

        h5 {
          font-size: 14px;
          line-height: 1.3;
          max-width: 100%;
          overflow: hidden;
          margin-bottom: 0;
          margin-top: 7px;
          text-align: left;
        }
      }
    }
  }

  div#dariqueWrapper {
    padding: 0;

    .page-heading {
      margin-bottom: 10px;
    }
  }

  .card-block {
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;

    h1 {
      text-align: left;
      font-size: 1.57143rem;
      font-weight: 700;
    }
  }

  @media(max-width: $smaller) {
    div#dariqueWrapper {

      > .dariqueRuleContainer {
        float: none;
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

#checkout {
  .cart-darique {
    margin-top: 20px;

    ul.dariqueWrapper {
      margin-bottom: 0;
    }

    ul#dariqueWrapper {
      margin-bottom: 0;
    }

    .page-heading {
      font-size: 11px;
      margin-bottom: 10px;
      letter-spacing: -0.02em;
    }

    hr {
      margin-top: 0;
    }

    h1 {
      font-size: 16px;
      font-family: "PFBulletinSansPro-Regular", sans-serif;
      font-weight: 400;
    }

      ul.dariqueWrapper {
        margin: 0 22px;

        > div > div {
          &:first-child {
            .gift {
              margin-left: 10px;
            }
          }

          &:last-child {
            .gift {
              margin-right: 10px;
            }
          }
        }

        li.dariqueItem {

          .gift {
       //     min-height: 220px;
            margin: 0 10px;

            h5 {
              line-height: 1.2;
              font-size: 12px;
              border-bottom: none !important;
            }
          }
        }
      }

    @media(max-width: $smaller) {
      div#dariqueWrapper {
        > .dariqueRuleContainer {
          float: left;
          width: 48.5%;
          margin-right: 3%;

          &:nth-child(2n) {
            margin-right: 0;
          }

          &:nth-child(2n+1) {
            clear: both;
          }
        }
      }
    }

    @media(max-width: 320px) {
      div#dariqueWrapper {

        > .dariqueRuleContainer {
          float: none;
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
}