.tabbed-carousel-front{
  @extend .clearfix;
  .tabs-container{
    width: 23.728814%;
    float: left;
    h2{
      @extend .PFBulletinSansPro-Regular;
      font-size: 3.571rem;
    }
    .nav-tabs{
      border-bottom: none;
      display: block;
      .nav-item{
        display: block;
        .nav-link{
          @extend .PFBulletinSansPro-Regular;
          @extend .clearfix;
          display: block;
          transition: border-color $presta_transition;
          font-size: 1.428571rem;
          border-color: $main_color;
          border-style: solid;
          border-width: 0 0 3px;
          opacity: 1;
          padding-top: 0.5rem;
          padding-bottom: 0.714285rem;
          .tab-title-part{
            &:first-child{
              float: left;
            }
            &:last-child{
              position: relative;
              overflow: hidden;
              height: 1em;
              &:before,
              &:after{
                content: '';
                display: inline-block;
                position: absolute;
                top: 50%;
                right: calc(100% - #{$red-angle-right-width + 5px});
                transform: translateY(-50%);
                transition: right $presta_transition, opacity $presta_transition;
              }
              &:before{
                @include sprite($red-angle-right);
                opacity: 1;
              }
              &:after{
                @include sprite($red-arrow-right);
                opacity: 0.01;
              }
            }
          }
          &.active,
          &:hover{
            border-color: $darker_secondary_background;
            .tab-title-part:last-child{
              &:before{
                opacity: 0;
                right: 0;
              }
              &:after{
                opacity: 1;
                right: 0;
              }
            }
          }
        }
      }
    }
  }
  .tab-content{
    background-color: $background;
    box-shadow: 0 2px 64px rgba(0, 0, 0, 0.1);
    width: 76.271186%;
    float: left;
    padding: 1.428571rem 0 1.5rem;
  }
}
@media(max-width: $tablet){
  .tabbed-carousel-front{
  .elementor-column-wrap{
    padding-right: 0;
    padding-left: 30px;
  }
    .tabs-container{
      width: 31.728814%;
      margin-top: 46px;

      h2{

      }
      .nav-tabs{

        .nav-item{

          .nav-link{

            .tab-title-part{
              &:first-child{

              }
              &:last-child{

                &:before,
                &:after{

                }
                &:before{

                }
                &:after{

                }
              }
            }
            &.active,
            &:hover{

              .tab-title-part:last-child{
                &:before{

                }
                &:after{

                }
              }
            }
          }
        }
      }
    }
    .tab-content{
      width: 68.271186%;
    }
  }
}
@media(max-width: $mobile){
  .tabbed-carousel-front{
    .elementor-column-wrap{
      padding-right: 15px;
      padding-left: 15px;
    }
    .tabs-container{
      width: 100%;
      margin-top: 0px;

      h2{
        font-size: 50px;
      }
      .nav-tabs{

        .nav-item{

          .nav-link{
            font-size: 20px;
            .tab-title-part{
              &:first-child{

              }
              &:last-child{

                &:before,
                &:after{

                }
                &:before{

                }
                &:after{

                }
              }
            }
            &.active,
            &:hover{

              .tab-title-part:last-child{
                &:before{

                }
                &:after{

                }
              }
            }
          }
        }
      }
    }
    .tab-content{
      width: 100%;
    }
  }
}