#modal-onexit{
  .modal__container{
    width: 100%;
    height: auto;
    padding: 23px;
    .modal__header{
      display: inline-block;
      float: right;
      position: relative;
      .modal__close{
        float: right;
        position: absolute;
        right: -17px;
        top: -17px;
        height: 28px;
        width: 28px;
        border: 1px solid $main_color;
        cursor: pointer;
        border-radius: 50%;
        &:before{
          color: $main_color;
          font-size: 20px;
          top: 1px;
          position: relative;
        }
      }
    }
  }
}