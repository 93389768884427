.product-miniature.product-miniature-layout-1{
  text-align: left;
  margin: 0 1.428571rem 2.857142rem;
  padding: 1.428571rem 0 0;
  border-bottom: 2px solid $secondary_background;
  display: block;
  flex-flow: initial;
  height: auto;
  .product-title{
    margin-bottom: 0.4rem;
    font-size: 18px;
    a{
      display: block;
      vertical-align: bottom;
      min-height: 2em;
      line-height: 1;
    }
  }
  .product-thumbnail{
    margin-bottom: 0.71429rem;
    padding-bottom: 91.525423%;
    position: relative;
    img{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.5;
      &.loaded{
        opacity: 1;
      }
    }
  }
  .product-description {
    //padding: 0.71429rem;
    //padding-top: 0;
    padding: 0;
    display: block;
  }
  //.price-countdown-wrapper{
  //  position: absolute;
  //  bottom: 5px;
  //  left: 50%;
  //  transform: translateX(-50%);
  //  display: inline-block;
  //}
  .product-reference{
    //font-size: 0.929rem;
    font-size: 13px;
    line-height: 1;
    padding: 0;
  }
  .product-price-and-shipping{
    @extend .PFBulletinSansPro-Regular;
    @extend .clearfix;
    margin-bottom: 5px;
  }
  .product-price,
  .regular-price{
    //font-size: 1.5rem;
    font-size: 21px;
    opacity: 1;
    float: left;
    line-height: 1.219047em;
  }
  .price-countdown-wrapper{
    float: right;
    .price-countdown{
      margin: 0;
      .price-countdown-title{
        display: inline-block;
        .fa-clock-o{
          font-size: 1.428571rem;
          vertical-align: bottom;
          margin-right: 0.5rem;
        }
      }
    }
  }
  @media(max-width: 480px){
    .price-countdown-wrapper{
      float: left;
    }
  }
  .regular-price{
    margin-left: 0.5rem;
  }
  .product-flags{
    right: 2.429rem;
    pointer-events: none;
  }
  .product-extra-flags{
    left: calc(100% - 2.429rem);
    right: 0;
    top: 0;
    position: absolute;
    pointer-events: none;
    > div{
      float: right;
      border-radius: 50%;
      width: 2.429rem;
      height: 2.429rem;
      display: inline-block;
      padding: 0;
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    .gim-feature-badge{
      pointer-events: auto;
      position: relative;
      background-color: $secondary_background;
      opacity: 0;
      transition: opacity $presta_transition;
      img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .discount-badge{
      text-align: center;
      line-height: 2.429rem;
      font-size: 1.286rem;
      @extend .PFBulletinSansPro-Regular;
      color: $background;
      background-color: $main-color;
      > span{
        font-size: 0.857rem;
        line-height: 2.429rem;
      }
    }
  }
  .product-flag{
    margin-top: 0;
    margin-bottom: 0.3rem;
    &.gim-best-seller-badge{
      background-color: #000;
      color: #fff;
      text-transform: uppercase;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
}

.product-miniature-grid{
  &:hover{
    .product-functional-buttons-bottom{
      transform: none;
    }
    .product-extra-flags{
      .gim-feature-badge{
        opacity: 1;
      }
    }
  }
  .product-functional-buttons-bottom{
    left: 0;
    right: 0;
    padding: 0;
    transform: translateY(50%);
    bottom: 0;
    background-color: transparent;
    position: absolute;
    z-index: 2;
    .product-functional-buttons-links{
      @extend .clearfix;
      a{
        &:hover{
          opacity: 1;
        }
      }
    }
  }
  @media(max-width: 991px){
    .product-functional-buttons-bottom{
      display: none;
    }
  }
  .product-add-cart{
    @extend .PFBulletinSansPro-Regular;
    display: inline-block;
    width: calc(100% - 81px);
    //margin-right: 1px;
    float: left;
    position: relative;
    &:before{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      z-index: -1;
    }
    button.add-to-cart,
    a.btn-product-list{
      width: 100%;
      text-transform: uppercase;
      font-size: 1.071rem;
      padding: .625rem;
      height: 40px;
      @media(max-width: 1150px){
        font-size: 12px;
      }
    }
    button.add-to-cart{
      @media(max-width: 1150px){
        line-height: 14px;
      }
    }
  }
  .btn-iqitcompare-add{
    i{
      &:after{
        @include sprite($white-compare);
      }
    }
  }
  .btn-iqitwishlist-add{
    i{
      &:after{
        @include sprite($white-heart-small);
      }
    }
  }
  .btn-iqitcompare-add,
  .btn-iqitwishlist-add{
    position: relative;
    background-color: $background;
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-left: 1px;
    float: left;
    transition: background-color $presta_transition;
    &:hover{
      background-color: $main_color;
      i {
        &:before{
          opacity: 0;
        }
        &:after {
          opacity: 1;
        }
      }
    }
    &:before{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: box-shadow $presta_transition;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      z-index: -1;
    }
    i{
      &:before,
      &:after{
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: opacity $presta_transition;
        display: inline-block;
      }
      &:before{
        opacity: 1;
      }
      &:after{
        opacity: 0.01;
      }
    }
  }
  .btn-iqitcompare-add{
    margin-left: 0;
  }
}

