.footer-fb{
  background-color: #4267B2;
  width: 21px;
  height: 21px;
  display: inline-block;
  position: relative;
  top: -5px;
  margin-right: 9px;
  border-radius: 2px;
}
.footer-fb i{
  width: 21px;
  height: 21px;
  line-height: 23px;
}
.ps-emailsubscription-block{
  .psgdpr_consent_message{
    font-size: 13px;
    a{
      color: #fff!important;
      font-size: 13px;
      font-weight: 700;
    }
  }
}
#footer-container-main{
  .block-title{
    margin-bottom: 0;
  }
  .block{
    padding-bottom: 0;
  }
  .block-links{
    ul {
      > li {
        margin-bottom: 0;
        a {
          padding-left: 0;
          text-transform: uppercase;
          font-size: 0.929rem;
          line-height: 1.6923;
          &:before{
            content: none;
          }
        }
      }
    }
  }
}
#footer-copyrights{
  td > *{
    vertical-align: middle;
  }
  .card-icons{
    margin-top: 4px;
    @media (min-width: 541px) {
      display: inline-block;
      margin-top: 0;
    }
  }
}
@media(max-width: $tablet){
  #footer-container-main{
    padding-left: 32px;
    padding-right: 32px;
    .block-title{

    }
    .block{

    }
    .block-links{
      ul {
        > li {

          a {

            &:before{

            }
          }
        }
      }
    }
  }
  #footer-copyrights{
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media(max-width: $mobile){
  #index{
    #footer-container-main{
      .container{
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
  #footer-container-main{
    padding-left: 0;
    padding-right: 0;
    .container{
      .row{
        .col{
          flex-basis: 50%;
          &:nth-child(3),
          &:nth-child(4){
            margin-top: 55px;
          }
        }
        .mb-5{
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
}
@media(max-width: 540px){
  #footer-copyrights{
    .container{
      table{
        tbody{
          tr{
            td{
              width: 100%;
              display: inline-block;
              text-align: center !important;
              margin-bottom: 6px;
              &:last-child{
                margin-bottom: 2px;
              }
            }
          }
        }
      }
    }
  }
}
@media(max-width: 420px){
  #footer-copyrights{
    .container{
      table{
        tbody{
          tr{
            td{
              &:nth-child(2){
                text-align: center !important;
              }
            }
          }
        }
      }
    }
  }
}