.simpleblog-posts{
  .post-item{
    box-shadow: 0 0 6px rgba(0,0,0,.25098);
    .post-thumbnail{
      margin-bottom: 0;
    }
    .post-title{
      background-color: $main_color;
      h2{
        margin: 0;
        padding: 0;
        a{
          color: #fff;
          text-align: center;
          padding: 10px;
          display: inline-block;
          width: 100%;
          font-size: 20px;
        }
      }
    }
    .post-content{
      padding: 20px 25px;
      margin: 0;
      .post-read-more{
        width: 100%;
        display: block;
        text-align: center;
        margin-top: 50px;
        margin-bottom: 20px;
        opacity: 1;
        span{
          text-decoration: none;
          background-color: $main_color;
          transition: 0.3s ease;
          color: #fff;
          padding: 10px;
          font-size: 14px;
          position: relative;
          &:before{
            content: "";
            position: absolute;
            display: inline-block;
            height: 2px;
            width: 0px;
            left: 50%;
            bottom: 6px;
            transform: translateX(-50%);
            background-color: #fff;
            transition: 0.4s ease;
          }
          &:hover{
           &:before{
             width: 110px;
           }
          }
        }
        .fa-chevron-right{
          display: none;
        }
      }
    }
    .post-additional-info{
      display: none;
    }
  }
}
@media (max-width: 768px){
  .simpleblog-posts{
    .simpleblog-post-item{
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}