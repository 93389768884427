body.loading #modal-onexit{
  display: none;
}
#modal-onexit.modal{
  .modal__container{
    @media (max-width: 600px) {
      width: 300px;
    }
    border-radius: 0;
    position: relative;
    width: 600px;
    max-width: calc(100% - 20px);
    padding: 0;
    img{
      max-width: 100%;
      height: auto;
    }
  }
  .modal__content{
    @extend .clearfix;
    margin: 0;
  }
  .modal__close{
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    line-height: 1em;
    font-size: 16px;
    width: 1em;
    color: $background;
    background-color: $main_color;
    box-sizing: content-box;
    padding: 6px;
    cursor: pointer;
    transition: color $presta_transition, background-color $presta_transition;
    border-color: $main_color;
    border-style: solid;
    border-width: 0 0 1px 1px;
    &:hover{
      color: $main_color;
      background-color: transparent;
    }
    &:before{
      content: '\2715';
    }
  }
  .modal__image{
    @media (max-width: 600px) {
      width: 100%;
      padding: 0;
    }
    width: 50%;
    float: left;
    padding: 10px 10px 0;
  }
  .modal__text{
    @media (max-width: 600px) {
      width: 100%;
      padding: 10px;
    }
    width: 50%;
    float: right;
    padding: 38px 10px 10px;
    .newsletter-input-group{
      border: 1px solid $main_color;
      .btn-subscribe{
        background-color: $main_color;
        position: relative;
        width: 47px;
        &:hover{
          background-color: transparent;
          i{
            &:before{
              opacity: 1;
            }
            &:after{
              opacity: 0;
            }
          }
        }
        i{
          display: inline-block;
          &:before,
          &:after{
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 0;
            transition: opacity $presta_transition;
          }
          &:before{
            opacity: 0.01;
          }
          &:after{
            @include sprite($white-arrow-right);
          }
        }
      }
    }
  }
}