#index{
  .elementor-element-y8xgt18{
    .elementor-iqit-banner-img{
      img{
        height: 360px;
        object-fit: cover;
      }
    }
    .elementor-iqit-banner-content{
      padding-left: 15px;
      padding-right: 15px;
      h4{
        text-align: left;
        line-height: 30px;
        margin-bottom: 19px;
      }
      .elementor-iqit-banner-description{
        text-align: left;
      }
    }
  }
}
@media(max-width: $tablet){
  .elementor-element-y8xgt18{

    .elementor-column{

    }
    .elementor-iqit-banner-img{
      img{

      }
    }
    .elementor-iqit-banner-content{
      min-height: 189px;
      margin-top: -60px;
      h4{
        line-height: 29px;
      }
      .elementor-iqit-banner-description{

      }
    }
  }
}
@media(max-width: $mobile){
  #index{
    .elementor-element-y8xgt18{
      padding-left: 15px;
      padding-right: 15px;
      .elementor-column{
        margin-bottom: 20px;
      }
      .elementor-iqit-banner-img{
        img{

        }
      }
      .elementor-iqit-banner-content{
        h4{

        }
        .elementor-iqit-banner-description{

        }
      }
    }
  }
}