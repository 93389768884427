.page-customer-account{
  #content-wrapper{
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .fa-heart-o{
    &:before{
      background-image: none;
      content: "\f08a";
      width: auto;
      height: auto;
    }
  }
  #lrp-account-link{
    .material-icons{
      font-size: 0;
      //&:before{
      //  content: "\f51e";
      //  font-family: 'FontAwesome';
      //  position: absolute;
      //  font-size: 15px;
      //}
    }
  }
  #lrp-customer-account{
    h6{
      margin-top: 10px;
    }
  }
}
.page-my-account{
  #wrapper{
    margin-top: 30px;
    margin-bottom: 30px;
  }
}