body.page-cms{
  #content-wrapper{
    margin-top: 40px;
    margin-bottom: 40px;
    .page-title{
      border-color: $main_color;
      span{
        color: #000;
        font-size: 50px;
        font-weight: 400;
        line-height: 54px;
      }
    }
  }
}