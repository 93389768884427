$banner_padding: 4.2142rem;

.hero-banners-front-page{
  .elementor-iqit-banner-subtitle,
  h3{
    text-shadow: 2px 3px 24px rgba(0, 0, 0, 0.75),1px 3px 6px rgba(0, 0, 0, 0.75),0 2px 0 rgba(24,24,25,.5);
  }
  .elementor-container:before {
    content: '';
    display: block;
    left: 0;
    top: 0;
    padding-bottom: 45.166666%;
  }
  .elementor-row{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    .elementor-column{
      &:first-child{
        .elementor-widget-wrap {
          .elementor-widget {
            .elementor-widget-container {
              .elementor-iqit-banner {
                a {
                  .elementor-iqit-banner-img {
                    img {
                      object-position: left center;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &:last-child{
        .elementor-widget-wrap {
          .elementor-widget {
            .elementor-widget-container {
              .elementor-iqit-banner {
                a {
                  .elementor-iqit-banner-img {
                    img {
                      object-position: right center;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .elementor-widget-wrap {
        height: 100%;
        .elementor-widget {
          height: 100%;
          .elementor-widget-container {
            height: 100%;
            .elementor-iqit-banner {
              height: 100%;
              a {
                height: 100%;
                display: block;
                .elementor-iqit-banner-img {
                  height: 100%;
                  img {
                    height: 100%;
                    object-fit: cover;
                  }
                }
              }
            }
          }
        }
      }
      .elementor-column-wrap{
        transition: margin-left .3s linear,
                    margin-right .3s linear,
                    z-index .3s step-end,
                    width .3s linear;
        z-index: 1;
        .elementor-iqit-banner-content{
          transition: padding-left .3s linear;
        }
      }
      &:first-child{
        &:hover{
          .elementor-column-wrap{
            margin-left: 0;
            width: 126.6666%;
          }
        }
      }
      &:last-child{
        &:hover{
          .elementor-column-wrap{
            margin-right: 0;
            width: 126.6666%;
          }
        }
      }
      &:hover{
        .elementor-column-wrap{
          margin-left: -26.6666%;
          margin-right: -26.6666%;
          width: 153.3333%;
          z-index: 2;
          position: relative;
          transition: margin-left .3s linear .3s,
                      margin-right .3s linear .3s,
                      z-index .3s step-start .3s,
                      width .3s linear .3s;
        }
        + .elementor-column{
          .elementor-column-wrap{
            .elementor-iqit-banner-content{
              transition: padding-left .3s linear .3s;
              padding-left: calc(26.6666% + #{$banner_padding});
            }
          }
        }
      }
    }
  }
}
@media(max-width: $tablet){
  .hero-banners-front-page{
    .elementor-container:before {

    }
    .elementor-row{

      .elementor-column{
        &:first-child{
          .elementor-widget-wrap {
            .elementor-widget {
              .elementor-widget-container {
                .elementor-iqit-banner {
                  a {
                    .elementor-iqit-banner-img {
                      img {

                      }
                    }
                  }
                }
              }
            }
          }
        }
        &:last-child{
          .elementor-widget-wrap {
            .elementor-widget {
              .elementor-widget-container {
                .elementor-iqit-banner {
                  a {
                    .elementor-iqit-banner-img {
                      img {

                      }
                    }
                  }
                }
              }
            }
          }
        }
        .elementor-widget-wrap {

          .elementor-widget {

            .elementor-widget-container {

              .elementor-iqit-banner {

                a {

                  .elementor-iqit-banner-img {

                    img {

                    }
                  }
                }
              }
            }
          }
        }
        .elementor-column-wrap{

          .elementor-iqit-banner-content{

          }
        }
        &:first-child{
          &:hover{
            .elementor-column-wrap{
              margin-left: 0;
              margin-right: 0;
              width: 100%;
            }
          }
        }
        &:last-child{
          &:hover{
            .elementor-column-wrap{
              margin-left: 0;
              margin-right: 0;
              width: 100%;
            }
          }
        }
        &:hover{
          .elementor-column-wrap{
            margin-left: 0;
            margin-right: 0;
            width: 100%;
          }
          + .elementor-column{
            .elementor-column-wrap{
              .elementor-iqit-banner-content{
                margin-left: 0;
                margin-right: 0;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
 body{
   #elementor .elementor-element-4d50fmy .elementor-banner-align-middle-center{
     padding-left: 0;
   }
   .elementor-element.elementor-element-aevpgnr .elementor-button{
     margin-top: 43px;
     float: left;
   }
   .elementor-element.elementor-element-bn401e7 .elementor-button{
     margin-top: 35px;
     float: left;
   }
   .elementor-element.elementor-element-q4i7xk7 .elementor-button{
     margin-top: 35px;
     float: left;
   }
 }
  .hero-banners-front-page{
    .elementor-container:before {

    }
    .elementor-row{
      display: inline-block;
      width: 100%;
      position: relative;
      padding-left: 31px;
      padding-right: 31px;
      .elementor-column{
        width: 100%;
        //display: block;
        max-height: 280px;
        //&:before{
        //  content: "";
        //  position: absolute;
        //  display: inline-block;
        //  width: 100%;
        //  height: 100%;
        //  background-color: #000;
        //  left: 0;
        //  right: 0;
        //  top: 0;
        //  bottom: 0;
        //  z-index: 999;
        //  opacity: 0.3;
        //}
        &:first-child{
          .elementor-widget-wrap {
            .elementor-widget {
              .elementor-widget-container {
                .elementor-iqit-banner {
                  a {
                    .elementor-iqit-banner-img {
                      img {
                        object-position: left -56px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &:last-child{
          .elementor-widget-wrap {
            .elementor-widget {
              .elementor-widget-container {
                .elementor-iqit-banner {
                  a {
                    .elementor-iqit-banner-img {
                      img {
                        object-position: left -31px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .elementor-widget-wrap {

          .elementor-widget {

            .elementor-widget-container {

              .elementor-iqit-banner {

                a {

                  .elementor-iqit-banner-img {

                    img {
                      object-position: left top;
                    }
                  }
                }
              }
            }
          }
        }
        .elementor-column-wrap{

          .elementor-iqit-banner-content{
            top:60px;
            left:60px;
            bottom:auto;
            right: auto;
            min-height: 170px;
            padding-left: 0;
            .elementor-iqit-banner-subtitle{
              text-align: left;
            }
            .elementor-iqit-banner-title{
              text-align: left;
            }
          }
        }
        &:first-child{
          &:hover{
            .elementor-column-wrap{

            }
          }
        }
        &:last-child{
          &:hover{
            .elementor-column-wrap{

            }
          }
        }
        &:hover{
          .elementor-column-wrap{

          }
          + .elementor-column{
            .elementor-column-wrap{
              .elementor-iqit-banner-content{

              }
            }
          }
        }
      }
    }
  }
}
@media(max-width: $mobile){
  body{
    #elementor .elementor-element-4d50fmy .elementor-banner-align-middle-center{
      padding-left: 15px;
    }
    .elementor-element.elementor-element-aevpgnr .elementor-button{
      position: absolute;
      width: 100%;
      left: 15px;
      max-width: 195px;
      top: 3px;
    }
    .elementor-element.elementor-element-bn401e7 .elementor-button{
      position: absolute;
      width: 100%;
      left: 15px;
      max-width: 195px;
      top: 3px;
    }
    .elementor-element.elementor-element-q4i7xk7 .elementor-button{
      position: absolute;
      width: 100%;
      left: 15px;
      max-width: 195px;
      top: 3px;
    }
  }
  .hero-banners-front-page{
    .elementor-container:before {

    }
    .elementor-row{
      padding-left: 0px;
      padding-right: 0px;
      .elementor-column{
        max-height: 222px;
        &:first-child{
          .elementor-widget-wrap {
            .elementor-widget {
              .elementor-widget-container {
                .elementor-iqit-banner {
                  a {
                    .elementor-iqit-banner-img {
                      img {

                      }
                    }
                  }
                }
              }
            }
          }
        }
        &:last-child{
          .elementor-widget-wrap {
            .elementor-widget {
              .elementor-widget-container {
                .elementor-iqit-banner {
                  a {
                    .elementor-iqit-banner-img {
                      img {

                      }
                    }
                  }
                }
              }
            }
          }
        }
        .elementor-widget-wrap {

          .elementor-widget {

            .elementor-widget-container {

              .elementor-iqit-banner {

                a {

                  .elementor-iqit-banner-img {

                    img {

                    }
                  }
                }
              }
            }
          }
        }
        .elementor-column-wrap{

          .elementor-iqit-banner-content{
            display: inline-block;
            width: 100%;
            left: 0;
            padding-left: 15px;
            top: 113px;
            .elementor-iqit-banner-subtitle{
              display: inline-block;
              float: left;
              font-size: 32px;
              line-height: 1.05em;
              margin-right: 10px;
            }
            .elementor-iqit-banner-title{
              display: inline-block;
              float: left;
              font-size: 32px;
            }
          }
        }
        &:first-child{
          &:hover{
            .elementor-column-wrap{

            }
          }
        }
        &:last-child{
          &:hover{
            .elementor-column-wrap{

            }
          }
        }
        &:hover{
          .elementor-column-wrap{

          }
          + .elementor-column{
            .elementor-column-wrap{
              .elementor-iqit-banner-content{

              }
            }
          }
        }
      }
    }
  }
}
