#footer-container-first{
  .container{
    max-width: 790px;
  }
  .block-newsletter{
    h5{
      font-size: 3.571rem;
      line-height: 1;
    }
    p{
      @extend .PFBulletinSansPro-Regular;
      font-size: 1.429rem;
    }
  }
  .ps-emailsubscription-block{
    .newsletter-input-group{
      margin-top: 2.8571rem;
    }
    input{
      font-size: 0.929rem;
      line-height: 1.5384;
      &.input-subscription{
        padding: 0.7143rem;
      }
      &::placeholder{
        color: $main_text;
        opacity: 0.6;
      }
    }
    .btn-subscribe{
      padding: 0 1.428571rem;
      position: relative;
      border: 1px solid $background;
      &:hover{
        background-color: $main_color;
        .fa-long-arrow-right{
          &:before{
            opacity: 0;
          }
          &:after{
            opacity: 1;
          }
        }
      }
      .fa-long-arrow-right{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &:before,
        &:after{
          content: '';
          transition: opacity $presta_transition;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: inline-block;
        }
        &:after{
          opacity: 0.01;
          @include sprite($white-arrow-right);
        }
      }
    }
  }
}