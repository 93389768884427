@media(max-width: $tablet){
  .elementor-element.elementor-element-robnem6{
    padding-left: 20px;
    padding-right: 10px;
  }
}
@media(max-width: $mobile){
  .elementor-element.elementor-element-robnem6{
    padding-left: 15px;
    padding-right: 15px;
    .elementor-container{
      display: block;
      width: 100%;
      max-width: 500px !important;
      margin: auto;
      .elementor-row{
        .elementor-column{
          width: calc(50% - 10px);
          padding-right: 10px;
          display: inline-block;
          float: left;
          margin-bottom: 40px;
          &:nth-child(2n){
            float: right;
            padding-right: 0;
           width: 50%;
          }
          &:nth-child(3){
            margin-bottom: 0;
          }
          &:nth-child(4){
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
@media(max-width: 420px){
  .elementor-element.elementor-element-robnem6{

    .elementor-container{

      .elementor-row{
        .elementor-column{
          .elementor-column-wrap{
            .elementor-widget-wrap{
              .elementor-widget{
                .elementor-widget-container{
                  .elementor-icon-box-wrapper{
                    .elementor-icon-box-content{
                      .elementor-icon-box-title{
                        font-size: 23px;
                      }
                      .elementor-icon-box-description{
                        font-size: 18px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}