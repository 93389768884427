.product-flags{
  width: 100%;
}
.product-flag.gim-custom-label.gim-custom-label-image{
  font-size: 0;
  line-height: 0;
  color: transparent;
  background-color: transparent !important;
  background-size: contain;
  background-repeat: no-repeat;
  width: 200px;
  height: 256px;
  @media (max-width: 720px) {
    width: 125px;
    height: 160px;
  }
}

.js-product-miniature .product-flag.gim-custom-label.gim-custom-label-image{
  width: 90px;
  height: 130px;
  @media (max-width: 720px) {
    width: 80px;
    height: 102px;
  }
}

#index .js-product-miniature .product-flag.gim-custom-label.gim-custom-label-image{
  width: 80px;
  height: 102px;
}
