#blockcart-modal-wrap{
  #blockcart-modal{
    .modal-header{
      padding-top: 7px;
      padding-bottom: 7px;
      background-color: $main_color;
      color: #fff;
      button{
        opacity: 1;
        span{
          color: #fff;
        }
      }
    }
    .modal-body{
      .col-md-7{
        .cart-content{
          .cart-products-count{
            margin-bottom: 0;
          }
          .cart-content-btn{
            .btn-primary{
              background-color: #000;
              color: #fff;
              transition: 0.3s ease;
              padding: 0.6rem 1.5rem;
              &:hover{
                background-color: $main_color;
              }
            }
            .btn-secondary{
              background-color: #fff;
              border: 1px solid #000;
              padding: 0.6rem 1.5rem;
              transition: 0.3s ease;
              &:hover{
                border-color: $main_color;
                color: $main_color;
              }
            }
          }
        }
      }
    }
  }
}
#blockcart-content{
  .cart-products{
    .col{
      *{
        color: #000;
        opacity: 1;
      }
    }
  }
  .cart-subtotals{
    border-color: $main_color;
  }
  .cart-buttons{
    .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{
      background-color: $main_color;
      box-shadow: none;
    }
    .btn-primary{
      background-color: $main_color;
      border:2px solid $main_color;
      padding: 0.6rem 1.5rem;
      transition: 0.3s ease;
      &:hover{
        opacity: 0.9;
      }
    }
    .btn-secondary{
      background-color: #ddd;
    }
    a{
      &.active{
        background-color: $main_color;
      }
    }
  }
}