#module-kbstorelocatorpickup-stores{
  #wrapper{
    margin-top: 30px;
  }
  .velo-search-container{
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    margin-top: 40px;
    margin-bottom: 40px;
    max-width: 1190px;
    label{
      font-weight: 700;
    }
    input,
    select{
      border: 2px solid #c2c2c2;
    }
    .velo-search-button{
      button{
        background: #6c955c;
        border: 1px solid #6c955c;
        padding: 8px 17px;
      }
    }
    .velo-store-select-link{
      background: #6c955c;
    }
    .button-show-more{
      position: relative;
      margin-top: 3px;
      margin-bottom: 10px;
      &:before{
        content:"";
        position: absolute;
        display: inline-block;
        bottom: 0px;
        width: 0;
        height: 2px;
        left: 0;
        background-color: $main_color;
        transition: 0.3s ease;
      }
      &:hover{
       opacity: 1;
        &:before{
          width: 100%;
        }
      }
    }
    .velo-store-filters{
      width: 100%;
      display: inline-block;
      .velo-field-inline{
        float: left;
        &:last-child{
          margin-right: 0;
        }
      }
    }
    .velo-directions-button,
    .velo-directions-link{
      width: 100%;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      background: $main_color;
      color: #fff;
      transition: 0.3s ease;
      i{
        display: none;
      }
      &:hover{
        background: #fff;
        color: $main_color;
      }
    }
  }
}
@media(max-width: 1200px){
  #module-kbstorelocatorpickup-stores{
    #wrapper{

    }
    .velo-search-container{

      label{

      }
      input,
      select{

      }
      .velo-search-button{
        button{

        }
      }
      .velo-store-select-link{

      }
      .button-show-more{

        &:before{

        }
        &:hover{

          &:before{

          }
        }
      }
      .velo-store-filters{

        .velo-field-inline{

          &:nth-child(4){
            clear: left;
          }
        }
      }
      .velo-directions-button,
      .velo-directions-link{

        i{

        }
        &:hover{

        }
      }
    }
  }
}
@media(max-width: 1024px){
  #module-kbstorelocatorpickup-stores{
    #wrapper{

    }
    .velo-search-container{

      label{

      }
      input,
      select{

      }
      .velo-search-button{
        button{
          padding: 8px 20px;
        }
      }
      .velo-store-select-link{

      }
      .button-show-more{

        &:before{

        }
        &:hover{

          &:before{

          }
        }
      }
      .velo-store-filters{

        .velo-field-inline{
          margin-right: 17px;
          &:nth-child(4){
           clear: unset;
          }
        }
      }
      .velo-directions-button,
      .velo-directions-link{

        i{

        }
        &:hover{

        }
      }
    }
  }
}
@media(max-width: 768px){
  #module-kbstorelocatorpickup-stores{
    #wrapper{

    }
    .velo-search-container{

      label{

      }
      input,
      select{

      }
      .velo-search-button{
        button{

        }
      }
      .velo-store-select-link{

      }
      .button-show-more{

        &:before{

        }
        &:hover{

          &:before{

          }
        }
      }
      .velo-store-filters{

        .velo-search-bar{
          display: inline-block;
          width: 100%;
          margin-bottom: 7px;
          input{
            width: calc(100% - 195px);
          }
        }
        .velo-search-limit{
          margin-right: 0;
        }
        .velo-search-button{
          width: calc(50% - 10px);
          margin: 0 auto;
          display: inline-block;
          margin-right: 10px;
          margin-top: 7px;
          &:last-child{
            margin-right: 0;
            margin-left: 10px;
          }
          button{
            max-width: unset;
          }
        }
      }
      .velo-directions-button,
      .velo-directions-link{

        i{

        }
        &:hover{

        }
      }
    }
  }
}
@media(max-width: 480px){
  #module-kbstorelocatorpickup-stores{
    #wrapper{

    }
    .velo-search-container{

      label{
        width: 100%;
        text-align: left;
      }
      input,
      select{
        float: left;
      }
      .velo-search-button{
        button{

        }
      }
      .velo-store-select-link{

      }
      .button-show-more{

        &:before{

        }
        &:hover{

          &:before{

          }
        }
      }
      .velo-store-filters{

        .velo-search-bar{
          label{
            width: 100%;
            text-align: left;
          }
          input{
            width: 100%;
          }
        }
        .velo-search-distance{
          max-width: 125px;
          margin-right: 14px;
        }
        .velo-search-limit{
          max-width: 105px;
        }
        .velo-search-button{

          &:last-child{

          }
          button{
            padding: 8px 10px;
          }
        }
      }
      .velo-directions-button,
      .velo-directions-link{

        i{

        }
        &:hover{

        }
      }
      .velo-location-list{
        padding-right: 0;
        ul{
          padding: 5px;
          li{
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}