#content-hook_payment_return{
  a{
    color: $main-color;
    transition: color $presta_transition;
    &:hover{
      color: $overlay_color;
    }
  }
  dl{
    dt{
      flex-basis: 35%;
      margin-left: 0;
    }
    dd{
      flex-basis: 55%;
    }
  }
}