div#onepagecheckoutps{
  #onepagecheckoutps_step_two_container{
    #onepagecheckoutps_step_two{
      #shipping_container{
        #kb-pts-carrier-wrapper{
          div{
            padding: 0;
          }
        }
      }
    }
  }
}
#kb_pts_carrier_block{
  #kb-pts-carrier-wrapper{
    #velo_address_search{
      margin: 0;
    }
    select{
      padding-top: 6px;
      margin: 0;
    }
  }
  .velo-search-container{
    padding: 0;
    margin: 0;
  }
  .velo-store-filters,
  .velo-location-list{
    background: transparent;
    margin: 0;
    border: none;
  }

  .velo-store-filters{
    @extend .clearfix;
    label{
      display: inline-block;
    }
    .velo-search-bar{
      float: left;
      width: 51%;
    }
    .velo-search-distance{
      float: left;
      width: 25%;
    }
    .velo-search-button{
      float: left;
      width: 12%;
      margin-top: 20px;
    }
  }

  .velo-location-list{
    float: none;
    width: initial;
    max-height: none;
    ul{
      margin-top: 10px;
      padding: 0;
      li{
        a.velo-directions-link{
          float: none;
          display: inline-block;
          padding: 4px 8px;
          border: none;
          border-radius: 0;
          background-color: #000;
          text-decoration: none;
          color: #fff;
          i.material-icons{
            display: none;
          }
          &:hover{
            background-color: #333;
          }
          &:after{
            content: '\f277';
            font-family: 'FontAwesome';
            color: #fff;
            vertical-align: bottom;
            font-size: 1.5rem;
            position: relative;
            bottom: -5px;
          }
        }
      }
    }
    td{
      padding: 0;
    }

    .mp-openday-list{
      table-layout: auto;
      .mp-openday-list-title{
        width: 100px;
      }
      .mp-openday-list-value{
        width: auto;
        padding: 0;
      }
    }
  }
  .velo-store-map{
    width: initial;
    float: none;
    height: 180px;
  }
  .velo-field-inline{
    margin: 0;
  }
  .velo-search-button{
    text-align: right;
    button{
      border: none;
      border-radius: 0;
      padding: 6px 12px;
      background-color: #000;
      margin: 0;
      transition: background-color $presta_transition;
      &:hover{
        background-color: #333;
      }
      &#searchStoreBtn,
      &#resetStoreBtn{
        font-size: 0;
        color: transparent;
        padding: 6px 10px;
        vertical-align: bottom;
        width: auto;
        &:after{
          content: '\f002';
          font-family: 'FontAwesome';
          font-size: 1rem;
          color: #fff;
          vertical-align: bottom;
        }
      }
      &#resetStoreBtn{
        &:after{
          content: '\f00d';
        }
      }
    }
  }

  .velo-store-date-time-block{
    margin-top: 20px;
  }

  #map{
    .gm-style-iw-d{
      overflow: hidden;
    }
    .velo-popup{
      z-index: 99;
      >div, >a{
        display: none;
      }
      td{
        padding: 15px;
      }
      .velo-add-address{
        margin-top: 5px;
      }
    }
  }

  .invalid{
    outline: 1px solid red;
    box-shadow: 0 0 5px red;
  }
}

#searchStoreForm{
  margin-bottom: 20px;
}

#velo_within_distance{

}