#index{
  position: relative;
  &:before{
    content: '';
    position: absolute;
    top: 41vh;
    left: 0;
    border-style: solid;
    border-width: 63vh 0 45vh 178vw;
    border-color: transparent $secondary_background;
    z-index: -2;
  }
  &:after{
    content: '';
    position: absolute;
    top: 15vh;
    right: -66.5vw;
    width: 75.7291666vw;
    height: 161.016949vh;
    transform: rotate(-335deg);
    background-color: $main_color;
    z-index: -1;
  }
  #main-page-content{
  }
  #wrapper{
    padding-top: 0;
  }
}
#content-wrapper{
  .crossselling-products{
    margin-top: 111px;
    .section-title{
      @extend .PFBulletinSansPro-Regular;
      color: $secondary_color;
      font-size: 50px;
      font-weight: 400;
      margin-bottom: 4px;
      line-height: 40px;
      border: none;
    }
    .slick-arrow{
      //display: none!important;
    }
    .block-content{
      .js-product-miniature-wrapper{
        .product-miniature{
          margin-left: 8px;
          margin-right: 8px;
        }
      }
    }
  }
}
@media(max-width: $laptop ){
  #index{
    &:before{
      top: 21vh;
    }
    &:after{
      top: -4%;
      right: -72.5vw;
    }
    #main-page-content{
    }
    #wrapper{

    }
  }
}
@media(max-width: $lg ){
  #index{
    &:before{
      top: 11vh;
    }
    &:after{
      top: -24%;
      right: -80.5vw;
    }
    #main-page-content{
    }
    #wrapper{

    }
  }
}
@media(max-width: $tablet ){
  #index{
    .container{
      padding-left: 0;
      padding-right: 0;
    }
    &:before{
      top: 25vh;
      height: 154%;
      transform: rotate(10deg);
    }
    &:after{
      top: 2%;
    }
    #main-page-content{
    }
    #wrapper{

    }
  }
}
@media(max-width: $mobile ){
  #index{
    .container{

    }
    &:before{
      transform: unset;
      border-style: unset;
      border-width: unset;
      border-color: unset;
      top: 26vh;
      clip-path: polygon(0 0, 100% 0%, 100% 97%, 0 100%);
      background: $secondary_background;
      height: 160vh;
      width: 100%;
    }
    &:after{
      display: none;
    }
    #main-page-content{
    }
    #wrapper{

    }
  }
}
@media(max-width: 620px ){
  #index{
    .container{

    }
    &:before{
      top: 30vh;
    }
    &:after{

    }
    #main-page-content{
    }
    #wrapper{

    }
  }
}
@media(max-width: 480px ){
  #index{
    .container{

    }
    &:before{
      height: 147vh;
    }
    &:after{

    }
    #main-page-content{
    }
    #wrapper{

    }
  }
}
@media(max-width: 420px ){
  #index{
    .container{

    }
    &:before{
      top: 26vh;
      height: 149vh;
    }
    &:after{

    }
    #main-page-content{
    }
    #wrapper{

    }
  }
}