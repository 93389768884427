.elementor-element.elementor-element-ld271sr{
  margin-left: -10px;
  margin-right: -10px;
  box-shadow: 0 2px 64px rgba(0, 0, 0, 0.1);
  .elementor-column{
    &:first-child{
      width: 300px;
    }
    &:last-child{
      width: calc(100% - 300px);
    }
  }
}

.elementor-element-ley8393{
  .elementor-column-wrap{
    position: relative;
  }
  .elementor-element-syvhn55,
  .elementor-widget-wrap,
  .slick-list,
  .slick-slider{
    position: initial;
  }
  .slick-slider{
    &.slick-arrows-above{
      .slick-arrow{
        transform: translate(-50%, 50%);
      }
    }
  }
}

.elementor-image-carousel .slick-slide > div {
  line-height: 0;
}

@media(max-width: $tablet){
  .elementor-element.elementor-element-ld271sr{
    padding-left: 25px;
  }
  .daily-offer{
    .js-product-miniature-wrapper{

    }
    .product-miniature.product-miniature-layout-1{

      .product-flags{

      }
      .product-title{
        margin-bottom: 22px;
      }
      .product-price-and-shipping{
        margin-bottom: 3px;
      }
    }
    .thumbnail-container{

    }
    .lhcom_products_lists_average,
    .price-countdown-wrapper{

    }
    div.product-description{

    }
    .product-functional-buttons.product-functional-buttons-bottom{

    }
    .product-reference,
    .product-title a,
    .product-price{

    }
  }
  #daily-offer-timer{
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 24px;
    > div{

      span{

        &:first-child{

        }
        &:last-child{

        }
      }
      &:last-child{

      }
    }
  }
}
@media(max-width: $mobile){
  .elementor-element-8m18no1{
    .elementor-element-populated{
      padding-left: 0;
      padding-right: 0;
      .tabs-container{
        padding-left: 15px;
        padding-right: 15px;
        h2{
          margin-bottom: 30px;
        }
        &.active{
          .allarea-tabs-mobile{
            .nav.nav-tabs{
              display: block;
              background-color: #fff;
              li{
                margin-right: 0;
                &:first-child{
                  border: none;
                }
                a{
                  border-bottom: 1px solid $main_color;
                  padding: 10px;
                  width: 100%;
                  display: inline-block;
                }
              }
              .nav-link{
                .tab-title-part{
                  &:before{
                    display: none;
                  }
                  &:after{
                    display: none;
                  }
                }
              }
              .nav-link.show.active{
                display: none;
              }
            }
            .title-tabs-mobile{
              &:before{
                transform: translateY(-50%) rotate(45deg);
              }
            }
          }
        }
        .allarea-tabs-mobile{
          margin-bottom: 44px;
          .title-tabs-mobile{
            font-size: 20px;
            border-bottom: 2px solid #c2c2c2;
            width: 100%;
            display: inline-block;
            padding-bottom: 15px;
            font-family: 'PFBulletinSansPro-Regular', sans-serif;
            position: relative;
            &:before{
              content: '';
              position: absolute;
              top: 30%;
              transform: translateY(-50%) rotate(-134deg);
              width: 11px;
              height: 11px;
              border-left: 2px solid $main_color;
              border-top: 2px solid $main_color;
              right: 0px;
              transition: .3s ease;
              opacity: 1;
            }
          }
          .nav.nav-tabs{
            display: none;
          }
        }
      }
    }
  }
  .elementor-element.elementor-element-ld271sr{
    padding-left: 10px;
    padding-right: 10px;
  }
  #elementor-section-wrap .elementor-element.elementor-element-ld271sr .elementor-column:first-child{
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #f5f2f2;
    padding-bottom: 60px;
    .elementor-column-wrap{
      max-width: 300px;
      margin: auto;
    }
  }
  #elementor-section-wrap .elementor-element.elementor-element-ld271sr .elementor-column:last-child{
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 20px;
    .elementor-widget-container{
      margin-bottom: 23px;
      h2.elementor-heading-title{
        padding-left: 15px;
        padding-right: 15px;
      }

    }
    .elementor-widget{
      .slick-slide{
        .product-miniature{

        }
      }
    }
  }
  .daily-offer{
    .js-product-miniature-wrapper{

    }
    .product-miniature.product-miniature-layout-1{

      .product-flags{

      }
      .product-title{
        margin-bottom: 22px;
      }
      .product-price-and-shipping{
        margin-bottom: 3px;
      }
    }
    .thumbnail-container{

    }
    .lhcom_products_lists_average,
    .price-countdown-wrapper{

    }
    div.product-description{

    }
    .product-functional-buttons.product-functional-buttons-bottom{

    }
    .product-reference,
    .product-title a,
    .product-price{

    }
  }
  #daily-offer-timer{
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 24px;
    > div{

      span{

        &:first-child{

        }
        &:last-child{

        }
      }
      &:last-child{

      }
    }
  }
}
