#module-ph_simpleblog-single{
  .simpleblog-single{
    .post-meta-info.text-muted{
      *{
        color: #000;
        opacity: 1;
      }
    }
    .post-content{
      margin-top: 40px;
      margin-bottom: 70px;
      p{
        img{
          max-width: 700px;
          width: 100%;
          display: block;
          margin: auto;
        }
      }
      @media(max-width: 993px){
        p{
          img{
            max-width: 450px;
            margin: auto;
            display: block;
            width: 100%;
          }
        }
      }
    }
    @media(min-width: 994px){
      .post-featured-image{
        float: left;
        margin: 0 20px 20px 0;
        padding: 10px;
        width: 100%;
        max-width: 485px;
      }
    }
    @media(max-width: 993px){
      .post-featured-image{
        max-width: 485px;
        margin: auto;
      }
    }
    .section-title{
      border-bottom: solid 1px rgba(199, 27, 27, 0.6);
    }
    #simpleblog-post-comments{
      .post-comments-list{

      }
      .alert-warning{
        background: #edf2f5;
        border-color: #edf2f5;
        color: #000;
        a{
          color: #000;
          text-decoration: none;
        }
      }
    }
  }
}