#contact{
  #content-wrapper{
    .page-title{
      border-bottom:1px solid $main_color;
      margin-top: 40px;
    }
    #content{
      .contact-page-info{
        .contact-rich>strong{
          margin-bottom: 30px;
        }
        hr{
          margin-top: 8px;
          margin-bottom: 8px;
        }
        i{
          &:before{
            font-size: 22px;
          }
        }
        .fa-phone{
          &:before{
            content: "\f095";
            width: 22px;
            height: 28px;
            background-image: none;
          }
        }
      }
      .contact-form{
        select,
        input,
        textarea{
          &:focus{
            box-shadow: none;
          }
        }
        .form-footer{
          .btn-primary{
            background-color: $main_color;
            transition: 0.3s ease;
            min-width: 150px;
            &:hover{
              opacity: 0.9;
            }
          }
        }
        .bootstrap-filestyle{
          label{
            background: $main_color;
            line-height: 21px;
          }
        }
      }
    }
  }
}
@media(max-width: $mobile){
  #contact{
    #content-wrapper{
      .page-title{
        margin-top: 20px;
      }
      #content{
        .contact-page-info{
          .contact-rich>strong{
            font-size: 16px;
          }
          hr{

          }
          i{
            &:before{

            }
          }
          .fa-phone{
            &:before{

            }
          }
        }
        .contact-form{
          h4{
            font-size: 16px;
            font-weight: 700;
          }
          select,
          input,
          textarea{
            &:focus{

            }
          }
          .form-footer{
            text-align: center!important;
            .btn-primary{
              font-size: 15px;
              line-height: 27px;
              @media(max-width: 420px){
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}