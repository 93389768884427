h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
.PFBulletinSansPro-Regular {
  font-family: 'PFBulletinSansPro-Regular', sans-serif;
  font-style: normal;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: bold;
}

.elementor-section.elementor-section-stretched{
  max-width: 1800px;
  transform: translateX(-50%);
  margin-left: 0;
}

.clearfix{
  &:after{
    content: '';
    display: table;
    clear: both;
  }
}

.red-overlay-background{
  background-color: rgba($overlay_color, 0.85);
  opacity: 1;
  mix-blend-mode: multiply;
}

.red-overlay .elementor-iqit-banner-overlay,
.red-overlay .overlay-element{
  @extend .red-overlay-background;
}

.red-overlay-element{
  position: relative;
  &:before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @extend .red-overlay-background;
  }
}

li.product-flag.product-unavailable {
  background-color: #000;
}

.product-flag span{
  @extend .PFBulletinSansPro-Regular;
}

.white-content-box{
  .elementor-iqit-banner{
    overflow: visible;
    &:hover{
      .elementor-iqit-banner-content{
        background-color: $main_color;
        color: $secondary_text;
      }
    }
  }
  .elementor-iqit-banner-content{
    margin: -40px 20px 0;
    background-color: $background;
    box-shadow: 0 2px 64px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 2;
    transition: background-color $presta_transition, color $presta_transition;
    min-height: 128px;
    color: $main_text;
  }
}

.slick-arrows-middle{
  .slick-arrow{
    &.slick-prev{
      transform: translate(-100%, -50%);
    }
    &.slick-next{
      transform: translate(100%, -50%);
    }
  }
}

.custom-icon{
  content: '';
  display: inline-block;
}

.fa-phone:before{
  @include sprite($phone);
  @extend .custom-icon;
  vertical-align: bottom;
}

.fa-credit-card:before{
  @include sprite($card);
  @extend .custom-icon;
}

.fa-angle-down:before{
  @include sprite($red-angle-down);
  @extend .custom-icon;
}

.fa-search:before{
  @include sprite($search);
  @extend .custom-icon;
}

.fa-user:before{
  @include sprite($user);
  @extend .custom-icon;
}

.fa-shopping-bag:before{
  @include sprite($cart);
  @extend .custom-icon;
}

.fa-long-arrow-down:before{
  @include sprite($red-arrow-down);
  @extend .custom-icon;
}

.fa-long-arrow-right:before{
  @include sprite($red-arrow-right);
  @extend .custom-icon;
}

.fa-heart-o:before{
  @include sprite($heart-small);
  @extend .custom-icon;
}

.fa-building-o:before{
  @include sprite($shop);
  @extend .custom-icon;
}

.fa-check-circle-o:before{
  @include sprite($checkmark);
  @extend .custom-icon;
}

.fa-users:before{
  @include sprite($users);
  @extend .custom-icon;
}

.fa-random:before{
  @include sprite($compare);
  @extend .custom-icon;
}

.product-miniature{
  .lhcom_list_reviews .star_display_off,
  .star_display_off{
    @include sprite($empty-star);
  }
  .lhcom_list_reviews .star_display_on,
  .star_display_on{
    @include sprite($full-star);
  }
  .lhcom_list_reviews .star_display_half,
  .star_display_half{
    @include sprite($half-star);
  }
}

.lhcom_products_lists_average{
  margin-top: 0;
  text-align: initial;
}
.lhcom_products_lists_average .average{
  text-align: inherit;
}

div.slick-slider.slick-arrows-above{
  .slick-arrow{
    top: auto;
    bottom: 0;
    right: auto;
    transform: translate(-50%, 100%);
    width: 40px;
    height: 40px;
    display: inline-block;
    &.slick-prev{
      padding: 0;
      left: calc(50% - 21px);
    }
    &.slick-next{
      padding: 0;
      left: calc(50% + 21px);
    }
  }
}

.slick-arrow{
  opacity: 1;
  width: 40px;
  height: 40px;
  background-color: $main_color;
  transition: background-color $presta_transition,
              color $presta_transition,
              box-shadow $presta_transition;
  &:hover{
    background-color: $background;
    box-shadow: 0 2px 40px rgba(0, 0, 0, 0.2);
    &:before{
      opacity: 1;
    }
    &:after{
      opacity: 0;
    }
  }
  &:before,
  &:after{
    content: '';
    font-family: inherit;
    font-size: 0;
    line-height: 0;
    display: inline-block;
    transition: opacity $presta_transition;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:before{
    opacity: 0.01;
  }
  &:after{
    opacity: 1;
  }
  &.slick-prev{
    &:before{
      @include sprite($red-arrow-left);
    }
    &:after{
      @include sprite($white-arrow-left);
    }
  }
  &.slick-next{
    &:before{
      @include sprite($red-arrow-right);
    }
    &:after{
      @include sprite($white-arrow-right);
    }
  }
}


.elementor-button-link{
  padding: 0.6245rem 0.937rem;
  padding-right: calc(0.937rem + 40px);
  position: relative;
  i.fa-arrow-right{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 40px;
    background-color: $main_color;
    transition: background-color $presta_transition;
    margin-right: 0;
    &:before,
    &:after{
      content: '';
      display: inline-block;
      transition: opacity $presta_transition;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &:before{
      @include sprite($white-arrow-right);
    }
    &:after{
      @include sprite($red-arrow-right);
      opacity: 0.01;
    }
  }
  &:hover{
    i.fa-arrow-right{
      background-color: $background;
      &:before{
        opacity: 0;
      }
      &:after{
        opacity: 1;
      }
    }
  }
}

.elementor-brands {
  img {
    padding-left: 15px;
    padding-right: 15px;
  }
}

#wrapper{
  .breadcrumb{
    margin-bottom: 0;
    ol{
      li{
        a{
          font-size: 16px;
          font-weight: 400;
          text-transform: uppercase;
          @extend .PFBulletinSansPro-Regular;
        }
        &:after{
          font-size: 21px;
          opacity: 1;
          top: 2px;
          position: relative;
          margin: .1em;
        }
        &:last-child{
          a{
            color: #000;
          }
        }
      }
    }
  }
}

.footer-social-buttons{
  margin-top: 1rem;
  letter-spacing: 0.4px;
}
.instagram-button{
  font-size: 0;
  line-height: 0;
  display: inline-block;
  color: transparent;
  position: relative;
  &:before{
    content: '';
    display: inline-block;
    @include sprite($instagram);
    transition: filter $presta_transition_function;
  }
  &:hover:before{
    filter: brightness(0.8);
  }
}

i.product-nutrition-icon,
i.product-dosage-icon{
  display: inline-block;
  margin-right: 4px;
  &:before{
    content: '';
    display: inline-block;
    vertical-align: bottom;
  }
}
i.product-nutrition-icon:before{
  @include sprite($nutrients);
}
i.product-dosage-icon:before{
  @include sprite($shaker);
}

@media(max-width: 1285px){
  .elementor-element-e0x5t6p{
    .slick-list.draggable{
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .slick-arrows-middle{
    .slick-arrow{
      &.slick-prev{
        transform: translate(-50%, -50%);
      }
      &.slick-next{
        transform: translate(50%, -50%);
      }
    }
  }
}
@media(max-width: $tablet){
  .elementor-element-e0x5t6p{
    .elementor-brands{
      padding-left: 35px;
      padding-right: 35px;
    }
  }
}
@media(max-width: $mobile){
  #wrapper{
    .breadcrumb{
      padding-top: 5px;
      padding-bottom: 5px;
      ol{
        line-height: 22px;
        li{
          a{

          }
          &:after{

          }
          &:last-child{
            a{

            }
          }
        }
      }
    }
  }
  .elementor-element-e0x5t6p .slick-list.draggable{
    padding-left: 0;
    padding-right: 0;
  }
  .elementor-element-e0x5t6p{
    .elementor-brands{
        img {
          padding-left: 10px;
          padding-right: 10px;
        }

      padding-left: 50px;
      padding-right: 50px;
      .slick-prev{
        left: -30px;
      }
      .slick-next{
        right: -30px;
      }
    }
  }
}