body.page-category.top-category-220{
  #content-wrapper{
    .active_filters{

      .h6.active-filter-title{

      }
      .filter-block{

        border: solid 1px $grey_color;

        .js-search-link{


          .material-icons.close{

            &:before{

            }
          }
        }
        &:hover{

          background-color: $grey_color;
          .js-search-link{
            .material-icons.close{
              &:before{
                background-color: $grey_color;

              }
            }
          }
        }
      }
    }
  }
}
#search_filters{
  .facet_color{
    li{

      label{
        .search-link{
          span{

          }
        }
      }
    }
  }
}
#category{
  #inner-wrapper{

    .row{

    }
  }
  #gim-category-hero-banner{

    img{

    }
    .gim-hero-image-mobile{

    }
    .gim-hero-text{

      .top{

      }
      @media(max-width: 767px){

        .top{

        }
      }
    }
  }
}
@media(max-width: 991px) and(min-width: 768px){
  body.page-category{
    #content-wrapper{
      #js-active-search-filters{

      }
      #js-product-list-top{

      }
    }
  }
}
@media(max-width: 767px){
  body#category.page-category{
    #gim-category-hero-banner{
      .gim-hero-image{

      }
      .gim-hero-image-mobile{

      }
    }
    #content-wrapper{
      #js-product-list-top{
        .row.align-items-center{

          .dropdown{

            &.products-nb-per-page{

            }
            .select-title{

            }
          }
        }
      }
    }
  }
}