.cart-summary{
  .btn-primary{
    background-color: $main_color;
    &:hover{
      background-color: $main_color;
    }
  }
}

#checkout #wrapper{
  padding-top: 0;
}

div#onepagecheckoutps {
  input[type=radio],
  input[type=checkbox]{
    appearance: none;
    background: none;
    border-radius: 1px;
    margin-left: 5px;
    margin-right: 5px;
    background: #fff;
    border: 2px solid #fff;
    box-shadow: 0px 0px 0px 1px #444;
    float: left;
    margin-top: 0!important;
    &:focus{
      outline: none;
      border: 2px solid #fff;
      box-shadow: 0px 0px 0px 1px #444;
    }
    &:checked{
      background: #000;
      box-shadow: 0px 0px 0px 1px #000;
      border: 2px solid #ffffff;
    }
  }
  .row{
    margin: 0;
    > div {
      padding-left: 5px;
      padding-right: 5px;
      select,
      input[type=text],
      input[type=email]{
        border-radius: 0!important;
        border-right: 1px solid #ccc;
        margin-bottom: 5px;
      }
    }
  }
  h5{
    //color: #487B34;
    border-bottom: 1px solid $main_color!important;
    i{
      color: #000;
    }
  }
  #panel_addresses_customer{
    .card {
      border: none;
      .card-header {
        background: $main_color;
        .card-title {
          a {
            color: #fff;
          }
        }
      }
      .card-body{
        padding: 0;
      }
    }
  }
  #onepagecheckoutps_step_one_container{
    #onepagecheckoutps_step_one{
      display: flex;
      flex-flow: column;
      .form-group{
        margin-top: 5px;
        label{
          line-height: 17px;
        }
      }
      .onepagecheckoutps_p_step {
        #opc_show_login {
          font-size: 13px;
          padding-left: 10px;
          padding-right: 10px;
          line-height: 20px;
          word-spacing: -0.5px;
          margin-top: 3px;
          border-radius: 0;
          float: right;
        }
      }
      #customer_container{
        margin-top: 10px;
      }
    }
  }
  #onepagecheckoutps_step_two_container,
  #onepagecheckoutps_step_three_container{
    #onepagecheckoutps_step_two,
    #onepagecheckoutps_step_three{
      #shipping_container,
      #payment_method_container{
        margin-top: 10px;
        .row{
          margin: 0;
        }
        div{
          padding-left: 3px;
          padding-right: 3px;
        }
      }
      .module_payment_container,
      .delivery-option{
        margin-bottom: 10px!important;
        padding-top: 5px;
        padding-bottom: 5px;
        border: 1px solid transparent;
        &:hover,
        &.selected{
          background: #f4f4f4;
          color: #000;
          border: 1px solid #bbb;
          margin-bottom: 10px;
        }
        .delivery_option_title{
          font-weight: 500;
          line-height: 20px;
        }
        .delivery_option_price{
          line-height: 12px;
          display: none;
        }
        .payment_content_html{
          font-size: 11px;
          line-height: 13px;
          section {
            > p {
              margin: 0;
            }
          }
        }
      }
    }
  }
  #onepagecheckoutps_step_review_container {
    margin-top: 30px;
    #onepagecheckoutps_step_review {
      #header-order-detail-content {
        background: #f7f7f9;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-bottom: 10px;
        font-weight: 700;
        margin-left: 0;
        margin-right: 0;
        > div{
          text-decoration: none;
        }
      }
      .cart_item{
        border-left: 1px solid #f7f7f9;
        border-top: 1px solid #f7f7f9;
        border-bottom: 1px solid #f7f7f9;
        padding: 5px;
        margin-bottom: 20px;
        margin-left: 0;
        margin-right: 0;
        > div{
          border-right: 1px solid #f7f7f9;
        }
      }
      .order_total_items{
        margin-bottom: 10px;
        //float: right;
        //min-width: 450px;
        > div.row{
          border-left: 1px solid #f7f7f9;
          height: auto;
          margin-left: 0;
          margin-right: 0;
          >div{
            border-right: 1px solid #f7f7f9;
            border-bottom-color: transparent;
            &:first-child{
              background: #fafafc;
            }
            &:last-child{
              border-bottom: 1px solid #f7f7f9;
              border-top: 1px solid #f7f7f9;
              background: #efeff1;
            }
            span{
              padding: 3px;
              display: inline-block;
              font-size: 14px;
              font-weight: 400;
            }
          }
          &.total_price{
            >div{
              span{
                //font-weight: 700;
              }
            }
          }
          &.cart_total_price.total_price{
            text-transform: initial;
          }
          &.not-last{
            display: none;
          }
          &.cart_total_price.total_price:not(.not-last){
            color: #000;
            text-transform: uppercase;
            > div{
              background: rgb(239, 239, 241);
              border-top: 1px solid rgb(239, 239, 241);
              border-bottom: 1px solid rgb(239, 239, 241);
            }
            span {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  #btn_continue_shopping{
    background: #000000;
    color: #FFF;
    text-decoration: none;
    padding: 1rem 1.5rem;
    margin-top: 0!important;
    transition: 0.3s ease;
    i{
      position: relative;
      right: 8px;
    }
    &:hover{
      opacity: 0.8!important;
    }
  }
  #btn_place_order{
    background: #6c955c;
    transition: 0.3s ease;
    &:hover{
      opacity: 0.9;
    }
  }
  #conditions-to-approve{
    text-align: right;
    margin-top: 10px;
    label{
      position: relative;
      padding-right: 25px;
      input{
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  #panel_addresses_customer{
    .card-header{
      display: none;
    }
  }

  #field_customer_invoice{
    .col-xs-6{
      padding: 0;
    }
  }
}
@media(max-width: 992px){
  .page-order{
    #main-page-content{
      #inner-wrapper{
        padding-left: 5px;
        padding-right: 5px;
        #onepagecheckoutps{
          #onepagecheckoutps_header{
            padding-left: 5px;
            padding-right: 5px;
          }
          #onepagecheckoutps_contenedor{
            padding-left: 5px;
            padding-right: 5px;
          }
        }
      }
    }
  }
}