body.page-category{
  #content-wrapper{
    .active_filters{
      margin-top: 30px;
      .h6.active-filter-title{
        float: left;
        margin-right: 10px;
        line-height: 29px;
      }
      .filter-block{
        background: #fff;
        border: solid 1px $main_color;
        border-radius: 10px;
        transition: 0.3s ease;
        display: inline-block;
        padding: 5px;
        position: relative;
        padding-right: 25px;
        .js-search-link{
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          .material-icons.close{
            line-height: 17px;
            position: relative;
            font-size: 14px;
            opacity: 1;
            &:before{
              content: "\f00d";
              position: absolute;
              display: inline-block;
              font-family: FontAwesome;
              width: 17px;
              height: 20px;
              background-color: #fff;
              right: 1px;
              top: 0;
              transition: 0.3s ease;
            }
          }
        }
        &:hover{
          color: #fff;
          background-color: $main_color;
          .js-search-link{
            .material-icons.close{
              &:before{
                background-color: $main_color;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
#search_filters{
  .facet_color{
    li{
      display: block;
      label{
        .search-link{
          span{
            display: none;
            position: relative;
          }
        }
      }
    }
  }
}
#category{
  #inner-wrapper{
    max-width: unset;
    padding-left: 0;
    padding-right: 0;
    .row{
      max-width: 1230px;
      padding-right: 15px;
      padding-left: 15px;
      margin: auto;
    }
  }
  #gim-category-hero-banner{
    //@media (max-width: 1024px) {
    //  display: none;
    //}
    text-align: center;
    position: relative;
    img{
      max-width: 100%;
      object-fit: cover;
    }
    .gim-hero-image-mobile{
      display: none;
    }
    .gim-hero-text{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-33%, 3%);
      color: #fff;
      font-size: 60px;
      font-weight: 400;
      line-height: 40px;
      .top{
        color: #fff;
        font-size: 32px;
        font-weight: 400;
        line-height: 32px;
        display: block;
      }
      @media(max-width: 767px){
        font-size: 40px;
        line-height: 22px;
        left: 50%;
        top: 50%;
        transform: translate(-50%,0%);
        .top{
          font-size: 22px;
        }
      }
    }
  }
}
@media(max-width: 991px) and(min-width: 768px){
  body.page-category{
    #content-wrapper{
      #js-active-search-filters{
        padding-left: 247px;
      }
    #js-product-list-top{
      padding-left: 222px;
    }
    }
  }
}
@media(max-width: 767px){
  body#category{
    #gim-category-hero-banner{
      .gim-hero-image{
        display: none;
      }
      .gim-hero-image-mobile{
        display: block;
      }
    }
    #content-wrapper{
    #js-product-list-top{
      .row.align-items-center{
        padding-left: 0;
        padding-right: 0;
        .dropdown{
          float: left;
          display: block;
          width: 100%;
          margin-bottom: 10px;
          &.products-nb-per-page{
            margin-bottom: 0;
          }
          .select-title{
            float: left;
          }
        }
      }
    }
    }
  }
}
