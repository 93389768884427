body.page-category.top-category-220{

  #wrapper{
    #left-column{
      .filter-gim-wrapper{

      }
      .simplebar-vertical{
        background-color: #fff;

        .simplebar-scrollbar{

          &:before{
            background: $grey_color;

          }
        }
        &:before{



        }
      }
      .block-categories{

        .block-title{

          border-bottom: solid 2px $grey_color;
          &:before{

            border-left: 2px solid $grey_color;
            border-top: 2px solid $grey_color;

          }
          a{

            color: #000;

          }
        }
        .block-content{
          li {
            &.current {
              > a {
                color: $grey_color;
                &:before{
                  background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png');
                }
              }
            }
            a {

            }
          }
          > .category-sub-menu{
            li[data-depth="0"]{
              > a{


                border-bottom: 1px solid $grey_color;
              }
              .category-sub-menu{

                li{


                  a{

                  }
                }
              }
              .fa-angle-down{

                &:before{

                  background-color: $grey_color;
                }
                &:after{

                  background-color: $grey_color;
                }
              }
              .fa-angle-up{

                &:before{

                  background-color: $grey_color;
                }
              }
            }
          }
        }
        &._toggled{
          h5{
            &:before{

            }
          }
          .block-content{

          }
        }
      }
      #facets_search_wrapper{

        .block-facets{

        }
        #search_filters{
          #_desktop_search_filters_clear_all{
            button{
              background-color: $grey_color;

              .material-icons{

              }
            }
          }
          .facet{

            &:last-child{

            }
            &:not(first-child){
              .simplebar-vertical{

              }
            }
            .h6{

              border-bottom: solid 2px $grey_color;
              &:before{

                border-left: 2px solid $grey_color;
                border-top: 2px solid $grey_color;

              }
              span{

              }
              &.current{
                &:before{

                }
              }
            }
            .current ~ ul{

            }
            ul{

              &:not(.faceted-slider){

              }

              &.faceted-slider{

                @media(min-width: 768px){

                }
                min-height: 54px;
                @media(max-width: 1024px){

                }
                @media(max-width: 767px){


                }
                li{

                  p{

                    @media(max-width: 768px){

                    }
                  }
                  .ui-slider{


                    .ui-slider-range{
                      background: $grey_color;
                      //min-width: 258px;
                    }
                    .ui-slider-handle{

                      background-color: $grey_color;
                    }
                  }
                }
              }

              li{

                label{

                  .ps-shown-by-js{
                    .material-icons{

                    }
                  }
                  &.active{
                    .custom-checkbox{
                      .ps-shown-by-js{
                        border: 2px solid $grey_color;
                        &:before{

                          background-color: $grey_color;

                        }
                        .material-icons{

                        }
                      }
                    }
                  }
                  .custom-checkbox{

                    input[type=checkbox] + span{

                    }

                  }
                  a{

                  }
                }
              }
            }
          }
        }
      }
      #gim_category_banner{

        .banner-content{
          img{

          }
          .button-container{

            .elementor-button{

              .elementor-button-text{

                color: $grey_color;

              }
            }
            &:hover{
              .elementor-button{
                background: $grey_color;
                .elementor-button-text{

                }
              }
            }
          }
        }
      }
      .elementor-products{

        .slick-arrow{

        }
        .product-miniature{

          &:hover{
            .product-functional-buttons-bottom{

            }
          }
        }
      }
    }
  }
}
@media(max-width: 1199px) and (min-width: 768px){
  body.page-category.top-category-220{
    #wrapper{

      #left-column{

      }
    }
  }
}
.filter-gim{
  @media(min-width: 992px){

  }
  &.remove{

  }
}
@media(max-width: 991px) and (min-width: 768px){
  body.page-category.top-category-220{
    #wrapper{
      .row{

      }
      #left-column{

        #facets_search_wrapper{

        }
        .simplebar-vertical{

          &:before{



          }
        }
        .filter-gim-wrapper{

        }
        .filter-gim{

          border-bottom: 1px solid $grey_color;
          &:before{

            border-left: 2px solid $grey_color;
            border-top: 2px solid $grey_color;

          }
          &.current{
            &:before{

            }

          }
        }
        .filter-gim-wrapper{

        }
        .filter-gim.current ~ .filter-gim-wrapper{

        }
        #gim_category_banner{

        }
        .elementor-products{

        }
      }
    }
  }
}
@media(max-width: 767px){
  body.page-category.top-category-220{
    #wrapper{
      #inner-wrapper{
        .row{
          &:nth-child(4){

            #content-wrapper{

              #js-product-list-top{

                .facated-toggler{

                }
              }
            }
          }
        }
      }
      #content-wrapper{
        #main{
          #products{
            #js-product-list{
              .js-product-miniature-wrapper{
                .product-miniature{

                }
                &:nth-child(2n){
                  .product-miniature{

                  }
                }
              }
            }
          }
        }
      }
      #left-column{

        #facets_search_wrapper{

          #search_filter_controls{
            .js-search-filters-clear-all{

              background-color: $grey_color;
              i{

              }
            }
            .ok{

            }
          }
        }
        &:not(.-only-facet-search){
          .hidden-md-up{

          }
          .block-facets{

          }
        }
        .simplebar-vertical{
          background-color: #f1f1f1;
        }
        .filter-gim{

        }
        #gim_category_banner{

        }
        .elementor-products{

        }
        #search_filters{
          .collapse:not(.show){

          }
          .float-xs-right{

          }
        }
      }
      .filter-button{

      }
      #search_filter_toggler{

        border-bottom: 2px solid $grey_color;

        &:before{

          border-left: 2px solid $grey_color;
          border-top: 2px solid $grey_color;

        }
        i{

        }
      }
    }
  }
  #left-column.-only-facet-search>:not(#facets_search_wrapper), #right-column.-only-facet-search>:not(#facets_search_wrapper){

  }
  #left-column{
    &:not(.-only-facet-search){

    }
  }
}
@media(max-width: 480px){
  body.page-category.top-category-220{
    #wrapper{
      #left-column{
        #facets_search_wrapper{

          #search_filter_controls{
            .js-search-filters-clear-all{

              i{

              }
            }
            .ok{

            }
          }
        }

      }
    }
  }
}

