body.page-category{
  #content-wrapper{
    position: relative;
    z-index: 2;
  }
  #wrapper{
    padding-bottom: 54px;
    #left-column{
      .filter-gim-wrapper{
        width: 100%;
        display: inline-block;
        //margin-bottom: 121px;
      }
      .simplebar-vertical{
        background-color: #fff;
        width: 5px;
        padding-top: 6px;
        padding-bottom: 6px;
        .simplebar-scrollbar{
          width: 5px;
          right: 0;
          &:before{
            background: $main_color;
            opacity: 1;
            top:0;
          }
        }
        &:before{
          content: "";
          position: absolute;
          display: inline-block;
          width: 5px;
          height: 100%;
          background-color: #d8d8d8;
          right: 0;
        }
      }
      .block-categories{
        margin-bottom: 25px;
        font-weight: bold;
        .block-title{
          margin-bottom: 0;
          position: relative;
          cursor: pointer;
          padding-bottom: 11px;
          padding-top: 11px;
          border-bottom: solid 2px $main_color;
          text-transform: uppercase;
          &:before{
            content: "";
            position: absolute;
            display: inline-block;
            width: 11px;
            height: 11px;
            border-left: 2px solid $main_color;
            border-top: 2px solid $main_color;
            right: 3px;
            top: 50%;
            transform: translateY(4%) rotate(45deg);
            transition: 0.2s ease;
            //background-color: $main_color;
          }
          a{
            color: #000;
            font-size: 20px;
            line-height: 24px;
          }
        }
        .block-content{
          li {
            &.current {
              > a {
                position: relative;
                color: $main-color;
                font-weight: bold;
                padding-left: 15px;
                &:before{
                  content: '';
                  @include sprite($red-angle-right);
                  position: relative;
                  top: 1px;
                  margin-right: 4px;
                  display: inline-block;
                }
              }
            }
            a {
              line-height: 32px;
              padding-left: 0;
            }
          }
          > .category-sub-menu{
            overflow-y: auto;
            max-height: 508px;
            padding-right: 13px;
            li[data-depth="0"]{
              > a{
                font-size: 18px;
                @extend .PFBulletinSansPro-Regular;
                width: 100%;
                display: inline-block;
                line-height: 40px;
                border-bottom: 1px solid $main_color;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-right: 25px;
                font-weight: bold;
                overflow: hidden;
                letter-spacing: -0.4px;
                @media (max-width: 767px) {
                  max-width: 92%;
                  padding-right: 0;
                  overflow: hidden;
                }
              }
              .category-sub-menu{
                margin-left: 0;
                li{
                  border-bottom: 1px solid #c2c2c2;
                  padding-left: 15px;
                  a{
                    font-size: 14px;
                    font-family: 'Open Sans', sans-serif;
                    border-bottom: none;
                    line-height: 17px;
                    //text-overflow: ellipsis;
                    //white-space: nowrap;
                    max-width: 92%;
                    //overflow: hidden;
                    vertical-align: middle;
                    padding: 9px 0;
                  }
                  &:not(.current) > a:before{
                    display: none;
                  }
                }
              }
              .fa-angle-down{
                width: 25px;
                height: 25px;
                position: relative;
                top: 2px;
                &:before{
                  content: "";
                  position: absolute;
                  display: inline-block;
                  width: 18px;
                  height: 2px;
                  right:0;
                  top: 16px;
                  background-color: $main_color;
                }
                &:after{
                  content: "";
                  position: absolute;
                  display: inline-block;
                  width: 2px;
                  height: 18px;
                  right: 8px;
                  top: 8px;
                  transform: translateX(14%);
                  background-color: $main_color;
                }
              }
              .fa-angle-up{
                width: 25px;
                height: 25px;
                position: relative;
                top: 2px;
                &:before{
                  content: "";
                  position: absolute;
                  display: inline-block;
                  width: 18px;
                  height: 2px;
                  right:0;
                  top: 16px;
                  background-color: $main_color;
                }
              }
            }
          }
          .category-sub-menu{
            .category-sub-menu{
              .collapse.show{
                .category-sub-menu{
                  li{
                    &:last-child{
                      border-bottom: none;
                    }
                  }
                }
              }
            }
          }
        }
        &._toggled{
          h5{
            &:before{
              transform: translateY(-4%)rotate(-135deg);
            }
          }
          .block-content{
            display: none;
          }
        }
      }
      #facets_search_wrapper{
        position: relative;
        top: auto;
        overflow-y: unset;
        .block-facets{
          margin-bottom: 0;
        }
        #search_filters{
          #_desktop_search_filters_clear_all{
            button{
              background-color: $main_color;
              color: #fff;
              padding-left: 40px;
              width: calc(100% - 12px);
              .material-icons{
                position: absolute;
                left: 13px;
                font-size: 18px;
                top: 9px;
              }
            }
          }
          .facet{
            margin-bottom: 13px;
            &:last-child{
              padding-top: 7px;
              margin-bottom: 0;
              min-height: 118px;
            }
            &:not(first-child){
              .simplebar-vertical{
                padding-left: 20px;
              }
            }
            .h6{
              margin-bottom: 0;
              position: relative;
              cursor: pointer;
              padding-bottom: 11px;
              padding-top: 9px;
              font-size: 20px;
              border-bottom: solid 2px #c71b1b;
              width: 100%;
              &:before{
                content: "";
                position: absolute;
                display: inline-block;
                width: 10px;
                height: 10px;
                border-left: 2px solid $main_color;
                border-top: 2px solid $main_color;
                right: 3px;
                top:50%;
                transform: translateY(14%)rotate(45deg);
                transition: 0.2s ease;
                //background-color: $main_color;
              }
              span{
                font-size: 20px;
                font-weight: 400;
                line-height: 16px;
                color: #000;
              }
              &.current{
                &:before{
                  transform: translateY(14%)rotate(-135deg);
                }
              }
            }
            .current ~ ul{
              display: none!important;
            }
            ul{
              max-height: 186px;
              overflow-y: auto;
              margin-top: 1px;
              &:not(.faceted-slider){
                //padding-right: 20px;
              }

              &.faceted-slider{
                overflow-y: unset;
                margin-top: 22px;
                //max-width: 245px;
                max-width: calc(100% - 12px);
                @media(min-width: 768px){
                  display: grid;
                }
                min-height: 54px;
                @media(max-width: 1024px){
                  //max-width: 204px;
                }
                @media(max-width: 767px){
                  //max-width: 96%;

                }
                li{
                  border: none;
                  margin-right: 0;
                  position: relative;
                  p{
                    position: absolute;
                    font-size: 14px;
                    line-height: 20px;
                    bottom: -6px;
                    @media(max-width: 768px){
                      bottom: -44px;
                    }
                  }
                  .ui-slider{
                    background: #d8d8d8;
                    border-color: #d8d8d8;
                    border-radius: 5px;
                    min-height: 5px;
                    &:before{
                      content: "";
                      position: absolute;
                      display: inline-block;
                      width: 100%;
                      background: #d8d8d8;
                      left: 13px;
                      border-radius: 5px;
                      min-height: 5px;
                      height: .4em;
                      top: -1px;

                    }
                    .ui-slider-range{
                      background: #8d1b11;
                      min-height: 5px;
                      //min-width: 258px;
                    }
                    .ui-slider-handle{
                      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
                      background-color: $main_color;
                      width: 18px;
                      height: 18px;
                      border-radius: 50%;
                      top: -7px;
                      border: unset;
                      margin-left: -4px;
                    }
                  }
                }
              }

              li{
                border-bottom: 1px solid #c2c2c2;
                //margin-right: 14px;
                background-color: #fff;
                label{
                  margin-bottom: 0;
                  .ps-shown-by-js{
                    .material-icons{
                      display: none;
                    }
                  }
                  &.active{
                    .custom-checkbox{
                      .ps-shown-by-js{
                        border: 2px solid $main_color;
                        &:before{
                          content: "";
                          position: absolute;
                          display: inline-block;
                          width: 12px;
                          height: 12px;
                          border: 1px solid #fff;
                          background-color: $main_color;
                          left: 0;
                        }
                        .material-icons{
                          display: none;
                        }
                      }
                    }
                  }
                  .custom-checkbox{
                    top: 0;
                    width: 30px;
                    float: left;
                    line-height: 27px;
                    input[type=checkbox] + span{
                      border: 2px solid #c2c2c2;
                    }

                  }
                  a{
                    padding-left: 5px;
                    line-height: 25px;
                    width: calc(100% - 30px);
                    display: inline-block;
                    padding-right: 20px;
                    margin-top: 3px;
                    font-family: open sans;
                    font-size: 14px;
                    background-color: #fff;
                  }
                }
              }
            }
          }
        }
      }
      #gim_category_banner{
        position: relative;
        margin-top: 33px;
        .banner-content{
          img{
            max-width: 100%;
          }
          .button-container{
            position: absolute;
            left: 14px;
            bottom: 21px;
            width: calc(100% - 14px);
            .elementor-button{
              background: #fff;
              width: 100%;
              max-width: 195px;
              .elementor-button-text{
                font-size: 15px;
                color: $main_color;
                font-weight: 400;
                line-height: 15px;
                @extend .PFBulletinSansPro-Regular;
              }
            }
            &:hover{
              .elementor-button{
                background: $main_color;
                .elementor-button-text{
                  color: #fff;
                }
              }
            }
          }
        }
      }
      .elementor-products{
        margin-top: 44px;
        padding-bottom: 50px;
        .slick-arrow{

        }
        .product-miniature{
          margin-bottom: 0;
          &:hover{
            .product-functional-buttons-bottom{
              bottom: -0.59571rem;
            }
          }
        }
      }
    }
  }
}
@media(max-width: 1199px) and (min-width: 768px){
  body.page-category{
    #wrapper{

      #left-column{
        flex: 0 0 28%;
        max-width: 28%;
      }
    }
  }
}
.filter-gim{
  @extend .PFBulletinSansPro-Regular;
  @media(min-width: 992px){
    display: none;
  }
  &.remove{
    display: none;
  }
}
@media(max-width: 991px) and (min-width: 768px){
  body.page-category{
    #wrapper{
      .row{
        position: relative;
      }
      #left-column{
        flex: 0 0 100%;
        max-width: 236px;
        background-color: #fff;
        position: relative;
        top: 0;
        z-index: 99;
        padding-left: 0;
        padding-right: 0;
        //margin-top: -30px;
        margin-top: 0;
        #facets_search_wrapper{
          max-height: unset;
        }
        .simplebar-vertical{
          background-color: #fff;
          width: 3px;
          &:before{
            content: "";
            position: absolute;
            display: inline-block;
            width: 5px;
            height: 100%;
            background-color: #d8d8d8;
            right: 0px;
          }
        }
        .filter-gim-wrapper{
          position: absolute;
          width: 100%;
          background-color: #fff;
        }
        .filter-gim{
          background-color: $secondary_background;
          border-bottom: 1px solid $main_color;
          font-size: 20px;
          font-weight: 400;
          line-height: 43px;
          max-width: 236px;
          padding-left: 20px;
          position: relative;
          &:before{
            content: "";
            position: absolute;
            display: inline-block;
            width: 11px;
            height: 11px;
            border-left: 2px solid #c71b1b;
            border-top: 2px solid #c71b1b;
            right: 12px;
            top: 50%;
            transform: translateY(-27%) rotate(-135deg);
          }
          &.current{
            &:before{
              transform: translateY(4%) rotate(45deg);
              transition: 0.2s ease;
            }

          }
        }
        .filter-gim-wrapper{
          box-shadow: 0 0 34px rgba(0, 0, 0, 0.2);
          padding-left: 10px;
          padding-right: 10px;
          padding-bottom: 30px;
          display: none;
        }
        .filter-gim.current ~ .filter-gim-wrapper{
          display: block;
        }
        #gim_category_banner{
          display: none;
        }
        .elementor-products{
          display: none;
        }
      }
    }
  }
}
@media(max-width: 767px){
  body.page-category{
    #wrapper{
      #inner-wrapper{
        .row{
          &:nth-child(4){
            padding-left: 0;
            padding-right: 0;
            #content-wrapper{
              padding-left: 0;
              padding-right: 0;
              #js-product-list-top{
                padding-left: 15px;
                padding-right: 15px;
                .facated-toggler{
                  padding-left: 0;
                  padding-right: 0;
                }
              }
            }
          }
        }
      }
      #content-wrapper{
        #main{
          #products{
            #js-product-list{
              .js-product-miniature-wrapper{
                .product-miniature{
                  margin-left: 0;
                  margin-right: 10px;
                }
                &:nth-child(2n){
                  .product-miniature{
                    margin-left: 10px;
                    margin-right: 0px;
                  }
                }
              }
            }
          }
        }
      }
      #left-column{
        flex: 0 0 100%;
        max-width: 100%;
        padding-top: 20px;
        background-color: #f1f1f1;
        margin-top: 40px;
        #facets_search_wrapper{
          max-height: unset;
          padding-bottom: 20px;
          #search_filter_controls{
            .js-search-filters-clear-all{
              padding: 12px;
              color: #fff;
              padding-left: 18px;
              background-color: $main_color;
              i{
                position: relative;
                left: -5px;
              }
            }
            .ok{
              min-width: 130px;
            }
          }
        }
        &:not(.-only-facet-search){
          .hidden-md-up{
            display: none;
          }
          .block-facets{
            display: none;
          }
        }
        .simplebar-vertical{
          background-color: #f1f1f1;
          width: 3px;
          padding-left: 12px;
        }
        .filter-gim{
          display: none;
        }
        #gim_category_banner{
          display: none;
        }
        .elementor-products{
          display: none;
        }
        #search_filters{
          .collapse:not(.show){
            display: none;
          }
          .float-xs-right{
            display: none;
          }
        }
      }
      .filter-button{
        max-width: 290px;
        margin: auto;
      }
      #search_filter_toggler{
        background-color: $secondary_background;
        border-bottom: 2px solid $main_color;
        margin-bottom: 20px;
        line-height: 31px;
        text-align: left;
        font-size: 20px;
        font-weight: 400;
        position: relative;
        &:before{
          content: "";
          position: absolute;
          display: inline-block;
          width: 11px;
          height: 11px;
          border-left: 2px solid #c71b1b;
          border-top: 2px solid #c71b1b;
          right: 12px;
          top: 50%;
          transform: translateY(-27%) rotate(-135deg);
        }
        i{
          display: none;
        }
      }
    }
  }
  #left-column.-only-facet-search>:not(#facets_search_wrapper), #right-column.-only-facet-search>:not(#facets_search_wrapper){
    display: block;
  }
  #left-column{
    &:not(.-only-facet-search){
      display: none;
    }
  }
}
@media(max-width: 480px){
  body.page-category{
    #wrapper{
      #left-column{
        #facets_search_wrapper{

          #search_filter_controls{
            .js-search-filters-clear-all{
              width: 100%;
              display: block;
              max-width: 300px;
              margin: auto;
              margin-bottom: 10px;
              font-size: 14px;
              i{
                left: -10px;
              }
            }
            .ok{
              width: 100%;
              display: block;
              max-width: 300px;
              margin: auto;
              font-size: 14px;
            }
          }
        }

      }
    }
  }
}

