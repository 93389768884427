#js-delivery{
  .img-thumbnail{
    width: 41px;
    border: none;
    background-color: transparent;
  }
}

.datetimepicker.dropdown-menu{
  opacity: initial;
  visibility: initial;
  transform: initial;
  min-width: initial;
  display: none;
  transition: none;
}

div#onepagecheckoutps{
  label{
    cursor: pointer;
  }
  input[type="radio"]{
    border-radius: 50% !important;
    cursor: pointer;
    &:checked{
      border: 3px solid #fff;
      padding: 4px;
    }
  }

  input[type="checkbox"]{
    border-radius: 4px !important;
    cursor: pointer;
    &:checked{
      background-color: transparent;
      background-image: url($spritesheet-image);
      background-position: $tick-offset-x $tick-offset-y;
    }
  }
}

#opc_modal{
  background: none;
  z-index: 2030;
}