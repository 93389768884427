body.top-category-220{
  .product-miniature{
    .lhcom_list_reviews .star_display_off,
    .star_display_off{
      background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png');
    }
    .lhcom_list_reviews .star_display_on,
    .star_display_on{
      background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png');
    }
    .lhcom_list_reviews .star_display_half,
    .star_display_half{
      background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png');
    }
  }
  #blockcart-modal-wrap{
    #blockcart-modal{
      .modal-header{
        background-color: $grey_color;
      }
      .modal-body{
        .col-md-7{
          .cart-content{
            .cart-content-btn{
              .btn-primary{
                &:hover{
                  background-color: $grey_color;
                }
              }
              .btn-secondary{
                &:hover{
                  color: $grey_color;
                  border-color: $grey_color;
                }
              }
            }
          }
        }
        .darique-addon-presents{
          .you-have-gift{
            color: $grey_color;
            border-color: $grey_color;
            &:before{
              color: $grey_color;
            }
          }
        }
      }
    }
  }
  .elementor-button-link{
    i.fa-arrow-right{
      background-color: $grey_color;
      &:before,
      &:after{
        background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png');
      }
    }
    &:hover{
      i.fa-arrow-right{
        background-color: #fff;
      }
    }
  }
  .fa-credit-card:before{
    background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png');
  }
  .fa-angle-down:before{
    background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png');
  }
  .fa-search:before{
    background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png');
  }
  .fa-user:before{
    background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png');
  }
  .fa-shopping-bag:before{
    background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png');
  }
  .fa-long-arrow-down:before{
    background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png');
  }
  .fa-long-arrow-right:before{
    background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png');
  }
  .fa-heart-o:before{
    background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png');
  }
  .fa-building-o:before{
    background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png');
  }
  .fa-check-circle-o:before{
    background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png');
  }
  .fa-users:before{
    background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png');
  }
  .fa-random:before{
    background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png');
  }
  #blockcart-content{
    .cart-subtotals{
      border-color: $grey_color;
    }
    .cart-buttons{
      .btn-primary{
        background-color: $grey_color;
        border-color: $grey_color;
      }
    }
  }
  #header{
    background-color: $grey_color;
    #header-search-btn{
      .dropdown-search{
        background-color: $grey_color;
        border-color: $grey_color;
      }
    }
    #iqithtmlandbanners-block-2{
      .d-inline-block{
        span{
          span{
            background-color: $grey_color !important;
          }
        }
      }
    }
    .block-iqitlinksmanager-2{
      ul{
        li{
          &:after{
            background-color: $grey_color;
          }
        }
      }
    }
  }
  #mobile-header{
    background-color: $grey_color;
    #search-widget-mobile{
      background-color: $grey_color;
      border-color: $grey_color;
    }
    #iqitmegamenu-mobile{
      background-color: $grey_color;
      li{
        >ul{
          background-color: $grey_color;
        }
      }
    }
    .mobile-main-bar{
      .col-mobile-menu-dropdown{
        a{
          i{
            background-color: $grey_color;
          }
        }
        &.show{
          #_mobile_iqitmegamenu-mobile{
            >ul{
              >li{
                >a{
                  border-color: #fff;
                }
                ul{
                  li{
                    a{
                      border-color: rgba(255, 255, 255, 0.54);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  #desktop-header{
    #iqitmegamenu-horizontal{
      .cbp-show.cbp-notfit{
        border-color: $grey_color;
        .cbp-category-tree{
          li{
            .cbp-category-link-w{
              a{
                &:hover{
                  border-color: $grey_color;
                }
              }
            }
          }
        }
      }
    }
  }
  #wrapper{
    .breadcrumb{
      ol{
        li{
          a{
            color: $grey_color;
          }
          &:last-child{
            a{
              color: #000;
            }
          }
          &:after{
            color: $grey_color;
          }
        }
      }
    }
  }
  #footer{
    #footer-container-first{
      background-color: $grey_color;
      .ps-emailsubscription-block{
        .newsletter-input-group{
          .btn-subscribe{
            &:hover{
              background-color: $grey_color;
            }
          }
        }
      }
    }
    #footer-container-main{
      .block-content{
        ul{
          li{
            a{
              &:hover{
                color: $grey_color;
              }
            }
          }
        }
      }
      img{
        filter: hue-rotate(313deg) brightness(1.1);
      }
    }
  }
  .product-miniature.product-miniature-layout-1{

    .product-title{

      a{

      }
    }
    .product-thumbnail{

      img{

        &.loaded{

        }
      }
    }
    .product-description {

    }


    .product-reference{

    }
    .product-price-and-shipping{

    }
    .product-price,
    .regular-price{

    }
    .product-price{
      color: $grey_color;
    }
    .price-countdown-wrapper{

      .price-countdown{

        .price-countdown-title{

          .fa-clock-o{

          }
        }
      }
    }
    @media(max-width: 480px){
      .price-countdown-wrapper{

      }
    }
    .regular-price{

    }
    .product-flags{

    }
    .product-extra-flags{

      > div{

        &:last-child{

        }
      }
      .gim-feature-badge{


        img{

        }
      }
      .discount-badge{



        background-color: $grey_color;
        > span{

        }
      }
    }
    .product-flag{

      &:last-child{

      }
    }
  }
  .product-miniature-grid{
    &:hover{
      .product-functional-buttons-bottom{

      }
      .product-extra-flags{
        .gim-feature-badge{

        }
      }
    }
    .product-functional-buttons-bottom{

      .product-functional-buttons-links{

        a{
          &:hover{

          }
        }
      }
    }
    @media(max-width: 991px){
      .product-functional-buttons-bottom{

      }
    }
    .product-add-cart{

      &:before{

      }
      button.add-to-cart,
      a.btn-product-list{
        background: $grey_color;
        &:hover{
          background-color: #fff;
          color: $grey_color;
        }
      }
      button.add-to-cart{
        background: $grey_color;
        &:hover{
          background-color: #fff;
          color: $grey_color;
        }
      }
    }
    .btn-iqitcompare-add{
      i{
        &:after{

        }
      }
    }
    .btn-iqitwishlist-add{
      i{
        &:after{

        }
      }
    }
    .btn-iqitcompare-add,
    .btn-iqitwishlist-add{

      &:hover{
        background-color: $grey_color;
        i {
          &:before{

          }
          &:after {

          }
        }
      }
      &:before{

      }
      i{
        &:before,
        &:after{

        }
        &:before{

        }
        &:after{

        }
      }
    }
    .btn-iqitcompare-add{

    }
  }
  .slick-arrow{

    background-color: $grey_color;
    &:hover{
      background-color: #fff;
      &:before{
        background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png');
      }
      &:after{
        background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png');
      }
    }
    &:before,
    &:after{

    }
    &:before{

    }
    &:after{

    }
    &.slick-prev{
      &:before{
        background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png');
      }
      &:after{
        background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png');
      }
    }
    &.slick-next{
      &:before{
        background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png');
      }
      &:after{
        background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png');
      }
    }
  }
}
