.gim-daily-offer{
  padding: 5px 40px 20px 40px;
  @media(max-width: $tablet){
    padding: 10px 15px 20px;
  }
  @media(max-width: $mobile){
    padding: 0;
  }
}
.gim-no-offer{
  position: relative;
  > a{
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  > img{

  }
}
.daily-offer{
  .js-product-miniature-wrapper{
    padding: 0;
  }
  .product-miniature.product-miniature-layout-1{
    margin: 0;
    padding: 0;
    .product-flags{
      top: 5px;
      bottom: 5px;
      left: 5px;
      right: calc(2.429rem + 5px);
    }
    .product-extra-flags{
      top: 5px;
      right: 5px;
      left: calc(100% - 2.429rem - 5px);
      > div{
        margin-bottom: 5px;
      }
    }
  }
  .thumbnail-container{
    background-color: $background;
  }
  .lhcom_products_lists_average,
  .price-countdown-wrapper{
    display: none;
  }
  div.product-description{
    padding: 0;
  }
  .product-functional-buttons.product-functional-buttons-bottom{
    padding: 0 1px;
    bottom: 1px;
  }
  .product-reference,
  .product-title a,
  .product-price{
    color: $background !important;
    opacity: 1;
  }
}
.daily-offer-title{
  font-size: 3.286rem;
  margin-bottom: 33px;
  line-height: 1;
  color: $background;
}
#daily-offer-timer{
  @extend .clearfix;
  margin-bottom: 30px;
  > div{
    width: 23.636363%;
    margin-right: 1.818182%;
    float: left;
    text-align: center;
    span{
      display: block;
      &:first-child{
        @extend .PFBulletinSansPro-Regular;
        color: $main_color;
        font-size: 2.857rem;
        line-height: 2.857rem;
        background-color: #cecece;
        box-shadow: 0 0 11px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        background-image: linear-gradient(180deg, #ffffff 0%, #cecece 100%);
        padding-bottom: 11px;
      }
      &:last-child{
        @extend .PFBulletinSansPro-Regular;
        color: $background;
        line-height: 1;
      }
    }
    &:last-child{
      margin-right: 0;
    }
  }
}
