body.top-category-220{
  #products{

    .alert.alert-warning{

    }
  }
  #js-product-list-top{

    .view-switcher{

    }
    .col.col-auto{

      .showing.hidden-sm-down{

      }
      .products-sort-order,
      .products-nb-per-page{
        label{

        }
        .select-title{

          span{

          }
          .fa-angle-down{
            &:before{
              background-image: url('/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png');
            }
          }
        }
        .current{
          color: $grey_color !important;
        }
        &.show{
          .select-title{

            .fa-angle-down{
              &:before{

              }
            }
          }
        }
      }
      .products-nb-per-page{

        .select-title{

        }
        .dropdown-menu{

          border-bottom: 1px solid $grey_color;
          a{

            &:last-child{

              background-color: $grey_color;

              border: 1px solid $grey_color;

            }
          }
        }
      }
    }
  }
  #js-product-list{
    .products-grid{

    }
    .pagination{
      ul{
        li{

          a{
            color: $grey_color;

          }
          .next,
          .previous{

            i{
              &:before{

              }
            }
          }
          .next{

          }
          .previous{

          }
          &.current{
            a{

            }
          }
        }
      }
    }
    .product-miniature-grid{

      .product-description{

        .price-countdown-wrapper{

        }
      }
    }
  }
}
.page-category.top-category-220{
  //font-family: 'PFBulletinSansPro-Regular', sans-serif, Sans-serif;
  #content-wrapper{

  }
  #left-column{

  }
  #inner-wrapper{
    #notifications +.row{

    }
    .row{
      &:nth-child(2){

      }
      #js-product-list-header{

        .page-title{

        }
      }
      h1.page-title{

      }
      .category-description{

        h3{

        }
        p{

        }
      }
      .category-description{
        &+ .category-description-more, a{
          color: $grey_color;
          &:hover{
            color: $grey_highlighted;
          }
        }
      }
    }
  }
}




