$main_color: #c71b1b;
$blue_color: #0a2f77;
$grey_color: #8e9197;
$highlighted_main: #d23024;
$secondary_color: #000;
$background: #fff;
$secondary_background: #e7e7e7;
$darker_secondary_background: #c2c2c2;
$main_text: $secondary_color;
$secondary_text: $background;
$overlay_color: #8d1b11;
$blue_overlay_color: #05143b;
$pink_overlay_color: #75204c;
$blue_highlighted: #102f7d;
$grey_highlighted: #9b9c9e;
$presta_transition_time: .2s;
$presta_transition_function: cubic-bezier(.7,0,.3,1);
$presta_transition: $presta_transition_time $presta_transition_function;

$xl: 1823px;
$laptop: 1440px;
$lg: 1223px;
$lgs: 1140px;
$md: 1023px;
$tablet: 991px;
$mobile: 767px;
$smaller: 640px;
$xs: 479px;
$sxs: 414px;
