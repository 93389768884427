h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
.PFBulletinSansPro-Regular,
.product-flag span,
#wrapper .breadcrumb ol li a,
#header .center-nav,
#header .ps-shoppingcart .cart-products-count-btn,
#_mobile_iqitmegamenu-mobile #iqitmegamenu-mobile > li > a,
#content-wrapper .crossselling-products .section-title,
#daily-offer-timer > div span:first-child,
#daily-offer-timer > div span:last-child,
#iqitmegamenu-wrapper .nav-link,
#footer-container-first .block-newsletter p,
.product-miniature.product-miniature-layout-1 .product-price-and-shipping,
.product-miniature.product-miniature-layout-1 .product-extra-flags .discount-badge,
.product-miniature-grid .product-add-cart,
.tabbed-carousel-front .tabs-container h2,
.tabbed-carousel-front .tabs-container .nav-tabs .nav-item .nav-link,
#js-product-list-top .col.col-auto .products-sort-order label,
#js-product-list-top .col.col-auto .products-nb-per-page label,
body.page-category #wrapper #left-column .block-categories .block-content > .category-sub-menu li[data-depth="0"] > a,
body.page-category #wrapper #left-column #gim_category_banner .banner-content .button-container .elementor-button .elementor-button-text,
.filter-gim,
#product #inner-wrapper .col-product-info h4,
#product #inner-wrapper .col-product-info .product-information .rte-content #see-more-description-product,
#product #inner-wrapper .col-product-info .product-information .product-prices .has-discount .current-price .product-price,
#product #inner-wrapper .col-product-info .product-information .product-prices .has-discount .current-price .offer-label,
#product #inner-wrapper .col-product-info .product-information .product-prices .has-discount .current-price .eshop-label,
#product #inner-wrapper .col-product-info .product-information .product-prices .current-price .product-price,
#product #inner-wrapper .col-product-info .product-information .product-prices .current-price .offer-label,
#product #inner-wrapper .col-product-info .product-information .product-prices .current-price .eshop-label,
#product #inner-wrapper .col-product-info .product-information .product-prices .product-discount .regular-price,
#product #inner-wrapper .col-product-info .product-information .product-prices .product-discount .eshop-label,
#product #inner-wrapper .col-product-info .product-information .product-prices .physical-shop-price-container .physical-shop-price,
#product #inner-wrapper .col-product-info .product-information .product-prices .physical-shop-price-container .eshop-label,
#product #inner-wrapper .col-product-info .product-information .product-actions form .product-variants .form-control-label,
#product #inner-wrapper .col-product-info .product-information .product-actions form .product-quantity .qty-label,
#product #inner-wrapper .col-product-info .product-information .product-actions form .product-add-to-cart .add-to-cart,
#product #inner-wrapper .col-product-info .scroll-icons > a,
#product #inner-wrapper .tabs.product-tabs #product-infos-tabs .nav-item .nav-link,
#product #inner-wrapper #product-infos-accordion-mobile .nav-tabs .nav-link,
#product #inner-wrapper #product-infos-accordion-mobile .tab-content *,
#product #inner-wrapper .wrapper-field-line .field-line-inner .line-title,
#product #inner-wrapper .wrapper-field-line .field-line-inner .box-line .box-line-title,
#product #inner-wrapper .product-accessories .section-title {
  font-family: 'PFBulletinSansPro-Regular', sans-serif;
  font-style: normal; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: bold; }

.elementor-section.elementor-section-stretched {
  max-width: 1800px;
  transform: translateX(-50%);
  margin-left: 0; }

.clearfix:after, #daily-offer-timer:after, #modal-onexit.modal .modal__content:after, #kb_pts_carrier_block .velo-store-filters:after, .product-miniature.product-miniature-layout-1 .product-price-and-shipping:after, .product-miniature-grid .product-functional-buttons-bottom .product-functional-buttons-links:after, .tabbed-carousel-front:after, .tabbed-carousel-front .tabs-container .nav-tabs .nav-item .nav-link:after, .page-category #inner-wrapper .row.image-description-row:after {
  content: '';
  display: table;
  clear: both; }

.red-overlay-background, .red-overlay .elementor-iqit-banner-overlay,
.red-overlay .overlay-element, .red-overlay-element:before {
  background-color: rgba(141, 27, 17, 0.85);
  opacity: 1;
  mix-blend-mode: multiply; }

.red-overlay-element {
  position: relative; }
  .red-overlay-element:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

li.product-flag.product-unavailable {
  background-color: #000; }

.white-content-box .elementor-iqit-banner {
  overflow: visible; }
  .white-content-box .elementor-iqit-banner:hover .elementor-iqit-banner-content {
    background-color: #c71b1b;
    color: #fff; }

.white-content-box .elementor-iqit-banner-content {
  margin: -40px 20px 0;
  background-color: #fff;
  box-shadow: 0 2px 64px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2;
  transition: background-color 0.2s cubic-bezier(0.7, 0, 0.3, 1), color 0.2s cubic-bezier(0.7, 0, 0.3, 1);
  min-height: 128px;
  color: #000; }

.slick-arrows-middle .slick-arrow.slick-prev {
  transform: translate(-100%, -50%); }

.slick-arrows-middle .slick-arrow.slick-next {
  transform: translate(100%, -50%); }

.custom-icon, .fa-phone:before, .fa-credit-card:before, .fa-angle-down:before, .fa-search:before, .fa-user:before, .fa-shopping-bag:before, .fa-long-arrow-down:before, .fa-long-arrow-right:before, .fa-heart-o:before, .fa-building-o:before, .fa-check-circle-o:before, .fa-users:before, .fa-random:before, #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile > ul #iqithtmlandbanners-block-5 .menu-phone a:before {
  content: '';
  display: inline-block; }

.fa-phone:before {
  background-image: url(../img/sprite.png);
  background-position: -478px 0px;
  width: 15px;
  height: 16px;
  vertical-align: bottom; }

.fa-credit-card:before {
  background-image: url(../img/sprite.png);
  background-position: 0px -454px;
  width: 16px;
  height: 12px; }

.fa-angle-down:before {
  background-image: url(../img/sprite.png);
  background-position: -478px -208px;
  width: 12px;
  height: 8px; }

.fa-search:before {
  background-image: url(../img/sprite.png);
  background-position: -278px -260px;
  width: 24px;
  height: 24px; }

.fa-user:before {
  background-image: url(../img/sprite.png);
  background-position: -86px -260px;
  width: 24px;
  height: 24px; }

.fa-shopping-bag:before {
  background-image: url(../img/sprite.png);
  background-position: -150px -260px;
  width: 24px;
  height: 24px; }

.fa-long-arrow-down:before {
  background-image: url(../img/sprite.png);
  background-position: -420px -291px;
  width: 15px;
  height: 20px; }

.fa-long-arrow-right:before {
  background-image: url(../img/sprite.png);
  background-position: -122px -397px;
  width: 19px;
  height: 14px; }

.fa-heart-o:before {
  background-image: url(../img/sprite.png);
  background-position: -301px -336px;
  width: 20px;
  height: 18px; }

.fa-building-o:before {
  background-image: url(../img/sprite.png);
  background-position: 0px 0px;
  width: 48px;
  height: 48px; }

.fa-check-circle-o:before {
  background-image: url(../img/sprite.png);
  background-position: -88px 0px;
  width: 48px;
  height: 48px; }

.fa-users:before {
  background-image: url(../img/sprite.png);
  background-position: 0px -176px;
  width: 48px;
  height: 44px; }

.fa-random:before {
  background-image: url(../img/sprite.png);
  background-position: -61px -397px;
  width: 21px;
  height: 17px; }

.product-miniature .lhcom_list_reviews .star_display_off,
.product-miniature .star_display_off {
  background-image: url(../img/sprite.png);
  background-position: -355px -397px;
  width: 16px;
  height: 14px; }

.product-miniature .lhcom_list_reviews .star_display_on,
.product-miniature .star_display_on {
  background-image: url(../img/sprite.png);
  background-position: -299px -397px;
  width: 16px;
  height: 14px; }

.product-miniature .lhcom_list_reviews .star_display_half,
.product-miniature .star_display_half {
  background-image: url(../img/sprite.png);
  background-position: -411px -397px;
  width: 16px;
  height: 14px; }

.lhcom_products_lists_average {
  margin-top: 0;
  text-align: initial; }

.lhcom_products_lists_average .average {
  text-align: inherit; }

div.slick-slider.slick-arrows-above .slick-arrow {
  top: auto;
  bottom: 0;
  right: auto;
  transform: translate(-50%, 100%);
  width: 40px;
  height: 40px;
  display: inline-block; }
  div.slick-slider.slick-arrows-above .slick-arrow.slick-prev {
    padding: 0;
    left: calc(50% - 21px); }
  div.slick-slider.slick-arrows-above .slick-arrow.slick-next {
    padding: 0;
    left: calc(50% + 21px); }

.slick-arrow {
  opacity: 1;
  width: 40px;
  height: 40px;
  background-color: #c71b1b;
  transition: background-color 0.2s cubic-bezier(0.7, 0, 0.3, 1), color 0.2s cubic-bezier(0.7, 0, 0.3, 1), box-shadow 0.2s cubic-bezier(0.7, 0, 0.3, 1); }
  .slick-arrow:hover {
    background-color: #fff;
    box-shadow: 0 2px 40px rgba(0, 0, 0, 0.2); }
    .slick-arrow:hover:before {
      opacity: 1; }
    .slick-arrow:hover:after {
      opacity: 0; }
  .slick-arrow:before, .slick-arrow:after {
    content: '';
    font-family: inherit;
    font-size: 0;
    line-height: 0;
    display: inline-block;
    transition: opacity 0.2s cubic-bezier(0.7, 0, 0.3, 1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .slick-arrow:before {
    opacity: 0.01; }
  .slick-arrow:after {
    opacity: 1; }
  .slick-arrow.slick-prev:before {
    background-image: url(../img/sprite.png);
    background-position: -361px -336px;
    width: 19px;
    height: 14px; }
  .slick-arrow.slick-prev:after {
    background-image: url(../img/sprite.png);
    background-position: -240px -397px;
    width: 19px;
    height: 14px; }
  .slick-arrow.slick-next:before {
    background-image: url(../img/sprite.png);
    background-position: -122px -397px;
    width: 19px;
    height: 14px; }
  .slick-arrow.slick-next:after {
    background-image: url(../img/sprite.png);
    background-position: -181px -397px;
    width: 19px;
    height: 14px; }

.elementor-button-link {
  padding: 0.6245rem 0.937rem;
  padding-right: calc(0.937rem + 40px);
  position: relative; }
  .elementor-button-link i.fa-arrow-right {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 40px;
    background-color: #c71b1b;
    transition: background-color 0.2s cubic-bezier(0.7, 0, 0.3, 1);
    margin-right: 0; }
    .elementor-button-link i.fa-arrow-right:before, .elementor-button-link i.fa-arrow-right:after {
      content: '';
      display: inline-block;
      transition: opacity 0.2s cubic-bezier(0.7, 0, 0.3, 1);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .elementor-button-link i.fa-arrow-right:before {
      background-image: url(../img/sprite.png);
      background-position: -181px -397px;
      width: 19px;
      height: 14px; }
    .elementor-button-link i.fa-arrow-right:after {
      background-image: url(../img/sprite.png);
      background-position: -122px -397px;
      width: 19px;
      height: 14px;
      opacity: 0.01; }
  .elementor-button-link:hover i.fa-arrow-right {
    background-color: #fff; }
    .elementor-button-link:hover i.fa-arrow-right:before {
      opacity: 0; }
    .elementor-button-link:hover i.fa-arrow-right:after {
      opacity: 1; }

.elementor-brands img {
  padding-left: 15px;
  padding-right: 15px; }

#wrapper .breadcrumb {
  margin-bottom: 0; }
  #wrapper .breadcrumb ol li a {
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase; }
  #wrapper .breadcrumb ol li:after {
    font-size: 21px;
    opacity: 1;
    top: 2px;
    position: relative;
    margin: .1em; }
  #wrapper .breadcrumb ol li:last-child a {
    color: #000; }

.footer-social-buttons {
  margin-top: 1rem;
  letter-spacing: 0.4px; }

.instagram-button {
  font-size: 0;
  line-height: 0;
  display: inline-block;
  color: transparent;
  position: relative; }
  .instagram-button:before {
    content: '';
    display: inline-block;
    background-image: url(../img/sprite.png);
    background-position: 0px -336px;
    width: 21px;
    height: 21px;
    transition: filter cubic-bezier(0.7, 0, 0.3, 1); }
  .instagram-button:hover:before {
    filter: brightness(0.8); }

i.product-nutrition-icon,
i.product-dosage-icon {
  display: inline-block;
  margin-right: 4px; }
  i.product-nutrition-icon:before,
  i.product-dosage-icon:before {
    content: '';
    display: inline-block;
    vertical-align: bottom; }

i.product-nutrition-icon:before {
  background-image: url(../img/sprite.png);
  background-position: -262px -172px;
  width: 37px;
  height: 44px; }

i.product-dosage-icon:before {
  background-image: url(../img/sprite.png);
  background-position: -343px -84px;
  width: 29px;
  height: 46px; }

@media (max-width: 1285px) {
  .elementor-element-e0x5t6p .slick-list.draggable {
    padding-left: 20px;
    padding-right: 20px; }
  .slick-arrows-middle .slick-arrow.slick-prev {
    transform: translate(-50%, -50%); }
  .slick-arrows-middle .slick-arrow.slick-next {
    transform: translate(50%, -50%); } }

@media (max-width: 991px) {
  .elementor-element-e0x5t6p .elementor-brands {
    padding-left: 35px;
    padding-right: 35px; } }

@media (max-width: 767px) {
  #wrapper .breadcrumb {
    padding-top: 5px;
    padding-bottom: 5px; }
    #wrapper .breadcrumb ol {
      line-height: 22px; }
  .elementor-element-e0x5t6p .slick-list.draggable {
    padding-left: 0;
    padding-right: 0; }
  .elementor-element-e0x5t6p .elementor-brands {
    padding-left: 50px;
    padding-right: 50px; }
    .elementor-element-e0x5t6p .elementor-brands img {
      padding-left: 10px;
      padding-right: 10px; }
    .elementor-element-e0x5t6p .elementor-brands .slick-prev {
      left: -30px; }
    .elementor-element-e0x5t6p .elementor-brands .slick-next {
      right: -30px; } }

p {
  margin-bottom: 1.42857rem; }

.footer-fb {
  background-color: #4267B2;
  width: 21px;
  height: 21px;
  display: inline-block;
  position: relative;
  top: -5px;
  margin-right: 9px;
  border-radius: 2px; }

.footer-fb i {
  width: 21px;
  height: 21px;
  line-height: 23px; }

.ps-emailsubscription-block .psgdpr_consent_message {
  font-size: 13px; }
  .ps-emailsubscription-block .psgdpr_consent_message a {
    color: #fff !important;
    font-size: 13px;
    font-weight: 700; }

#footer-container-main .block-title {
  margin-bottom: 0; }

#footer-container-main .block {
  padding-bottom: 0; }

#footer-container-main .block-links ul > li {
  margin-bottom: 0; }
  #footer-container-main .block-links ul > li a {
    padding-left: 0;
    text-transform: uppercase;
    font-size: 0.929rem;
    line-height: 1.6923; }
    #footer-container-main .block-links ul > li a:before {
      content: none; }

#footer-copyrights td > * {
  vertical-align: middle; }

#footer-copyrights .card-icons {
  margin-top: 4px; }
  @media (min-width: 541px) {
    #footer-copyrights .card-icons {
      display: inline-block;
      margin-top: 0; } }

@media (max-width: 991px) {
  #footer-container-main {
    padding-left: 32px;
    padding-right: 32px; }
  #footer-copyrights {
    padding-left: 32px;
    padding-right: 32px; } }

@media (max-width: 767px) {
  #index #footer-container-main .container {
    padding-left: 15px;
    padding-right: 15px; }
  #footer-container-main {
    padding-left: 0;
    padding-right: 0; }
    #footer-container-main .container .row .col {
      flex-basis: 50%; }
      #footer-container-main .container .row .col:nth-child(3), #footer-container-main .container .row .col:nth-child(4) {
        margin-top: 55px; }
    #footer-container-main .container .row .mb-5 {
      width: 100%;
      margin-right: 0; } }

@media (max-width: 540px) {
  #footer-copyrights .container table tbody tr td {
    width: 100%;
    display: inline-block;
    text-align: center !important;
    margin-bottom: 6px; }
    #footer-copyrights .container table tbody tr td:last-child {
      margin-bottom: 2px; } }

@media (max-width: 420px) {
  #footer-copyrights .container table tbody tr td:nth-child(2) {
    text-align: center !important; } }

.darique-addon-presents .section-title {
  margin-bottom: 20px; }

.darique-addon-presents .you-have-gift {
  margin-top: 24px;
  font-family: 'PFBulletinSansPro-Regular', sans-serif;
  border-bottom: 2px solid #c71b1b;
  color: #c71b1b;
  padding-bottom: 8px;
  margin-bottom: 15px;
  font-size: 1.5rem; }
  .darique-addon-presents .you-have-gift:before {
    font-family: FontAwesome;
    content: "\f06b";
    display: inline-block;
    color: #c71b1b;
    margin-right: 8px;
    font-size: 1.5rem; }

.darique-addon-presents .darique-addon-grid {
  display: flex;
  flex-wrap: wrap; }
  .darique-addon-presents .darique-addon-grid .gift-product-inner {
    border-bottom: 2px solid #e7e7e7;
    margin: 0 15px; }
    @media (max-width: 640px) {
      .darique-addon-presents .darique-addon-grid .gift-product-inner {
        margin: 0 5px; } }
  .darique-addon-presents .darique-addon-grid .gift-product .gift-product-title {
    margin-top: 10px;
    display: block;
    vertical-align: bottom;
    min-height: 2.4em;
    line-height: 1;
    margin-bottom: 0.4rem;
    font-size: 14px;
    padding-bottom: 0.4rem;
    text-align: left;
    font-family: 'PFBulletinSansPro-Regular'; }
  .darique-addon-presents .darique-addon-grid .gift-product .gift-product-inner {
    text-align: center; }
    .darique-addon-presents .darique-addon-grid .gift-product .gift-product-inner .gift-img-product img {
      max-width: 100%; }
    .darique-addon-presents .darique-addon-grid .gift-product .gift-product-inner h5 {
      text-align: center; }

.cart-darique {
  padding: 0;
  border: none; }
  .cart-darique:after {
    content: "";
    display: table;
    clear: both; }
  .cart-darique .arrow_left {
    left: 4px;
    opacity: 0.3;
    border: solid black;
    border-width: 0 5px 5px 0;
    display: inline-block;
    padding: 9px;
    top: 50%;
    transform: translateY(-50%) rotate(135deg); }
    .cart-darique .arrow_left:hover {
      opacity: 1; }
  .cart-darique .arrow_right {
    opacity: 0.3;
    border: solid black;
    border-width: 0 5px 5px 0;
    display: inline-block;
    padding: 9px;
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
    right: 4px; }
    .cart-darique .arrow_right:hover {
      opacity: 1; }
  .cart-darique .dariqueItem .gift .gift-img img {
    max-width: 100%;
    height: auto;
    width: 100%; }
  .cart-darique div#dariqueWrapper > .dariqueRuleContainer {
    float: left;
    width: 48.5%;
    margin-bottom: 1.2rem;
    margin-right: 3%; }
    .cart-darique div#dariqueWrapper > .dariqueRuleContainer:nth-child(2n) {
      margin-right: 0; }
    .cart-darique div#dariqueWrapper > .dariqueRuleContainer:nth-child(2n+1) {
      clear: both; }
  .cart-darique ul.dariqueWrapper {
    margin-bottom: 0;
    display: block;
    margin: 0 30px; }
    .cart-darique ul.dariqueWrapper > div > div:first-child .gift {
      margin-left: 0; }
    .cart-darique ul.dariqueWrapper > div > div:last-child .gift {
      margin-right: 0; }
    .cart-darique ul.dariqueWrapper li {
      padding: 0; }
      .cart-darique ul.dariqueWrapper li .gift {
        margin: 0 15px;
        float: left;
        border: none;
        text-align: center;
        cursor: pointer;
        padding: 0 0 0.6em; }
        .cart-darique ul.dariqueWrapper li .gift:hover, .cart-darique ul.dariqueWrapper li .gift:focus {
          background: transparent; }
        .cart-darique ul.dariqueWrapper li .gift.active {
          background: transparent; }
        .cart-darique ul.dariqueWrapper li .gift h5 {
          font-size: 14px;
          line-height: 1.3;
          max-width: 100%;
          overflow: hidden;
          margin-bottom: 0;
          margin-top: 7px;
          text-align: left; }
  .cart-darique div#dariqueWrapper {
    padding: 0; }
    .cart-darique div#dariqueWrapper .page-heading {
      margin-bottom: 10px; }
  .cart-darique .card-block {
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0; }
    .cart-darique .card-block h1 {
      text-align: left;
      font-size: 1.57143rem;
      font-weight: 700; }
  @media (max-width: 640px) {
    .cart-darique div#dariqueWrapper > .dariqueRuleContainer {
      float: none;
      width: 100%;
      margin-right: 0; } }

#checkout .cart-darique {
  margin-top: 20px; }
  #checkout .cart-darique ul.dariqueWrapper {
    margin-bottom: 0; }
  #checkout .cart-darique ul#dariqueWrapper {
    margin-bottom: 0; }
  #checkout .cart-darique .page-heading {
    font-size: 11px;
    margin-bottom: 10px;
    letter-spacing: -0.02em; }
  #checkout .cart-darique hr {
    margin-top: 0; }
  #checkout .cart-darique h1 {
    font-size: 16px;
    font-family: "PFBulletinSansPro-Regular", sans-serif;
    font-weight: 400; }
  #checkout .cart-darique ul.dariqueWrapper {
    margin: 0 22px; }
    #checkout .cart-darique ul.dariqueWrapper > div > div:first-child .gift {
      margin-left: 10px; }
    #checkout .cart-darique ul.dariqueWrapper > div > div:last-child .gift {
      margin-right: 10px; }
    #checkout .cart-darique ul.dariqueWrapper li.dariqueItem .gift {
      margin: 0 10px; }
      #checkout .cart-darique ul.dariqueWrapper li.dariqueItem .gift h5 {
        line-height: 1.2;
        font-size: 12px;
        border-bottom: none !important; }
  @media (max-width: 640px) {
    #checkout .cart-darique div#dariqueWrapper > .dariqueRuleContainer {
      float: left;
      width: 48.5%;
      margin-right: 3%; }
      #checkout .cart-darique div#dariqueWrapper > .dariqueRuleContainer:nth-child(2n) {
        margin-right: 0; }
      #checkout .cart-darique div#dariqueWrapper > .dariqueRuleContainer:nth-child(2n+1) {
        clear: both; } }
  @media (max-width: 320px) {
    #checkout .cart-darique div#dariqueWrapper > .dariqueRuleContainer {
      float: none;
      width: 100%;
      margin-right: 0; } }

#header {
  background-color: #c71b1b; }
  #header .container {
    max-width: 1800px;
    margin-right: auto;
    margin-left: auto;
    padding: 0; }
  #header #header-search-btn .dropdown-search {
    background-color: #c71b1b;
    border-color: #c71b1b;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.721569); }
    #header #header-search-btn .dropdown-search .form-search-control {
      background-color: #e4e4e4;
      border: solid 1px #e4e4e4;
      color: #000; }
      #header #header-search-btn .dropdown-search .form-search-control:focus {
        box-shadow: none; }
  #header .header-top {
    padding: 0; }
  #header .center-nav {
    font-size: 1.5em; }
  #header .header-nav .right-nav > div:after {
    content: none; }
  #header .header-nav .right-nav .fa-credit-card {
    vertical-align: middle;
    margin-right: 3px; }
  #header .block-links-inline ul > li:after {
    background-color: #c71b1b;
    opacity: 1;
    margin: 0 .3rem 0.1rem;
    height: 1.1em; }
  #header .ps-shoppingcart .cart-products-count-btn {
    top: -6px;
    right: -6px; }
  #header .ps-shoppingcart .fa-shopping-bag:before {
    background-image: url(../img/sprite.png);
    background-position: -214px -260px;
    width: 24px;
    height: 24px; }

#mobile-header {
  background-color: #c71b1b; }
  #mobile-header #search-widget-mobile {
    background-color: #c71b1b;
    border-color: #c71b1b; }
    #mobile-header #search-widget-mobile .form-search-control {
      background-color: #f1f1f1;
      border: solid 1px #f1f1f1;
      color: #000; }
      #mobile-header #search-widget-mobile .form-search-control:focus {
        box-shadow: none; }
    #mobile-header #search-widget-mobile .search-btn i {
      position: relative;
      top: 2px; }

#iqithtmlandbanners-block-4 {
  margin-right: 1rem; }
  #iqithtmlandbanners-block-4 a > i {
    margin-right: 1.5rem;
    font-size: 1.142857rem;
    line-height: 12px; }
    #iqithtmlandbanners-block-4 a > i.fa-facebook {
      margin-right: .5rem; }

#language_selector {
  margin-left: 1rem;
  text-transform: uppercase; }

@media (max-width: 1815px) and (min-width: 992px) {
  #header #desktop-header {
    padding-left: 5px;
    padding-right: 5px; }
  #header .header-nav {
    padding-left: 5px;
    padding-right: 5px; }
    #header .header-nav .right-nav {
      padding-left: 0;
      padding-right: 20px; } }

@media (max-width: 1440px) and (min-width: 1141px) {
  #header .center-nav {
    position: relative;
    left: 28px; } }

@media (max-width: 1140px) {
  #header .center-nav {
    font-size: 1.3em;
    width: 100%;
    flex: 0 0 auto;
    max-width: 129px;
    padding-left: 5px;
    padding-right: 5px; }
  #header .header-nav .right-nav {
    width: 100%;
    flex: 0 0 auto;
    max-width: 417px; }
    #header .header-nav .right-nav > div p {
      font-size: 11px; }
    #header .header-nav .right-nav .block-iqitlinksmanager-2 ul li a {
      font-size: 11px; }
  #header .header-nav .left-nav {
    width: 100%;
    flex: 0 0 auto;
    max-width: 465px;
    padding-right: 10px; }
    #header .header-nav .left-nav p {
      font-size: 11px; }
  #iqithtmlandbanners-block-4 {
    margin-right: 5px; } }

@media (max-width: 1250px) {
  #header #iqithtmlandbanners-block-2 {
    font-size: 11px; }
  #header .header-nav .right-nav > div p {
    font-size: 0px; }
    #header .header-nav .right-nav > div p i {
      display: none; } }

@media (max-width: 991px) {
  #header .center-nav {
    padding-right: 65px;
    max-width: 192px; }
  #header .header-nav {
    padding-bottom: 6px; }
    #header .header-nav .block-iqitlinksmanager-2 {
      display: none !important; }
    #header .header-nav .right-nav {
      position: absolute;
      right: 0;
      bottom: 0; }
    #header .header-nav .left-nav {
      padding-left: 43px;
      max-width: 495px; } }

#mobile-header .mobile-main-bar {
  padding-left: 32px;
  padding-right: 32px; }
  #mobile-header .mobile-main-bar .col-auto {
    margin-left: 7px; }
  #mobile-header .mobile-main-bar .col-mobile-menu-dropdown {
    margin-left: 20px; }
    #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show {
      background: none; }
      #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile {
        height: 100vh;
        position: fixed;
        z-index: 5005;
        top: 0;
        left: 0;
        overflow-x: hidden;
        width: 100%; }
        #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile > ul {
          padding-top: 100px; }
          #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile > ul > li > a {
            border-bottom: 2px solid #e38d8c; }
          #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile > ul > li .mm-expand .fa-angle-down {
            position: relative;
            bottom: 10px; }
            #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile > ul > li .mm-expand .fa-angle-down:before {
              content: "\f107";
              font-size: 25px; }
          #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile > ul > li .mm-expand .fa-angle-up {
            bottom: 9px;
            position: relative; }
            #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile > ul > li .mm-expand .fa-angle-up:before {
              font-size: 25px; }
          #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile > ul > li ul {
            padding-left: 0; }
            #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile > ul > li ul li a {
              font-size: 13px;
              border-bottom: 1px solid #a11b14;
              line-height: 32px;
              padding-bottom: 0;
              padding-top: 0;
              min-height: unset; }
          #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile > ul #language_selector {
            position: absolute;
            top: 58px;
            left: 9px; }
            #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile > ul #language_selector .language-selector-wrapper .language-selector .expand-more {
              font-size: 15px;
              padding: 0;
              min-height: 23px; }
              #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile > ul #language_selector .language-selector-wrapper .language-selector .expand-more .fa-angle-down {
                width: 30px;
                height: 10px; }
                #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile > ul #language_selector .language-selector-wrapper .language-selector .expand-more .fa-angle-down:after {
                  display: none; }
                #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile > ul #language_selector .language-selector-wrapper .language-selector .expand-more .fa-angle-down:before {
                  content: '';
                  position: absolute;
                  top: 3px;
                  width: 8px;
                  height: 8px;
                  background-color: transparent;
                  border-left: 2px solid #fff;
                  border-top: 2px solid #fff;
                  right: 18px;
                  left: inherit;
                  box-shadow: none;
                  transform: rotate(225deg); }
          #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile > ul #iqithtmlandbanners-block-5 {
            position: absolute;
            top: 5px;
            left: 12px; }
            #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile > ul #iqithtmlandbanners-block-5 .block-title {
              display: none; }
            #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile > ul #iqithtmlandbanners-block-5 .menu-phone a {
              width: 100%;
              max-width: 143px;
              position: relative;
              font-size: 18px;
              font-weight: 400;
              margin: 0 auto;
              padding: 0;
              line-height: 27px;
              min-height: unset;
              opacity: 1;
              padding-left: 20px;
              text-align: left;
              font-family: 'PFBulletinSansPro-Regular'; }
              #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile > ul #iqithtmlandbanners-block-5 .menu-phone a:before {
                content: "";
                position: absolute;
                display: inline-block;
                background-image: url(../img/sprite.png);
                background-position: -478px 0px;
                width: 15px;
                height: 16px;
                vertical-align: bottom;
                left: 0;
                top: 50%;
                transform: translateY(-50%); }
        #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile .menu-close {
          position: absolute;
          right: 16px;
          top: 47px;
          width: 40px;
          height: 40px;
          font-size: 0; }
          #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile .menu-close:before, #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile .menu-close:after {
            content: "";
            position: absolute;
            display: inline-block;
            width: 23px;
            height: 2px;
            background-color: #fff;
            left: 50%; }
          #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile .menu-close:before {
            transform: rotate(45deg);
            top: 17px;
            left: 9px; }
          #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile .menu-close:after {
            transform: rotate(-45deg);
            top: 17px;
            left: 9px; }
        #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile #iqitmegamenu-mobile {
          min-height: 100%;
          padding-left: 20px;
          padding-right: 20px; }
          #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile #iqitmegamenu-mobile > li > a {
            min-height: unset;
            font-size: 20px;
            padding: 4px 0px;
            padding-bottom: 15px; }
          #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile #iqitmegamenu-mobile > li:nth-child(5) {
            margin-top: 31px;
            border: none; }
          #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile #iqitmegamenu-mobile > li:nth-child(n + 5) {
            border: none; }
            #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile #iqitmegamenu-mobile > li:nth-child(n + 5) > a {
              font-size: 15px;
              border: none;
              padding: 0;
              line-height: 26px;
              font-family: 'Open Sans'; }
          #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile #iqitmegamenu-mobile > li:nth-child(8) {
            padding-left: 34px;
            margin-top: 5px; }
            #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile #iqitmegamenu-mobile > li:nth-child(8):before {
              content: "";
              position: absolute;
              width: 25px;
              height: 20px;
              left: 0;
              top: 50%;
              transform: translateY(-37%);
              background-image: url(../img/mobile-menu-bbclub.png);
              background-repeat: no-repeat; }
    #mobile-header .mobile-main-bar .col-mobile-menu-dropdown a i {
      background-color: #c71b1b;
      padding: 7px;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      position: relative; }
      #mobile-header .mobile-main-bar .col-mobile-menu-dropdown a i:before {
        content: "";
        width: 26px;
        height: 2px;
        display: inline-block;
        box-shadow: 0 6px 0 0 #fff;
        background-color: #fff;
        top: 13px;
        position: absolute;
        left: 7px; }
      #mobile-header .mobile-main-bar .col-mobile-menu-dropdown a i:after {
        content: "";
        position: absolute;
        display: inline-block;
        background-color: #fff;
        bottom: 13px;
        left: 7px;
        width: 26px;
        height: 2px; }

@media (max-width: 767px) {
  #header .container {
    display: inline-block;
    width: 100%; }
    #header .container .justify-content-between {
      flex-wrap: unset;
      margin-right: 0;
      margin-left: 0;
      width: 100%;
      flex-flow: column; }
  #header .center-nav {
    margin: auto;
    margin-top: 8px;
    font-size: 16px;
    margin-bottom: 4px; }
  #header .header-nav .right-nav {
    bottom: 5px;
    font-size: 15px; }
  #header .header-nav .left-nav {
    max-width: unset;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    flex: unset;
    display: inline-block;
    width: 100%; }
  #header #mobile-header .mobile-main-bar {
    padding-top: 0;
    padding-bottom: 38px;
    padding-left: 17px;
    padding-right: 17px; } }

@media (max-width: 470px) {
  #header #mobile-header .mobile-main-bar {
    position: relative;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 2px; }
    #header #mobile-header .mobile-main-bar .row-mobile-header {
      width: 100%;
      display: inline-block; }
      #header #mobile-header .mobile-main-bar .row-mobile-header .col-mobile-logo {
        text-align: left;
        width: 100%;
        float: left;
        margin-bottom: 15px;
        padding-left: 0;
        padding-right: 50px; }
    #header #mobile-header .mobile-main-bar .col-auto {
      display: inline-block; }
      #header #mobile-header .mobile-main-bar .col-auto #mobile-cart-toogle .fa-shopping-bag {
        position: relative;
        top: 2px; }
    #header #mobile-header .mobile-main-bar .col-mobile-menu-dropdown {
      margin-left: 0;
      position: absolute;
      top: 4px;
      right: 8px; } }

#index {
  position: relative; }
  #index:before {
    content: '';
    position: absolute;
    top: 41vh;
    left: 0;
    border-style: solid;
    border-width: 63vh 0 45vh 178vw;
    border-color: transparent #e7e7e7;
    z-index: -2; }
  #index:after {
    content: '';
    position: absolute;
    top: 15vh;
    right: -66.5vw;
    width: 75.7291666vw;
    height: 161.016949vh;
    transform: rotate(-335deg);
    background-color: #c71b1b;
    z-index: -1; }
  #index #wrapper {
    padding-top: 0; }

#content-wrapper .crossselling-products {
  margin-top: 111px; }
  #content-wrapper .crossselling-products .section-title {
    color: #000;
    font-size: 50px;
    font-weight: 400;
    margin-bottom: 4px;
    line-height: 40px;
    border: none; }
  #content-wrapper .crossselling-products .block-content .js-product-miniature-wrapper .product-miniature {
    margin-left: 8px;
    margin-right: 8px; }

@media (max-width: 1440px) {
  #index:before {
    top: 21vh; }
  #index:after {
    top: -4%;
    right: -72.5vw; } }

@media (max-width: 1223px) {
  #index:before {
    top: 11vh; }
  #index:after {
    top: -24%;
    right: -80.5vw; } }

@media (max-width: 991px) {
  #index .container {
    padding-left: 0;
    padding-right: 0; }
  #index:before {
    top: 25vh;
    height: 154%;
    transform: rotate(10deg); }
  #index:after {
    top: 2%; } }

@media (max-width: 767px) {
  #index:before {
    transform: unset;
    border-style: unset;
    border-width: unset;
    border-color: unset;
    top: 26vh;
    clip-path: polygon(0 0, 100% 0%, 100% 97%, 0 100%);
    background: #e7e7e7;
    height: 160vh;
    width: 100%; }
  #index:after {
    display: none; } }

@media (max-width: 620px) {
  #index:before {
    top: 30vh; } }

@media (max-width: 480px) {
  #index:before {
    height: 147vh; } }

@media (max-width: 420px) {
  #index:before {
    top: 26vh;
    height: 149vh; } }

.elementor-widget-icon-box .fa-heart-o:before {
  background-image: url(../img/sprite.png);
  background-position: -88px -176px;
  width: 46px;
  height: 41px; }

.elementor-element-y8xgt18 {
  position: relative;
  z-index: 2; }

#left-column .slick-products-carousel {
  height: auto;
  visibility: visible; }

.fa.fa-clock-o.fa-spin {
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 2px solid #fff;
  position: relative;
  animation: none; }
  .fa.fa-clock-o.fa-spin:before, .fa.fa-clock-o.fa-spin:after {
    content: '|';
    background: none;
    position: absolute;
    bottom: 50%;
    left: 50%;
    background-color: #fff;
    animation-name: clockHands;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    transform-origin: bottom center; }
  .fa.fa-clock-o.fa-spin:before {
    z-index: 3;
    font-size: 0.25em;
    font-weight: 700;
    animation-duration: 24s; }
  .fa.fa-clock-o.fa-spin:after {
    z-index: 4;
    font-size: 0.3em;
    animation-duration: 2s; }

@keyframes clockHands {
  from {
    transform: translateX(-50%) rotate(0); }
  to {
    transform: translateX(-50%) rotate(360deg); } }

.category-description img {
  height: auto !important;
  max-width: 100%; }

#mobile-cart-products-count:empty:before {
  content: '0'; }

#iqithtmlandbanners-block-7 {
  margin-top: 20px; }
  @media (max-width: 767px) {
    #iqithtmlandbanners-block-7 {
      text-align: right; } }

@media (max-width: 767px) {
  #iqithtmlandbanners-block-6,
  #iqithtmlandbanners-block-7 {
    margin: 20px 0 0;
    align-items: end; } }

#iqithtmlandbanners-block-6 .block-title,
#iqithtmlandbanners-block-7 .block-title {
  display: none; }

#search_widget .fa-search:before,
#search-widget-mobile .fa-search:before {
  background-position: -58px -333px; }

.product-flags {
  width: 100%; }

.product-flag.gim-custom-label.gim-custom-label-image {
  font-size: 0;
  line-height: 0;
  color: transparent;
  background-color: transparent !important;
  background-size: contain;
  background-repeat: no-repeat;
  width: 200px;
  height: 256px; }
  @media (max-width: 720px) {
    .product-flag.gim-custom-label.gim-custom-label-image {
      width: 125px;
      height: 160px; } }

.js-product-miniature .product-flag.gim-custom-label.gim-custom-label-image {
  width: 90px;
  height: 130px; }
  @media (max-width: 720px) {
    .js-product-miniature .product-flag.gim-custom-label.gim-custom-label-image {
      width: 80px;
      height: 102px; } }

#index .js-product-miniature .product-flag.gim-custom-label.gim-custom-label-image {
  width: 80px;
  height: 102px; }

.gim-daily-offer {
  padding: 5px 40px 20px 40px; }
  @media (max-width: 991px) {
    .gim-daily-offer {
      padding: 10px 15px 20px; } }
  @media (max-width: 767px) {
    .gim-daily-offer {
      padding: 0; } }

.gim-no-offer {
  position: relative; }
  .gim-no-offer > a {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

.daily-offer .js-product-miniature-wrapper {
  padding: 0; }

.daily-offer .product-miniature.product-miniature-layout-1 {
  margin: 0;
  padding: 0; }
  .daily-offer .product-miniature.product-miniature-layout-1 .product-flags {
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: calc(2.429rem + 5px); }
  .daily-offer .product-miniature.product-miniature-layout-1 .product-extra-flags {
    top: 5px;
    right: 5px;
    left: calc(100% - 2.429rem - 5px); }
    .daily-offer .product-miniature.product-miniature-layout-1 .product-extra-flags > div {
      margin-bottom: 5px; }

.daily-offer .thumbnail-container {
  background-color: #fff; }

.daily-offer .lhcom_products_lists_average,
.daily-offer .price-countdown-wrapper {
  display: none; }

.daily-offer div.product-description {
  padding: 0; }

.daily-offer .product-functional-buttons.product-functional-buttons-bottom {
  padding: 0 1px;
  bottom: 1px; }

.daily-offer .product-reference,
.daily-offer .product-title a,
.daily-offer .product-price {
  color: #fff !important;
  opacity: 1; }

.daily-offer-title {
  font-size: 3.286rem;
  margin-bottom: 33px;
  line-height: 1;
  color: #fff; }

#daily-offer-timer {
  margin-bottom: 30px; }
  #daily-offer-timer > div {
    width: 23.636363%;
    margin-right: 1.818182%;
    float: left;
    text-align: center; }
    #daily-offer-timer > div span {
      display: block; }
      #daily-offer-timer > div span:first-child {
        color: #c71b1b;
        font-size: 2.857rem;
        line-height: 2.857rem;
        background-color: #cecece;
        box-shadow: 0 0 11px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        background-image: linear-gradient(180deg, #ffffff 0%, #cecece 100%);
        padding-bottom: 11px; }
      #daily-offer-timer > div span:last-child {
        color: #fff;
        line-height: 1; }
    #daily-offer-timer > div:last-child {
      margin-right: 0; }

body.loading #modal-onexit {
  display: none; }

#modal-onexit.modal .modal__container {
  border-radius: 0;
  position: relative;
  width: 600px;
  max-width: calc(100% - 20px);
  padding: 0; }
  @media (max-width: 600px) {
    #modal-onexit.modal .modal__container {
      width: 300px; } }
  #modal-onexit.modal .modal__container img {
    max-width: 100%;
    height: auto; }

#modal-onexit.modal .modal__content {
  margin: 0; }

#modal-onexit.modal .modal__close {
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  line-height: 1em;
  font-size: 16px;
  width: 1em;
  color: #fff;
  background-color: #c71b1b;
  box-sizing: content-box;
  padding: 6px;
  cursor: pointer;
  transition: color 0.2s cubic-bezier(0.7, 0, 0.3, 1), background-color 0.2s cubic-bezier(0.7, 0, 0.3, 1);
  border-color: #c71b1b;
  border-style: solid;
  border-width: 0 0 1px 1px; }
  #modal-onexit.modal .modal__close:hover {
    color: #c71b1b;
    background-color: transparent; }
  #modal-onexit.modal .modal__close:before {
    content: '\2715'; }

#modal-onexit.modal .modal__image {
  width: 50%;
  float: left;
  padding: 10px 10px 0; }
  @media (max-width: 600px) {
    #modal-onexit.modal .modal__image {
      width: 100%;
      padding: 0; } }

#modal-onexit.modal .modal__text {
  width: 50%;
  float: right;
  padding: 38px 10px 10px; }
  @media (max-width: 600px) {
    #modal-onexit.modal .modal__text {
      width: 100%;
      padding: 10px; } }
  #modal-onexit.modal .modal__text .newsletter-input-group {
    border: 1px solid #c71b1b; }
    #modal-onexit.modal .modal__text .newsletter-input-group .btn-subscribe {
      background-color: #c71b1b;
      position: relative;
      width: 47px; }
      #modal-onexit.modal .modal__text .newsletter-input-group .btn-subscribe:hover {
        background-color: transparent; }
        #modal-onexit.modal .modal__text .newsletter-input-group .btn-subscribe:hover i:before {
          opacity: 1; }
        #modal-onexit.modal .modal__text .newsletter-input-group .btn-subscribe:hover i:after {
          opacity: 0; }
      #modal-onexit.modal .modal__text .newsletter-input-group .btn-subscribe i {
        display: inline-block; }
        #modal-onexit.modal .modal__text .newsletter-input-group .btn-subscribe i:before, #modal-onexit.modal .modal__text .newsletter-input-group .btn-subscribe i:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: 0;
          transition: opacity 0.2s cubic-bezier(0.7, 0, 0.3, 1); }
        #modal-onexit.modal .modal__text .newsletter-input-group .btn-subscribe i:before {
          opacity: 0.01; }
        #modal-onexit.modal .modal__text .newsletter-input-group .btn-subscribe i:after {
          background-image: url(../img/sprite.png);
          background-position: -181px -397px;
          width: 19px;
          height: 14px; }

.hero-banners-front-page .elementor-iqit-banner-subtitle,
.hero-banners-front-page h3 {
  text-shadow: 2px 3px 24px rgba(0, 0, 0, 0.75), 1px 3px 6px rgba(0, 0, 0, 0.75), 0 2px 0 rgba(24, 24, 25, 0.5); }

.hero-banners-front-page .elementor-container:before {
  content: '';
  display: block;
  left: 0;
  top: 0;
  padding-bottom: 45.166666%; }

.hero-banners-front-page .elementor-row {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.3); }
  .hero-banners-front-page .elementor-row .elementor-column:first-child .elementor-widget-wrap .elementor-widget .elementor-widget-container .elementor-iqit-banner a .elementor-iqit-banner-img img {
    object-position: left center; }
  .hero-banners-front-page .elementor-row .elementor-column:last-child .elementor-widget-wrap .elementor-widget .elementor-widget-container .elementor-iqit-banner a .elementor-iqit-banner-img img {
    object-position: right center; }
  .hero-banners-front-page .elementor-row .elementor-column .elementor-widget-wrap {
    height: 100%; }
    .hero-banners-front-page .elementor-row .elementor-column .elementor-widget-wrap .elementor-widget {
      height: 100%; }
      .hero-banners-front-page .elementor-row .elementor-column .elementor-widget-wrap .elementor-widget .elementor-widget-container {
        height: 100%; }
        .hero-banners-front-page .elementor-row .elementor-column .elementor-widget-wrap .elementor-widget .elementor-widget-container .elementor-iqit-banner {
          height: 100%; }
          .hero-banners-front-page .elementor-row .elementor-column .elementor-widget-wrap .elementor-widget .elementor-widget-container .elementor-iqit-banner a {
            height: 100%;
            display: block; }
            .hero-banners-front-page .elementor-row .elementor-column .elementor-widget-wrap .elementor-widget .elementor-widget-container .elementor-iqit-banner a .elementor-iqit-banner-img {
              height: 100%; }
              .hero-banners-front-page .elementor-row .elementor-column .elementor-widget-wrap .elementor-widget .elementor-widget-container .elementor-iqit-banner a .elementor-iqit-banner-img img {
                height: 100%;
                object-fit: cover; }
  .hero-banners-front-page .elementor-row .elementor-column .elementor-column-wrap {
    transition: margin-left .3s linear, margin-right .3s linear, z-index .3s step-end, width .3s linear;
    z-index: 1; }
    .hero-banners-front-page .elementor-row .elementor-column .elementor-column-wrap .elementor-iqit-banner-content {
      transition: padding-left .3s linear; }
  .hero-banners-front-page .elementor-row .elementor-column:first-child:hover .elementor-column-wrap {
    margin-left: 0;
    width: 126.6666%; }
  .hero-banners-front-page .elementor-row .elementor-column:last-child:hover .elementor-column-wrap {
    margin-right: 0;
    width: 126.6666%; }
  .hero-banners-front-page .elementor-row .elementor-column:hover .elementor-column-wrap {
    margin-left: -26.6666%;
    margin-right: -26.6666%;
    width: 153.3333%;
    z-index: 2;
    position: relative;
    transition: margin-left .3s linear .3s, margin-right .3s linear .3s, z-index .3s step-start .3s, width .3s linear .3s; }
  .hero-banners-front-page .elementor-row .elementor-column:hover + .elementor-column .elementor-column-wrap .elementor-iqit-banner-content {
    transition: padding-left .3s linear .3s;
    padding-left: calc(26.6666% + 4.2142rem); }

@media (max-width: 991px) {
  .hero-banners-front-page .elementor-row .elementor-column:first-child:hover .elementor-column-wrap {
    margin-left: 0;
    margin-right: 0;
    width: 100%; }
  .hero-banners-front-page .elementor-row .elementor-column:last-child:hover .elementor-column-wrap {
    margin-left: 0;
    margin-right: 0;
    width: 100%; }
  .hero-banners-front-page .elementor-row .elementor-column:hover .elementor-column-wrap {
    margin-left: 0;
    margin-right: 0;
    width: 100%; }
  .hero-banners-front-page .elementor-row .elementor-column:hover + .elementor-column .elementor-column-wrap .elementor-iqit-banner-content {
    margin-left: 0;
    margin-right: 0;
    width: 100%; }
  body #elementor .elementor-element-4d50fmy .elementor-banner-align-middle-center {
    padding-left: 0; }
  body .elementor-element.elementor-element-aevpgnr .elementor-button {
    margin-top: 43px;
    float: left; }
  body .elementor-element.elementor-element-bn401e7 .elementor-button {
    margin-top: 35px;
    float: left; }
  body .elementor-element.elementor-element-q4i7xk7 .elementor-button {
    margin-top: 35px;
    float: left; }
  .hero-banners-front-page .elementor-row {
    display: inline-block;
    width: 100%;
    position: relative;
    padding-left: 31px;
    padding-right: 31px; }
    .hero-banners-front-page .elementor-row .elementor-column {
      width: 100%;
      max-height: 280px; }
      .hero-banners-front-page .elementor-row .elementor-column:first-child .elementor-widget-wrap .elementor-widget .elementor-widget-container .elementor-iqit-banner a .elementor-iqit-banner-img img {
        object-position: left -56px; }
      .hero-banners-front-page .elementor-row .elementor-column:last-child .elementor-widget-wrap .elementor-widget .elementor-widget-container .elementor-iqit-banner a .elementor-iqit-banner-img img {
        object-position: left -31px; }
      .hero-banners-front-page .elementor-row .elementor-column .elementor-widget-wrap .elementor-widget .elementor-widget-container .elementor-iqit-banner a .elementor-iqit-banner-img img {
        object-position: left top; }
      .hero-banners-front-page .elementor-row .elementor-column .elementor-column-wrap .elementor-iqit-banner-content {
        top: 60px;
        left: 60px;
        bottom: auto;
        right: auto;
        min-height: 170px;
        padding-left: 0; }
        .hero-banners-front-page .elementor-row .elementor-column .elementor-column-wrap .elementor-iqit-banner-content .elementor-iqit-banner-subtitle {
          text-align: left; }
        .hero-banners-front-page .elementor-row .elementor-column .elementor-column-wrap .elementor-iqit-banner-content .elementor-iqit-banner-title {
          text-align: left; } }

@media (max-width: 767px) {
  body #elementor .elementor-element-4d50fmy .elementor-banner-align-middle-center {
    padding-left: 15px; }
  body .elementor-element.elementor-element-aevpgnr .elementor-button {
    position: absolute;
    width: 100%;
    left: 15px;
    max-width: 195px;
    top: 3px; }
  body .elementor-element.elementor-element-bn401e7 .elementor-button {
    position: absolute;
    width: 100%;
    left: 15px;
    max-width: 195px;
    top: 3px; }
  body .elementor-element.elementor-element-q4i7xk7 .elementor-button {
    position: absolute;
    width: 100%;
    left: 15px;
    max-width: 195px;
    top: 3px; }
  .hero-banners-front-page .elementor-row {
    padding-left: 0px;
    padding-right: 0px; }
    .hero-banners-front-page .elementor-row .elementor-column {
      max-height: 222px; }
      .hero-banners-front-page .elementor-row .elementor-column .elementor-column-wrap .elementor-iqit-banner-content {
        display: inline-block;
        width: 100%;
        left: 0;
        padding-left: 15px;
        top: 113px; }
        .hero-banners-front-page .elementor-row .elementor-column .elementor-column-wrap .elementor-iqit-banner-content .elementor-iqit-banner-subtitle {
          display: inline-block;
          float: left;
          font-size: 32px;
          line-height: 1.05em;
          margin-right: 10px; }
        .hero-banners-front-page .elementor-row .elementor-column .elementor-column-wrap .elementor-iqit-banner-content .elementor-iqit-banner-title {
          display: inline-block;
          float: left;
          font-size: 32px; } }

#iqitmegamenu-wrapper {
  position: relative; }
  #iqitmegamenu-wrapper .cbp-hrmenu-tab .cbp-hrsub.cbp-notfit {
    top: calc(100% - 15px);
    left: 0;
    border-bottom: 5px solid #c71b1b;
    box-shadow: 0 2px 64px rgba(0, 0, 0, 0.1); }
  #iqitmegamenu-wrapper .cbp-categories-row > div {
    padding: 0 5px; }
  #iqitmegamenu-wrapper .cbp-categories-row .cbp-category-link-w a {
    color: #000; }
  #iqitmegamenu-wrapper .cbp-categories-row .cbp-category-link-w .cbp-category-title {
    display: none; }
  #iqitmegamenu-wrapper .cbp-categories-row .cbp-category-thumb {
    width: 22.55%;
    margin: 0 1.45% 0 0;
    float: left;
    position: relative; }
  #iqitmegamenu-wrapper .cbp-categories-row .cbp-category-tree {
    width: 74.55%;
    margin-left: 1.45%;
    columns: 3;
    margin-top: -10px;
    column-gap: 20px; }
    #iqitmegamenu-wrapper .cbp-categories-row .cbp-category-tree li {
      padding: 0;
      margin: 0;
      break-inside: avoid; }
      #iqitmegamenu-wrapper .cbp-categories-row .cbp-category-tree li:nth-child(3n + 1) {
        clear: left; }
      #iqitmegamenu-wrapper .cbp-categories-row .cbp-category-tree li:nth-child(3n + 3) {
        clear: right; }
      #iqitmegamenu-wrapper .cbp-categories-row .cbp-category-tree li a {
        padding: 9px 0;
        border-bottom: 1px solid #c0c0c0;
        display: block;
        line-height: 1;
        transition: border-color 0.2s cubic-bezier(0.7, 0, 0.3, 1), border-width-bottom 0.2s cubic-bezier(0.7, 0, 0.3, 1);
        white-space: nowrap;
        width: 100%;
        max-width: 279px;
        overflow: hidden;
        text-overflow: ellipsis; }
        #iqitmegamenu-wrapper .cbp-categories-row .cbp-category-tree li a:hover {
          border-color: #c71b1b;
          border-bottom-width: 2px;
          padding-bottom: 8px; }
        #iqitmegamenu-wrapper .cbp-categories-row .cbp-category-tree li a:before {
          content: none; }

#wk_bankwireName {
  margin: 5px 0;
  max-width: 320px; }

#footer-container-first .container {
  max-width: 790px; }

#footer-container-first .block-newsletter h5 {
  font-size: 3.571rem;
  line-height: 1; }

#footer-container-first .block-newsletter p {
  font-size: 1.429rem; }

#footer-container-first .ps-emailsubscription-block .newsletter-input-group {
  margin-top: 2.8571rem; }

#footer-container-first .ps-emailsubscription-block input {
  font-size: 0.929rem;
  line-height: 1.5384; }
  #footer-container-first .ps-emailsubscription-block input.input-subscription {
    padding: 0.7143rem; }
  #footer-container-first .ps-emailsubscription-block input::placeholder {
    color: #000;
    opacity: 0.6; }

#footer-container-first .ps-emailsubscription-block .btn-subscribe {
  padding: 0 1.428571rem;
  position: relative;
  border: 1px solid #fff; }
  #footer-container-first .ps-emailsubscription-block .btn-subscribe:hover {
    background-color: #c71b1b; }
    #footer-container-first .ps-emailsubscription-block .btn-subscribe:hover .fa-long-arrow-right:before {
      opacity: 0; }
    #footer-container-first .ps-emailsubscription-block .btn-subscribe:hover .fa-long-arrow-right:after {
      opacity: 1; }
  #footer-container-first .ps-emailsubscription-block .btn-subscribe .fa-long-arrow-right {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    #footer-container-first .ps-emailsubscription-block .btn-subscribe .fa-long-arrow-right:before, #footer-container-first .ps-emailsubscription-block .btn-subscribe .fa-long-arrow-right:after {
      content: '';
      transition: opacity 0.2s cubic-bezier(0.7, 0, 0.3, 1);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: inline-block; }
    #footer-container-first .ps-emailsubscription-block .btn-subscribe .fa-long-arrow-right:after {
      opacity: 0.01;
      background-image: url(../img/sprite.png);
      background-position: -181px -397px;
      width: 19px;
      height: 14px; }

div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_two #shipping_container #kb-pts-carrier-wrapper div {
  padding: 0; }

#kb_pts_carrier_block #kb-pts-carrier-wrapper #velo_address_search {
  margin: 0; }

#kb_pts_carrier_block #kb-pts-carrier-wrapper select {
  padding-top: 6px;
  margin: 0; }

#kb_pts_carrier_block .velo-search-container {
  padding: 0;
  margin: 0; }

#kb_pts_carrier_block .velo-store-filters,
#kb_pts_carrier_block .velo-location-list {
  background: transparent;
  margin: 0;
  border: none; }

#kb_pts_carrier_block .velo-store-filters label {
  display: inline-block; }

#kb_pts_carrier_block .velo-store-filters .velo-search-bar {
  float: left;
  width: 51%; }

#kb_pts_carrier_block .velo-store-filters .velo-search-distance {
  float: left;
  width: 25%; }

#kb_pts_carrier_block .velo-store-filters .velo-search-button {
  float: left;
  width: 12%;
  margin-top: 20px; }

#kb_pts_carrier_block .velo-location-list {
  float: none;
  width: initial;
  max-height: none; }
  #kb_pts_carrier_block .velo-location-list ul {
    margin-top: 10px;
    padding: 0; }
    #kb_pts_carrier_block .velo-location-list ul li a.velo-directions-link {
      float: none;
      display: inline-block;
      padding: 4px 8px;
      border: none;
      border-radius: 0;
      background-color: #000;
      text-decoration: none;
      color: #fff; }
      #kb_pts_carrier_block .velo-location-list ul li a.velo-directions-link i.material-icons {
        display: none; }
      #kb_pts_carrier_block .velo-location-list ul li a.velo-directions-link:hover {
        background-color: #333; }
      #kb_pts_carrier_block .velo-location-list ul li a.velo-directions-link:after {
        content: '\f277';
        font-family: 'FontAwesome';
        color: #fff;
        vertical-align: bottom;
        font-size: 1.5rem;
        position: relative;
        bottom: -5px; }
  #kb_pts_carrier_block .velo-location-list td {
    padding: 0; }
  #kb_pts_carrier_block .velo-location-list .mp-openday-list {
    table-layout: auto; }
    #kb_pts_carrier_block .velo-location-list .mp-openday-list .mp-openday-list-title {
      width: 100px; }
    #kb_pts_carrier_block .velo-location-list .mp-openday-list .mp-openday-list-value {
      width: auto;
      padding: 0; }

#kb_pts_carrier_block .velo-store-map {
  width: initial;
  float: none;
  height: 180px; }

#kb_pts_carrier_block .velo-field-inline {
  margin: 0; }

#kb_pts_carrier_block .velo-search-button {
  text-align: right; }
  #kb_pts_carrier_block .velo-search-button button {
    border: none;
    border-radius: 0;
    padding: 6px 12px;
    background-color: #000;
    margin: 0;
    transition: background-color 0.2s cubic-bezier(0.7, 0, 0.3, 1); }
    #kb_pts_carrier_block .velo-search-button button:hover {
      background-color: #333; }
    #kb_pts_carrier_block .velo-search-button button#searchStoreBtn, #kb_pts_carrier_block .velo-search-button button#resetStoreBtn {
      font-size: 0;
      color: transparent;
      padding: 6px 10px;
      vertical-align: bottom;
      width: auto; }
      #kb_pts_carrier_block .velo-search-button button#searchStoreBtn:after, #kb_pts_carrier_block .velo-search-button button#resetStoreBtn:after {
        content: '\f002';
        font-family: 'FontAwesome';
        font-size: 1rem;
        color: #fff;
        vertical-align: bottom; }
    #kb_pts_carrier_block .velo-search-button button#resetStoreBtn:after {
      content: '\f00d'; }

#kb_pts_carrier_block .velo-store-date-time-block {
  margin-top: 20px; }

#kb_pts_carrier_block #map .gm-style-iw-d {
  overflow: hidden; }

#kb_pts_carrier_block #map .velo-popup {
  z-index: 99; }
  #kb_pts_carrier_block #map .velo-popup > div, #kb_pts_carrier_block #map .velo-popup > a {
    display: none; }
  #kb_pts_carrier_block #map .velo-popup td {
    padding: 15px; }
  #kb_pts_carrier_block #map .velo-popup .velo-add-address {
    margin-top: 5px; }

#kb_pts_carrier_block .invalid {
  outline: 1px solid red;
  box-shadow: 0 0 5px red; }

#searchStoreForm {
  margin-bottom: 20px; }

.product-miniature.product-miniature-layout-1 {
  text-align: left;
  margin: 0 1.428571rem 2.857142rem;
  padding: 1.428571rem 0 0;
  border-bottom: 2px solid #e7e7e7;
  display: block;
  flex-flow: initial;
  height: auto; }
  .product-miniature.product-miniature-layout-1 .product-title {
    margin-bottom: 0.4rem;
    font-size: 18px; }
    .product-miniature.product-miniature-layout-1 .product-title a {
      display: block;
      vertical-align: bottom;
      min-height: 2em;
      line-height: 1; }
  .product-miniature.product-miniature-layout-1 .product-thumbnail {
    margin-bottom: 0.71429rem;
    padding-bottom: 91.525423%;
    position: relative; }
    .product-miniature.product-miniature-layout-1 .product-thumbnail img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.5; }
      .product-miniature.product-miniature-layout-1 .product-thumbnail img.loaded {
        opacity: 1; }
  .product-miniature.product-miniature-layout-1 .product-description {
    padding: 0;
    display: block; }
  .product-miniature.product-miniature-layout-1 .product-reference {
    font-size: 13px;
    line-height: 1;
    padding: 0; }
  .product-miniature.product-miniature-layout-1 .product-price-and-shipping {
    margin-bottom: 5px; }
  .product-miniature.product-miniature-layout-1 .product-price,
  .product-miniature.product-miniature-layout-1 .regular-price {
    font-size: 21px;
    opacity: 1;
    float: left;
    line-height: 1.219047em; }
  .product-miniature.product-miniature-layout-1 .price-countdown-wrapper {
    float: right; }
    .product-miniature.product-miniature-layout-1 .price-countdown-wrapper .price-countdown {
      margin: 0; }
      .product-miniature.product-miniature-layout-1 .price-countdown-wrapper .price-countdown .price-countdown-title {
        display: inline-block; }
        .product-miniature.product-miniature-layout-1 .price-countdown-wrapper .price-countdown .price-countdown-title .fa-clock-o {
          font-size: 1.428571rem;
          vertical-align: bottom;
          margin-right: 0.5rem; }
  @media (max-width: 480px) {
    .product-miniature.product-miniature-layout-1 .price-countdown-wrapper {
      float: left; } }
  .product-miniature.product-miniature-layout-1 .regular-price {
    margin-left: 0.5rem; }
  .product-miniature.product-miniature-layout-1 .product-flags {
    right: 2.429rem;
    pointer-events: none; }
  .product-miniature.product-miniature-layout-1 .product-extra-flags {
    left: calc(100% - 2.429rem);
    right: 0;
    top: 0;
    position: absolute;
    pointer-events: none; }
    .product-miniature.product-miniature-layout-1 .product-extra-flags > div {
      float: right;
      border-radius: 50%;
      width: 2.429rem;
      height: 2.429rem;
      display: inline-block;
      padding: 0;
      margin-bottom: 10px; }
      .product-miniature.product-miniature-layout-1 .product-extra-flags > div:last-child {
        margin-bottom: 0; }
    .product-miniature.product-miniature-layout-1 .product-extra-flags .gim-feature-badge {
      pointer-events: auto;
      position: relative;
      background-color: #e7e7e7;
      opacity: 0;
      transition: opacity 0.2s cubic-bezier(0.7, 0, 0.3, 1); }
      .product-miniature.product-miniature-layout-1 .product-extra-flags .gim-feature-badge img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    .product-miniature.product-miniature-layout-1 .product-extra-flags .discount-badge {
      text-align: center;
      line-height: 2.429rem;
      font-size: 1.286rem;
      color: #fff;
      background-color: #c71b1b; }
      .product-miniature.product-miniature-layout-1 .product-extra-flags .discount-badge > span {
        font-size: 0.857rem;
        line-height: 2.429rem; }
  .product-miniature.product-miniature-layout-1 .product-flag {
    margin-top: 0;
    margin-bottom: 0.3rem; }
    .product-miniature.product-miniature-layout-1 .product-flag.gim-best-seller-badge {
      background-color: #000;
      color: #fff;
      text-transform: uppercase; }
    .product-miniature.product-miniature-layout-1 .product-flag:last-child {
      margin-bottom: 0; }

.product-miniature-grid:hover .product-functional-buttons-bottom {
  transform: none; }

.product-miniature-grid:hover .product-extra-flags .gim-feature-badge {
  opacity: 1; }

.product-miniature-grid .product-functional-buttons-bottom {
  left: 0;
  right: 0;
  padding: 0;
  transform: translateY(50%);
  bottom: 0;
  background-color: transparent;
  position: absolute;
  z-index: 2; }
  .product-miniature-grid .product-functional-buttons-bottom .product-functional-buttons-links a:hover {
    opacity: 1; }

@media (max-width: 991px) {
  .product-miniature-grid .product-functional-buttons-bottom {
    display: none; } }

.product-miniature-grid .product-add-cart {
  display: inline-block;
  width: calc(100% - 81px);
  float: left;
  position: relative; }
  .product-miniature-grid .product-add-cart:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    z-index: -1; }
  .product-miniature-grid .product-add-cart button.add-to-cart,
  .product-miniature-grid .product-add-cart a.btn-product-list {
    width: 100%;
    text-transform: uppercase;
    font-size: 1.071rem;
    padding: .625rem;
    height: 40px; }
    @media (max-width: 1150px) {
      .product-miniature-grid .product-add-cart button.add-to-cart,
      .product-miniature-grid .product-add-cart a.btn-product-list {
        font-size: 12px; } }
  @media (max-width: 1150px) {
    .product-miniature-grid .product-add-cart button.add-to-cart {
      line-height: 14px; } }

.product-miniature-grid .btn-iqitcompare-add i:after {
  background-image: url(../img/sprite.png);
  background-position: 0px -397px;
  width: 21px;
  height: 17px; }

.product-miniature-grid .btn-iqitwishlist-add i:after {
  background-image: url(../img/sprite.png);
  background-position: -241px -336px;
  width: 20px;
  height: 18px; }

.product-miniature-grid .btn-iqitcompare-add,
.product-miniature-grid .btn-iqitwishlist-add {
  position: relative;
  background-color: #fff;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-left: 1px;
  float: left;
  transition: background-color 0.2s cubic-bezier(0.7, 0, 0.3, 1); }
  .product-miniature-grid .btn-iqitcompare-add:hover,
  .product-miniature-grid .btn-iqitwishlist-add:hover {
    background-color: #c71b1b; }
    .product-miniature-grid .btn-iqitcompare-add:hover i:before,
    .product-miniature-grid .btn-iqitwishlist-add:hover i:before {
      opacity: 0; }
    .product-miniature-grid .btn-iqitcompare-add:hover i:after,
    .product-miniature-grid .btn-iqitwishlist-add:hover i:after {
      opacity: 1; }
  .product-miniature-grid .btn-iqitcompare-add:before,
  .product-miniature-grid .btn-iqitwishlist-add:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: box-shadow 0.2s cubic-bezier(0.7, 0, 0.3, 1);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    z-index: -1; }
  .product-miniature-grid .btn-iqitcompare-add i:before, .product-miniature-grid .btn-iqitcompare-add i:after,
  .product-miniature-grid .btn-iqitwishlist-add i:before,
  .product-miniature-grid .btn-iqitwishlist-add i:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.2s cubic-bezier(0.7, 0, 0.3, 1);
    display: inline-block; }
  .product-miniature-grid .btn-iqitcompare-add i:before,
  .product-miniature-grid .btn-iqitwishlist-add i:before {
    opacity: 1; }
  .product-miniature-grid .btn-iqitcompare-add i:after,
  .product-miniature-grid .btn-iqitwishlist-add i:after {
    opacity: 0.01; }

.product-miniature-grid .btn-iqitcompare-add {
  margin-left: 0; }

.scroll-to-content .fa-long-arrow-down {
  cursor: pointer; }
  .scroll-to-content .fa-long-arrow-down:before {
    transition: height 0.2s cubic-bezier(0.7, 0, 0.3, 1), background-position 0.2s cubic-bezier(0.7, 0, 0.3, 1), margin-top 0.2s cubic-bezier(0.7, 0, 0.3, 1); }
  .scroll-to-content .fa-long-arrow-down:hover:before {
    margin-top: 3px;
    height: 17px;
    background-position-y: -294px; }

.tabbed-carousel-front .tabs-container {
  width: 23.728814%;
  float: left; }
  .tabbed-carousel-front .tabs-container h2 {
    font-size: 3.571rem; }
  .tabbed-carousel-front .tabs-container .nav-tabs {
    border-bottom: none;
    display: block; }
    .tabbed-carousel-front .tabs-container .nav-tabs .nav-item {
      display: block; }
      .tabbed-carousel-front .tabs-container .nav-tabs .nav-item .nav-link {
        display: block;
        transition: border-color 0.2s cubic-bezier(0.7, 0, 0.3, 1);
        font-size: 1.428571rem;
        border-color: #c71b1b;
        border-style: solid;
        border-width: 0 0 3px;
        opacity: 1;
        padding-top: 0.5rem;
        padding-bottom: 0.714285rem; }
        .tabbed-carousel-front .tabs-container .nav-tabs .nav-item .nav-link .tab-title-part:first-child {
          float: left; }
        .tabbed-carousel-front .tabs-container .nav-tabs .nav-item .nav-link .tab-title-part:last-child {
          position: relative;
          overflow: hidden;
          height: 1em; }
          .tabbed-carousel-front .tabs-container .nav-tabs .nav-item .nav-link .tab-title-part:last-child:before, .tabbed-carousel-front .tabs-container .nav-tabs .nav-item .nav-link .tab-title-part:last-child:after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 50%;
            right: calc(100% - 13px);
            transform: translateY(-50%);
            transition: right 0.2s cubic-bezier(0.7, 0, 0.3, 1), opacity 0.2s cubic-bezier(0.7, 0, 0.3, 1); }
          .tabbed-carousel-front .tabs-container .nav-tabs .nav-item .nav-link .tab-title-part:last-child:before {
            background-image: url(../img/sprite.png);
            background-position: -478px -56px;
            width: 8px;
            height: 14px;
            opacity: 1; }
          .tabbed-carousel-front .tabs-container .nav-tabs .nav-item .nav-link .tab-title-part:last-child:after {
            background-image: url(../img/sprite.png);
            background-position: -122px -397px;
            width: 19px;
            height: 14px;
            opacity: 0.01; }
        .tabbed-carousel-front .tabs-container .nav-tabs .nav-item .nav-link.active, .tabbed-carousel-front .tabs-container .nav-tabs .nav-item .nav-link:hover {
          border-color: #c2c2c2; }
          .tabbed-carousel-front .tabs-container .nav-tabs .nav-item .nav-link.active .tab-title-part:last-child:before, .tabbed-carousel-front .tabs-container .nav-tabs .nav-item .nav-link:hover .tab-title-part:last-child:before {
            opacity: 0;
            right: 0; }
          .tabbed-carousel-front .tabs-container .nav-tabs .nav-item .nav-link.active .tab-title-part:last-child:after, .tabbed-carousel-front .tabs-container .nav-tabs .nav-item .nav-link:hover .tab-title-part:last-child:after {
            opacity: 1;
            right: 0; }

.tabbed-carousel-front .tab-content {
  background-color: #fff;
  box-shadow: 0 2px 64px rgba(0, 0, 0, 0.1);
  width: 76.271186%;
  float: left;
  padding: 1.428571rem 0 1.5rem; }

@media (max-width: 991px) {
  .tabbed-carousel-front .elementor-column-wrap {
    padding-right: 0;
    padding-left: 30px; }
  .tabbed-carousel-front .tabs-container {
    width: 31.728814%;
    margin-top: 46px; }
  .tabbed-carousel-front .tab-content {
    width: 68.271186%; } }

@media (max-width: 767px) {
  .tabbed-carousel-front .elementor-column-wrap {
    padding-right: 15px;
    padding-left: 15px; }
  .tabbed-carousel-front .tabs-container {
    width: 100%;
    margin-top: 0px; }
    .tabbed-carousel-front .tabs-container h2 {
      font-size: 50px; }
    .tabbed-carousel-front .tabs-container .nav-tabs .nav-item .nav-link {
      font-size: 20px; }
  .tabbed-carousel-front .tab-content {
    width: 100%; } }

.page-customer-account #content-wrapper {
  margin-top: 30px;
  margin-bottom: 30px; }

.page-customer-account .fa-heart-o:before {
  background-image: none;
  content: "\f08a";
  width: auto;
  height: auto; }

.page-customer-account #lrp-account-link .material-icons {
  font-size: 0; }

.page-customer-account #lrp-customer-account h6 {
  margin-top: 10px; }

.page-my-account #wrapper {
  margin-top: 30px;
  margin-bottom: 30px; }

#blockcart-modal-wrap #blockcart-modal .modal-header {
  padding-top: 7px;
  padding-bottom: 7px;
  background-color: #c71b1b;
  color: #fff; }
  #blockcart-modal-wrap #blockcart-modal .modal-header button {
    opacity: 1; }
    #blockcart-modal-wrap #blockcart-modal .modal-header button span {
      color: #fff; }

#blockcart-modal-wrap #blockcart-modal .modal-body .col-md-7 .cart-content .cart-products-count {
  margin-bottom: 0; }

#blockcart-modal-wrap #blockcart-modal .modal-body .col-md-7 .cart-content .cart-content-btn .btn-primary {
  background-color: #000;
  color: #fff;
  transition: 0.3s ease;
  padding: 0.6rem 1.5rem; }
  #blockcart-modal-wrap #blockcart-modal .modal-body .col-md-7 .cart-content .cart-content-btn .btn-primary:hover {
    background-color: #c71b1b; }

#blockcart-modal-wrap #blockcart-modal .modal-body .col-md-7 .cart-content .cart-content-btn .btn-secondary {
  background-color: #fff;
  border: 1px solid #000;
  padding: 0.6rem 1.5rem;
  transition: 0.3s ease; }
  #blockcart-modal-wrap #blockcart-modal .modal-body .col-md-7 .cart-content .cart-content-btn .btn-secondary:hover {
    border-color: #c71b1b;
    color: #c71b1b; }

#blockcart-content .cart-products .col * {
  color: #000;
  opacity: 1; }

#blockcart-content .cart-subtotals {
  border-color: #c71b1b; }

#blockcart-content .cart-buttons .btn-primary:not(:disabled):not(.disabled).active, #blockcart-content .cart-buttons .btn-primary:not(:disabled):not(.disabled):active, #blockcart-content .cart-buttons .show > .btn-primary.dropdown-toggle {
  background-color: #c71b1b;
  box-shadow: none; }

#blockcart-content .cart-buttons .btn-primary {
  background-color: #c71b1b;
  border: 2px solid #c71b1b;
  padding: 0.6rem 1.5rem;
  transition: 0.3s ease; }
  #blockcart-content .cart-buttons .btn-primary:hover {
    opacity: 0.9; }

#blockcart-content .cart-buttons .btn-secondary {
  background-color: #ddd; }

#blockcart-content .cart-buttons a.active {
  background-color: #c71b1b; }

body.page-cart #main-page-content .fa-angle-down:before {
  content: "\f107";
  background-image: none;
  background-position: unset;
  width: 100%;
  height: auto;
  color: #c71b1b;
  font-weight: 700; }

body.page-cart #main-page-content .fa-angle-up:before {
  font-weight: 700;
  color: #c71b1b; }

body.page-cart #main-page-content h1.page-title {
  border-bottom: none;
  padding-bottom: 0;
  color: #000; }

body.page-cart #main-page-content .cart-grid .cart-grid-body .cart-item-header {
  padding-top: 10px;
  padding-bottom: 10px; }

body.page-cart #main-page-content .cart-grid .cart-grid-body .product-price {
  color: #000; }

body.page-cart #main-page-content .cart-grid .cart-detailed-totals .value {
  font-weight: 600; }

body.page-cart #main-page-content .cart-grid .cart-detailed-actions .btn-primary {
  transition: 0.3s ease; }
  body.page-cart #main-page-content .cart-grid .cart-detailed-actions .btn-primary:hover {
    opacity: 0.9; }
  body.page-cart #main-page-content .cart-grid .cart-detailed-actions .btn-primary:active {
    background-color: #c71b1b; }

body.page-cart #main-page-content .cart-grid .btn-secondary {
  margin-top: 5px !important;
  background-color: #000;
  color: #fff; }

.cart-summary .btn-primary {
  background-color: #c71b1b; }
  .cart-summary .btn-primary:hover {
    background-color: #c71b1b; }

#checkout #wrapper {
  padding-top: 0; }

div#onepagecheckoutps input[type=radio],
div#onepagecheckoutps input[type=checkbox] {
  appearance: none;
  background: none;
  border-radius: 1px;
  margin-left: 5px;
  margin-right: 5px;
  background: #fff;
  border: 2px solid #fff;
  box-shadow: 0px 0px 0px 1px #444;
  float: left;
  margin-top: 0 !important; }
  div#onepagecheckoutps input[type=radio]:focus,
  div#onepagecheckoutps input[type=checkbox]:focus {
    outline: none;
    border: 2px solid #fff;
    box-shadow: 0px 0px 0px 1px #444; }
  div#onepagecheckoutps input[type=radio]:checked,
  div#onepagecheckoutps input[type=checkbox]:checked {
    background: #000;
    box-shadow: 0px 0px 0px 1px #000;
    border: 2px solid #ffffff; }

div#onepagecheckoutps .row {
  margin: 0; }
  div#onepagecheckoutps .row > div {
    padding-left: 5px;
    padding-right: 5px; }
    div#onepagecheckoutps .row > div select,
    div#onepagecheckoutps .row > div input[type=text],
    div#onepagecheckoutps .row > div input[type=email] {
      border-radius: 0 !important;
      border-right: 1px solid #ccc;
      margin-bottom: 5px; }

div#onepagecheckoutps h5 {
  border-bottom: 1px solid #c71b1b !important; }
  div#onepagecheckoutps h5 i {
    color: #000; }

div#onepagecheckoutps #panel_addresses_customer .card {
  border: none; }
  div#onepagecheckoutps #panel_addresses_customer .card .card-header {
    background: #c71b1b; }
    div#onepagecheckoutps #panel_addresses_customer .card .card-header .card-title a {
      color: #fff; }
  div#onepagecheckoutps #panel_addresses_customer .card .card-body {
    padding: 0; }

div#onepagecheckoutps #onepagecheckoutps_step_one_container #onepagecheckoutps_step_one {
  display: flex;
  flex-flow: column; }
  div#onepagecheckoutps #onepagecheckoutps_step_one_container #onepagecheckoutps_step_one .form-group {
    margin-top: 5px; }
    div#onepagecheckoutps #onepagecheckoutps_step_one_container #onepagecheckoutps_step_one .form-group label {
      line-height: 17px; }
  div#onepagecheckoutps #onepagecheckoutps_step_one_container #onepagecheckoutps_step_one .onepagecheckoutps_p_step #opc_show_login {
    font-size: 13px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 20px;
    word-spacing: -0.5px;
    margin-top: 3px;
    border-radius: 0;
    float: right; }
  div#onepagecheckoutps #onepagecheckoutps_step_one_container #onepagecheckoutps_step_one #customer_container {
    margin-top: 10px; }

div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_two #shipping_container,
div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_two #payment_method_container,
div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_three #shipping_container,
div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_three #payment_method_container,
div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_two #shipping_container,
div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_two #payment_method_container,
div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_three #shipping_container,
div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_three #payment_method_container {
  margin-top: 10px; }
  div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_two #shipping_container .row,
  div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_two #payment_method_container .row,
  div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_three #shipping_container .row,
  div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_three #payment_method_container .row,
  div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_two #shipping_container .row,
  div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_two #payment_method_container .row,
  div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_three #shipping_container .row,
  div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_three #payment_method_container .row {
    margin: 0; }
  div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_two #shipping_container div,
  div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_two #payment_method_container div,
  div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_three #shipping_container div,
  div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_three #payment_method_container div,
  div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_two #shipping_container div,
  div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_two #payment_method_container div,
  div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_three #shipping_container div,
  div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_three #payment_method_container div {
    padding-left: 3px;
    padding-right: 3px; }

div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_two .module_payment_container,
div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_two .delivery-option,
div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_three .module_payment_container,
div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_three .delivery-option,
div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_two .module_payment_container,
div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_two .delivery-option,
div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_three .module_payment_container,
div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_three .delivery-option {
  margin-bottom: 10px !important;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid transparent; }
  div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_two .module_payment_container:hover, div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_two .module_payment_container.selected,
  div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_two .delivery-option:hover,
  div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_two .delivery-option.selected,
  div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_three .module_payment_container:hover,
  div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_three .module_payment_container.selected,
  div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_three .delivery-option:hover,
  div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_three .delivery-option.selected,
  div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_two .module_payment_container:hover,
  div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_two .module_payment_container.selected,
  div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_two .delivery-option:hover,
  div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_two .delivery-option.selected,
  div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_three .module_payment_container:hover,
  div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_three .module_payment_container.selected,
  div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_three .delivery-option:hover,
  div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_three .delivery-option.selected {
    background: #f4f4f4;
    color: #000;
    border: 1px solid #bbb;
    margin-bottom: 10px; }
  div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_two .module_payment_container .delivery_option_title,
  div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_two .delivery-option .delivery_option_title,
  div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_three .module_payment_container .delivery_option_title,
  div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_three .delivery-option .delivery_option_title,
  div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_two .module_payment_container .delivery_option_title,
  div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_two .delivery-option .delivery_option_title,
  div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_three .module_payment_container .delivery_option_title,
  div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_three .delivery-option .delivery_option_title {
    font-weight: 500;
    line-height: 20px; }
  div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_two .module_payment_container .delivery_option_price,
  div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_two .delivery-option .delivery_option_price,
  div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_three .module_payment_container .delivery_option_price,
  div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_three .delivery-option .delivery_option_price,
  div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_two .module_payment_container .delivery_option_price,
  div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_two .delivery-option .delivery_option_price,
  div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_three .module_payment_container .delivery_option_price,
  div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_three .delivery-option .delivery_option_price {
    line-height: 12px;
    display: none; }
  div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_two .module_payment_container .payment_content_html,
  div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_two .delivery-option .payment_content_html,
  div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_three .module_payment_container .payment_content_html,
  div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_three .delivery-option .payment_content_html,
  div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_two .module_payment_container .payment_content_html,
  div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_two .delivery-option .payment_content_html,
  div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_three .module_payment_container .payment_content_html,
  div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_three .delivery-option .payment_content_html {
    font-size: 11px;
    line-height: 13px; }
    div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_two .module_payment_container .payment_content_html section > p,
    div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_two .delivery-option .payment_content_html section > p,
    div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_three .module_payment_container .payment_content_html section > p,
    div#onepagecheckoutps #onepagecheckoutps_step_two_container #onepagecheckoutps_step_three .delivery-option .payment_content_html section > p,
    div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_two .module_payment_container .payment_content_html section > p,
    div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_two .delivery-option .payment_content_html section > p,
    div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_three .module_payment_container .payment_content_html section > p,
    div#onepagecheckoutps #onepagecheckoutps_step_three_container #onepagecheckoutps_step_three .delivery-option .payment_content_html section > p {
      margin: 0; }

div#onepagecheckoutps #onepagecheckoutps_step_review_container {
  margin-top: 30px; }
  div#onepagecheckoutps #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #header-order-detail-content {
    background: #f7f7f9;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    font-weight: 700;
    margin-left: 0;
    margin-right: 0; }
    div#onepagecheckoutps #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #header-order-detail-content > div {
      text-decoration: none; }
  div#onepagecheckoutps #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review .cart_item {
    border-left: 1px solid #f7f7f9;
    border-top: 1px solid #f7f7f9;
    border-bottom: 1px solid #f7f7f9;
    padding: 5px;
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0; }
    div#onepagecheckoutps #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review .cart_item > div {
      border-right: 1px solid #f7f7f9; }
  div#onepagecheckoutps #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review .order_total_items {
    margin-bottom: 10px; }
    div#onepagecheckoutps #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review .order_total_items > div.row {
      border-left: 1px solid #f7f7f9;
      height: auto;
      margin-left: 0;
      margin-right: 0; }
      div#onepagecheckoutps #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review .order_total_items > div.row > div {
        border-right: 1px solid #f7f7f9;
        border-bottom-color: transparent; }
        div#onepagecheckoutps #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review .order_total_items > div.row > div:first-child {
          background: #fafafc; }
        div#onepagecheckoutps #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review .order_total_items > div.row > div:last-child {
          border-bottom: 1px solid #f7f7f9;
          border-top: 1px solid #f7f7f9;
          background: #efeff1; }
        div#onepagecheckoutps #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review .order_total_items > div.row > div span {
          padding: 3px;
          display: inline-block;
          font-size: 14px;
          font-weight: 400; }
      div#onepagecheckoutps #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review .order_total_items > div.row.cart_total_price.total_price {
        text-transform: initial; }
      div#onepagecheckoutps #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review .order_total_items > div.row.not-last {
        display: none; }
      div#onepagecheckoutps #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review .order_total_items > div.row.cart_total_price.total_price:not(.not-last) {
        color: #000;
        text-transform: uppercase; }
        div#onepagecheckoutps #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review .order_total_items > div.row.cart_total_price.total_price:not(.not-last) > div {
          background: #efeff1;
          border-top: 1px solid #efeff1;
          border-bottom: 1px solid #efeff1; }
        div#onepagecheckoutps #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review .order_total_items > div.row.cart_total_price.total_price:not(.not-last) span {
          font-weight: 700; }

div#onepagecheckoutps #btn_continue_shopping {
  background: #000000;
  color: #FFF;
  text-decoration: none;
  padding: 1rem 1.5rem;
  margin-top: 0 !important;
  transition: 0.3s ease; }
  div#onepagecheckoutps #btn_continue_shopping i {
    position: relative;
    right: 8px; }
  div#onepagecheckoutps #btn_continue_shopping:hover {
    opacity: 0.8 !important; }

div#onepagecheckoutps #btn_place_order {
  background: #6c955c;
  transition: 0.3s ease; }
  div#onepagecheckoutps #btn_place_order:hover {
    opacity: 0.9; }

div#onepagecheckoutps #conditions-to-approve {
  text-align: right;
  margin-top: 10px; }
  div#onepagecheckoutps #conditions-to-approve label {
    position: relative;
    padding-right: 25px; }
    div#onepagecheckoutps #conditions-to-approve label input {
      position: absolute;
      right: 0;
      top: 0; }

div#onepagecheckoutps #panel_addresses_customer .card-header {
  display: none; }

div#onepagecheckoutps #field_customer_invoice .col-xs-6 {
  padding: 0; }

@media (max-width: 992px) {
  .page-order #main-page-content #inner-wrapper {
    padding-left: 5px;
    padding-right: 5px; }
    .page-order #main-page-content #inner-wrapper #onepagecheckoutps #onepagecheckoutps_header {
      padding-left: 5px;
      padding-right: 5px; }
    .page-order #main-page-content #inner-wrapper #onepagecheckoutps #onepagecheckoutps_contenedor {
      padding-left: 5px;
      padding-right: 5px; } }

body.page-cms #content-wrapper {
  margin-top: 40px;
  margin-bottom: 40px; }
  body.page-cms #content-wrapper .page-title {
    border-color: #c71b1b; }
    body.page-cms #content-wrapper .page-title span {
      color: #000;
      font-size: 50px;
      font-weight: 400;
      line-height: 54px; }

#contact #content-wrapper .page-title {
  border-bottom: 1px solid #c71b1b;
  margin-top: 40px; }

#contact #content-wrapper #content .contact-page-info .contact-rich > strong {
  margin-bottom: 30px; }

#contact #content-wrapper #content .contact-page-info hr {
  margin-top: 8px;
  margin-bottom: 8px; }

#contact #content-wrapper #content .contact-page-info i:before {
  font-size: 22px; }

#contact #content-wrapper #content .contact-page-info .fa-phone:before {
  content: "\f095";
  width: 22px;
  height: 28px;
  background-image: none; }

#contact #content-wrapper #content .contact-form select:focus,
#contact #content-wrapper #content .contact-form input:focus,
#contact #content-wrapper #content .contact-form textarea:focus {
  box-shadow: none; }

#contact #content-wrapper #content .contact-form .form-footer .btn-primary {
  background-color: #c71b1b;
  transition: 0.3s ease;
  min-width: 150px; }
  #contact #content-wrapper #content .contact-form .form-footer .btn-primary:hover {
    opacity: 0.9; }

#contact #content-wrapper #content .contact-form .bootstrap-filestyle label {
  background: #c71b1b;
  line-height: 21px; }

@media (max-width: 767px) {
  #contact #content-wrapper .page-title {
    margin-top: 20px; }
  #contact #content-wrapper #content .contact-page-info .contact-rich > strong {
    font-size: 16px; }
  #contact #content-wrapper #content .contact-form h4 {
    font-size: 16px;
    font-weight: 700; }
  #contact #content-wrapper #content .contact-form .form-footer {
    text-align: center !important; }
    #contact #content-wrapper #content .contact-form .form-footer .btn-primary {
      font-size: 15px;
      line-height: 27px; } }
    @media (max-width: 767px) and (max-width: 420px) {
      #contact #content-wrapper #content .contact-form .form-footer .btn-primary {
        width: 100%; } }

body.page-customer-account #content-wrapper {
  margin-top: 30px;
  margin-bottom: 30px; }
  body.page-customer-account #content-wrapper .page-header .page-title {
    font-size: 27px;
    border: none;
    padding-bottom: 0; }
  body.page-customer-account #content-wrapper #content hr {
    display: none; }
  body.page-customer-account #content-wrapper #content #submit-login {
    background: #c71b1b;
    padding-left: 45px;
    padding-right: 45px;
    font-size: 15px;
    transition: 0.3s ease; }
    body.page-customer-account #content-wrapper #content #submit-login:hover {
      opacity: 0.9; }
    body.page-customer-account #content-wrapper #content #submit-login:focus {
      box-shadow: none; }
    body.page-customer-account #content-wrapper #content #submit-login:active {
      background: #c71b1b; }
  body.page-customer-account #content-wrapper #content .no-account {
    margin-top: 15px; }

body.page-customer-account .page-header .page-title {
  font-size: 27px;
  border: none;
  padding-bottom: 0; }

body.page-customer-account #content .register-form p:first-child {
  margin-bottom: 20px; }

body.page-customer-account #content .register-form .custom-checkbox span {
  border: 2px solid #c2c2c2;
  width: 18px;
  height: 18px; }
  body.page-customer-account #content .register-form .custom-checkbox span .fa-check {
    top: 1px; }
    body.page-customer-account #content .register-form .custom-checkbox span .fa-check:before {
      color: #c71b1b;
      font-size: 13px; }

body.page-customer-account #content .register-form .btn-primary {
  background: #c71b1b;
  padding-left: 45px;
  padding-right: 45px;
  font-size: 15px;
  transition: 0.3s ease; }
  body.page-customer-account #content .register-form .btn-primary:hover {
    opacity: 0.9; }
  body.page-customer-account #content .register-form .btn-primary:focus {
    box-shadow: none; }
  body.page-customer-account #content .register-form .btn-primary:active {
    background: #c71b1b; }

#modal-onexit .modal__container {
  width: 100%;
  height: auto;
  padding: 23px; }
  #modal-onexit .modal__container .modal__header {
    display: inline-block;
    float: right;
    position: relative; }
    #modal-onexit .modal__container .modal__header .modal__close {
      float: right;
      position: absolute;
      right: -17px;
      top: -17px;
      height: 28px;
      width: 28px;
      border: 1px solid #c71b1b;
      cursor: pointer;
      border-radius: 50%; }
      #modal-onexit .modal__container .modal__header .modal__close:before {
        color: #c71b1b;
        font-size: 20px;
        top: 1px;
        position: relative; }

#checkout .velo-field-preferred-date {
  width: 100%; }
  #checkout .velo-field-preferred-date label {
    text-align: left;
    float: left; }
  #checkout .velo-field-preferred-date #kb_pickup_select_date {
    width: 100%;
    max-width: 180px;
    margin-top: 5px;
    float: left; }

#checkout #kb_pts_carrier_block .velo-location-list .mp-openday-list .mp-openday-list-title {
  width: 86px;
  float: left; }

#checkout #kb_pts_carrier_block .velo-location-list .mp-openday-list .mp-openday-list-value {
  width: calc(100% - 86px);
  float: right; }

@media (max-width: 1180px) {
  #checkout #kb_pts_carrier_block .velo-search-bar {
    width: 100%; }
    #checkout #kb_pts_carrier_block .velo-search-bar label, #checkout #kb_pts_carrier_block .velo-search-bar input {
      width: 100%;
      text-align: left; }
  #checkout #kb_pts_carrier_block .velo-search-distance {
    min-width: 89px; }
  #checkout #kb_pts_carrier_block .velo-search-button {
    margin-right: 4px; } }

@media (max-width: 950px) and (min-width: 768px) {
  #checkout .col-md-4.col-sm-5.col-xs-12.col-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  #checkout .col-md-8.col-sm-7.col-xs-12.col-12 {
    flex: 0 0 50%;
    max-width: 50%; }
    #checkout .col-md-8.col-sm-7.col-xs-12.col-12 #onepagecheckoutps_step_two_container {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 20px; }
    #checkout .col-md-8.col-sm-7.col-xs-12.col-12 .velo-search-distance {
      text-align: left; }
    #checkout .col-md-8.col-sm-7.col-xs-12.col-12 .velo-search-bar {
      margin-bottom: 10px; }
    #checkout .col-md-8.col-sm-7.col-xs-12.col-12 .velo-search-button {
      width: 35px; }
    #checkout .col-md-8.col-sm-7.col-xs-12.col-12 #onepagecheckoutps_step_three_container {
      flex: 0 0 100%;
      max-width: 100%; } }

@media (max-width: 768px) {
  #checkout .col-md-4.col-sm-5.col-xs-12.col-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  #checkout .col-md-8.col-sm-7.col-xs-12.col-12 {
    flex: 0 0 100%;
    max-width: 100%; }
    #checkout .col-md-8.col-sm-7.col-xs-12.col-12 #onepagecheckoutps_step_two_container {
      flex: 0 0 100%;
      max-width: 100%; }
    #checkout .col-md-8.col-sm-7.col-xs-12.col-12 #onepagecheckoutps_step_three_container {
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 30px !important; }
  #checkout div#onepagecheckoutps #onepagecheckoutps_step_review_container {
    margin-top: 15px; }
  #checkout #kb_pts_carrier_block .velo-search-bar {
    width: 50%; }
    #checkout #kb_pts_carrier_block .velo-search-bar #velo_address_search {
      padding: 6px; }
  #checkout #kb_pts_carrier_block .velo-search-distance {
    width: 100px; }
  #checkout #kb_pts_carrier_block .velo-search-button {
    width: 35px;
    margin-right: 0; } }

@media (max-width: 425px) {
  #checkout #kb_pts_carrier_block .velo-search-bar {
    width: 100%;
    margin-bottom: 10px; }
    #checkout #kb_pts_carrier_block .velo-search-bar label {
      margin-bottom: 5px; }
    #checkout #kb_pts_carrier_block .velo-search-bar input {
      width: calc(100% - 16px);
      text-align: left;
      float: left; }
  #checkout #kb_pts_carrier_block .velo-search-distance {
    text-align: left;
    width: 89px; }
    #checkout #kb_pts_carrier_block .velo-search-distance label {
      margin-bottom: 5px; }
  #checkout #kb_pts_carrier_block .velo-search-button {
    margin-top: 23px; }
  #checkout #kb_pts_carrier_block .velo-location-list label {
    margin-bottom: 5px; }
  #checkout #kb_pts_carrier_block .velo-location-list #kb_pickup_selected_store {
    width: calc(100% - 16px); } }

#module-kbstorelocatorpickup-stores #wrapper {
  margin-top: 30px; }

#module-kbstorelocatorpickup-stores .velo-search-container {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  margin-top: 40px;
  margin-bottom: 40px;
  max-width: 1190px; }
  #module-kbstorelocatorpickup-stores .velo-search-container label {
    font-weight: 700; }
  #module-kbstorelocatorpickup-stores .velo-search-container input,
  #module-kbstorelocatorpickup-stores .velo-search-container select {
    border: 2px solid #c2c2c2; }
  #module-kbstorelocatorpickup-stores .velo-search-container .velo-search-button button {
    background: #6c955c;
    border: 1px solid #6c955c;
    padding: 8px 17px; }
  #module-kbstorelocatorpickup-stores .velo-search-container .velo-store-select-link {
    background: #6c955c; }
  #module-kbstorelocatorpickup-stores .velo-search-container .button-show-more {
    position: relative;
    margin-top: 3px;
    margin-bottom: 10px; }
    #module-kbstorelocatorpickup-stores .velo-search-container .button-show-more:before {
      content: "";
      position: absolute;
      display: inline-block;
      bottom: 0px;
      width: 0;
      height: 2px;
      left: 0;
      background-color: #c71b1b;
      transition: 0.3s ease; }
    #module-kbstorelocatorpickup-stores .velo-search-container .button-show-more:hover {
      opacity: 1; }
      #module-kbstorelocatorpickup-stores .velo-search-container .button-show-more:hover:before {
        width: 100%; }
  #module-kbstorelocatorpickup-stores .velo-search-container .velo-store-filters {
    width: 100%;
    display: inline-block; }
    #module-kbstorelocatorpickup-stores .velo-search-container .velo-store-filters .velo-field-inline {
      float: left; }
      #module-kbstorelocatorpickup-stores .velo-search-container .velo-store-filters .velo-field-inline:last-child {
        margin-right: 0; }
  #module-kbstorelocatorpickup-stores .velo-search-container .velo-directions-button,
  #module-kbstorelocatorpickup-stores .velo-search-container .velo-directions-link {
    width: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    background: #c71b1b;
    color: #fff;
    transition: 0.3s ease; }
    #module-kbstorelocatorpickup-stores .velo-search-container .velo-directions-button i,
    #module-kbstorelocatorpickup-stores .velo-search-container .velo-directions-link i {
      display: none; }
    #module-kbstorelocatorpickup-stores .velo-search-container .velo-directions-button:hover,
    #module-kbstorelocatorpickup-stores .velo-search-container .velo-directions-link:hover {
      background: #fff;
      color: #c71b1b; }

@media (max-width: 1200px) {
  #module-kbstorelocatorpickup-stores .velo-search-container .velo-store-filters .velo-field-inline:nth-child(4) {
    clear: left; } }

@media (max-width: 1024px) {
  #module-kbstorelocatorpickup-stores .velo-search-container .velo-search-button button {
    padding: 8px 20px; }
  #module-kbstorelocatorpickup-stores .velo-search-container .velo-store-filters .velo-field-inline {
    margin-right: 17px; }
    #module-kbstorelocatorpickup-stores .velo-search-container .velo-store-filters .velo-field-inline:nth-child(4) {
      clear: unset; } }

@media (max-width: 768px) {
  #module-kbstorelocatorpickup-stores .velo-search-container .velo-store-filters .velo-search-bar {
    display: inline-block;
    width: 100%;
    margin-bottom: 7px; }
    #module-kbstorelocatorpickup-stores .velo-search-container .velo-store-filters .velo-search-bar input {
      width: calc(100% - 195px); }
  #module-kbstorelocatorpickup-stores .velo-search-container .velo-store-filters .velo-search-limit {
    margin-right: 0; }
  #module-kbstorelocatorpickup-stores .velo-search-container .velo-store-filters .velo-search-button {
    width: calc(50% - 10px);
    margin: 0 auto;
    display: inline-block;
    margin-right: 10px;
    margin-top: 7px; }
    #module-kbstorelocatorpickup-stores .velo-search-container .velo-store-filters .velo-search-button:last-child {
      margin-right: 0;
      margin-left: 10px; }
    #module-kbstorelocatorpickup-stores .velo-search-container .velo-store-filters .velo-search-button button {
      max-width: unset; } }

@media (max-width: 480px) {
  #module-kbstorelocatorpickup-stores .velo-search-container label {
    width: 100%;
    text-align: left; }
  #module-kbstorelocatorpickup-stores .velo-search-container input,
  #module-kbstorelocatorpickup-stores .velo-search-container select {
    float: left; }
  #module-kbstorelocatorpickup-stores .velo-search-container .velo-store-filters .velo-search-bar label {
    width: 100%;
    text-align: left; }
  #module-kbstorelocatorpickup-stores .velo-search-container .velo-store-filters .velo-search-bar input {
    width: 100%; }
  #module-kbstorelocatorpickup-stores .velo-search-container .velo-store-filters .velo-search-distance {
    max-width: 125px;
    margin-right: 14px; }
  #module-kbstorelocatorpickup-stores .velo-search-container .velo-store-filters .velo-search-limit {
    max-width: 105px; }
  #module-kbstorelocatorpickup-stores .velo-search-container .velo-store-filters .velo-search-button button {
    padding: 8px 10px; }
  #module-kbstorelocatorpickup-stores .velo-search-container .velo-location-list {
    padding-right: 0; }
    #module-kbstorelocatorpickup-stores .velo-search-container .velo-location-list ul {
      padding: 5px; }
      #module-kbstorelocatorpickup-stores .velo-search-container .velo-location-list ul li {
        margin-bottom: 5px; } }

#content .rte-content .doc-title {
  background-color: #c71b1b;
  padding: 3px;
  margin: 3px 0px;
  color: #fff;
  cursor: pointer; }

#content .rte-content .doc-answer p:first-child {
  border: 1px solid red;
  line-height: 1.5em;
  padding: 10px;
  margin-bottom: 10px; }

#content .rte-content .doc-answer p:first-of-type:first-letter {
  display: block;
  width: 30px;
  padding-top: 5px;
  float: left;
  font-size: 20px;
  color: #b11c11;
  font-weight: bold;
  padding-right: 10px; }

#content .rte-content .doc-answer .doc-content-answer {
  padding: 10px;
  border: 1px solid #369bcf;
  line-height: 1.5em;
  margin-bottom: 10px; }
  #content .rte-content .doc-answer .doc-content-answer p {
    padding: 0;
    border: none; }
    #content .rte-content .doc-answer .doc-content-answer p:first-of-type:first-letter {
      display: block;
      width: 30px;
      padding-top: 5px;
      float: left;
      font-size: 20px;
      color: #369bcf;
      font-weight: bold;
      padding-right: 10px; }

.simpleblog-posts .post-item {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.25098); }
  .simpleblog-posts .post-item .post-thumbnail {
    margin-bottom: 0; }
  .simpleblog-posts .post-item .post-title {
    background-color: #c71b1b; }
    .simpleblog-posts .post-item .post-title h2 {
      margin: 0;
      padding: 0; }
      .simpleblog-posts .post-item .post-title h2 a {
        color: #fff;
        text-align: center;
        padding: 10px;
        display: inline-block;
        width: 100%;
        font-size: 20px; }
  .simpleblog-posts .post-item .post-content {
    padding: 20px 25px;
    margin: 0; }
    .simpleblog-posts .post-item .post-content .post-read-more {
      width: 100%;
      display: block;
      text-align: center;
      margin-top: 50px;
      margin-bottom: 20px;
      opacity: 1; }
      .simpleblog-posts .post-item .post-content .post-read-more span {
        text-decoration: none;
        background-color: #c71b1b;
        transition: 0.3s ease;
        color: #fff;
        padding: 10px;
        font-size: 14px;
        position: relative; }
        .simpleblog-posts .post-item .post-content .post-read-more span:before {
          content: "";
          position: absolute;
          display: inline-block;
          height: 2px;
          width: 0px;
          left: 50%;
          bottom: 6px;
          transform: translateX(-50%);
          background-color: #fff;
          transition: 0.4s ease; }
        .simpleblog-posts .post-item .post-content .post-read-more span:hover:before {
          width: 110px; }
      .simpleblog-posts .post-item .post-content .post-read-more .fa-chevron-right {
        display: none; }
  .simpleblog-posts .post-item .post-additional-info {
    display: none; }

@media (max-width: 768px) {
  .simpleblog-posts .simpleblog-post-item {
    flex: 0 0 100%;
    max-width: 100%; } }

#module-ph_simpleblog-single .simpleblog-single .post-meta-info.text-muted * {
  color: #000;
  opacity: 1; }

#module-ph_simpleblog-single .simpleblog-single .post-content {
  margin-top: 40px;
  margin-bottom: 70px; }
  #module-ph_simpleblog-single .simpleblog-single .post-content p img {
    max-width: 700px;
    width: 100%;
    display: block;
    margin: auto; }
  @media (max-width: 993px) {
    #module-ph_simpleblog-single .simpleblog-single .post-content p img {
      max-width: 450px;
      margin: auto;
      display: block;
      width: 100%; } }

@media (min-width: 994px) {
  #module-ph_simpleblog-single .simpleblog-single .post-featured-image {
    float: left;
    margin: 0 20px 20px 0;
    padding: 10px;
    width: 100%;
    max-width: 485px; } }

@media (max-width: 993px) {
  #module-ph_simpleblog-single .simpleblog-single .post-featured-image {
    max-width: 485px;
    margin: auto; } }

#module-ph_simpleblog-single .simpleblog-single .section-title {
  border-bottom: solid 1px rgba(199, 27, 27, 0.6); }

#module-ph_simpleblog-single .simpleblog-single #simpleblog-post-comments .alert-warning {
  background: #edf2f5;
  border-color: #edf2f5;
  color: #000; }
  #module-ph_simpleblog-single .simpleblog-single #simpleblog-post-comments .alert-warning a {
    color: #000;
    text-decoration: none; }

body.top-category-4 .product-miniature .lhcom_list_reviews .star_display_off,
body.top-category-4 .product-miniature .star_display_off {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

body.top-category-4 .product-miniature .lhcom_list_reviews .star_display_on,
body.top-category-4 .product-miniature .star_display_on {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

body.top-category-4 .product-miniature .lhcom_list_reviews .star_display_half,
body.top-category-4 .product-miniature .star_display_half {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

body.top-category-4 #blockcart-modal-wrap #blockcart-modal .modal-header {
  background-color: #0a2f77; }

body.top-category-4 #blockcart-modal-wrap #blockcart-modal .modal-body .col-md-7 .cart-content .cart-content-btn .btn-primary:hover {
  background-color: #0a2f77; }

body.top-category-4 #blockcart-modal-wrap #blockcart-modal .modal-body .col-md-7 .cart-content .cart-content-btn .btn-secondary:hover {
  color: #0a2f77;
  border-color: #0a2f77; }

body.top-category-4 #blockcart-modal-wrap #blockcart-modal .modal-body .darique-addon-presents .you-have-gift {
  color: #0a2f77;
  border-color: #0a2f77; }
  body.top-category-4 #blockcart-modal-wrap #blockcart-modal .modal-body .darique-addon-presents .you-have-gift:before {
    color: #0a2f77; }

body.top-category-4 .elementor-button-link i.fa-arrow-right {
  background-color: #0a2f77; }
  body.top-category-4 .elementor-button-link i.fa-arrow-right:before, body.top-category-4 .elementor-button-link i.fa-arrow-right:after {
    background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

body.top-category-4 .elementor-button-link:hover i.fa-arrow-right {
  background-color: #fff; }

body.top-category-4 .fa-credit-card:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

body.top-category-4 .fa-angle-down:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

body.top-category-4 .fa-search:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

body.top-category-4 .fa-user:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

body.top-category-4 .fa-shopping-bag:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

body.top-category-4 .fa-long-arrow-down:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

body.top-category-4 .fa-long-arrow-right:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

body.top-category-4 .fa-heart-o:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

body.top-category-4 .fa-building-o:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

body.top-category-4 .fa-check-circle-o:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

body.top-category-4 .fa-users:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

body.top-category-4 .fa-random:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

body.top-category-4 #blockcart-content .cart-subtotals {
  border-color: #0a2f77; }

body.top-category-4 #blockcart-content .cart-buttons .btn-primary {
  background-color: #0a2f77;
  border-color: #0a2f77; }

body.top-category-4 #header {
  background-color: #0a2f77; }
  body.top-category-4 #header #header-search-btn .dropdown-search {
    background-color: #0a2f77;
    border-color: #0a2f77; }
  body.top-category-4 #header #iqithtmlandbanners-block-2 .d-inline-block span span {
    background-color: #0a2f77 !important; }
  body.top-category-4 #header .block-iqitlinksmanager-2 ul li:after {
    background-color: #0a2f77; }

body.top-category-4 #mobile-header {
  background-color: #0a2f77; }
  body.top-category-4 #mobile-header #search-widget-mobile {
    background-color: #0a2f77;
    border-color: #0a2f77; }
  body.top-category-4 #mobile-header #iqitmegamenu-mobile {
    background-color: #0a2f77; }
    body.top-category-4 #mobile-header #iqitmegamenu-mobile li > ul {
      background-color: #0a2f77; }
  body.top-category-4 #mobile-header .mobile-main-bar .col-mobile-menu-dropdown a i {
    background-color: #0a2f77; }
  body.top-category-4 #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile > ul > li > a {
    border-color: #fff; }
  body.top-category-4 #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile > ul > li ul li a {
    border-color: rgba(255, 255, 255, 0.54); }

body.top-category-4 #desktop-header #iqitmegamenu-horizontal .cbp-show.cbp-notfit {
  border-color: #0a2f77; }
  body.top-category-4 #desktop-header #iqitmegamenu-horizontal .cbp-show.cbp-notfit .cbp-category-tree li .cbp-category-link-w a:hover {
    border-color: #0a2f77; }

body.top-category-4 #wrapper .breadcrumb ol li a {
  color: #0a2f77; }

body.top-category-4 #wrapper .breadcrumb ol li:last-child a {
  color: #000; }

body.top-category-4 #wrapper .breadcrumb ol li:after {
  color: #0a2f77; }

body.top-category-4 #footer #footer-container-first {
  background-color: #0a2f77; }
  body.top-category-4 #footer #footer-container-first .ps-emailsubscription-block .newsletter-input-group .btn-subscribe:hover {
    background-color: #0a2f77; }

body.top-category-4 #footer #footer-container-main .block-content ul li a:hover {
  color: #0a2f77; }

body.top-category-4 #footer #footer-container-main img {
  filter: hue-rotate(235deg) brightness(0.75); }

body.top-category-4 .product-miniature.product-miniature-layout-1 .product-price {
  color: #0a2f77; }

body.top-category-4 .product-miniature.product-miniature-layout-1 .product-extra-flags .discount-badge {
  background-color: #0a2f77; }

body.top-category-4 .product-miniature-grid .product-add-cart button.add-to-cart,
body.top-category-4 .product-miniature-grid .product-add-cart a.btn-product-list {
  background: #0a2f77; }
  body.top-category-4 .product-miniature-grid .product-add-cart button.add-to-cart:hover,
  body.top-category-4 .product-miniature-grid .product-add-cart a.btn-product-list:hover {
    background: #fff;
    color: #0a2f77; }

body.top-category-4 .product-miniature-grid .product-add-cart button.add-to-cart {
  background: #0a2f77; }
  body.top-category-4 .product-miniature-grid .product-add-cart button.add-to-cart:hover {
    background: #fff;
    color: #0a2f77; }

body.top-category-4 .product-miniature-grid .btn-iqitcompare-add i:after {
  background-image: url(../img/sprite.png);
  background-position: 0px -397px;
  width: 21px;
  height: 17px; }

body.top-category-4 .product-miniature-grid .btn-iqitwishlist-add i:after {
  background-image: url(../img/sprite.png);
  background-position: -241px -336px;
  width: 20px;
  height: 18px; }

body.top-category-4 .product-miniature-grid .btn-iqitcompare-add:hover,
body.top-category-4 .product-miniature-grid .btn-iqitwishlist-add:hover {
  background-color: #0a2f77; }

body.top-category-4 .slick-arrow {
  background-color: #0a2f77; }
  body.top-category-4 .slick-arrow:hover {
    background-color: #fff; }
    body.top-category-4 .slick-arrow:hover:before {
      background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }
    body.top-category-4 .slick-arrow:hover:after {
      background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }
  body.top-category-4 .slick-arrow.slick-prev:before {
    background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }
  body.top-category-4 .slick-arrow.slick-prev:after {
    background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }
  body.top-category-4 .slick-arrow.slick-next:before {
    background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }
  body.top-category-4 .slick-arrow.slick-next:after {
    background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

body.category-.top-category-4 #content-wrapper .active_filters .filter-block {
  border: solid 1px #0a2f77; }
  body.category-.top-category-4 #content-wrapper .active_filters .filter-block:hover {
    background-color: #0a2f77; }
    body.category-.top-category-4 #content-wrapper .active_filters .filter-block:hover .js-search-link .material-icons.close:before {
      background-color: #0a2f77; }

body.top-category-4 #js-product-list-top .col.col-auto .products-sort-order .select-title .fa-angle-down:before,
body.top-category-4 #js-product-list-top .col.col-auto .products-nb-per-page .select-title .fa-angle-down:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

body.top-category-4 #js-product-list-top .col.col-auto .products-sort-order .current,
body.top-category-4 #js-product-list-top .col.col-auto .products-nb-per-page .current {
  color: #0a2f77 !important; }

body.top-category-4 #js-product-list-top .col.col-auto .products-nb-per-page .dropdown-menu {
  border-bottom: 1px solid #0a2f77; }
  body.top-category-4 #js-product-list-top .col.col-auto .products-nb-per-page .dropdown-menu a:last-child {
    background-color: #0a2f77;
    border: 1px solid #0a2f77; }

body.top-category-4 #js-product-list .pagination ul li a {
  color: #0a2f77; }

.page-category.top-category-4 #inner-wrapper .row .category-description + .category-description-more, .page-category.top-category-4 #inner-wrapper .row .category-description a {
  color: #0a2f77; }
  .page-category.top-category-4 #inner-wrapper .row .category-description + .category-description-more:hover, .page-category.top-category-4 #inner-wrapper .row .category-description a:hover {
    color: #102f7d; }

body.page-category.top-category-4 #wrapper #left-column .simplebar-vertical {
  background-color: #fff; }
  body.page-category.top-category-4 #wrapper #left-column .simplebar-vertical .simplebar-scrollbar:before {
    background: #0a2f77; }
  body.page-category.top-category-4 #wrapper #left-column .simplebar-vertical:before {
    background-color: #d8d8d8; }

body.page-category.top-category-4 #wrapper #left-column .block-categories .block-title {
  border-bottom: solid 2px #0a2f77; }
  body.page-category.top-category-4 #wrapper #left-column .block-categories .block-title:before {
    border-left: 2px solid #0a2f77;
    border-top: 2px solid #0a2f77; }
  body.page-category.top-category-4 #wrapper #left-column .block-categories .block-title a {
    color: #000; }

body.page-category.top-category-4 #wrapper #left-column .block-categories .block-content li.current > a {
  color: #0a2f77; }
  body.page-category.top-category-4 #wrapper #left-column .block-categories .block-content li.current > a:before {
    background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

body.page-category.top-category-4 #wrapper #left-column .block-categories .block-content > .category-sub-menu li[data-depth="0"] > a {
  border-bottom: 1px solid #0a2f77; }

body.page-category.top-category-4 #wrapper #left-column .block-categories .block-content > .category-sub-menu li[data-depth="0"] .category-sub-menu li {
  border-bottom: 1px solid #c2c2c2; }

body.page-category.top-category-4 #wrapper #left-column .block-categories .block-content > .category-sub-menu li[data-depth="0"] .fa-angle-down:before {
  background-color: #0a2f77; }

body.page-category.top-category-4 #wrapper #left-column .block-categories .block-content > .category-sub-menu li[data-depth="0"] .fa-angle-down:after {
  background-color: #0a2f77; }

body.page-category.top-category-4 #wrapper #left-column .block-categories .block-content > .category-sub-menu li[data-depth="0"] .fa-angle-up:before {
  background-color: #0a2f77; }

body.page-category.top-category-4 #wrapper #left-column #facets_search_wrapper #search_filters #_desktop_search_filters_clear_all button {
  background-color: #0a2f77; }

body.page-category.top-category-4 #wrapper #left-column #facets_search_wrapper #search_filters .facet .h6 {
  border-bottom: solid 2px #0a2f77; }
  body.page-category.top-category-4 #wrapper #left-column #facets_search_wrapper #search_filters .facet .h6:before {
    border-left: 2px solid #0a2f77;
    border-top: 2px solid #0a2f77; }

body.page-category.top-category-4 #wrapper #left-column #facets_search_wrapper #search_filters .facet ul.faceted-slider {
  min-height: 54px; }
  body.page-category.top-category-4 #wrapper #left-column #facets_search_wrapper #search_filters .facet ul.faceted-slider li .ui-slider {
    background: #d8d8d8;
    border-color: #d8d8d8; }
    body.page-category.top-category-4 #wrapper #left-column #facets_search_wrapper #search_filters .facet ul.faceted-slider li .ui-slider .ui-slider-range {
      background: #0a2f77; }
    body.page-category.top-category-4 #wrapper #left-column #facets_search_wrapper #search_filters .facet ul.faceted-slider li .ui-slider .ui-slider-handle {
      background-color: #0a2f77; }

body.page-category.top-category-4 #wrapper #left-column #facets_search_wrapper #search_filters .facet ul li {
  border-bottom: 1px solid #c2c2c2; }
  body.page-category.top-category-4 #wrapper #left-column #facets_search_wrapper #search_filters .facet ul li label.active .custom-checkbox .ps-shown-by-js {
    border: 2px solid #0a2f77; }
    body.page-category.top-category-4 #wrapper #left-column #facets_search_wrapper #search_filters .facet ul li label.active .custom-checkbox .ps-shown-by-js:before {
      background-color: #0a2f77; }

body.page-category.top-category-4 #wrapper #left-column #gim_category_banner .banner-content .button-container .elementor-button .elementor-button-text {
  color: #0a2f77; }

body.page-category.top-category-4 #wrapper #left-column #gim_category_banner .banner-content .button-container:hover .elementor-button {
  background: #0a2f77; }

@media (max-width: 991px) and (min-width: 768px) {
  body.page-category.top-category-4 #wrapper #left-column .simplebar-vertical:before {
    background-color: #d8d8d8; }
  body.page-category.top-category-4 #wrapper #left-column .filter-gim {
    border-bottom: 1px solid #0a2f77; }
    body.page-category.top-category-4 #wrapper #left-column .filter-gim:before {
      border-left: 2px solid #0a2f77;
      border-top: 2px solid #0a2f77; } }

@media (max-width: 767px) {
  body.page-category.top-category-4 #wrapper #left-column #facets_search_wrapper #search_filter_controls .js-search-filters-clear-all {
    background-color: #0a2f77; }
  body.page-category.top-category-4 #wrapper #left-column .simplebar-vertical {
    background-color: #f1f1f1; }
  body.page-category.top-category-4 #wrapper #search_filter_toggler {
    border-bottom: 2px solid #0a2f77; }
    body.page-category.top-category-4 #wrapper #search_filter_toggler:before {
      border-left: 2px solid #0a2f77;
      border-top: 2px solid #0a2f77; } }

body.top-category-220 .product-miniature .lhcom_list_reviews .star_display_off,
body.top-category-220 .product-miniature .star_display_off {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }

body.top-category-220 .product-miniature .lhcom_list_reviews .star_display_on,
body.top-category-220 .product-miniature .star_display_on {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }

body.top-category-220 .product-miniature .lhcom_list_reviews .star_display_half,
body.top-category-220 .product-miniature .star_display_half {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }

body.top-category-220 #blockcart-modal-wrap #blockcart-modal .modal-header {
  background-color: #8e9197; }

body.top-category-220 #blockcart-modal-wrap #blockcart-modal .modal-body .col-md-7 .cart-content .cart-content-btn .btn-primary:hover {
  background-color: #8e9197; }

body.top-category-220 #blockcart-modal-wrap #blockcart-modal .modal-body .col-md-7 .cart-content .cart-content-btn .btn-secondary:hover {
  color: #8e9197;
  border-color: #8e9197; }

body.top-category-220 #blockcart-modal-wrap #blockcart-modal .modal-body .darique-addon-presents .you-have-gift {
  color: #8e9197;
  border-color: #8e9197; }
  body.top-category-220 #blockcart-modal-wrap #blockcart-modal .modal-body .darique-addon-presents .you-have-gift:before {
    color: #8e9197; }

body.top-category-220 .elementor-button-link i.fa-arrow-right {
  background-color: #8e9197; }
  body.top-category-220 .elementor-button-link i.fa-arrow-right:before, body.top-category-220 .elementor-button-link i.fa-arrow-right:after {
    background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }

body.top-category-220 .elementor-button-link:hover i.fa-arrow-right {
  background-color: #fff; }

body.top-category-220 .fa-credit-card:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }

body.top-category-220 .fa-angle-down:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }

body.top-category-220 .fa-search:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }

body.top-category-220 .fa-user:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }

body.top-category-220 .fa-shopping-bag:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }

body.top-category-220 .fa-long-arrow-down:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }

body.top-category-220 .fa-long-arrow-right:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }

body.top-category-220 .fa-heart-o:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }

body.top-category-220 .fa-building-o:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }

body.top-category-220 .fa-check-circle-o:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }

body.top-category-220 .fa-users:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }

body.top-category-220 .fa-random:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }

body.top-category-220 #blockcart-content .cart-subtotals {
  border-color: #8e9197; }

body.top-category-220 #blockcart-content .cart-buttons .btn-primary {
  background-color: #8e9197;
  border-color: #8e9197; }

body.top-category-220 #header {
  background-color: #8e9197; }
  body.top-category-220 #header #header-search-btn .dropdown-search {
    background-color: #8e9197;
    border-color: #8e9197; }
  body.top-category-220 #header #iqithtmlandbanners-block-2 .d-inline-block span span {
    background-color: #8e9197 !important; }
  body.top-category-220 #header .block-iqitlinksmanager-2 ul li:after {
    background-color: #8e9197; }

body.top-category-220 #mobile-header {
  background-color: #8e9197; }
  body.top-category-220 #mobile-header #search-widget-mobile {
    background-color: #8e9197;
    border-color: #8e9197; }
  body.top-category-220 #mobile-header #iqitmegamenu-mobile {
    background-color: #8e9197; }
    body.top-category-220 #mobile-header #iqitmegamenu-mobile li > ul {
      background-color: #8e9197; }
  body.top-category-220 #mobile-header .mobile-main-bar .col-mobile-menu-dropdown a i {
    background-color: #8e9197; }
  body.top-category-220 #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile > ul > li > a {
    border-color: #fff; }
  body.top-category-220 #mobile-header .mobile-main-bar .col-mobile-menu-dropdown.show #_mobile_iqitmegamenu-mobile > ul > li ul li a {
    border-color: rgba(255, 255, 255, 0.54); }

body.top-category-220 #desktop-header #iqitmegamenu-horizontal .cbp-show.cbp-notfit {
  border-color: #8e9197; }
  body.top-category-220 #desktop-header #iqitmegamenu-horizontal .cbp-show.cbp-notfit .cbp-category-tree li .cbp-category-link-w a:hover {
    border-color: #8e9197; }

body.top-category-220 #wrapper .breadcrumb ol li a {
  color: #8e9197; }

body.top-category-220 #wrapper .breadcrumb ol li:last-child a {
  color: #000; }

body.top-category-220 #wrapper .breadcrumb ol li:after {
  color: #8e9197; }

body.top-category-220 #footer #footer-container-first {
  background-color: #8e9197; }
  body.top-category-220 #footer #footer-container-first .ps-emailsubscription-block .newsletter-input-group .btn-subscribe:hover {
    background-color: #8e9197; }

body.top-category-220 #footer #footer-container-main .block-content ul li a:hover {
  color: #8e9197; }

body.top-category-220 #footer #footer-container-main img {
  filter: hue-rotate(313deg) brightness(1.1); }

body.top-category-220 .product-miniature.product-miniature-layout-1 .product-price {
  color: #8e9197; }

body.top-category-220 .product-miniature.product-miniature-layout-1 .product-extra-flags .discount-badge {
  background-color: #8e9197; }

body.top-category-220 .product-miniature-grid .product-add-cart button.add-to-cart,
body.top-category-220 .product-miniature-grid .product-add-cart a.btn-product-list {
  background: #8e9197; }
  body.top-category-220 .product-miniature-grid .product-add-cart button.add-to-cart:hover,
  body.top-category-220 .product-miniature-grid .product-add-cart a.btn-product-list:hover {
    background-color: #fff;
    color: #8e9197; }

body.top-category-220 .product-miniature-grid .product-add-cart button.add-to-cart {
  background: #8e9197; }
  body.top-category-220 .product-miniature-grid .product-add-cart button.add-to-cart:hover {
    background-color: #fff;
    color: #8e9197; }

body.top-category-220 .product-miniature-grid .btn-iqitcompare-add:hover,
body.top-category-220 .product-miniature-grid .btn-iqitwishlist-add:hover {
  background-color: #8e9197; }

body.top-category-220 .slick-arrow {
  background-color: #8e9197; }
  body.top-category-220 .slick-arrow:hover {
    background-color: #fff; }
    body.top-category-220 .slick-arrow:hover:before {
      background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }
    body.top-category-220 .slick-arrow:hover:after {
      background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }
  body.top-category-220 .slick-arrow.slick-prev:before {
    background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }
  body.top-category-220 .slick-arrow.slick-prev:after {
    background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }
  body.top-category-220 .slick-arrow.slick-next:before {
    background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }
  body.top-category-220 .slick-arrow.slick-next:after {
    background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }

body.page-category.top-category-220 #content-wrapper .active_filters .filter-block {
  border: solid 1px #8e9197; }
  body.page-category.top-category-220 #content-wrapper .active_filters .filter-block:hover {
    background-color: #8e9197; }
    body.page-category.top-category-220 #content-wrapper .active_filters .filter-block:hover .js-search-link .material-icons.close:before {
      background-color: #8e9197; }

body.top-category-220 #js-product-list-top .col.col-auto .products-sort-order .select-title .fa-angle-down:before,
body.top-category-220 #js-product-list-top .col.col-auto .products-nb-per-page .select-title .fa-angle-down:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }

body.top-category-220 #js-product-list-top .col.col-auto .products-sort-order .current,
body.top-category-220 #js-product-list-top .col.col-auto .products-nb-per-page .current {
  color: #8e9197 !important; }

body.top-category-220 #js-product-list-top .col.col-auto .products-nb-per-page .dropdown-menu {
  border-bottom: 1px solid #8e9197; }
  body.top-category-220 #js-product-list-top .col.col-auto .products-nb-per-page .dropdown-menu a:last-child {
    background-color: #8e9197;
    border: 1px solid #8e9197; }

body.top-category-220 #js-product-list .pagination ul li a {
  color: #8e9197; }

.page-category.top-category-220 #inner-wrapper .row .category-description + .category-description-more, .page-category.top-category-220 #inner-wrapper .row .category-description a {
  color: #8e9197; }
  .page-category.top-category-220 #inner-wrapper .row .category-description + .category-description-more:hover, .page-category.top-category-220 #inner-wrapper .row .category-description a:hover {
    color: #9b9c9e; }

body.page-category.top-category-220 #wrapper #left-column .simplebar-vertical {
  background-color: #fff; }
  body.page-category.top-category-220 #wrapper #left-column .simplebar-vertical .simplebar-scrollbar:before {
    background: #8e9197; }

body.page-category.top-category-220 #wrapper #left-column .block-categories .block-title {
  border-bottom: solid 2px #8e9197; }
  body.page-category.top-category-220 #wrapper #left-column .block-categories .block-title:before {
    border-left: 2px solid #8e9197;
    border-top: 2px solid #8e9197; }
  body.page-category.top-category-220 #wrapper #left-column .block-categories .block-title a {
    color: #000; }

body.page-category.top-category-220 #wrapper #left-column .block-categories .block-content li.current > a {
  color: #8e9197; }
  body.page-category.top-category-220 #wrapper #left-column .block-categories .block-content li.current > a:before {
    background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }

body.page-category.top-category-220 #wrapper #left-column .block-categories .block-content > .category-sub-menu li[data-depth="0"] > a {
  border-bottom: 1px solid #8e9197; }

body.page-category.top-category-220 #wrapper #left-column .block-categories .block-content > .category-sub-menu li[data-depth="0"] .fa-angle-down:before {
  background-color: #8e9197; }

body.page-category.top-category-220 #wrapper #left-column .block-categories .block-content > .category-sub-menu li[data-depth="0"] .fa-angle-down:after {
  background-color: #8e9197; }

body.page-category.top-category-220 #wrapper #left-column .block-categories .block-content > .category-sub-menu li[data-depth="0"] .fa-angle-up:before {
  background-color: #8e9197; }

body.page-category.top-category-220 #wrapper #left-column #facets_search_wrapper #search_filters #_desktop_search_filters_clear_all button {
  background-color: #8e9197; }

body.page-category.top-category-220 #wrapper #left-column #facets_search_wrapper #search_filters .facet .h6 {
  border-bottom: solid 2px #8e9197; }
  body.page-category.top-category-220 #wrapper #left-column #facets_search_wrapper #search_filters .facet .h6:before {
    border-left: 2px solid #8e9197;
    border-top: 2px solid #8e9197; }

body.page-category.top-category-220 #wrapper #left-column #facets_search_wrapper #search_filters .facet ul.faceted-slider {
  min-height: 54px; }
  body.page-category.top-category-220 #wrapper #left-column #facets_search_wrapper #search_filters .facet ul.faceted-slider li .ui-slider .ui-slider-range {
    background: #8e9197; }
  body.page-category.top-category-220 #wrapper #left-column #facets_search_wrapper #search_filters .facet ul.faceted-slider li .ui-slider .ui-slider-handle {
    background-color: #8e9197; }

body.page-category.top-category-220 #wrapper #left-column #facets_search_wrapper #search_filters .facet ul li label.active .custom-checkbox .ps-shown-by-js {
  border: 2px solid #8e9197; }
  body.page-category.top-category-220 #wrapper #left-column #facets_search_wrapper #search_filters .facet ul li label.active .custom-checkbox .ps-shown-by-js:before {
    background-color: #8e9197; }

body.page-category.top-category-220 #wrapper #left-column #gim_category_banner .banner-content .button-container .elementor-button .elementor-button-text {
  color: #8e9197; }

body.page-category.top-category-220 #wrapper #left-column #gim_category_banner .banner-content .button-container:hover .elementor-button {
  background: #8e9197; }

@media (max-width: 991px) and (min-width: 768px) {
  body.page-category.top-category-220 #wrapper #left-column .filter-gim {
    border-bottom: 1px solid #8e9197; }
    body.page-category.top-category-220 #wrapper #left-column .filter-gim:before {
      border-left: 2px solid #8e9197;
      border-top: 2px solid #8e9197; } }

@media (max-width: 767px) {
  body.page-category.top-category-220 #wrapper #left-column #facets_search_wrapper #search_filter_controls .js-search-filters-clear-all {
    background-color: #8e9197; }
  body.page-category.top-category-220 #wrapper #left-column .simplebar-vertical {
    background-color: #f1f1f1; }
  body.page-category.top-category-220 #wrapper #search_filter_toggler {
    border-bottom: 2px solid #8e9197; }
    body.page-category.top-category-220 #wrapper #search_filter_toggler:before {
      border-left: 2px solid #8e9197;
      border-top: 2px solid #8e9197; } }

body.page-category #content-wrapper .active_filters {
  margin-top: 30px; }
  body.page-category #content-wrapper .active_filters .h6.active-filter-title {
    float: left;
    margin-right: 10px;
    line-height: 29px; }
  body.page-category #content-wrapper .active_filters .filter-block {
    background: #fff;
    border: solid 1px #c71b1b;
    border-radius: 10px;
    transition: 0.3s ease;
    display: inline-block;
    padding: 5px;
    position: relative;
    padding-right: 25px; }
    body.page-category #content-wrapper .active_filters .filter-block .js-search-link {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%); }
      body.page-category #content-wrapper .active_filters .filter-block .js-search-link .material-icons.close {
        line-height: 17px;
        position: relative;
        font-size: 14px;
        opacity: 1; }
        body.page-category #content-wrapper .active_filters .filter-block .js-search-link .material-icons.close:before {
          content: "\f00d";
          position: absolute;
          display: inline-block;
          font-family: FontAwesome;
          width: 17px;
          height: 20px;
          background-color: #fff;
          right: 1px;
          top: 0;
          transition: 0.3s ease; }
    body.page-category #content-wrapper .active_filters .filter-block:hover {
      color: #fff;
      background-color: #c71b1b; }
      body.page-category #content-wrapper .active_filters .filter-block:hover .js-search-link .material-icons.close:before {
        background-color: #c71b1b;
        color: #fff; }

#search_filters .facet_color li {
  display: block; }
  #search_filters .facet_color li label .search-link span {
    display: none;
    position: relative; }

#category #inner-wrapper {
  max-width: unset;
  padding-left: 0;
  padding-right: 0; }
  #category #inner-wrapper .row {
    max-width: 1230px;
    padding-right: 15px;
    padding-left: 15px;
    margin: auto; }

#category #gim-category-hero-banner {
  text-align: center;
  position: relative; }
  #category #gim-category-hero-banner img {
    max-width: 100%;
    object-fit: cover; }
  #category #gim-category-hero-banner .gim-hero-image-mobile {
    display: none; }
  #category #gim-category-hero-banner .gim-hero-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-33%, 3%);
    color: #fff;
    font-size: 60px;
    font-weight: 400;
    line-height: 40px; }
    #category #gim-category-hero-banner .gim-hero-text .top {
      color: #fff;
      font-size: 32px;
      font-weight: 400;
      line-height: 32px;
      display: block; }
    @media (max-width: 767px) {
      #category #gim-category-hero-banner .gim-hero-text {
        font-size: 40px;
        line-height: 22px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, 0%); }
        #category #gim-category-hero-banner .gim-hero-text .top {
          font-size: 22px; } }

@media (max-width: 991px) and (min-width: 768px) {
  body.page-category #content-wrapper #js-active-search-filters {
    padding-left: 247px; }
  body.page-category #content-wrapper #js-product-list-top {
    padding-left: 222px; } }

@media (max-width: 767px) {
  body#category #gim-category-hero-banner .gim-hero-image {
    display: none; }
  body#category #gim-category-hero-banner .gim-hero-image-mobile {
    display: block; }
  body#category #content-wrapper #js-product-list-top .row.align-items-center {
    padding-left: 0;
    padding-right: 0; }
    body#category #content-wrapper #js-product-list-top .row.align-items-center .dropdown {
      float: left;
      display: block;
      width: 100%;
      margin-bottom: 10px; }
      body#category #content-wrapper #js-product-list-top .row.align-items-center .dropdown.products-nb-per-page {
        margin-bottom: 0; }
      body#category #content-wrapper #js-product-list-top .row.align-items-center .dropdown .select-title {
        float: left; } }

#products {
  width: 100%;
  display: inline-block; }
  #products .alert.alert-warning {
    margin-top: 60px; }

#js-product-list-top {
  border: none;
  padding-bottom: 2px;
  padding-top: 2px;
  margin-top: 41px; }
  #js-product-list-top .view-switcher {
    display: none; }
  #js-product-list-top .col.col-auto {
    width: 100%; }
    #js-product-list-top .col.col-auto .showing.hidden-sm-down {
      display: none; }
    #js-product-list-top .col.col-auto .products-sort-order label,
    #js-product-list-top .col.col-auto .products-nb-per-page label {
      float: left;
      font-size: 16px;
      font-weight: 400;
      margin-right: 8px;
      line-height: 27px;
      margin-bottom: 0; }
    #js-product-list-top .col.col-auto .products-sort-order .select-title,
    #js-product-list-top .col.col-auto .products-nb-per-page .select-title {
      float: right;
      border: 2px solid #c2c2c2;
      width: 139px;
      position: relative; }
      #js-product-list-top .col.col-auto .products-sort-order .select-title span,
      #js-product-list-top .col.col-auto .products-nb-per-page .select-title span {
        color: #9b9b9b;
        font-size: 13px; }
      #js-product-list-top .col.col-auto .products-sort-order .select-title .fa-angle-down:before,
      #js-product-list-top .col.col-auto .products-nb-per-page .select-title .fa-angle-down:before {
        position: absolute;
        right: 7px;
        top: 50%;
        transform: translateY(-50%); }
    #js-product-list-top .col.col-auto .products-sort-order .current,
    #js-product-list-top .col.col-auto .products-nb-per-page .current {
      color: #c71b1b !important; }
    #js-product-list-top .col.col-auto .products-sort-order.show .select-title .fa-angle-down:before,
    #js-product-list-top .col.col-auto .products-nb-per-page.show .select-title .fa-angle-down:before {
      transform: translateY(-50%) rotate(180deg); }
    #js-product-list-top .col.col-auto .products-nb-per-page {
      float: right; }
      #js-product-list-top .col.col-auto .products-nb-per-page .select-title {
        width: 66px;
        color: #9b9b9b;
        font-size: 13px; }
      #js-product-list-top .col.col-auto .products-nb-per-page .dropdown-menu {
        border: 1px solid #c2c2c2;
        border-bottom: 1px solid #c71b1b; }
        #js-product-list-top .col.col-auto .products-nb-per-page .dropdown-menu a {
          border-bottom: 1px solid #c2c2c2; }
          #js-product-list-top .col.col-auto .products-nb-per-page .dropdown-menu a:last-child {
            text-align: center;
            background-color: #c71b1b;
            color: #fff !important;
            border: 1px solid #c71b1b;
            border-top: none;
            transition: 0.3s ease; }

#js-product-list .products-grid {
  background-color: #fff;
  box-shadow: 0 2px 64px rgba(0, 0, 0, 0.1); }

#js-product-list .pagination ul li {
  border: none; }
  #js-product-list .pagination ul li a {
    color: #c71b1b;
    font-size: 15px;
    padding: .4rem 2px; }
  #js-product-list .pagination ul li .next,
  #js-product-list .pagination ul li .previous {
    position: relative;
    top: 3px; }
    #js-product-list .pagination ul li .next i:before,
    #js-product-list .pagination ul li .previous i:before {
      font-size: 27px; }
  #js-product-list .pagination ul li .next {
    padding-left: 12px; }
  #js-product-list .pagination ul li .previous {
    padding-right: 12px;
    color: #c2c2c2; }
  #js-product-list .pagination ul li.current a {
    color: #000; }

#js-product-list .product-miniature-grid {
  height: auto; }
  #js-product-list .product-miniature-grid .product-description {
    position: relative; }

.page-category #content-wrapper {
  flex: 0 0 76%;
  max-width: 76%;
  padding-left: 9px;
  padding-right: 0; }

.page-category #left-column {
  flex: 0 0 24%;
  max-width: 24%; }

.page-category #inner-wrapper #notifications + .row {
  position: relative;
  padding-top: 40px; }

.page-category #inner-wrapper .row:nth-child(2) {
  margin-bottom: 30px; }

.page-category #inner-wrapper .row #js-product-list-header {
  width: 100%;
  display: inline-block; }

.page-category #inner-wrapper .row h1.page-title {
  width: 100%;
  display: inline-block;
  font-size: 50px;
  margin-bottom: 23px; }

.page-category #inner-wrapper .row.image-description-row {
  display: block; }
  .page-category #inner-wrapper .row.image-description-row + .row {
    padding-top: 83px; }
    @media (max-width: 767px) {
      .page-category #inner-wrapper .row.image-description-row + .row {
        padding-top: 40px; } }
    @media (max-width: 480px) {
      .page-category #inner-wrapper .row.image-description-row + .row {
        padding-top: 0; } }

.page-category #inner-wrapper .row .category-description + .category-description-more, .page-category #inner-wrapper .row .category-description a {
  color: #c71b1b;
  transition: color 0.2s cubic-bezier(0.7, 0, 0.3, 1);
  cursor: pointer;
  display: inline-block; }
  .page-category #inner-wrapper .row .category-description + .category-description-more:hover, .page-category #inner-wrapper .row .category-description a:hover {
    color: #d23024; }

.page-category #inner-wrapper .row .category-description-more,
.page-category #inner-wrapper .row .category-description {
  float: left;
  margin-bottom: 0;
  transition: max-height 0.2s cubic-bezier(0.7, 0, 0.3, 1);
  overflow: hidden;
  clear: left; }
  .page-category #inner-wrapper .row .category-description-more h3,
  .page-category #inner-wrapper .row .category-description h3 {
    font-size: 50px;
    font-weight: 400;
    line-height: 54px;
    margin-bottom: 25px; }
  .page-category #inner-wrapper .row .category-description-more p,
  .page-category #inner-wrapper .row .category-description p {
    font-size: 14px;
    line-height: 20px; }

.page-category #inner-wrapper .row .category-image {
  float: right;
  width: 30%;
  margin-bottom: 0;
  position: relative; }
  .page-category #inner-wrapper .row .category-image + .category-description, .page-category #inner-wrapper .row .category-image + .page-title {
    width: 70%;
    padding-right: 63px; }
  .page-category #inner-wrapper .row .category-image ~ .category-description-more {
    width: 70%;
    padding-right: 63px; }

@media (max-width: 1199px) and (min-width: 768px) {
  body.page-category #wrapper #content-wrapper {
    flex: 0 0 72%;
    max-width: 72%; } }

@media (max-width: 991px) and (min-width: 768px) {
  body.page-category #wrapper #content-wrapper {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (max-width: 767px) {
  body.page-category #inner-wrapper .row h1.page-title {
    line-height: 45px;
    padding-top: 5px; }
  body.page-category #inner-wrapper .row .category-description {
    width: 100%;
    padding: 0; }
  body.page-category #inner-wrapper .row .category-image {
    width: 100%;
    float: unset;
    text-align: center;
    margin-top: 10px; }
    body.page-category #inner-wrapper .row .category-image + .category-description, body.page-category #inner-wrapper .row .category-image + .page-title {
      width: 100%; }
  body.page-category #wrapper #content-wrapper {
    flex: 0 0 100%;
    max-width: 100%; } }

body.page-category #content-wrapper {
  position: relative;
  z-index: 2; }

body.page-category #wrapper {
  padding-bottom: 54px; }
  body.page-category #wrapper #left-column .filter-gim-wrapper {
    width: 100%;
    display: inline-block; }
  body.page-category #wrapper #left-column .simplebar-vertical {
    background-color: #fff;
    width: 5px;
    padding-top: 6px;
    padding-bottom: 6px; }
    body.page-category #wrapper #left-column .simplebar-vertical .simplebar-scrollbar {
      width: 5px;
      right: 0; }
      body.page-category #wrapper #left-column .simplebar-vertical .simplebar-scrollbar:before {
        background: #c71b1b;
        opacity: 1;
        top: 0; }
    body.page-category #wrapper #left-column .simplebar-vertical:before {
      content: "";
      position: absolute;
      display: inline-block;
      width: 5px;
      height: 100%;
      background-color: #d8d8d8;
      right: 0; }
  body.page-category #wrapper #left-column .block-categories {
    margin-bottom: 25px;
    font-weight: bold; }
    body.page-category #wrapper #left-column .block-categories .block-title {
      margin-bottom: 0;
      position: relative;
      cursor: pointer;
      padding-bottom: 11px;
      padding-top: 11px;
      border-bottom: solid 2px #c71b1b;
      text-transform: uppercase; }
      body.page-category #wrapper #left-column .block-categories .block-title:before {
        content: "";
        position: absolute;
        display: inline-block;
        width: 11px;
        height: 11px;
        border-left: 2px solid #c71b1b;
        border-top: 2px solid #c71b1b;
        right: 3px;
        top: 50%;
        transform: translateY(4%) rotate(45deg);
        transition: 0.2s ease; }
      body.page-category #wrapper #left-column .block-categories .block-title a {
        color: #000;
        font-size: 20px;
        line-height: 24px; }
    body.page-category #wrapper #left-column .block-categories .block-content li.current > a {
      position: relative;
      color: #c71b1b;
      font-weight: bold;
      padding-left: 15px; }
      body.page-category #wrapper #left-column .block-categories .block-content li.current > a:before {
        content: '';
        background-image: url(../img/sprite.png);
        background-position: -478px -56px;
        width: 8px;
        height: 14px;
        position: relative;
        top: 1px;
        margin-right: 4px;
        display: inline-block; }
    body.page-category #wrapper #left-column .block-categories .block-content li a {
      line-height: 32px;
      padding-left: 0; }
    body.page-category #wrapper #left-column .block-categories .block-content > .category-sub-menu {
      overflow-y: auto;
      max-height: 508px;
      padding-right: 13px; }
      body.page-category #wrapper #left-column .block-categories .block-content > .category-sub-menu li[data-depth="0"] > a {
        font-size: 18px;
        width: 100%;
        display: inline-block;
        line-height: 40px;
        border-bottom: 1px solid #c71b1b;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 25px;
        font-weight: bold;
        overflow: hidden;
        letter-spacing: -0.4px; }
        @media (max-width: 767px) {
          body.page-category #wrapper #left-column .block-categories .block-content > .category-sub-menu li[data-depth="0"] > a {
            max-width: 92%;
            padding-right: 0;
            overflow: hidden; } }
      body.page-category #wrapper #left-column .block-categories .block-content > .category-sub-menu li[data-depth="0"] .category-sub-menu {
        margin-left: 0; }
        body.page-category #wrapper #left-column .block-categories .block-content > .category-sub-menu li[data-depth="0"] .category-sub-menu li {
          border-bottom: 1px solid #c2c2c2;
          padding-left: 15px; }
          body.page-category #wrapper #left-column .block-categories .block-content > .category-sub-menu li[data-depth="0"] .category-sub-menu li a {
            font-size: 14px;
            font-family: 'Open Sans', sans-serif;
            border-bottom: none;
            line-height: 17px;
            max-width: 92%;
            vertical-align: middle;
            padding: 9px 0; }
          body.page-category #wrapper #left-column .block-categories .block-content > .category-sub-menu li[data-depth="0"] .category-sub-menu li:not(.current) > a:before {
            display: none; }
      body.page-category #wrapper #left-column .block-categories .block-content > .category-sub-menu li[data-depth="0"] .fa-angle-down {
        width: 25px;
        height: 25px;
        position: relative;
        top: 2px; }
        body.page-category #wrapper #left-column .block-categories .block-content > .category-sub-menu li[data-depth="0"] .fa-angle-down:before {
          content: "";
          position: absolute;
          display: inline-block;
          width: 18px;
          height: 2px;
          right: 0;
          top: 16px;
          background-color: #c71b1b; }
        body.page-category #wrapper #left-column .block-categories .block-content > .category-sub-menu li[data-depth="0"] .fa-angle-down:after {
          content: "";
          position: absolute;
          display: inline-block;
          width: 2px;
          height: 18px;
          right: 8px;
          top: 8px;
          transform: translateX(14%);
          background-color: #c71b1b; }
      body.page-category #wrapper #left-column .block-categories .block-content > .category-sub-menu li[data-depth="0"] .fa-angle-up {
        width: 25px;
        height: 25px;
        position: relative;
        top: 2px; }
        body.page-category #wrapper #left-column .block-categories .block-content > .category-sub-menu li[data-depth="0"] .fa-angle-up:before {
          content: "";
          position: absolute;
          display: inline-block;
          width: 18px;
          height: 2px;
          right: 0;
          top: 16px;
          background-color: #c71b1b; }
    body.page-category #wrapper #left-column .block-categories .block-content .category-sub-menu .category-sub-menu .collapse.show .category-sub-menu li:last-child {
      border-bottom: none; }
    body.page-category #wrapper #left-column .block-categories._toggled h5:before {
      transform: translateY(-4%) rotate(-135deg); }
    body.page-category #wrapper #left-column .block-categories._toggled .block-content {
      display: none; }
  body.page-category #wrapper #left-column #facets_search_wrapper {
    position: relative;
    top: auto;
    overflow-y: unset; }
    body.page-category #wrapper #left-column #facets_search_wrapper .block-facets {
      margin-bottom: 0; }
    body.page-category #wrapper #left-column #facets_search_wrapper #search_filters #_desktop_search_filters_clear_all button {
      background-color: #c71b1b;
      color: #fff;
      padding-left: 40px;
      width: calc(100% - 12px); }
      body.page-category #wrapper #left-column #facets_search_wrapper #search_filters #_desktop_search_filters_clear_all button .material-icons {
        position: absolute;
        left: 13px;
        font-size: 18px;
        top: 9px; }
    body.page-category #wrapper #left-column #facets_search_wrapper #search_filters .facet {
      margin-bottom: 13px; }
      body.page-category #wrapper #left-column #facets_search_wrapper #search_filters .facet:last-child {
        padding-top: 7px;
        margin-bottom: 0;
        min-height: 118px; }
      body.page-category #wrapper #left-column #facets_search_wrapper #search_filters .facet:not(first-child) .simplebar-vertical {
        padding-left: 20px; }
      body.page-category #wrapper #left-column #facets_search_wrapper #search_filters .facet .h6 {
        margin-bottom: 0;
        position: relative;
        cursor: pointer;
        padding-bottom: 11px;
        padding-top: 9px;
        font-size: 20px;
        border-bottom: solid 2px #c71b1b;
        width: 100%; }
        body.page-category #wrapper #left-column #facets_search_wrapper #search_filters .facet .h6:before {
          content: "";
          position: absolute;
          display: inline-block;
          width: 10px;
          height: 10px;
          border-left: 2px solid #c71b1b;
          border-top: 2px solid #c71b1b;
          right: 3px;
          top: 50%;
          transform: translateY(14%) rotate(45deg);
          transition: 0.2s ease; }
        body.page-category #wrapper #left-column #facets_search_wrapper #search_filters .facet .h6 span {
          font-size: 20px;
          font-weight: 400;
          line-height: 16px;
          color: #000; }
        body.page-category #wrapper #left-column #facets_search_wrapper #search_filters .facet .h6.current:before {
          transform: translateY(14%) rotate(-135deg); }
      body.page-category #wrapper #left-column #facets_search_wrapper #search_filters .facet .current ~ ul {
        display: none !important; }
      body.page-category #wrapper #left-column #facets_search_wrapper #search_filters .facet ul {
        max-height: 186px;
        overflow-y: auto;
        margin-top: 1px; }
        body.page-category #wrapper #left-column #facets_search_wrapper #search_filters .facet ul.faceted-slider {
          overflow-y: unset;
          margin-top: 22px;
          max-width: calc(100% - 12px);
          min-height: 54px; }
          @media (min-width: 768px) {
            body.page-category #wrapper #left-column #facets_search_wrapper #search_filters .facet ul.faceted-slider {
              display: grid; } }
          body.page-category #wrapper #left-column #facets_search_wrapper #search_filters .facet ul.faceted-slider li {
            border: none;
            margin-right: 0;
            position: relative; }
            body.page-category #wrapper #left-column #facets_search_wrapper #search_filters .facet ul.faceted-slider li p {
              position: absolute;
              font-size: 14px;
              line-height: 20px;
              bottom: -6px; }
              @media (max-width: 768px) {
                body.page-category #wrapper #left-column #facets_search_wrapper #search_filters .facet ul.faceted-slider li p {
                  bottom: -44px; } }
            body.page-category #wrapper #left-column #facets_search_wrapper #search_filters .facet ul.faceted-slider li .ui-slider {
              background: #d8d8d8;
              border-color: #d8d8d8;
              border-radius: 5px;
              min-height: 5px; }
              body.page-category #wrapper #left-column #facets_search_wrapper #search_filters .facet ul.faceted-slider li .ui-slider:before {
                content: "";
                position: absolute;
                display: inline-block;
                width: 100%;
                background: #d8d8d8;
                left: 13px;
                border-radius: 5px;
                min-height: 5px;
                height: .4em;
                top: -1px; }
              body.page-category #wrapper #left-column #facets_search_wrapper #search_filters .facet ul.faceted-slider li .ui-slider .ui-slider-range {
                background: #8d1b11;
                min-height: 5px; }
              body.page-category #wrapper #left-column #facets_search_wrapper #search_filters .facet ul.faceted-slider li .ui-slider .ui-slider-handle {
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
                background-color: #c71b1b;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                top: -7px;
                border: unset;
                margin-left: -4px; }
        body.page-category #wrapper #left-column #facets_search_wrapper #search_filters .facet ul li {
          border-bottom: 1px solid #c2c2c2;
          background-color: #fff; }
          body.page-category #wrapper #left-column #facets_search_wrapper #search_filters .facet ul li label {
            margin-bottom: 0; }
            body.page-category #wrapper #left-column #facets_search_wrapper #search_filters .facet ul li label .ps-shown-by-js .material-icons {
              display: none; }
            body.page-category #wrapper #left-column #facets_search_wrapper #search_filters .facet ul li label.active .custom-checkbox .ps-shown-by-js {
              border: 2px solid #c71b1b; }
              body.page-category #wrapper #left-column #facets_search_wrapper #search_filters .facet ul li label.active .custom-checkbox .ps-shown-by-js:before {
                content: "";
                position: absolute;
                display: inline-block;
                width: 12px;
                height: 12px;
                border: 1px solid #fff;
                background-color: #c71b1b;
                left: 0; }
              body.page-category #wrapper #left-column #facets_search_wrapper #search_filters .facet ul li label.active .custom-checkbox .ps-shown-by-js .material-icons {
                display: none; }
            body.page-category #wrapper #left-column #facets_search_wrapper #search_filters .facet ul li label .custom-checkbox {
              top: 0;
              width: 30px;
              float: left;
              line-height: 27px; }
              body.page-category #wrapper #left-column #facets_search_wrapper #search_filters .facet ul li label .custom-checkbox input[type=checkbox] + span {
                border: 2px solid #c2c2c2; }
            body.page-category #wrapper #left-column #facets_search_wrapper #search_filters .facet ul li label a {
              padding-left: 5px;
              line-height: 25px;
              width: calc(100% - 30px);
              display: inline-block;
              padding-right: 20px;
              margin-top: 3px;
              font-family: open sans;
              font-size: 14px;
              background-color: #fff; }
  body.page-category #wrapper #left-column #gim_category_banner {
    position: relative;
    margin-top: 33px; }
    body.page-category #wrapper #left-column #gim_category_banner .banner-content img {
      max-width: 100%; }
    body.page-category #wrapper #left-column #gim_category_banner .banner-content .button-container {
      position: absolute;
      left: 14px;
      bottom: 21px;
      width: calc(100% - 14px); }
      body.page-category #wrapper #left-column #gim_category_banner .banner-content .button-container .elementor-button {
        background: #fff;
        width: 100%;
        max-width: 195px; }
        body.page-category #wrapper #left-column #gim_category_banner .banner-content .button-container .elementor-button .elementor-button-text {
          font-size: 15px;
          color: #c71b1b;
          font-weight: 400;
          line-height: 15px; }
      body.page-category #wrapper #left-column #gim_category_banner .banner-content .button-container:hover .elementor-button {
        background: #c71b1b; }
        body.page-category #wrapper #left-column #gim_category_banner .banner-content .button-container:hover .elementor-button .elementor-button-text {
          color: #fff; }
  body.page-category #wrapper #left-column .elementor-products {
    margin-top: 44px;
    padding-bottom: 50px; }
    body.page-category #wrapper #left-column .elementor-products .product-miniature {
      margin-bottom: 0; }
      body.page-category #wrapper #left-column .elementor-products .product-miniature:hover .product-functional-buttons-bottom {
        bottom: -0.59571rem; }

@media (max-width: 1199px) and (min-width: 768px) {
  body.page-category #wrapper #left-column {
    flex: 0 0 28%;
    max-width: 28%; } }

@media (min-width: 992px) {
  .filter-gim {
    display: none; } }

.filter-gim.remove {
  display: none; }

@media (max-width: 991px) and (min-width: 768px) {
  body.page-category #wrapper .row {
    position: relative; }
  body.page-category #wrapper #left-column {
    flex: 0 0 100%;
    max-width: 236px;
    background-color: #fff;
    position: relative;
    top: 0;
    z-index: 99;
    padding-left: 0;
    padding-right: 0;
    margin-top: 0; }
    body.page-category #wrapper #left-column #facets_search_wrapper {
      max-height: unset; }
    body.page-category #wrapper #left-column .simplebar-vertical {
      background-color: #fff;
      width: 3px; }
      body.page-category #wrapper #left-column .simplebar-vertical:before {
        content: "";
        position: absolute;
        display: inline-block;
        width: 5px;
        height: 100%;
        background-color: #d8d8d8;
        right: 0px; }
    body.page-category #wrapper #left-column .filter-gim-wrapper {
      position: absolute;
      width: 100%;
      background-color: #fff; }
    body.page-category #wrapper #left-column .filter-gim {
      background-color: #e7e7e7;
      border-bottom: 1px solid #c71b1b;
      font-size: 20px;
      font-weight: 400;
      line-height: 43px;
      max-width: 236px;
      padding-left: 20px;
      position: relative; }
      body.page-category #wrapper #left-column .filter-gim:before {
        content: "";
        position: absolute;
        display: inline-block;
        width: 11px;
        height: 11px;
        border-left: 2px solid #c71b1b;
        border-top: 2px solid #c71b1b;
        right: 12px;
        top: 50%;
        transform: translateY(-27%) rotate(-135deg); }
      body.page-category #wrapper #left-column .filter-gim.current:before {
        transform: translateY(4%) rotate(45deg);
        transition: 0.2s ease; }
    body.page-category #wrapper #left-column .filter-gim-wrapper {
      box-shadow: 0 0 34px rgba(0, 0, 0, 0.2);
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 30px;
      display: none; }
    body.page-category #wrapper #left-column .filter-gim.current ~ .filter-gim-wrapper {
      display: block; }
    body.page-category #wrapper #left-column #gim_category_banner {
      display: none; }
    body.page-category #wrapper #left-column .elementor-products {
      display: none; } }

@media (max-width: 767px) {
  body.page-category #wrapper #inner-wrapper .row:nth-child(4) {
    padding-left: 0;
    padding-right: 0; }
    body.page-category #wrapper #inner-wrapper .row:nth-child(4) #content-wrapper {
      padding-left: 0;
      padding-right: 0; }
      body.page-category #wrapper #inner-wrapper .row:nth-child(4) #content-wrapper #js-product-list-top {
        padding-left: 15px;
        padding-right: 15px; }
        body.page-category #wrapper #inner-wrapper .row:nth-child(4) #content-wrapper #js-product-list-top .facated-toggler {
          padding-left: 0;
          padding-right: 0; }
  body.page-category #wrapper #content-wrapper #main #products #js-product-list .js-product-miniature-wrapper .product-miniature {
    margin-left: 0;
    margin-right: 10px; }
  body.page-category #wrapper #content-wrapper #main #products #js-product-list .js-product-miniature-wrapper:nth-child(2n) .product-miniature {
    margin-left: 10px;
    margin-right: 0px; }
  body.page-category #wrapper #left-column {
    flex: 0 0 100%;
    max-width: 100%;
    padding-top: 20px;
    background-color: #f1f1f1;
    margin-top: 40px; }
    body.page-category #wrapper #left-column #facets_search_wrapper {
      max-height: unset;
      padding-bottom: 20px; }
      body.page-category #wrapper #left-column #facets_search_wrapper #search_filter_controls .js-search-filters-clear-all {
        padding: 12px;
        color: #fff;
        padding-left: 18px;
        background-color: #c71b1b; }
        body.page-category #wrapper #left-column #facets_search_wrapper #search_filter_controls .js-search-filters-clear-all i {
          position: relative;
          left: -5px; }
      body.page-category #wrapper #left-column #facets_search_wrapper #search_filter_controls .ok {
        min-width: 130px; }
    body.page-category #wrapper #left-column:not(.-only-facet-search) .hidden-md-up {
      display: none; }
    body.page-category #wrapper #left-column:not(.-only-facet-search) .block-facets {
      display: none; }
    body.page-category #wrapper #left-column .simplebar-vertical {
      background-color: #f1f1f1;
      width: 3px;
      padding-left: 12px; }
    body.page-category #wrapper #left-column .filter-gim {
      display: none; }
    body.page-category #wrapper #left-column #gim_category_banner {
      display: none; }
    body.page-category #wrapper #left-column .elementor-products {
      display: none; }
    body.page-category #wrapper #left-column #search_filters .collapse:not(.show) {
      display: none; }
    body.page-category #wrapper #left-column #search_filters .float-xs-right {
      display: none; }
  body.page-category #wrapper .filter-button {
    max-width: 290px;
    margin: auto; }
  body.page-category #wrapper #search_filter_toggler {
    background-color: #e7e7e7;
    border-bottom: 2px solid #c71b1b;
    margin-bottom: 20px;
    line-height: 31px;
    text-align: left;
    font-size: 20px;
    font-weight: 400;
    position: relative; }
    body.page-category #wrapper #search_filter_toggler:before {
      content: "";
      position: absolute;
      display: inline-block;
      width: 11px;
      height: 11px;
      border-left: 2px solid #c71b1b;
      border-top: 2px solid #c71b1b;
      right: 12px;
      top: 50%;
      transform: translateY(-27%) rotate(-135deg); }
    body.page-category #wrapper #search_filter_toggler i {
      display: none; }
  #left-column.-only-facet-search > :not(#facets_search_wrapper), #right-column.-only-facet-search > :not(#facets_search_wrapper) {
    display: block; }
  #left-column:not(.-only-facet-search) {
    display: none; } }

@media (max-width: 480px) {
  body.page-category #wrapper #left-column #facets_search_wrapper #search_filter_controls .js-search-filters-clear-all {
    width: 100%;
    display: block;
    max-width: 300px;
    margin: auto;
    margin-bottom: 10px;
    font-size: 14px; }
    body.page-category #wrapper #left-column #facets_search_wrapper #search_filter_controls .js-search-filters-clear-all i {
      left: -10px; }
  body.page-category #wrapper #left-column #facets_search_wrapper #search_filter_controls .ok {
    width: 100%;
    display: block;
    max-width: 300px;
    margin: auto;
    font-size: 14px; } }

#iqithtmlandbanners-block-2 {
  font-size: 0.928571rem;
  font-weight: 700; }

@media (max-width: 991px) {
  .elementor-element.elementor-element-robnem6 {
    padding-left: 20px;
    padding-right: 10px; } }

@media (max-width: 767px) {
  .elementor-element.elementor-element-robnem6 {
    padding-left: 15px;
    padding-right: 15px; }
    .elementor-element.elementor-element-robnem6 .elementor-container {
      display: block;
      width: 100%;
      max-width: 500px !important;
      margin: auto; }
      .elementor-element.elementor-element-robnem6 .elementor-container .elementor-row .elementor-column {
        width: calc(50% - 10px);
        padding-right: 10px;
        display: inline-block;
        float: left;
        margin-bottom: 40px; }
        .elementor-element.elementor-element-robnem6 .elementor-container .elementor-row .elementor-column:nth-child(2n) {
          float: right;
          padding-right: 0;
          width: 50%; }
        .elementor-element.elementor-element-robnem6 .elementor-container .elementor-row .elementor-column:nth-child(3) {
          margin-bottom: 0; }
        .elementor-element.elementor-element-robnem6 .elementor-container .elementor-row .elementor-column:nth-child(4) {
          margin-bottom: 0; } }

@media (max-width: 420px) {
  .elementor-element.elementor-element-robnem6 .elementor-container .elementor-row .elementor-column .elementor-column-wrap .elementor-widget-wrap .elementor-widget .elementor-widget-container .elementor-icon-box-wrapper .elementor-icon-box-content .elementor-icon-box-title {
    font-size: 23px; }
  .elementor-element.elementor-element-robnem6 .elementor-container .elementor-row .elementor-column .elementor-column-wrap .elementor-widget-wrap .elementor-widget .elementor-widget-container .elementor-icon-box-wrapper .elementor-icon-box-content .elementor-icon-box-description {
    font-size: 18px; } }

#index .elementor-element-y8xgt18 .elementor-iqit-banner-img img {
  height: 360px;
  object-fit: cover; }

#index .elementor-element-y8xgt18 .elementor-iqit-banner-content {
  padding-left: 15px;
  padding-right: 15px; }
  #index .elementor-element-y8xgt18 .elementor-iqit-banner-content h4 {
    text-align: left;
    line-height: 30px;
    margin-bottom: 19px; }
  #index .elementor-element-y8xgt18 .elementor-iqit-banner-content .elementor-iqit-banner-description {
    text-align: left; }

@media (max-width: 991px) {
  .elementor-element-y8xgt18 .elementor-iqit-banner-content {
    min-height: 189px;
    margin-top: -60px; }
    .elementor-element-y8xgt18 .elementor-iqit-banner-content h4 {
      line-height: 29px; } }

@media (max-width: 767px) {
  #index .elementor-element-y8xgt18 {
    padding-left: 15px;
    padding-right: 15px; }
    #index .elementor-element-y8xgt18 .elementor-column {
      margin-bottom: 20px; } }

@media (max-width: 991px) {
  #index #footer-container-first {
    padding-left: 32px;
    padding-right: 32px; } }

@media (max-width: 576px) {
  #footer-container-first .block-newsletter p {
    line-height: 23px; }
  #footer-container-first .ps-emailsubscription-block .newsletter-input-group {
    margin-top: 0; } }

@media (max-width: 1100px) {
  #elementor .elementor-element-4d50fmy .elementor-banner-align-middle-center {
    padding-left: 40px; } }

#product.top-category-4 #iqitproductsnav a {
  color: #0a2f77; }

#product.top-category-4 #wrapper #gim-product-tags ul li {
  border: 1px solid #0a2f77; }

#product.top-category-4 #inner-wrapper .col-product-image #product-images-thumbs .slick-track button.btn-secondary i:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

#product.top-category-4 #inner-wrapper .col-product-info #lhcom_product_page_average .average_star_position .lhcom_list_reviews .star_display_off,
#product.top-category-4 #inner-wrapper .col-product-info #lhcom_product_page_average .average_star_position .star_display_off {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

#product.top-category-4 #inner-wrapper .col-product-info #lhcom_product_page_average .average_star_position .lhcom_list_reviews .star_display_on,
#product.top-category-4 #inner-wrapper .col-product-info #lhcom_product_page_average .average_star_position .star_display_on {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

#product.top-category-4 #inner-wrapper .col-product-info #lhcom_product_page_average .average_star_position .lhcom_list_reviews .star_display_half,
#product.top-category-4 #inner-wrapper .col-product-info #lhcom_product_page_average .average_star_position .star_display_half {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

#product.top-category-4 #inner-wrapper .col-product-info .product-information .rte-content #see-more-description-product {
  color: #0a2f77; }

#product.top-category-4 #inner-wrapper .col-product-info .product-information .product-prices .has-discount .current-price .product-price {
  color: #0a2f77; }

#product.top-category-4 #inner-wrapper .col-product-info .product-information .product-actions form .product-variants .custom-select2:after {
  color: #0a2f77; }

#product.top-category-4 #inner-wrapper .col-product-info .product-information .product-actions form .product-quantity .qty .input-group-btn-vertical button .touchspin-up:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

#product.top-category-4 #inner-wrapper .col-product-info .product-information .product-actions form .product-add-to-cart .add-to-cart {
  background-color: #0a2f77; }
  #product.top-category-4 #inner-wrapper .col-product-info .product-information .product-actions form .product-add-to-cart .add-to-cart:hover {
    color: #0a2f77; }
    #product.top-category-4 #inner-wrapper .col-product-info .product-information .product-actions form .product-add-to-cart .add-to-cart:hover .fa-shopping-bag {
      background-color: #0a2f77; }

#product.top-category-4 #inner-wrapper .col-product-info .product-information .product-actions form .product-additional-info .see_price {
  background-color: #0a2f77; }
  #product.top-category-4 #inner-wrapper .col-product-info .product-information .product-actions form .product-additional-info .see_price:hover {
    background-color: #fff;
    color: #0a2f77; }

#product.top-category-4 #inner-wrapper .tabs.product-tabs #product-infos-tabs .nav-item .nav-link.active {
  border-top-color: #0a2f77; }

#product.top-category-4 #inner-wrapper .tabs.product-tabs #product-infos-tabs-content ul li:before {
  background-color: #0a2f77; }

#product.top-category-4 #inner-wrapper .tabs.product-tabs #product-infos-tabs-content .product-features table tbody tr td ul li:before {
  background-color: #0a2f77; }

#product.top-category-4 #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .review .rate_star .lhcom_list_reviews .star_display_off,
#product.top-category-4 #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .review .rate_star .star_display_off {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

#product.top-category-4 #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .review .rate_star .lhcom_list_reviews .star_display_on,
#product.top-category-4 #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .review .rate_star .star_display_on {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

#product.top-category-4 #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .review .rate_star .lhcom_list_reviews .star_display_half,
#product.top-category-4 #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .review .rate_star .star_display_half {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

#product.top-category-4 #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .review .review_column .content {
  border-left: 1px solid #0a2f77 !important; }

#product.top-category-4 #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .lhcom_buttons .write .btn-primary {
  background-color: #0a2f77;
  border: 1px solid #0a2f77; }
  #product.top-category-4 #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .lhcom_buttons .write .btn-primary:hover {
    color: #0a2f77; }

#product.top-category-4 #inner-wrapper #product-infos-accordion-mobile .nav-tabs .nav-link {
  border-color: #0a2f77; }
  #product.top-category-4 #inner-wrapper #product-infos-accordion-mobile .nav-tabs .nav-link .fa-angle-up:before {
    background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

#product.top-category-4 #inner-wrapper #product-infos-accordion-mobile .tab-content ul li:before {
  background-color: #0a2f77; }

#product.top-category-4 #inner-wrapper .wrapper-field-line {
  background-color: #0a2f77; }
  #product.top-category-4 #inner-wrapper .wrapper-field-line:before {
    background-color: #0a2f77; }
  #product.top-category-4 #inner-wrapper .wrapper-field-line:after {
    background-color: #0a2f77; }

#product.top-category-4 #form_show_price .wrapp_action_quantity a.up_quantity:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

#product.top-category-4 #form_show_price .wrapp_action_quantity a.down_quantity:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

#product.top-category-4 #form_show_price #sendShowPrice {
  background-color: #0a2f77;
  font-size: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  transition: 0.3s ease; }
  #product.top-category-4 #form_show_price #sendShowPrice:hover {
    background-color: #fff;
    color: #0a2f77; }

@media (max-width: 991px) {
  #product.top-category-4 #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .lhcom_buttons .write .btn-primary {
    background-color: #0a2f77; } }

@media (max-width: 767px) {
  #product.top-category-4 #inner-wrapper #product-infos-accordion-mobile .nav-tabs .nav-link .fa-angle-up:before {
    background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }
  #product.top-category-4 #inner-wrapper #product-infos-accordion-mobile .product-features table tbody tr td ul li:before {
    background-color: #0a2f77; }
  #product.top-category-4 #inner-wrapper #product-infos-accordion-mobile #lhcom_product_page_reviews .review .review_column .content {
    border-left: 1px solid #0a2f77 !important; }
  #product.top-category-4 #inner-wrapper #product-infos-accordion-mobile #lhcom_product_page_reviews .lhcom_buttons .write .btn-primary {
    background-color: #0a2f77;
    border: 1px solid #0a2f77; }
    #product.top-category-4 #inner-wrapper #product-infos-accordion-mobile #lhcom_product_page_reviews .lhcom_buttons .write .btn-primary:hover {
      color: #0a2f77; }
  #gim-product-tags ul li:last-child span {
    border-bottom: 1px solid #0a2f77; }
  #gim-product-tags ul li span {
    border: 1px solid #0a2f77; } }

#product.top-category-220 #iqitproductsnav a {
  color: #8e9197; }

#product.top-category-220 #wrapper #gim-product-tags ul li {
  border: 1px solid #8e9197; }

#product.top-category-220 #inner-wrapper .col-product-image #product-images-thumbs .slick-track button.btn-secondary i:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }

#product.top-category-220 #inner-wrapper .col-product-info #lhcom_product_page_average .average_star_position .lhcom_list_reviews .star_display_off,
#product.top-category-220 #inner-wrapper .col-product-info #lhcom_product_page_average .average_star_position .star_display_off {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

#product.top-category-220 #inner-wrapper .col-product-info #lhcom_product_page_average .average_star_position .lhcom_list_reviews .star_display_on,
#product.top-category-220 #inner-wrapper .col-product-info #lhcom_product_page_average .average_star_position .star_display_on {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

#product.top-category-220 #inner-wrapper .col-product-info #lhcom_product_page_average .average_star_position .lhcom_list_reviews .star_display_half,
#product.top-category-220 #inner-wrapper .col-product-info #lhcom_product_page_average .average_star_position .star_display_half {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

#product.top-category-220 #inner-wrapper .col-product-info .product-information .rte-content #see-more-description-product {
  color: #8e9197; }

#product.top-category-220 #inner-wrapper .col-product-info .product-information .product-prices .has-discount .current-price .product-price {
  color: #8e9197; }

#product.top-category-220 #inner-wrapper .col-product-info .product-information .product-actions form .product-variants .custom-select2:after {
  color: #8e9197; }

#product.top-category-220 #inner-wrapper .col-product-info .product-information .product-actions form .product-quantity .qty .input-group-btn-vertical button .touchspin-up:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }

#product.top-category-220 #inner-wrapper .col-product-info .product-information .product-actions form .product-add-to-cart .add-to-cart {
  background-color: #8e9197; }
  #product.top-category-220 #inner-wrapper .col-product-info .product-information .product-actions form .product-add-to-cart .add-to-cart:hover {
    color: #8e9197; }
    #product.top-category-220 #inner-wrapper .col-product-info .product-information .product-actions form .product-add-to-cart .add-to-cart:hover .fa-shopping-bag {
      background-color: #8e9197; }

#product.top-category-220 #inner-wrapper .col-product-info .product-information .product-actions form .product-additional-info .see_price {
  background-color: #8e9197; }
  #product.top-category-220 #inner-wrapper .col-product-info .product-information .product-actions form .product-additional-info .see_price:hover {
    background-color: #fff;
    color: #8e9197; }

#product.top-category-220 #inner-wrapper .tabs.product-tabs #product-infos-tabs .nav-item .nav-link.active {
  border-top-color: #8e9197; }

#product.top-category-220 #inner-wrapper .tabs.product-tabs #product-infos-tabs-content ul li:before {
  background-color: #8e9197; }

#product.top-category-220 #inner-wrapper .tabs.product-tabs #product-infos-tabs-content .product-features table tbody tr td ul li:before {
  background-color: #8e9197; }

#product.top-category-220 #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .review .rate_star .lhcom_list_reviews .star_display_off,
#product.top-category-220 #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .review .rate_star .star_display_off {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

#product.top-category-220 #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .review .rate_star .lhcom_list_reviews .star_display_on,
#product.top-category-220 #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .review .rate_star .star_display_on {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

#product.top-category-220 #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .review .rate_star .lhcom_list_reviews .star_display_half,
#product.top-category-220 #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .review .rate_star .star_display_half {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_blue.png"); }

#product.top-category-220 #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .review .review_column .content {
  border-left: 1px solid #8e9197 !important; }

#product.top-category-220 #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .lhcom_buttons .write .btn-primary {
  background-color: #8e9197;
  border: 1px solid #8e9197; }
  #product.top-category-220 #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .lhcom_buttons .write .btn-primary:hover {
    color: #8e9197; }

#product.top-category-220 #inner-wrapper #product-infos-accordion-mobile .nav-tabs .nav-link {
  border-color: #8e9197; }
  #product.top-category-220 #inner-wrapper #product-infos-accordion-mobile .nav-tabs .nav-link .fa-angle-up:before {
    background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }

#product.top-category-220 #inner-wrapper #product-infos-accordion-mobile .tab-content ul li:before {
  background-color: #8e9197; }

#product.top-category-220 #inner-wrapper .wrapper-field-line {
  background-color: #8e9197; }
  #product.top-category-220 #inner-wrapper .wrapper-field-line:before {
    background-color: #8e9197; }
  #product.top-category-220 #inner-wrapper .wrapper-field-line:after {
    background-color: #8e9197; }

#product.top-category-220 #form_show_price .wrapp_action_quantity a.up_quantity:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }

#product.top-category-220 #form_show_price .wrapp_action_quantity a.down_quantity:before {
  background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }

#product.top-category-220 #form_show_price #sendShowPrice {
  background-color: #8e9197;
  font-size: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  transition: 0.3s ease; }
  #product.top-category-220 #form_show_price #sendShowPrice:hover {
    background-color: #fff;
    color: #8e9197; }

#gim-product-tags ul li {
  border: 1px solid #8e9197; }

@media (max-width: 991px) {
  #product.top-category-220 #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .lhcom_buttons .write .btn-primary {
    background-color: #8e9197; } }

@media (max-width: 767px) {
  #product.top-category-220 #inner-wrapper #product-infos-accordion-mobile .nav-tabs .nav-link .fa-angle-up:before {
    background-image: url("/themes/gim_warehousechild_bbclub/assets/img/sprite_grey.png"); }
  #product.top-category-220 #inner-wrapper #product-infos-accordion-mobile .product-features table tbody tr td ul li:before {
    background-color: #8e9197; }
  #product.top-category-220 #inner-wrapper #product-infos-accordion-mobile #lhcom_product_page_reviews .review .review_column .content {
    border-left: 1px solid #8e9197 !important; }
  #product.top-category-220 #inner-wrapper #product-infos-accordion-mobile #lhcom_product_page_reviews .lhcom_buttons .write .btn-primary {
    background-color: #8e9197;
    border: 1px solid #8e9197; }
    #product.top-category-220 #inner-wrapper #product-infos-accordion-mobile #lhcom_product_page_reviews .lhcom_buttons .write .btn-primary:hover {
      color: #8e9197; }
  #gim-product-tags ul li:last-child span {
    border-bottom: 1px solid #8e9197; }
  #gim-product-tags ul li span {
    border: 1px solid #8e9197; } }

#product #wrapper .breadcrumb .col.col-auto {
  display: none; }

#product #inner-wrapper {
  margin-top: 65px; }
  #product #inner-wrapper .product-info-row {
    margin-bottom: 20px; }
  #product #inner-wrapper .col-product-image #product-images-thumbs button.btn-secondary {
    font-size: 0;
    background: none;
    border: none;
    padding: 5px 7px; }
    #product #inner-wrapper .col-product-image #product-images-thumbs button.btn-secondary i:before {
      content: "";
      display: inline-block;
      background-image: url(../img/sprite.png);
      background-position: 0px -88px;
      width: 48px;
      height: 48px; }
  #product #inner-wrapper .col-product-image .product-cover .expander {
    display: none; }
  #product #inner-wrapper .col-product-info .product_header_container {
    border-bottom: none;
    margin-bottom: 2px; }
  #product #inner-wrapper .col-product-info .product-reference {
    font-size: 0.857142rem;
    line-height: 10px;
    text-transform: uppercase;
    color: #000;
    font-weight: bold; }
    #product #inner-wrapper .col-product-info .product-reference label {
      margin: 0; }
    #product #inner-wrapper .col-product-info .product-reference span {
      font-size: 1.071428rem; }
  #product #inner-wrapper .col-product-info h1.page-title {
    margin-bottom: 6px;
    margin-top: 16px; }
    #product #inner-wrapper .col-product-info h1.page-title span {
      color: #000;
      font-size: 3rem;
      font-weight: bold;
      line-height: 1; }
  #product #inner-wrapper .col-product-info h4 {
    color: #000;
    font-size: 15px;
    font-weight: 400;
    line-height: 15px;
    text-transform: uppercase; }
  #product #inner-wrapper .col-product-info #lhcom_product_page_average {
    width: 100%;
    display: inline-block;
    margin-top: 11px; }
    #product #inner-wrapper .col-product-info #lhcom_product_page_average .average_note {
      display: none; }
    #product #inner-wrapper .col-product-info #lhcom_product_page_average .average_star_position {
      margin-left: 0; }
      #product #inner-wrapper .col-product-info #lhcom_product_page_average .average_star_position .lhcom_list_reviews .star_display_off,
      #product #inner-wrapper .col-product-info #lhcom_product_page_average .average_star_position .star_display_off {
        background-image: url(../img/sprite.png);
        background-position: -420px -118px;
        width: 18px;
        height: 18px; }
      #product #inner-wrapper .col-product-info #lhcom_product_page_average .average_star_position .lhcom_list_reviews .star_display_on,
      #product #inner-wrapper .col-product-info #lhcom_product_page_average .average_star_position .star_display_on {
        background-image: url(../img/sprite.png);
        background-position: -420px -60px;
        width: 18px;
        height: 18px; }
      #product #inner-wrapper .col-product-info #lhcom_product_page_average .average_star_position .lhcom_list_reviews .star_display_half,
      #product #inner-wrapper .col-product-info #lhcom_product_page_average .average_star_position .star_display_half {
        background-image: url(../img/sprite.png);
        background-position: -420px -176px;
        width: 18px;
        height: 18px; }
  #product #inner-wrapper .col-product-info .product-information {
    max-width: 460px; }
    #product #inner-wrapper .col-product-info .product-information .rte-content {
      color: #000;
      font-family: "Open Sans";
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 20px; }
      #product #inner-wrapper .col-product-info .product-information .rte-content #see-more-description-product {
        color: #c71b1b;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 400;
        display: inline; }
        #product #inner-wrapper .col-product-info .product-information .rte-content #see-more-description-product:before {
          content: ' '; }
        #product #inner-wrapper .col-product-info .product-information .rte-content #see-more-description-product.margin-top {
          margin: 0; }
    #product #inner-wrapper .col-product-info .product-information .product-prices {
      border-bottom: 2px solid #e7e7e7;
      padding-bottom: 26px; }
      #product #inner-wrapper .col-product-info .product-information .product-prices .tax-shipping-delivery-label {
        display: none; }
      #product #inner-wrapper .col-product-info .product-information .product-prices .has-discount .current-price {
        display: inline-block;
        margin-right: 13px; }
        #product #inner-wrapper .col-product-info .product-information .product-prices .has-discount .current-price .product-price {
          color: #d23024;
          font-size: 40px;
          font-weight: 400;
          line-height: 40px; }
        #product #inner-wrapper .col-product-info .product-information .product-prices .has-discount .current-price .offer-label {
          color: #000;
          font-size: 15px;
          font-weight: 400;
          line-height: 15px;
          text-transform: uppercase;
          display: block;
          margin-top: 7px; }
        #product #inner-wrapper .col-product-info .product-information .product-prices .has-discount .current-price .eshop-label {
          color: #000;
          font-size: 15px;
          font-weight: 400;
          line-height: 15px;
          text-transform: uppercase;
          display: block;
          margin-top: 7px; }
      #product #inner-wrapper .col-product-info .product-information .product-prices .current-price {
        display: inline-block;
        margin-right: 13px; }
        #product #inner-wrapper .col-product-info .product-information .product-prices .current-price .product-price {
          color: #000;
          font-size: 40px;
          font-weight: 400;
          line-height: 40px; }
        #product #inner-wrapper .col-product-info .product-information .product-prices .current-price .offer-label {
          color: #000;
          font-size: 15px;
          font-weight: 400;
          line-height: 15px;
          text-transform: uppercase;
          display: block;
          margin-top: 7px; }
        #product #inner-wrapper .col-product-info .product-information .product-prices .current-price .eshop-label {
          color: #000;
          font-size: 15px;
          font-weight: 400;
          line-height: 15px;
          text-transform: uppercase;
          display: block;
          margin-top: 7px; }
      #product #inner-wrapper .col-product-info .product-information .product-prices .product-discount {
        display: inline-block;
        margin-right: 13px; }
        #product #inner-wrapper .col-product-info .product-information .product-prices .product-discount .regular-price {
          color: #000;
          font-size: 40px;
          font-weight: 400;
          line-height: 40px;
          text-decoration: none; }
        #product #inner-wrapper .col-product-info .product-information .product-prices .product-discount .eshop-label {
          color: #000;
          font-size: 15px;
          font-weight: 400;
          line-height: 15px;
          text-transform: uppercase;
          display: block;
          margin-top: 7px; }
      #product #inner-wrapper .col-product-info .product-information .product-prices .physical-shop-price-container {
        display: inline-block; }
        #product #inner-wrapper .col-product-info .product-information .product-prices .physical-shop-price-container .physical-shop-price {
          color: #000;
          font-size: 21px;
          font-weight: 400;
          line-height: 18px;
          text-decoration: line-through; }
        #product #inner-wrapper .col-product-info .product-information .product-prices .physical-shop-price-container .eshop-label {
          color: #000;
          font-size: 15px;
          font-weight: 400;
          line-height: 15px;
          text-transform: uppercase;
          display: block;
          margin-top: 7px; }
      #product #inner-wrapper .col-product-info .product-information .product-prices .badge-discount {
        display: none; }
    #product #inner-wrapper .col-product-info .product-information .product-actions {
      width: 100%;
      display: inline-block; }
      #product #inner-wrapper .col-product-info .product-information .product-actions form {
        float: left;
        margin-right: 5px; }
        #product #inner-wrapper .col-product-info .product-information .product-actions form .product-variants {
          float: left;
          margin-right: 23px; }
          #product #inner-wrapper .col-product-info .product-information .product-actions form .product-variants .form-control-label {
            color: #000;
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            margin-top: 12px;
            padding-bottom: 6px; }
          #product #inner-wrapper .col-product-info .product-information .product-actions form .product-variants .custom-select2 select {
            border: 2px solid #c2c2c2;
            padding: 4px 1rem;
            line-height: 20px;
            padding-right: 41px;
            color: #9b9b9b;
            font-family: "Open Sans";
            font-size: 13px;
            line-height: 20px;
            height: auto; }
            #product #inner-wrapper .col-product-info .product-information .product-actions form .product-variants .custom-select2 select:focus {
              box-shadow: none; }
          #product #inner-wrapper .col-product-info .product-information .product-actions form .product-variants .custom-select2:after {
            color: #c71b1b;
            font-size: 25px;
            height: auto;
            top: 50%;
            transform: translateY(-50%);
            bottom: auto; }
        #product #inner-wrapper .col-product-info .product-information .product-actions form .product-quantity {
          float: left;
          margin-top: .5rem; }
          #product #inner-wrapper .col-product-info .product-information .product-actions form .product-quantity .qty-label {
            padding-bottom: 6px;
            padding-top: .8rem;
            color: #000;
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            display: block;
            margin-top: 4px; }
          #product #inner-wrapper .col-product-info .product-information .product-actions form .product-quantity .qty {
            width: 63px;
            float: left;
            border: 2px solid #c2c2c2;
            top: 1px;
            position: relative; }
            #product #inner-wrapper .col-product-info .product-information .product-actions form .product-quantity .qty #quantity_wanted {
              padding-top: 5px;
              padding-bottom: 4px;
              padding-left: 6px;
              padding-right: 6px;
              text-align: center;
              border: none; }
            #product #inner-wrapper .col-product-info .product-information .product-actions form .product-quantity .qty .input-group-btn-vertical button {
              padding: 5px 12px;
              border: none; }
              #product #inner-wrapper .col-product-info .product-information .product-actions form .product-quantity .qty .input-group-btn-vertical button:hover {
                background-color: #fff; }
              #product #inner-wrapper .col-product-info .product-information .product-actions form .product-quantity .qty .input-group-btn-vertical button .touchspin-up {
                top: 0px; }
                #product #inner-wrapper .col-product-info .product-information .product-actions form .product-quantity .qty .input-group-btn-vertical button .touchspin-up:before {
                  background-image: url(../img/sprite.png);
                  background-position: -478px -160px;
                  width: 12px;
                  height: 8px;
                  content: "";
                  display: inline-block; }
              #product #inner-wrapper .col-product-info .product-information .product-actions form .product-quantity .qty .input-group-btn-vertical button .touchspin-down {
                top: -1px; }
        #product #inner-wrapper .col-product-info .product-information .product-actions form .product-add-to-cart {
          width: 100%;
          display: inline-block;
          padding-top: 7px !important; }
          #product #inner-wrapper .col-product-info .product-information .product-actions form .product-add-to-cart .btn-iqitcompare-add {
            display: none; }
          #product #inner-wrapper .col-product-info .product-information .product-actions form .product-add-to-cart .btn-iqitwishlist-add {
            display: none; }
          #product #inner-wrapper .col-product-info .product-information .product-actions form .product-add-to-cart .add-to-cart {
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
            background-color: #c71b1b;
            min-width: 260px;
            padding: 0 1.5rem;
            font-size: 15px;
            line-height: 36px;
            padding-right: 66px;
            text-transform: uppercase;
            padding-top: 4px;
            text-transform: uppercase; }
            #product #inner-wrapper .col-product-info .product-information .product-actions form .product-add-to-cart .add-to-cart .fa-shopping-bag {
              position: absolute;
              right: 3px;
              top: 0;
              background-color: #fff;
              width: 40px;
              height: 40px;
              margin-right: 0;
              z-index: 99;
              transition: background-color 0.2s cubic-bezier(0.7, 0, 0.3, 1); }
              #product #inner-wrapper .col-product-info .product-information .product-actions form .product-add-to-cart .add-to-cart .fa-shopping-bag:before {
                top: 50%;
                position: absolute;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 1;
                transition: opacity 0.2s cubic-bezier(0.7, 0, 0.3, 1); }
              #product #inner-wrapper .col-product-info .product-information .product-actions form .product-add-to-cart .add-to-cart .fa-shopping-bag:after {
                content: "";
                top: 50%;
                position: absolute;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
                background-image: url(../img/sprite.png);
                background-position: -214px -260px;
                width: 24px;
                height: 24px;
                transition: opacity 0.2s cubic-bezier(0.7, 0, 0.3, 1); }
            #product #inner-wrapper .col-product-info .product-information .product-actions form .product-add-to-cart .add-to-cart:hover {
              background-color: #fff;
              color: #c71b1b; }
              #product #inner-wrapper .col-product-info .product-information .product-actions form .product-add-to-cart .add-to-cart:hover .fa-shopping-bag {
                background-color: #c71b1b; }
                #product #inner-wrapper .col-product-info .product-information .product-actions form .product-add-to-cart .add-to-cart:hover .fa-shopping-bag:before {
                  opacity: 0; }
                #product #inner-wrapper .col-product-info .product-information .product-actions form .product-add-to-cart .add-to-cart:hover .fa-shopping-bag:after {
                  opacity: 1; }
        #product #inner-wrapper .col-product-info .product-information .product-actions form .product-additional-info {
          border: none; }
          #product #inner-wrapper .col-product-info .product-information .product-actions form .product-additional-info .see_price {
            width: auto;
            font-size: 15px;
            background-color: #c71b1b;
            margin-top: 0;
            margin-bottom: 10px;
            min-width: 262px;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
            transition: 0.3s ease; }
            #product #inner-wrapper .col-product-info .product-information .product-actions form .product-additional-info .see_price:hover {
              background-color: #fff;
              color: #c71b1b; }
      #product #inner-wrapper .col-product-info .product-information .product-actions .after-product-actions {
        width: calc(22% - 5px);
        float: right;
        margin-left: 5px;
        margin-top: 19px; }
        #product #inner-wrapper .col-product-info .product-information .product-actions .after-product-actions .wrapper-field-eof .eof-banner {
          width: 76px;
          height: 76px;
          background-image: url(../img/Bitmap.png);
          background-repeat: no-repeat;
          float: right; }
  #product #inner-wrapper .col-product-info .scroll-icons > a {
    display: inline-block;
    text-transform: uppercase;
    padding: 5px;
    font-size: 1.2rem; }
    #product #inner-wrapper .col-product-info .scroll-icons > a:hover {
      color: #c71b1b; }
    #product #inner-wrapper .col-product-info .scroll-icons > a:last-child {
      margin-right: 0; }
    #product #inner-wrapper .col-product-info .scroll-icons > a#nutrition-button {
      line-height: 44px; }
    #product #inner-wrapper .col-product-info .scroll-icons > a#useful-button {
      line-height: 46px; }
  #product #inner-wrapper .tabs.product-tabs #product-infos-tabs {
    margin-bottom: 0;
    border: none; }
    #product #inner-wrapper .tabs.product-tabs #product-infos-tabs .nav-item {
      margin-right: 0; }
      #product #inner-wrapper .tabs.product-tabs #product-infos-tabs .nav-item .nav-link {
        border-bottom: none;
        padding: 11px 20px;
        color: #000;
        font-size: 20px;
        opacity: 1;
        transition: border-top-width 0.1s cubic-bezier(0.7, 0, 0.3, 1), background-color 0.1s cubic-bezier(0.7, 0, 0.3, 1); }
        #product #inner-wrapper .tabs.product-tabs #product-infos-tabs .nav-item .nav-link.active {
          border-top: 3px solid #c71b1b;
          background-color: #fff;
          font-weight: bold; }
  #product #inner-wrapper .tabs.product-tabs #product-infos-tabs-content {
    box-shadow: 0 2px 64px rgba(0, 0, 0, 0.15);
    padding: 19px;
    padding-top: 44px;
    padding-bottom: 38px; }
    #product #inner-wrapper .tabs.product-tabs #product-infos-tabs-content img {
      max-width: 100%; }
    #product #inner-wrapper .tabs.product-tabs #product-infos-tabs-content .product-features table tbody tr td {
      border: none;
      padding: 0; }
      #product #inner-wrapper .tabs.product-tabs #product-infos-tabs-content .product-features table tbody tr td ul li {
        display: inline-block;
        position: relative;
        margin-right: 20px; }
        #product #inner-wrapper .tabs.product-tabs #product-infos-tabs-content .product-features table tbody tr td ul li:before {
          content: "";
          position: absolute;
          display: inline-block;
          width: 2px;
          height: 15px;
          background-color: #c71b1b;
          right: -11px;
          top: 50%;
          left: auto;
          transform: translateY(-39%) rotate(30deg); }
        #product #inner-wrapper .tabs.product-tabs #product-infos-tabs-content .product-features table tbody tr td ul li:last-child:before {
          display: none; }
    #product #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .review .rate_star {
      margin-bottom: 8px; }
      #product #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .review .rate_star .lhcom_list_reviews .star_display_off,
      #product #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .review .rate_star .star_display_off {
        background-image: url(../img/sprite.png);
        background-position: -420px -118px;
        width: 18px;
        height: 18px; }
      #product #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .review .rate_star .lhcom_list_reviews .star_display_on,
      #product #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .review .rate_star .star_display_on {
        background-image: url(../img/sprite.png);
        background-position: -420px -60px;
        width: 18px;
        height: 18px; }
      #product #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .review .rate_star .lhcom_list_reviews .star_display_half,
      #product #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .review .rate_star .star_display_half {
        background-image: url(../img/sprite.png);
        background-position: -420px -176px;
        width: 18px;
        height: 18px; }
    #product #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .review .review_column .content {
      border-left: 1px solid #c71b1b !important; }
    #product #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .review .review_column .review_column .review_text {
      margin-top: 0; }
    #product #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .review .review_column .review_column .lhcom_usefulness_like_dislike .vote-like a i {
      color: #149a14; }
    #product #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .review .review_column .review_column .lhcom_usefulness_like_dislike .vote-dislike a i {
      color: #c71b1b; }
    #product #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .lhcom_buttons .write .btn-primary {
      background-color: #c71b1b;
      color: #fff;
      border: 1px solid #c71b1b;
      transition: 0.3s ease; }
      #product #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .lhcom_buttons .write .btn-primary:hover {
        background-color: #fff;
        color: #c71b1b; }
    #product #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .lhcom_buttons .view .all-product-link span i {
      top: 7px;
      position: relative; }
  #product #inner-wrapper #product-infos-accordion-mobile img {
    max-width: 100%; }
  #product #inner-wrapper #product-infos-accordion-mobile .nav-tabs .nav-link {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    padding: 8px 10px; }
  #product #inner-wrapper #product-infos-accordion-mobile .tab-content ul li {
    list-style: none;
    position: relative; }
    #product #inner-wrapper #product-infos-accordion-mobile .tab-content ul li:before {
      content: "";
      position: absolute;
      display: inline-block;
      width: 15px;
      height: 1px;
      background-color: #c71b1b;
      left: -23px;
      top: 10px; }
  #product #inner-wrapper .wrapper-field-line {
    background-color: #c71b1b;
    width: 100%;
    display: none;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    padding-bottom: 58px;
    padding-top: 60px;
    margin-bottom: 24px; }
    @media (max-width: 1245px) {
      #product #inner-wrapper .wrapper-field-line {
        padding-left: 20px;
        padding-right: 20px; } }
    #product #inner-wrapper .wrapper-field-line.show {
      display: inline-block; }
    #product #inner-wrapper .wrapper-field-line:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #c71b1b;
      left: -100%;
      top: 0; }
    #product #inner-wrapper .wrapper-field-line:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #c71b1b;
      right: -100%;
      top: 0; }
    #product #inner-wrapper .wrapper-field-line .field-line-inner .line-title {
      color: #fff;
      font-size: 50px;
      text-align: center;
      font-weight: 400;
      line-height: 40px;
      padding-bottom: 24px; }
    #product #inner-wrapper .wrapper-field-line .field-line-inner .box-line {
      padding-top: 75px; }
      @media (min-width: 768px) {
        #product #inner-wrapper .wrapper-field-line .field-line-inner .box-line.text-md-center .box-line-title:before {
          left: 50%;
          transform: translateX(-50%); } }
      #product #inner-wrapper .wrapper-field-line .field-line-inner .box-line .box-line-title {
        font-size: 28px;
        font-weight: 400;
        line-height: 32px;
        color: #fff;
        position: relative;
        margin-bottom: 14px; }
        #product #inner-wrapper .wrapper-field-line .field-line-inner .box-line .box-line-title:before {
          content: "";
          position: absolute;
          left: 0; }
      #product #inner-wrapper .wrapper-field-line .field-line-inner .box-line .box-line-text {
        color: #fff;
        font-family: "Open Sans";
        font-weight: 300;
        font-size: 14px;
        line-height: 20px; }
      #product #inner-wrapper .wrapper-field-line .field-line-inner .box-line.box-line-1 .box-line-title:before {
        top: -56px;
        background-image: url(../img/sprite.png);
        background-position: -262px 0px;
        width: 41px;
        height: 46px; }
      #product #inner-wrapper .wrapper-field-line .field-line-inner .box-line.box-line-2 .box-line-title:before {
        top: -54px;
        background-image: url(../img/sprite.png);
        background-position: -176px 0px;
        width: 46px;
        height: 46px; }
      #product #inner-wrapper .wrapper-field-line .field-line-inner .box-line.box-line-3 .box-line-title:before {
        top: -49px;
        background-image: url(../img/sprite.png);
        background-position: 0px -260px;
        width: 46px;
        height: 36px; }
  #product #inner-wrapper .crossselling-products .block-content .slick-arrow {
    top: auto;
    bottom: 15px;
    right: auto;
    transform: translate(-50%, 100%);
    width: 40px;
    height: 40px;
    display: inline-block;
    cursor: pointer; }
    #product #inner-wrapper .crossselling-products .block-content .slick-arrow.slick-prev {
      padding: 0;
      left: calc(50% - 21px); }
    #product #inner-wrapper .crossselling-products .block-content .slick-arrow.slick-next {
      padding: 0;
      left: calc(50% + 21px); }
  #product #inner-wrapper .product-accessories {
    margin-top: 100px; }
    #product #inner-wrapper .product-accessories .section-title {
      color: #000;
      font-size: 50px;
      font-weight: 400;
      line-height: 40px;
      margin-bottom: 4px;
      border: none; }
    #product #inner-wrapper .product-accessories .block-content .slick-arrow {
      top: auto;
      bottom: 15px;
      right: auto;
      transform: translate(-50%, 100%);
      width: 40px;
      height: 40px;
      display: inline-block;
      opacity: 1;
      cursor: pointer; }
      #product #inner-wrapper .product-accessories .block-content .slick-arrow.slick-prev {
        padding: 0;
        left: calc(50% - 21px); }
      #product #inner-wrapper .product-accessories .block-content .slick-arrow.slick-next {
        padding: 0;
        left: calc(50% + 21px); }

#product #footer {
  margin-top: 68px; }

#product #form_show_price {
  background: #fff;
  border-radius: 0; }
  #product #form_show_price input,
  #product #form_show_price textarea {
    border: 2px solid  #c2c2c2; }
  #product #form_show_price input[name="quantity"] {
    padding: 0 6px; }
  #product #form_show_price label {
    color: #000;
    font-weight: 700; }
  #product #form_show_price .wrapp_action_quantity {
    border: 2px solid  #c2c2c2;
    border-left: none; }
    #product #form_show_price .wrapp_action_quantity a {
      background-color: #fff;
      padding: 5px; }
      #product #form_show_price .wrapp_action_quantity a.up_quantity:before {
        background-image: url(../img/sprite.png);
        background-position: -478px -160px;
        width: 12px;
        height: 8px; }
      #product #form_show_price .wrapp_action_quantity a.down_quantity:before {
        background-image: url(../img/sprite.png);
        background-position: -478px -208px;
        width: 12px;
        height: 8px; }
  #product #form_show_price #sendShowPrice {
    background-color: #c71b1b;
    font-size: 15px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    transition: 0.3s ease; }
    #product #form_show_price #sendShowPrice:hover {
      background-color: #fff;
      color: #c71b1b; }

#iqitproductsnav {
  margin-top: 10px; }
  #iqitproductsnav a {
    color: #c71b1b;
    font-size: 24px;
    font-weight: 400;
    margin-left: 10px; }

#gim-product-tags {
  margin-bottom: 62px; }
  #gim-product-tags ul {
    text-align: center;
    width: fit-content;
    overflow: hidden;
    margin: auto;
    display: block;
    padding-left: 1px;
    padding-right: 1px; }
    #gim-product-tags ul li {
      border: 1px solid #c71b1b;
      padding: 0px 10px;
      float: left;
      margin-left: -1px;
      margin-bottom: 1px; }
      #gim-product-tags ul li:first-child {
        margin-left: 0; }
      #gim-product-tags ul li span {
        color: #000;
        font-size: 13px;
        line-height: 20px;
        padding: 5.5px 10px;
        display: inline-block;
        cursor: default;
        text-transform: uppercase; }

@media (max-width: 991px) {
  #product #inner-wrapper .wrapper-field-line {
    padding-left: 20px;
    padding-right: 13px; } }

@media (max-width: 767px) {
  #product #inner-wrapper {
    margin-top: 47px; }
    #product #inner-wrapper .product-info-row {
      margin-bottom: 2px; }
    #product #inner-wrapper .col-product-image #product-images-thumbs .slick-track button.btn-secondary {
      padding-bottom: 0px;
      padding-top: 0px; }
      #product #inner-wrapper .col-product-image #product-images-thumbs .slick-track button.btn-secondary i:before {
        content: "";
        display: inline-block;
        background-image: url(../img/sprite.png);
        background-position: 0px -88px;
        width: 48px;
        height: 48px; }
    #product #inner-wrapper .col-product-info {
      margin-top: 10px; }
      #product #inner-wrapper .col-product-info .product-information .rte-content {
        margin-bottom: 20px; }
      #product #inner-wrapper .col-product-info .product-information .product-prices {
        padding-bottom: 30px; }
      #product #inner-wrapper .col-product-info .product-information .product-actions form {
        width: 100%;
        margin-right: 0; }
        #product #inner-wrapper .col-product-info .product-information .product-actions form .product-variants .form-control-label {
          margin-top: 8px; }
        #product #inner-wrapper .col-product-info .product-information .product-actions form .product-variants .custom-select2 select {
          max-width: 139px; }
        #product #inner-wrapper .col-product-info .product-information .product-actions form .product-quantity {
          margin-top: 3px; }
          #product #inner-wrapper .col-product-info .product-information .product-actions form .product-quantity .qty-label {
            margin-top: 4px; }
          #product #inner-wrapper .col-product-info .product-information .product-actions form .product-quantity .qty .input-group {
            height: 28px; }
          #product #inner-wrapper .col-product-info .product-information .product-actions form .product-quantity .qty .input-group-btn-vertical button .touchspin-up {
            top: 2px; }
          #product #inner-wrapper .col-product-info .product-information .product-actions form .product-quantity .qty .input-group-btn-vertical button .touchspin-down {
            top: 0px; }
        #product #inner-wrapper .col-product-info .product-information .product-actions form .product-add-to-cart .add-to-cart {
          width: 260px;
          position: relative; }
          #product #inner-wrapper .col-product-info .product-information .product-actions form .product-add-to-cart .add-to-cart .fa-shopping-bag {
            right: 0px; }
      #product #inner-wrapper .col-product-info .product-information .product-actions .after-product-actions {
        margin-top: 24px;
        margin-right: 0;
        margin-left: 19px;
        float: left; }
        #product #inner-wrapper .col-product-info .product-information .product-actions .after-product-actions .wrapper-field-eof .eof-banner {
          width: 53px;
          height: 53px;
          background-size: 100%; }
    #product #inner-wrapper .tabs.product-tabs {
      margin-top: 0; }
      #product #inner-wrapper .tabs.product-tabs #product-infos-tabs-content #lhcom_product_page_reviews .review .review_column .content {
        border-bottom: none; }
    #product #inner-wrapper #product-infos-accordion-mobile {
      box-shadow: 0 2px 64px rgba(0, 0, 0, 0.1); }
      #product #inner-wrapper #product-infos-accordion-mobile .nav-tabs {
        border-bottom: none; }
        #product #inner-wrapper #product-infos-accordion-mobile .nav-tabs .nav-link {
          background-color: #fff; }
          #product #inner-wrapper #product-infos-accordion-mobile .nav-tabs .nav-link .fa-angle-up {
            top: 0px; }
            #product #inner-wrapper #product-infos-accordion-mobile .nav-tabs .nav-link .fa-angle-up:before {
              background-image: url(../img/sprite.png);
              background-position: -478px -160px;
              width: 12px;
              height: 8px;
              content: "";
              display: inline-block; }
      #product #inner-wrapper #product-infos-accordion-mobile .tab-content {
        padding: 10px 20px;
        font-size: 14px;
        padding-bottom: 15px; }
        #product #inner-wrapper #product-infos-accordion-mobile .tab-content#product-infos-accordion-mobile-0 {
          padding-top: 38px; }
        #product #inner-wrapper #product-infos-accordion-mobile .tab-content .rte-content {
          font-size: 14px;
          line-height: 20px; }
      #product #inner-wrapper #product-infos-accordion-mobile .product-features table tbody tr td {
        border: none;
        padding: 0; } }
      @media (max-width: 767px) and (max-width: 480px) {
        #product #inner-wrapper #product-infos-accordion-mobile .product-features table tbody tr td {
          width: 100%;
          display: inline-block;
          margin-bottom: 5px; } }

@media (max-width: 767px) {
        #product #inner-wrapper #product-infos-accordion-mobile .product-features table tbody tr td ul li {
          display: inline-block;
          position: relative;
          margin-right: 20px; }
          #product #inner-wrapper #product-infos-accordion-mobile .product-features table tbody tr td ul li:before {
            content: "";
            position: absolute;
            display: inline-block;
            width: 2px;
            height: 15px;
            background-color: #c71b1b;
            right: -11px;
            top: 50%;
            transform: translateY(-39%) rotate(30deg); }
          #product #inner-wrapper #product-infos-accordion-mobile .product-features table tbody tr td ul li:last-child:before {
            display: none; }
      #product #inner-wrapper #product-infos-accordion-mobile #lhcom_product_page_reviews .review .rate_star {
        margin-bottom: 8px; }
        #product #inner-wrapper #product-infos-accordion-mobile #lhcom_product_page_reviews .review .rate_star .lhcom_list_reviews .star_display_off,
        #product #inner-wrapper #product-infos-accordion-mobile #lhcom_product_page_reviews .review .rate_star .star_display_off {
          background-image: url(../img/sprite.png);
          background-position: -420px -118px;
          width: 18px;
          height: 18px; }
        #product #inner-wrapper #product-infos-accordion-mobile #lhcom_product_page_reviews .review .rate_star .lhcom_list_reviews .star_display_on,
        #product #inner-wrapper #product-infos-accordion-mobile #lhcom_product_page_reviews .review .rate_star .star_display_on {
          background-image: url(../img/sprite.png);
          background-position: -420px -60px;
          width: 18px;
          height: 18px; }
        #product #inner-wrapper #product-infos-accordion-mobile #lhcom_product_page_reviews .review .rate_star .lhcom_list_reviews .star_display_half,
        #product #inner-wrapper #product-infos-accordion-mobile #lhcom_product_page_reviews .review .rate_star .star_display_half {
          background-image: url(../img/sprite.png);
          background-position: -420px -176px;
          width: 18px;
          height: 18px; }
      #product #inner-wrapper #product-infos-accordion-mobile #lhcom_product_page_reviews .review .review_column .content {
        border-left: 1px solid #c71b1b !important; } }
      @media (max-width: 767px) and (max-width: 767px) {
        #product #inner-wrapper #product-infos-accordion-mobile #lhcom_product_page_reviews .review .review_column .content {
          border-bottom: none; } }

@media (max-width: 767px) {
      #product #inner-wrapper #product-infos-accordion-mobile #lhcom_product_page_reviews .review .review_column .review_column .review_text {
        margin-top: 0; }
      #product #inner-wrapper #product-infos-accordion-mobile #lhcom_product_page_reviews .review .review_column .review_column .lhcom_usefulness_like_dislike .vote-like a i {
        color: #149a14;
        font-family: 'Material Icons'; }
      #product #inner-wrapper #product-infos-accordion-mobile #lhcom_product_page_reviews .review .review_column .review_column .lhcom_usefulness_like_dislike .vote-dislike a i {
        color: #c71b1b;
        font-family: 'Material Icons'; }
      #product #inner-wrapper #product-infos-accordion-mobile #lhcom_product_page_reviews .lhcom_buttons .write .btn-primary {
        background-color: #c71b1b;
        color: #fff;
        border: 1px solid #c71b1b;
        transition: 0.3s ease; }
        #product #inner-wrapper #product-infos-accordion-mobile #lhcom_product_page_reviews .lhcom_buttons .write .btn-primary:hover {
          background-color: #fff;
          color: #c71b1b; }
      #product #inner-wrapper #product-infos-accordion-mobile #lhcom_product_page_reviews .lhcom_buttons .view .all-product-link span i {
        top: 7px;
        font-family: 'Material Icons';
        position: relative; }
      #product #inner-wrapper #product-infos-accordion-mobile .lhcom_list_reviews .review {
        border-bottom: none; }
    #product #inner-wrapper .wrapper-field-line {
      padding-top: 55px;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 48px;
      margin-bottom: 5px; }
      #product #inner-wrapper .wrapper-field-line .field-line-inner .line-title {
        line-height: 48px; }
  #gim-product-tags {
    margin-bottom: 37px;
    width: 100%;
    display: inline-block; }
    #gim-product-tags ul {
      text-align: left;
      float: left; }
      #gim-product-tags ul li {
        display: block;
        border: none;
        padding: 0;
        margin-top: -2px;
        float: unset; }
        #gim-product-tags ul li:first-child {
          margin-top: 0; }
        #gim-product-tags ul li:last-child span {
          border-bottom: 1px solid #c71b1b; }
        #gim-product-tags ul li span {
          border: 1px solid #c71b1b;
          padding: 6px 10px; } }

@media (max-width: 420px) {
  #product #inner-wrapper .col-product-info .product-information .product-prices .physical-shop-price {
    width: 100%;
    display: block;
    bottom: auto;
    margin-top: 10px; } }

#js-delivery .img-thumbnail {
  width: 41px;
  border: none;
  background-color: transparent; }

.datetimepicker.dropdown-menu {
  opacity: initial;
  visibility: initial;
  transform: initial;
  min-width: initial;
  display: none;
  transition: none; }

div#onepagecheckoutps label {
  cursor: pointer; }

div#onepagecheckoutps input[type="radio"] {
  border-radius: 50% !important;
  cursor: pointer; }
  div#onepagecheckoutps input[type="radio"]:checked {
    border: 3px solid #fff;
    padding: 4px; }

div#onepagecheckoutps input[type="checkbox"] {
  border-radius: 4px !important;
  cursor: pointer; }
  div#onepagecheckoutps input[type="checkbox"]:checked {
    background-color: transparent;
    background-image: url("../img/sprite.png");
    background-position: -478px -110px; }

#opc_modal {
  background: none;
  z-index: 2030; }

.elementor-element.elementor-element-ld271sr {
  margin-left: -10px;
  margin-right: -10px;
  box-shadow: 0 2px 64px rgba(0, 0, 0, 0.1); }
  .elementor-element.elementor-element-ld271sr .elementor-column:first-child {
    width: 300px; }
  .elementor-element.elementor-element-ld271sr .elementor-column:last-child {
    width: calc(100% - 300px); }

.elementor-element-ley8393 .elementor-column-wrap {
  position: relative; }

.elementor-element-ley8393 .elementor-element-syvhn55,
.elementor-element-ley8393 .elementor-widget-wrap,
.elementor-element-ley8393 .slick-list,
.elementor-element-ley8393 .slick-slider {
  position: initial; }

.elementor-element-ley8393 .slick-slider.slick-arrows-above .slick-arrow {
  transform: translate(-50%, 50%); }

.elementor-image-carousel .slick-slide > div {
  line-height: 0; }

@media (max-width: 991px) {
  .elementor-element.elementor-element-ld271sr {
    padding-left: 25px; }
  .daily-offer .product-miniature.product-miniature-layout-1 .product-title {
    margin-bottom: 22px; }
  .daily-offer .product-miniature.product-miniature-layout-1 .product-price-and-shipping {
    margin-bottom: 3px; }
  #daily-offer-timer {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 24px; } }

@media (max-width: 767px) {
  .elementor-element-8m18no1 .elementor-element-populated {
    padding-left: 0;
    padding-right: 0; }
    .elementor-element-8m18no1 .elementor-element-populated .tabs-container {
      padding-left: 15px;
      padding-right: 15px; }
      .elementor-element-8m18no1 .elementor-element-populated .tabs-container h2 {
        margin-bottom: 30px; }
      .elementor-element-8m18no1 .elementor-element-populated .tabs-container.active .allarea-tabs-mobile .nav.nav-tabs {
        display: block;
        background-color: #fff; }
        .elementor-element-8m18no1 .elementor-element-populated .tabs-container.active .allarea-tabs-mobile .nav.nav-tabs li {
          margin-right: 0; }
          .elementor-element-8m18no1 .elementor-element-populated .tabs-container.active .allarea-tabs-mobile .nav.nav-tabs li:first-child {
            border: none; }
          .elementor-element-8m18no1 .elementor-element-populated .tabs-container.active .allarea-tabs-mobile .nav.nav-tabs li a {
            border-bottom: 1px solid #c71b1b;
            padding: 10px;
            width: 100%;
            display: inline-block; }
        .elementor-element-8m18no1 .elementor-element-populated .tabs-container.active .allarea-tabs-mobile .nav.nav-tabs .nav-link .tab-title-part:before {
          display: none; }
        .elementor-element-8m18no1 .elementor-element-populated .tabs-container.active .allarea-tabs-mobile .nav.nav-tabs .nav-link .tab-title-part:after {
          display: none; }
        .elementor-element-8m18no1 .elementor-element-populated .tabs-container.active .allarea-tabs-mobile .nav.nav-tabs .nav-link.show.active {
          display: none; }
      .elementor-element-8m18no1 .elementor-element-populated .tabs-container.active .allarea-tabs-mobile .title-tabs-mobile:before {
        transform: translateY(-50%) rotate(45deg); }
      .elementor-element-8m18no1 .elementor-element-populated .tabs-container .allarea-tabs-mobile {
        margin-bottom: 44px; }
        .elementor-element-8m18no1 .elementor-element-populated .tabs-container .allarea-tabs-mobile .title-tabs-mobile {
          font-size: 20px;
          border-bottom: 2px solid #c2c2c2;
          width: 100%;
          display: inline-block;
          padding-bottom: 15px;
          font-family: 'PFBulletinSansPro-Regular', sans-serif;
          position: relative; }
          .elementor-element-8m18no1 .elementor-element-populated .tabs-container .allarea-tabs-mobile .title-tabs-mobile:before {
            content: '';
            position: absolute;
            top: 30%;
            transform: translateY(-50%) rotate(-134deg);
            width: 11px;
            height: 11px;
            border-left: 2px solid #c71b1b;
            border-top: 2px solid #c71b1b;
            right: 0px;
            transition: .3s ease;
            opacity: 1; }
        .elementor-element-8m18no1 .elementor-element-populated .tabs-container .allarea-tabs-mobile .nav.nav-tabs {
          display: none; }
  .elementor-element.elementor-element-ld271sr {
    padding-left: 10px;
    padding-right: 10px; }
  #elementor-section-wrap .elementor-element.elementor-element-ld271sr .elementor-column:first-child {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #f5f2f2;
    padding-bottom: 60px; }
    #elementor-section-wrap .elementor-element.elementor-element-ld271sr .elementor-column:first-child .elementor-column-wrap {
      max-width: 300px;
      margin: auto; }
  #elementor-section-wrap .elementor-element.elementor-element-ld271sr .elementor-column:last-child {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 20px; }
    #elementor-section-wrap .elementor-element.elementor-element-ld271sr .elementor-column:last-child .elementor-widget-container {
      margin-bottom: 23px; }
      #elementor-section-wrap .elementor-element.elementor-element-ld271sr .elementor-column:last-child .elementor-widget-container h2.elementor-heading-title {
        padding-left: 15px;
        padding-right: 15px; }
  .daily-offer .product-miniature.product-miniature-layout-1 .product-title {
    margin-bottom: 22px; }
  .daily-offer .product-miniature.product-miniature-layout-1 .product-price-and-shipping {
    margin-bottom: 3px; }
  #daily-offer-timer {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 24px; } }

#content-hook_payment_return a {
  color: #c71b1b;
  transition: color 0.2s cubic-bezier(0.7, 0, 0.3, 1); }
  #content-hook_payment_return a:hover {
    color: #8d1b11; }

#content-hook_payment_return dl dt {
  flex-basis: 35%;
  margin-left: 0; }

#content-hook_payment_return dl dd {
  flex-basis: 55%; }
