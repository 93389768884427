#checkout{
  .velo-field-preferred-date{
    width: 100%;
    label{
      text-align: left;
      float: left;
    }
    #kb_pickup_select_date{
      width: 100%;
      max-width: 180px;
      margin-top: 5px;
      float: left;
    }
  }
  #kb_pts_carrier_block .velo-location-list .mp-openday-list .mp-openday-list-title{
    width: 86px;
    float: left;
  }
  #kb_pts_carrier_block .velo-location-list .mp-openday-list .mp-openday-list-value{
    width: calc(100% - 86px);
    float: right;
  }
}
@media(max-width: 1180px){
  #checkout{
    #kb_pts_carrier_block{
      .velo-search-bar{
        width: 100%;
        label,input{
          width: 100%;
          text-align: left;
        }
      }
      .velo-search-distance{
        min-width: 89px;
      }
      .velo-search-button{
        margin-right: 4px;
      }
    }
  }
}
@media(max-width: 950px) and (min-width: 768px){
  #checkout{
    .col-md-4.col-sm-5.col-xs-12.col-12{
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-md-8.col-sm-7.col-xs-12.col-12{
      flex: 0 0 50%;
      max-width: 50%;
      #onepagecheckoutps_step_two_container{
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 20px;
      }
      .velo-search-distance{
        text-align: left;
      }
      .velo-search-bar{
        margin-bottom: 10px;
      }
      .velo-search-button{
        width: 35px;
      }
      #onepagecheckoutps_step_three_container{
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}
@media(max-width: 768px){
  #checkout{
    .col-md-4.col-sm-5.col-xs-12.col-12{
      flex: 0 0 100%;
      max-width: 100%;
    }
    .col-md-8.col-sm-7.col-xs-12.col-12{
      flex: 0 0 100%;
      max-width: 100%;
      #onepagecheckoutps_step_two_container{
        flex: 0 0 100%;
        max-width: 100%;
      }
      #onepagecheckoutps_step_three_container{
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 30px!important;
      }
    }
    div#onepagecheckoutps #onepagecheckoutps_step_review_container{
      margin-top: 15px;
    }
    #kb_pts_carrier_block{
      .velo-search-bar{
        width: 50%;
        #velo_address_search{
          padding: 6px;
        }
      }
      .velo-search-distance{
        width: 100px;
      }
      .velo-search-button{
        width: 35px;
        margin-right: 0;
      }
    }
  }
}
@media(max-width: 425px){
  #checkout{

    #kb_pts_carrier_block{
      .velo-search-bar{
        width: 100%;
        margin-bottom: 10px;
        label{
          margin-bottom: 5px;
        }
        input{
          width: calc(100% - 16px);
          text-align: left;
          float: left;
        }
      }
      .velo-search-distance{
        label{
          margin-bottom: 5px;
        }
        text-align: left;
        width: 89px;
      }
      .velo-search-button{
        margin-top: 23px;
      }
      .velo-location-list{
        label{
          margin-bottom: 5px;
        }
        #kb_pickup_selected_store{
          width: calc(100% - 16px);
        }
      }
    }
  }
}